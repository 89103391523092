import { LinearFilter, RGBAFormat, WebGLRenderTarget, MeshBasicMaterial, Vector2, ShaderMaterial, Mesh } from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { ClearPass } from 'three/examples/jsm/postprocessing/ClearPass.js';
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass.js';

import Experience from "./Experience";
import EventEmitter from './Utils/EventEmitter.js';

let isMobile;

export default class Composer extends EventEmitter
{
    // Set constructor
    constructor(BACKGROUND_OPTION)
    {
        // Extends the EventEmitter class
        super(); 

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.renderer = this.experience.renderer;
        this.environment = this.experience.environment;
        this.backgrounds = this.experience.backgrounds;

        // Set background option
        this.BACKGROUND_OPTION = BACKGROUND_OPTION;

        // Set composer
        if(!this.experience.INTERRUPTED) this.setComposer();
    }

    // Method called to create and set up the composer
    setComposer()
    {
        // Create the render pass
        this.renderScene = new RenderPass( this.scene, this.camera.renderCamera );
        this.renderScene.renderToScreen = true;

        // Set rendering parameters
        let renderTargetParameters = { minFilter: LinearFilter, magFilter: LinearFilter, format: RGBAFormat, stencilBuffer: true };
        let renderTarget = new WebGLRenderTarget(this.sizes.width, this.sizes.height, renderTargetParameters);
        // Create the composer
        this.instance = new EffectComposer( this.renderer.instance, renderTarget );

        // Set size and pixel ratio
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);

        // Add render pass to the composer
        if(!this.experience.INTERRUPTED) this.instance.addPass( this.renderScene );

        // If the render quality is medium or high
        if(this.experience.parameters.QUALITY > 0)
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                this.#setBloomPass();
                // Add bloom pass to the composer
                this.instance.addPass( this.bloomPass );
            }

            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the render quality is high
                if(this.experience.parameters.QUALITY === 2)
                {
                    this.#setSSAOPass();
                    // Add SSAO pass to the composer
                    this.instance.addPass( this.ssaoPass );
                }
            }
        }

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            this.#setGammaCorrectionPass();
            // Add gamma correction pass to the composer
            this.instance.addPass( this.gammaCorrectionPass );
        }

        // Add FXAA pass to the composer if the render quality is medium or high
        if(this.experience.parameters.QUALITY > 0)
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Get the mobile detector class from the experience
                this.mobileDetector = this.experience.mobileDetector;
                // Get if the device is a mobile
                isMobile = this.mobileDetector.isMobile;
                // Remove reference
                this.mobileDetector = null;
                
                // If the device is not a mobile
                if(isMobile === false)
                {
                    this.#setFXAAPass();
                    // Add FXAA pass to the composer
                    this.instance.addPass( this.fxaaPass );
                }
            }

            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                this.#setVignettePass();
                // Add vignette pass to the composer
                this.instance.addPass( this.vignettePass );
            }

            // If the render quality is high and the background option isn't transparent
            if(this.experience.parameters.QUALITY === 2 && this.BACKGROUND_OPTION != 2)
            {
                // Set pitch black material
                this.darkMaterial = new MeshBasicMaterial({ color: 0x000000 });
                
                if(!this.experience.INTERRUPTED) this.#setBloomComposer();
                if(!this.experience.INTERRUPTED) this.#setFinalPass();
                
                // Add final pass to the composer
                if(!this.experience.INTERRUPTED) this.instance.addPass( this.finalPass );
            }
        }
    }

    // Method called to clear all the passes
    clearComposer()
    {
        // If the passes array was created
        if(this.instance.passes instanceof Array)
        {
            // For each of the composer passes
            this.instance.passes.forEach(pass =>
            {
                // Remove pass
                try { this.instance.removePass( pass ) } catch(e) { console.log(e) };
            });
        }

        // If there is a bloom composer
        if(this.bloomComposer)
        {
            // For each of the composer passes
            this.bloomComposer.passes.forEach(pass =>
            {
                // Remove pass
                try { this.bloomComposer.removePass( pass ) } catch(e) { console.log(e) };
            });
        }
    }

    // Method called by the screenshot to add or remove the final pass from the composer
    addOrRemoveFinalPass(remove)
    {
        // If the final pass is to be removed
        if(remove === true)
        {
            // For each of the composer passes
            this.instance.passes.forEach(pass =>
            {
                // Remove the final pass
                if(pass === this.finalPass)
                    try { this.instance.removePass( pass ) } catch(e) { console.log(e) };
            });
        }
        // If the final pass is to be added
        else
        {
            // Add final pass to the composer
            if(!this.experience.INTERRUPTED) this.instance.addPass( this.finalPass );
        }
    }

    // Private method called to set up the SSAO pass
    #setSSAOPass()
    {
        // Set the ambient occlusion pass
        this.ssaoPass = new SSAOPass( this.scene, this.camera.renderCamera, this.sizes.width, this.sizes.height );
        this.ssaoPass.kernelRadius = 16;
        this.ssaoPass.minDistance = 0.001;
        this.ssaoPass.maxDistance = 0.3;
        this.ssaoPass.output = SSAOPass.OUTPUT.Default;
        this.ssaoPass.transparent = true;
    }

    // Private method called to set up the Gamma Correction pass
    #setGammaCorrectionPass()
    {
        // Set gamma correction pass for color balancing
        this.gammaCorrectionPass = new ShaderPass( GammaCorrectionShader );
        this.gammaCorrectionPass.material.name = "GammaCorrectionPass";
    }

    // Private method called to set up the FXAA pass
    #setFXAAPass()
    {
        // Set the fxaa pass configurations for antialias
        this.fxaaPass = new ShaderPass( FXAAShader );
        this.fxaaPass.material.name = "FXAAPass";
        this.fxaaPass.uniforms['resolution'].value.set(1 / (this.sizes.width * this.sizes.pixelRatio), 1 / (this.sizes.height * this.sizes.pixelRatio));
    }

    // Private method called to set up the Bloom pass
    #setBloomPass()
    {
        // Set the world bloom pass
        this.bloomPass = new UnrealBloomPass( new Vector2( this.sizes.width, this.sizes.height ), 1.5, 0.4, 0.85 );
        this.bloomPass.threshold = 0;
        this.bloomPass.strength = 0.08;
        this.bloomPass.radius = 0.5;
    }

    // Private method called to set up the Vignette pass
    #setVignettePass()
    {
        // Create vertex shader
        const vShader = `
            varying vec2 vUv;
            void main()
            {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `;

        // Create fragment shader
        const fShader = `
            uniform float offset;
            uniform float darkness;
            uniform sampler2D tDiffuse;

            varying vec2 vUv;

            void main()
            {
                vec4 texel = texture2D( tDiffuse, vUv );
                vec2 uv = ( vUv - vec2( 0.5 ) ) * vec2( offset );
                gl_FragColor = vec4( mix( texel.rgb, vec3( 1.0 - darkness ), dot( uv, uv ) ), texel.a );
            }
        `;

        // Create vignette shader material
        const vignette = new ShaderMaterial(
        {
            uniforms:
            {
                tDiffuse: { type: 't', value: null },
                offset:   { type: 'f', value: 1.0 },
                darkness: { type: 'f', value: 1.0 }
            },
            vertexShader: vShader,
            fragmentShader: fShader
        });

        // Set the vignette pass configurations
        this.vignettePass = new ShaderPass( vignette );
        this.vignettePass.material.name = "VignettePass";
        this.vignettePass.material.uniforms['offset'].value = 1;
        this.vignettePass.material.uniforms['darkness'].value = 1.15;
    }

    // Private method called to set up the Clear pass
    #setClearPass()
    {
        // Set clear pass
        this.clearPass = new ClearPass(0x000000, 0);
    }

    // Private method called to set up the Hologram Bloom pass
    #setHologramBloomPass()
    {
        // Set the selective bloom pass
        this.hologramBloomPass = new UnrealBloomPass( new Vector2( this.sizes.width, this.sizes.height ), 1.5, 0.4, 0.85 );
        this.hologramBloomPass.threshold = 0;
        this.hologramBloomPass.strength = 0.2;
        this.hologramBloomPass.radius = 0.1;
    }

    // Private method called to set up the Bloom pass
    #setBloomComposer()
    {
        // Create a new composer for the selective bloom pass
        this.bloomComposer = new EffectComposer( this.renderer.instance );
        this.bloomComposer.renderToScreen = false;

        // Set exclusive passes
        this.#setClearPass();
        this.#setHologramBloomPass();
        // Add passes to the bloom composer
        this.bloomComposer.addPass( this.clearPass );
        this.bloomComposer.addPass( this.renderScene );
        this.bloomComposer.addPass( this.hologramBloomPass );
    }

    // Private method called to set up the custom final pass
    #setFinalPass()
    {
        // Set the final pass
        this.finalPass = new ShaderPass(new ShaderMaterial(
        {
            uniforms:
            {
                baseTexture: { value: null },
                bloomTexture: { value: this.bloomComposer.renderTarget2.texture }
            },
            vertexShader: `
                varying vec2 vUv;
                void main()
                {
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }
            `,
            fragmentShader: `
                uniform sampler2D baseTexture;
                uniform sampler2D bloomTexture;
                varying vec2 vUv;
                void main()
                {
                    gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
                }
            `,
            defines: {}
        } ), "baseTexture" );
        this.finalPass.needsSwap = true;
        this.finalPass.material.name = "FinalPass";
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Set new size and pixel ratio
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);

        // If there is a bloom composer, update it
        if(this.bloomComposer)
        {
            this.bloomComposer.setSize(this.sizes.width, this.sizes.height);
            this.bloomComposer.setPixelRatio(this.sizes.pixelRatio);
        }

        // Update fxaa pass resolution
        if(this.fxaaPass) this.fxaaPass.uniforms['resolution'].value.set(1 / (this.sizes.width * this.sizes.pixelRatio), 1 / (this.sizes.height * this.sizes.pixelRatio));
    }

    // Method propagated by the experience each tick event
    update()
    {
        // If the render quality is high and the background option isn't transparent
        if(this.experience.parameters.QUALITY === 2 && this.BACKGROUND_OPTION != 2)
        {
            let originalMaterials = [], count = 0;

            // Traverse all objects in the scene to set the materials that shouldn't glow to pitch black
            this.scene.traverse((obj) =>
            {
                // If the object is a mesh
                if(obj instanceof Mesh)
                {
                    // Deactivate the totems circle
                    if(obj.material.name === "glowing_circle")
                    {
                        obj.visible = false;
                    }

                    // If the material isn't null
                    if(obj.material !== null)
                    {
                        // If the material has a name
                        if(obj.material.name !== null)
                        {
                            // If the name isn't from the objects that must glow or are already deactivated
                            if(obj.material.name !== "luz" && obj.material.name !== "glowing_circle")
                            {
                                // Save original material
                                originalMaterials[count] = obj.material;
                                // Apply black material
                                obj.material = this.darkMaterial;
                                // Add to count
                                count++;
                            }
                        }
                        // If the material doesn't have a name
                        else
                        {
                            // Save original material
                            originalMaterials[count] = obj.material;
                            // Apply black material
                            obj.material = this.darkMaterial;
                            // Add to count
                            count++;
                        }
                    }
                }
            });

            // If the background option is the 2D plane
            if(this.BACKGROUND_OPTION === 1)
            {
                if(!this.backgrounds) this.backgrounds = this.experience.backgrounds;

                // Deactivate background
                this.scene.background = null;
                // Deactivate ground plane
                this.backgrounds.groundPlane.visible = false;
            }

            // Render the selective bloom composer
            this.bloomComposer.render();
            // Reset count
            count = 0;

            // If the background option is the 2D plane
            if(this.BACKGROUND_OPTION === 1)
            {
                // Activate background
                this.environment.setEnvironmentBackground();
                // Activate ground plane
                this.backgrounds.groundPlane.visible = true;
            }

            // Traverse all objects in the scene to restore materials to their original configuration
            this.scene.traverse((obj) =>
            {
                // If the object is a mesh
                if(obj instanceof Mesh)
                {
                    // Activate the totems circle
                    if(obj.material.name === "glowing_circle")
                    {
                        obj.visible = true;
                    }

                    // If the material isn't null
                    if(obj.material !== null)
                    {
                        // If the material has a name
                        if(obj.material.name !== null)
                        {
                            // If the name isn't from the objects that haven't changed
                            if(obj.material.name !== "luz" && obj.material.name !== "glowing_circle")
                            {
                                // Apply original material
                                obj.material = originalMaterials[count];
                                // Add to count
                                count++;
                            }
                        }
                        // If the material doesn't have a name
                        else
                        {
                            // Apply original material
                            obj.material = originalMaterials[count];
                            // Add to count
                            count++;
                        }
                    }
                }
            });
        }

        // Render
        this.instance.render();
    }

    
    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Clear composer
        this.clearComposer();

        // Reset variables
        this.renderScene = null;
        this.bloomComposer = null;
        this.instance = null;
        isMobile = null;

        // Remove passes references
        if(this.darkMaterial) this.darkMaterial = null;
        if(this.bloomPass) this.bloomPass = null;
        if(this.ssaoPass) this.ssaoPass = null;
        if(this.gammaCorrectionPass) this.gammaCorrectionPass = null;
        if(this.fxaaPass) this.fxaaPass = null;
        if(this.vignettePass) this.vignettePass = null;
        if(this.clearPass) this.clearPass = null;
        if(this.hologramBloomPass) this.hologramBloomPass = null;
        if(this.finalPass) this.finalPass = null;

        // Remove references
        this.experience = null;
        this.sizes = null;
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.environment = null;
        this.backgrounds = null;
    }
}