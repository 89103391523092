import EventEmitter from './EventEmitter.js';

export default class Sizes extends EventEmitter
{
    // Set constructor
    constructor(canvas)
    {
        // Extends the EventEmitter class
        super();

        // Get the canvas size
        this.canvasWidth = canvas.offsetWidth;
        this.canvasHeight = canvas.offsetHeight;

        // Get canvas margin
        let rect = canvas.getBoundingClientRect();
        // Get canvas sizes and pixel ratio
        this.width = rect.width;
        this.height = rect.height;
        this.pixelRatio = Math.min(window.devicePixelRatio, 2);
    }

    // Resize event handler
    resizeHandler(canvas)
    {
        // Get canvas margin
        let rect = canvas.getBoundingClientRect();

        // If the canvas sizes changed
        if((this.width !== rect.width) || (this.height !== rect.height))
        {
            // Get the new canvas offset sizes
            this.canvasWidth = canvas.offsetWidth;
            this.canvasHeight = canvas.offsetHeight;

            // Get new canvas sizes and pixel ratio
            this.width = rect.width;
            this.height = rect.height;
            this.pixelRatio = Math.min(window.devicePixelRatio, 2);
            
            // Trigger resize event
            this.trigger('resize');
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Reset variables
        this.width = null;
        this.height = null;
        this.canvasWidth = null;
        this.canvasHeight = null;
        this.pixelRatio = null;
    }
}