import * as THREE from "three";
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';

import Experience from "../Experience.js";
import EventEmitter from "../Utils/EventEmitter.js";
import Stand from "./Stand.js";
import NPC from "../Characters/NPC.js";

let switching, delta;
let glassMat;
let doorOpen;

export default class Pavilion extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.time = this.experience.time;
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.resources = this.experience.resources;
        this.audio = this.experience.audio;
        this.environment = this.experience.environment;
        this.colliders = this.experience.colliders;
        this.minimap = this.experience.minimap;

        // Default value
        switching = false;
        doorOpen = false;

        // Create instance
        this.instance = {};
        // Create arrays
        this.instance.stands = [];
        this.instance.npcs = [];
        this.instance.bullets = [];
        // Set loading variable
        this.loaded = false;

        this.#initJSONfile();

        // Create local clock for the animations
        this.clock = new THREE.Clock();

        // Listen to when the resources are loaded
        this.resources.on('loadedResources', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Get the player class from the experience
                this.player = this.experience.player;

                try
                {
                    // Set environment lights
                    this.environment.setHemisphereLight();
                    this.environment.setDirectionalLight();
                    // Set environment background
                    this.environment.setEnvironmentBackground();
                    // If the render quality is medium or high
                    if(this.experience.parameters.QUALITY > 0)
                    {
                        // Set environment map
                        this.environment.setEnvironmentMap();
                    }
                }
                catch(e) { console.log(e) };

                // Set common walls
                if(!this.experience.INTERRUPTED) this.#setWalls();
                // Set listeners
                if(!this.experience.INTERRUPTED) this.#setListeners();

                // Trigger event warning that the basic elements finished loading
                if(!this.experience.INTERRUPTED) this.trigger('loaded3DScene');
            }
        });
    }

    // Private method called to initialize the local JSON object
    #initJSONfile()
    {
        // JSON object containing the pavilion customization info
        this.instance.info =
        {
            "pavilion_id": 0,
            "pavilion_name": "",
            "bullets_color": "",

            "sections":
            [
                {
                    "setion_id": 0,
                    "section_style": "",
                    "section_name": ""
                }
            ]
        };

        // JSON object containing the stands customization info
        this.instance.standsInfo = { "stands": [] };
    }

    // Method called to update the pavilion
    updateObjectsByJSON(jsonObj, startingSection, playerPosition, playerRotation, npcsQuantity, maleNpcsPercentage, staticNpcsPercentage)
    {
        // Set instance JSON file
        this.instance.info = jsonObj;

        // Save player info
        this.saved = {
            playerPosition: playerPosition,
            playerRotation: playerRotation
        };

        let arrayPos = 0;
        // If the section id value is invalid
        if(startingSection !== "" && startingSection !== null && startingSection !== undefined)
        {
            // For each of the sections
            for(let i = 0; i < this.instance.info.sections.length; i++)
            {
                // If the section id is the same as the starting section id
                if(this.instance.info.sections[i].section_id === startingSection)
                {
                    // Get the array position
                    arrayPos = i;
                    break;
                }
            }
        }

        // If the npcs quantity value is invalid
        if(npcsQuantity === "" || npcsQuantity === null || npcsQuantity === undefined || isNaN(npcsQuantity))
        {
            console.log('Invalid value for the quantity of NPCs: ' + npcsQuantity + '. Using default quantity instead (50).')
            // Set default value
            npcsQuantity = 50;
        }
        this.npcsQuantity = npcsQuantity;

        // If the npcs male percentage value is invalid
        if(maleNpcsPercentage === "" || maleNpcsPercentage === null || maleNpcsPercentage === undefined || isNaN(maleNpcsPercentage))
        {
            console.log('Invalid value for the percentage of male NPCs: ' + maleNpcsPercentage + '. Using default quantity instead (50%).')
            // Set default value
            maleNpcsPercentage = 50;
        }
        this.maleNpcsPercentage = maleNpcsPercentage;

        // If the static npcs percentage value is invalid
        if(staticNpcsPercentage === "" || staticNpcsPercentage === null || staticNpcsPercentage === undefined || isNaN(staticNpcsPercentage))
        {
            console.log('Invalid value for the percentage of static NPCs: ' + staticNpcsPercentage + '. Using default quantity instead (30%).')
            // Set default value
            staticNpcsPercentage = 30;
        }
        this.staticNpcsPercentage = staticNpcsPercentage;

        // Set the bullet mesh
        if(!this.experience.INTERRUPTED) this.#setBulletMesh(this.instance.info.bullets_color);
        // Create minimap
        if(!this.experience.INTERRUPTED) this.minimap.setMinimap(this.instance.info.sections, arrayPos);

        // Set the pavilion name to the wall canvas
        if(!this.experience.INTERRUPTED) this.#setPavilionName(this.instance.info.sections[arrayPos].section_name);
        // Set the first section
        if(!this.experience.INTERRUPTED) this.#setPavilionSection(this.instance.info.sections[arrayPos].section_id, this.instance.info.sections[arrayPos].section_style);
    }

    // Method called to update the section stands
    updateStandsByJSON(jsonObj)
    {
        // Set instance JSON file
        this.instance.standsInfo = jsonObj;

        // If the section model is loaded
        if(this.instance.model !== undefined)
        {
            // Set the stands to this section
            if(!this.experience.INTERRUPTED) try { this.#setStands() } catch(e) { console.log(e) };
            // Set colliders
            if(!this.experience.INTERRUPTED) try { this.#setColliders() } catch(e) { console.log(e) };

            // Trigger event warning that the stands are loaded, so the player can get the full array
            setTimeout(() => {
                // If the experience is still active
                if(this.experience)
                {
                    // If the experience wasn't interrupted
                    if(!this.experience.INTERRUPTED)
                    {
                        this.loaded = true;
                        this.trigger('standsReady');
                    }
                }
            }, 1000);
        }
    }

    // Private method called to set up the bullets mesh
    #setBulletMesh(hexcode)
    {
        // If the hexcode is empty, set the default color
        if(hexcode === "" || hexcode === null || hexcode === undefined) hexcode = "0xfe5101";
        // If the hexcode is on the wrong format, set to correct format
        if(hexcode.slice(0, 1) === '#') hexcode = '0x' + hexcode.slice(1);

        // Create the inner circle, whose color is customizable
        let innerMat = new THREE.MeshBasicMaterial({ map: this.resources.items.bullet_inner, transparent: true, side: THREE.DoubleSide, opacity: 0.9 });
        innerMat.map.encoding = THREE.sRGBEncoding;
        innerMat.encoding = THREE.sRGBEncoding;
        // Set color hexcode
        innerMat.color.setHex(hexcode).convertSRGBToLinear();
        innerMat.needsUpdate = true;

        // Create the outer circle, permanently white
        let outerMat = new THREE.MeshBasicMaterial({ map: this.resources.items.bullet_outer, transparent: true, side: THREE.DoubleSide, opacity: 0.9 });
        outerMat.map.encoding = THREE.sRGBEncoding;
        outerMat.encoding = THREE.sRGBEncoding;

        // Create the plane geometry
        let geometry = new THREE.PlaneGeometry(5, 5);
        // Create groups to load this materials, from the first pixel to the infinity
        geometry.addGroup( 0, Infinity, 0 );
        geometry.addGroup( 0, Infinity, 1 );

        // Set the bullet materials
        this.instance.bulletMesh = new THREE.Mesh(geometry, [innerMat, outerMat]);
        this.instance.bulletMesh.renderOrder = 1;
        this.instance.bulletMesh.material[0].depthTest = false;
        this.instance.bulletMesh.material[1].depthTest = false;
        this.instance.bulletMesh.material.needsUpdate = true;
    }

    // Method called to set the walls used by all the sections
    #setWalls()
    {
        // Set glass material
        glassMat = new THREE.MeshPhysicalMaterial({
            metalness: 0.9,
            roughness: 0.05,
            envMapIntensity: 0.1,
            clearcoat: 1,
            transparent: true,
            opacity: 0.5,
            reflectivity: 0.2,
            refractionRatio: 0.985,
            ior: 0.9,
            side: THREE.BackSide,
        });

        // Create walls instance
        this.walls = {};

        // Create roof and colliders arrays
        this.walls.roof = [];
        this.walls.colliders = [];
        this.walls.totemTexts = [];
        this.walls.totemColors = [];
        this.walls.doorFrames = [];
        this.walls.doorNames = { "local": [], "neighbor": [] };
        this.walls.doors = [];
        this.walls.color = null;
        this.walls.nameScreen = null;

        // Get model from the resources
        let gltf = this.resources.items['section_walls'];
        // Get walls model
        this.walls.model = SkeletonUtils.clone(gltf.scene);

        try
        {
            // Set the animations object
            this.walls.animations = {};
            this.walls.animations.doors = {};
            // Set animation mixer
            this.walls.animations.mixer = new THREE.AnimationMixer(this.walls.model);
            // Get the doors animations
            this.walls.animations.doors.n = this.walls.animations.mixer.clipAction(gltf.animations[24]);
            this.walls.animations.doors.s = this.walls.animations.mixer.clipAction(gltf.animations[28]);
            this.walls.animations.doors.w = this.walls.animations.mixer.clipAction(gltf.animations[32]);
            this.walls.animations.doors.e = this.walls.animations.mixer.clipAction(gltf.animations[36]);
            // Set animations loop and clamp
            Object.keys(this.walls.animations.doors).forEach((key) =>
            {
                this.walls.animations.doors[key].setLoop(THREE.LoopOnce);
                this.walls.animations.doors[key].clampWhenFinished = true;
            });

            // Set the active door object
            this.walls.animations.activeDoor = { "pos": null, "action": null };
        }
        catch(e) { console.log(e) };

        // Reset variable
        gltf = null;

        // Go through all the model's children
        this.walls.model.traverse((child) =>
        {
            // If the child is a roof piece
            if(child.name === 'roof' || child.name === 'supporting')
            {
                // If the first person camera is active, activate roof
                if(this.camera.FIRST_PERSON_CAM === true) child.visible = true;
                // If the third person camera is active, deactivate roof
                else child.visible = false;
                
                // Add to the roof array
                this.walls.roof.push(child);
            }
            // If the child is a color plane from the central totem
            else if(child.name.split('_')[0] === 'pav')
            {
                // Get the respective id
                let id = 0;
                if(child.name === 'pav_s') id = 1;
                else if(child.name === 'pav_w') id = 2;
                else if(child.name === 'pav_e') id = 3;

                // Add to the totem colors array
                this.walls.totemColors[id] = child;
            }
            // If the child is a text plane from the central totem
            else if(child.name.split('_')[0] === 'pavName' || child.name === 'pavNameScreen_totem')
            {
                // Get the respective id
                let id = 0;
                if(child.name === 'pavName_n') id = 1;
                else if(child.name === 'pavName_s') id = 2;
                else if(child.name === 'pavName_w') id = 3;
                else if(child.name === 'pavName_e') id = 4;

                // Add to the totem texts array
                this.walls.totemTexts[id] = child;
            }
            // If the child is a door frame
            else if(child.name.split('_')[0] === 'portalDoor')
            {
                // Get the respective id
                let id = 0;
                if(child.name === 'portalDoor_s') id = 1;
                else if(child.name === 'portalDoor_w') id = 2;
                else if(child.name === 'portalDoor_e') id = 3;

                // Add to the door frames array
                this.walls.doorFrames[id] = child;
            }
            // If the child is a door name screen
            else if(child.name.split('_')[0] === 'doorNameScreen')
            {
                // Get the type of screen
                let type = 'neighbor';
                if(child.name.includes('local')) type = 'local';

                // Get the respective id
                let id = 0;
                if(child.name.includes('doorNameScreen_s')) id = 1;
                else if(child.name.includes('doorNameScreen_w')) id = 2;
                else if(child.name.includes('doorNameScreen_e')) id = 3;

                // Add to the door name screens array
                this.walls.doorNames[type][id] = child;
                child.frustumCulled = false;
            }
            // If the child is a door
            else if(child.name.split('_')[0] === 'door' && child.name.split('_')[1].length === 1 && child instanceof THREE.Group)
            {
                // Get the respective id
                let id = 0;
                if(child.name === 'door_s') id = 1;
                else if(child.name === 'door_w') id = 2;
                else if(child.name === 'door_e') id = 3;

                // Add to the doors array
                this.walls.doors[id] = child;
                // Set doors as always visible to avoid frustrum bugs
                child.traverse((elem) => { elem.frustumCulled = false });
            }
            // If the child is the pavilion name screen
            else if(child.name === 'pavNameScreen_wall')
            {
                // Get child
                this.walls.nameScreen = child;
                this.walls.nameScreen.material = child.material.clone();
            }
            // If the child is a collider
            else if(child.name.split('_')[0] === 'collider')
            {
                // Get child
                this.walls.colliders.push(child);
                child.material = child.material.clone();
            }

            // If the child has a standard material
            if(child.material instanceof THREE.MeshStandardMaterial)
            {
                // If the child contains a glass material
                if(child.material.name === "Glass")
                {
                    // Set default glass material
                    child.material = glassMat;
                }
            }
        });

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Add to the scene
            this.scene.add(this.walls.model);
            // Update materials
            this.#updateMaterials(this.walls.model);
        }
    }

    // Private method called to set the doors and the central totem numbers accordingly to the sections around the current section
    #setDoorsAndTotem()
    {
        // For each of the central totem numbers
        for(let i = 0; i < 4; i++)
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Set the section direction
                let direction = 'n';
                if(i === 1) direction = 's';
                else if(i === 2) direction = 'w';
                else if(i === 3) direction = 'e';

                // Set black as the default color
                let sectionColor = "0x151717";
                let name = "EXIT";

                // Get the section to this direction
                const section = this.minimap.getSectionIdByDirection(direction);
                // If there is a section in this direction
                if(section !== undefined)
                {
                    // Get section color
                    sectionColor = "0xfe5101";
                    // Get section id
                    const id = section.split('_')[1];
                    name = this.instance.info.sections[id].section_name;
                }

                // Set the door and the central totem colors
                this.walls.doorFrames[i].children[0].material.color.setHex(sectionColor).convertSRGBToLinear();
                this.walls.totemColors[i].material.color.setHex(sectionColor).convertSRGBToLinear();

                // Create a bullet instance
                let bullet = this.instance.bulletMesh.clone();
                bullet.material = [bullet.material[0].clone(), bullet.material[1].clone()];
                bullet.position.copy(this.walls.doorFrames[i].position);
                bullet.position.y += 5;
                bullet.lookAt(this.camera.tpCamera.position);
                bullet.name = "bullet_" + this.walls.doors[i].name;

                // Only set the bullet as visible if the third person camera mode is active
                if(this.camera.FIRST_PERSON_CAM === true) bullet.visible = false;

                // If the experience wasn't interrupted
                if(!this.experience.INTERRUPTED)
                {
                    // Add to the interactives array and the bullets array
                    this.instance.bullets.push(bullet);
                    // Add to the scene
                    this.scene.add(bullet);
                }

                // If the experience wasn't interrupted
                if(!this.experience.INTERRUPTED)
                {
                    if(i === 0)
                    {
                        // Create canvas
                        const localCanvas = document.createElement('canvas');
                        const localCanvasCtx = localCanvas.getContext('2d');

                        localCanvas.height = 1024;
                        localCanvas.width = 1024;

                        // Create and update texture
                        const doorTexture = new THREE.Texture(localCanvas);
                        doorTexture.needsUpdate = true;

                        // Set text texture as the material map
                        this.walls.doorNames.local[0].material = this.walls.doorNames.local[0].material.clone();
                        this.walls.doorNames.local[0].material.map = doorTexture;
                        this.walls.doorNames.local[0].material.side = THREE.DoubleSide;
                        this.walls.doorNames.local[0].material.map.encoding = THREE.sRGBEncoding;
                        this.walls.doorNames.local[0].material.encoding = THREE.sRGBEncoding;

                        // Get the current section name
                        let currentName = this.instance.info.sections[this.minimap.currentSectionId].section_name;

                        // Fill the canvas with the current section name
                        localCanvasCtx.fillStyle = "black";
                        localCanvasCtx.textAlign = "center";
                        localCanvasCtx.textBaseline = "middle";
                        localCanvasCtx.font = "bold 180px Nasalization";
                        localCanvasCtx.fillText("SECTION", 512, 420);
                        localCanvasCtx.font = "bold 350px Nasalization";
                        localCanvasCtx.fillText(currentName, 512, 688);

                        // Set the other doors to copy the created texture
                        this.walls.doorNames.local[1].material = this.walls.doorNames.local[0].material;
                        this.walls.doorNames.local[2].material = this.walls.doorNames.local[0].material;
                        this.walls.doorNames.local[3].material = this.walls.doorNames.local[0].material;
                    }

                    // Create canvas
                    const neighborCanvas = document.createElement('canvas');
                    const neighborCanvasCtx = neighborCanvas.getContext('2d');

                    neighborCanvas.height = 1024;
                    neighborCanvas.width = 1024;

                    // Create and update texture
                    const doorTexture = new THREE.Texture(neighborCanvas);
                    doorTexture.needsUpdate = true;

                    // Set text texture as the material map
                    this.walls.doorNames.neighbor[i].material = this.walls.doorNames.neighbor[i].material.clone();
                    this.walls.doorNames.neighbor[i].material.map = doorTexture;
                    this.walls.doorNames.neighbor[i].material.side = THREE.DoubleSide;
                    this.walls.doorNames.neighbor[i].material.map.encoding = THREE.sRGBEncoding;
                    this.walls.doorNames.neighbor[i].material.encoding = THREE.sRGBEncoding;

                    neighborCanvasCtx.fillStyle = "black";
                    neighborCanvasCtx.textAlign = "center";
                    neighborCanvasCtx.textBaseline = "middle";

                    // If the door isn't leading to an exit
                    if(name !== "EXIT")
                    {
                        // Fill the canvas with the "section" word
                        neighborCanvasCtx.font = "bold 180px Nasalization";
                        neighborCanvasCtx.fillText("SECTION", 512, 420);
                        // Fill the canvas with the section name
                        neighborCanvasCtx.font = "bold 350px Nasalization";
                        neighborCanvasCtx.fillText(name, 512, 688);
                    }
                    // If the door is leading to an exit
                    else
                    {
                        // Fill the canvas with the section name
                        neighborCanvasCtx.font = "bold 350px Nasalization";
                        neighborCanvasCtx.fillText(name, 512, 512);
                    }
                }
            }
        }
    }

    // Private method called to set the totem texts accordingly to the sections around the current section
    #setTotemTexts()
    {
        // For each of the totem screens
        for(let i = 0; i < 5; i++)
        {
            // Create canvas
            const totemCanvas = document.createElement('canvas');
            const totemCanvasCtx = totemCanvas.getContext('2d');

            // If the mesh is the title text
            if(i === 0)
            {
                // Set canvas height and width to fit inside the geometry
                totemCanvas.height = 512;
                totemCanvas.width = 2304;
            }
            // If the mesh is a direction text
            else
            {
                // Set canvas height and width to fit inside the geometry
                totemCanvas.height = 512;
                totemCanvas.width = 1536;
            }

            // Create and update texture
            const totemTexture = new THREE.Texture(totemCanvas);
            totemTexture.needsUpdate = true;

            // Set text texture as the material map
            this.walls.totemTexts[i].material = this.walls.totemTexts[i].material.clone();
            this.walls.totemTexts[i].material.map = totemTexture;
            this.walls.totemTexts[i].material.side = THREE.DoubleSide;
            this.walls.totemTexts[i].material.map.encoding = THREE.sRGBEncoding;
            this.walls.totemTexts[i].material.encoding = THREE.sRGBEncoding;

            // Fill the canvas with the pavilion name
            totemCanvasCtx.fillStyle = "black";
            totemCanvasCtx.textAlign = "rtl";
            totemCanvasCtx.textBaseline = "middle";

            // If the mesh is the title text
            if(i === 0)
            {
                // Get the pavilion name
                let name = this.instance.info.pavilion_name;
                // If the name is too long
                if(name.length > 9)
                {
                    // Only show the first nine characteres
                    name = this.instance.info.pavilion_name.substring(0, 9);
                    name += "...";
                }

                // Write the pavilion name
                totemCanvasCtx.font = "bold 300px Nasalization";
                totemCanvasCtx.fillText(name, 0, 256);
            }
            // If the mesh is a direction text
            else
            {
                // Set the section direction
                let direction = 'n';
                if(i === 2) direction = 's';
                else if(i === 3) direction = 'w';
                else if(i === 4) direction = 'e';
                let name = "EXIT";

                // Get the section to this direction
                const section = this.minimap.getSectionIdByDirection(direction);
                if(section !== undefined)
                {
                    // Set the door color as orange
                    this.walls.totemColors[i - 1].material.color.setHex("0xfe5101").convertSRGBToLinear();

                    // Get section id
                    const id = section.split('_')[1];
                    name = "SECTION " + this.instance.info.sections[id].section_name;
                }
                // Set the door color as dark lead
                else this.walls.totemColors[i - 1].material.color.setHex("0x151717").convertSRGBToLinear();

                // Write the section name
                totemCanvasCtx.font = "bold 220px Nasalization";
                totemCanvasCtx.fillText(name, 0, 256);
            }

            // Update texture
            totemTexture.needsUpdate = true;
        }
    }

    // Private method called to set the pavilion name on the wall screen
    #setPavilionName(sectionName)
    {
        // Create canvas
        const nameCanvas = document.createElement('canvas');
        const nameCanvasCtx = nameCanvas.getContext('2d');

        // Set canvas height and width to fit inside the geometry
        nameCanvas.height = 1024;
        nameCanvas.width = 3072;

        // Create and update texture
        const nameTexture = new THREE.Texture(nameCanvas);
        nameTexture.needsUpdate = true;

        // Set text texture as the material map
        this.walls.nameScreen.material.map = nameTexture;
        this.walls.nameScreen.material.side = THREE.DoubleSide;
        this.walls.nameScreen.material.map.encoding = THREE.sRGBEncoding;
        this.walls.nameScreen.material.encoding = THREE.sRGBEncoding;

        // Fill the canvas with the pavilion name
        nameCanvasCtx.fillStyle = "black";
        nameCanvasCtx.font = "bold 250px Nasalization";
        nameCanvasCtx.textAlign = "center";
        nameCanvasCtx.textBaseline = "middle";
        nameCanvasCtx.fillText(this.instance.info.pavilion_name, 1536, 350);

        // Fill the canvas with the complementary "pavilion" word
        nameCanvasCtx.font = "150px Nasalization";
        nameCanvasCtx.textAlign = "center";
        nameCanvasCtx.textBaseline = "middle";
        nameCanvasCtx.fillText("SECTION " + sectionName, 1536, 550);

        // Update texture
        nameTexture.needsUpdate = true;
    }

    // Private method called to create the section content
    #setPavilionSection(id, style)
    {
        try
        {
            // If the section style wasn't loaded yet
            if(this.resources.items["section_" + style] === undefined)
            {
                // Set the section info
                const source = {
                    name: "section_" + style,
                    type: "gltfModel",
                    path: "models/sections/section_" + style + "/section_" + style + ".gltf"
                };

                try
                {
                    // Load the section model
                    this.resources.loaders.gltfLoader.load(
                        this.resources.ASSETS_PATH + source.path,
                        (file) =>
                        {
                            // If the experience wasn't interrupted
                            if(this.resources)
                            {
                                // Save the loaded resource
                                this.resources.sourceLoaded(source, file);
                                // Set section model
                                this.#setSectionModel(id, style);
                            }
                        }
                    );
                }
                catch(e) { console.log(e) };
            }
            // If the section style was loaded previously
            else
            {
                // Set section model
                if(!this.experience.INTERRUPTED) this.#setSectionModel(id, style);
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to set the section model
    #setSectionModel(id, style)
    {
        // Set id and model
        this.instance.id = id;
        this.instance.style = style;
        this.instance.model = this.resources.items["section_" + style].scene;
        this.instance.model.name = "section_" + style;
        this.instance.model.position.y = -0.09;

        // Get the needed children from the model
        this.instance.standCoordinates = [];
        this.instance.stands = [];
        this.instance.standModels = [];
        this.instance.sectionColliders = [];

        // Go through all the model's children
        this.instance.model.traverse((child) =>
        {
            // If the child is a stand coordinate
            if(child.name.split('_')[0] === 'coords')
            {
                // Get coordinate id
                const coordsId = parseInt(child.name.split('_')[2]);

                // Create object with all the needed info
                const coordinates = {
                    'name': child.name,
                    'position': child.position,
                    'rotation': child.rotation
                };

                // If the section is a sponsor section
                if(this.instance.style.includes("s"))
                {
                    // Add the coordinates to the main array
                    this.instance.standCoordinates[coordsId] = coordinates;
                }
                // If the section is a mixed section
                else if(this.instance.style.includes("g"))
                {
                    // If the id is less than 8, it's a double coordinate spot
                    if(coordsId < 8)
                    {
                        // Get the type of the coordinate (expo stands = 0; or sponsor stands = 1)
                        let coordType = 0;
                        if(child.name.split('_')[1] === 'sponsor') coordType = 1;

                        // If the coordinates haven't been filled yet
                        if(this.instance.standCoordinates[coordsId] === undefined)
                        {
                            // Create an empty array
                            this.instance.standCoordinates[coordsId] = [];
                        }

                        // Add the coordinates to the respective array inside the main array
                        this.instance.standCoordinates[coordsId][coordType] = coordinates;
                    }
                    // If the coordinate id is more than 8, it's a single coordinate spot
                    else
                    {
                        // Add the coordinates to the main array
                        this.instance.standCoordinates[coordsId] = coordinates;
                    }
                }
            }
            // If the child is a collider
            else if(child.name.split('_')[0] === 'collider')
            {
                // Get child
                this.instance.sectionColliders.push(child);
                child.material = child.material.clone();
            }

            // If the child has a standard material
            if(child.material instanceof THREE.MeshStandardMaterial)
            {
                // If the child contains a glass material
                if(child.material.name === "Glass")
                {
                    // Set default glass material
                    child.material = glassMat;
                }
            }
        });

        // Update the model materials
        if(!this.experience.INTERRUPTED) this.#updateMaterials(this.instance.model);
        // Set totem texts
        if(!this.experience.INTERRUPTED) this.#setTotemTexts();

        // Add to scene
        if(!this.experience.INTERRUPTED) this.scene.add(this.instance.model);

        // Trigger event warning that the pavilion is loaded, so that the navmesh can be set
        if(!this.experience.INTERRUPTED) this.trigger('pavilionLoaded');

        // If the section model is loaded
        if(this.instance.model !== undefined)
        {
            // Set new NPCs for this section
            if(!this.experience.INTERRUPTED) this.#setNewNPCs(this.npcsQuantity, this.maleNpcsPercentage, this.staticNpcsPercentage);
            // Set the doors and the central totem numbers
            if(!this.experience.INTERRUPTED) this.#setDoorsAndTotem();

            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Set the player position
                this.player.setPosition(this.saved.playerPosition, this.saved.playerRotation);

                if(!this.renderer) this.renderer = this.experience.renderer;
                // Update shadow map
                this.renderer.instance.shadowMap.needsUpdate = true;
            }

            // Trigger event warning that all the elements finished loading
            setTimeout(() => {
                // If the experience is still active
                if(this.experience)
                {
                    // Trigger the loaded model event
                    if(!this.experience.INTERRUPTED) this.trigger('loaded3DModel');
                }
            }, 500);
        }
        // If the section model isn't loaded
        else
        {
            console.log("The section model could not be loaded, further customization interrupted");
        }
    }

    // Private method called to create the section walls
    #setColliders()
    {
        // Get both the wall colliders and the section colliders into one array
        let collidersArray = this.walls.colliders.concat(this.instance.sectionColliders);

        // Add colliders to eacho of the wall objects
        for(let i = 0; i < collidersArray.length; i++)
        {
            // If the wall element is a mesh
            if(collidersArray[i] instanceof THREE.Mesh)
            {
                // Set wall colliders
                this.colliders.addCollider(collidersArray[i].clone());
                // Disable new material to make the collider invisible
                collidersArray[i].material.visible = false;
            }
            // If the wall element is a group
            else if(collidersArray[i] instanceof THREE.Group)
            {
                // For each of the element's children
                collidersArray[i].children.forEach(child =>
                {
                    // Set wall colliders
                    this.colliders.addCollider(child.clone());
                    // Disable new material to make the collider invisible
                    child.material.visible = false;
                });
            }
        }

        // Generate the octree colliders
        this.colliders.generateOctreeColliders();
        collidersArray.length = 0;
    }

    // Method called to set up new NPC instances to this pavilion
    #setNewNPCs(numOfNPCs, malesPercentage, staticPercentage)
    {
        // Set NPCs array
        this.instance.npcs = [];

        // Set how many male NPCs need to be created
        const males = (numOfNPCs / 100) * malesPercentage;
        const stat = (numOfNPCs / 100) * staticPercentage;

        // Create multiple NPCs
        for(let i = 0; i < numOfNPCs; i++)
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Setup NPC
                let npc = new NPC();

                // Set NPC gender
                if(i < males) npc.setNPC(0);
                else npc.setNPC(1);
                
                // If all the NPCs are static, set as static
                if(staticPercentage === 100) npc.setStatic(true);
                else if(staticPercentage === 0) npc.setStatic(false);

                // Add to NPCs array
                this.instance.npcs.push(npc);
            }
        }

        // If not all the NPCs are static
        if(staticPercentage < 100 && staticPercentage > 0)
        {
            // Create array of drawn ids
            let drawn = [];

            // Loop until all the needed NPCs were set as dinamic
            do {
                // If the experience wasn't interrupted
                if(!this.experience.INTERRUPTED)
                {
                    // Get a random id from the available NPCs
                    const id = Math.floor(Math.random() * numOfNPCs);

                    // If the id wasn't drawn already
                    if(drawn.includes(id) === false)
                    {
                        // Add to the drawn ids array
                        drawn.push(id);
                        // Set NPC as dinamic
                        this.instance.npcs[id].setStatic(false);
                    }
                }
                else break;
            } while (drawn.length < (numOfNPCs - stat));
        }
    }

    // Method called to set the stands inside the section
    #setStands()
    {
        try
        {
            if(this.instance.standsInfo.stands.length > this.instance.standCoordinates.length)
                console.log("Number of stands exceeded the section's limit. Loading only the first " + this.instance.standCoordinates.length + " stands of the array.");

            let positionsTaken = [];

            // Set each stand from the JSON file
            for(let i = 0; i < this.instance.standsInfo.stands.length; i++)
            {
                // If the experience wasn't interrupted
                if(!this.experience.INTERRUPTED)
                {
                    if(i < this.instance.standCoordinates.length)
                    {
                        let coords;

                        const id = this.instance.standsInfo.stands[i].stand_id;
                        const position = this.instance.standsInfo.stands[i].stand_position;

                        if(isNaN(position) || position === "")
                        {
                            console.log("'stand_position' value is not a number: '" + position + "' (at stand id: " + id + ")");
                            continue;
                        }
                        if (position < 0 || position >= this.instance.standCoordinates.length)
                        {
                            console.log("'stand_position' value is not a valid number (0 to " + (this.instance.standCoordinates.length - 1) + "): '" + position + "' (at stand id: " + id + ")");
                            continue;
                        }
                        if(positionsTaken[position] === true)
                        {
                            console.log("'stand_position': '" + position + "' was already taken by another stand (at stand id: " + id + ")");
                            continue;
                        }
                        
                        positionsTaken[position] = true;

                        // Get stand style
                        const style = this.instance.standsInfo.stands[i].stand_style;

                        // If the section is a sponsor section
                        if(this.instance.style.includes("s"))
                        {
                            // If the stand is a sponsor stand
                            if(style.includes("g"))
                            {
                                console.log("Adding standard stands to a sponsors only section is not recommended (at stand id: " + id + ", section id: " + this.instance.id + ")");
                            }

                            // Get coordinates of the spot
                            coords = this.instance.standCoordinates[position];
                        }
                        // If the section is a mixed section
                        else if(this.instance.style.includes("g"))
                        {
                            // If the stand is going to be in one of the double coordinates spots
                            if(position < 8)
                            {
                                // If the stand is a sponsor stand
                                if(style.includes("s"))
                                {
                                    // Get coordinates of the sponsor spot
                                    coords = this.instance.standCoordinates[position][1];
                                }
                                // If the stand is a expo stand
                                else if(style.includes("g"))
                                {
                                    // Get coordinates of the expo spot
                                    coords = this.instance.standCoordinates[position][0];
                                }
                            }
                            // If the stand is going to be in one of the single coordinate spots
                            else
                            {
                                // If the stand is a sponsor stand
                                if(style.includes("s"))
                                {
                                    console.log("Sponsor stands are not accepted in positions above 7, only small stands allowed (stand id: " + id + " at position: " + position + ")");
                                    continue;
                                }

                                // Get coordinates of the spot
                                coords = this.instance.standCoordinates[position];
                            }
                        }

                        // Create new stand class
                        let stand = new Stand();
                        // Set stand model and custom elements
                        stand.updateObjectsByJSON(i, this.instance.standsInfo.stands[i], glassMat);

                        // If the stand model is loaded
                        if(stand.instance.model !== undefined)
                        {
                            // Set stand position and rotation
                            stand.setPosition(position, coords.position);
                            stand.setRotation(coords.rotation);
                            // Set colliders
                            stand.setColliders();

                            // If the experience wasn't interrupted
                            if(!this.experience.INTERRUPTED)
                            {
                                // Add stand to the instance arrays
                                this.instance.stands.push(stand);
                                this.instance.standModels.push(stand.instance.model);
                            }
                        }

                        // Create a bullet instance
                        let bullet = this.instance.bulletMesh.clone();
                        bullet.material = [bullet.material[0].clone(), bullet.material[1].clone()];
                        bullet.position.copy(stand.instance.model.position);
                        bullet.position.y += 10;
                        bullet.lookAt(this.camera.tpCamera.position);
                        bullet.name = "bullet_" + stand.instance.model.name;

                        // Only set the bullet as visible if the third person camera mode is active
                        if(this.camera.FIRST_PERSON_CAM === true) bullet.visible = false;

                        // If the experience wasn't interrupted
                        if(!this.experience.INTERRUPTED)
                        {
                            // Add to the interactives array and the bullets array
                            this.instance.bullets.push(bullet);
                            // Add to the scene
                            this.scene.add(bullet);
                        }
                    }
                }
            }
        }
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called by external classes to set the listeners
    #setListeners()
    {
        // Listen for stands hovered by the player
        this.player.on('hoveringStand', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                if(this.player.instance.hoveredStand.includes('pav') || this.player.instance.hoveredStand.includes('door'))
                {
                    // Get add factor
                    let add = 0;
                    if(this.player.instance.hoveredStand.includes('bullet')) add = 1;

                    // Get the section array id
                    const arrayId = this.minimap.getSectionIdByDirection(this.player.instance.hoveredStand.split('_')[1 + add]);

                    let data;
                    // If the id isn't undefined
                    if(arrayId !== undefined)
                    {
                        // Data to be sent
                        data =
                        {
                            'section_id': this.instance.info.sections[arrayId.split('_')[1]].section_id,
                            'section_name': this.instance.info.sections[arrayId.split('_')[1]].section_name
                        };
                    }
                    else data = { 'section_id': 'exit' };

                    // Trigger event once to signal that the stand is being hovered
                    const hoveringSectionEvent = new CustomEvent( 'hoveringSectionButton', { detail: data } );
                    window.novvaC3.eventTarget.dispatchEvent(hoveringSectionEvent);
                }
                else
                {
                    // Get add factor
                    let add = 0;
                    if(this.player.instance.hoveredStand.includes('bullet')) add = 1;

                    // Get the stand array id
                    const arrayId = this.player.instance.hoveredStand.split('_')[1 + add];

                    // Get stand id
                    const id = this.instance.standsInfo.stands[arrayId].stand_id;
                    // Get stand tooltip info
                    const ttName = this.instance.standsInfo.stands[arrayId].tooltip.name;
                    const ttLogo = this.instance.standsInfo.stands[arrayId].tooltip.logo;

                    // Data to be sent
                    const data =
                    {
                        'stand_id': id,
                        'tooltip_name': ttName,
                        'tooltip_logo': ttLogo
                    };

                    // Trigger event once to signal that the stand is being hovered
                    const hoveringStandEvent = new CustomEvent( 'hoveringStand', { detail: data } );
                    window.novvaC3.eventTarget.dispatchEvent(hoveringStandEvent);
                }

                // Set cursor to pointer
                document.body.style.cursor = "pointer";
            }
        });

        // Listen for stands that the player stopped hovering
        this.player.on('stoppedHoveringStand', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                if(this.player.instance.hoveredStand.includes('pav') || this.player.instance.hoveredStand.includes('door'))
                {
                    // Get add factor
                    let add = 0;
                    if(this.player.instance.hoveredStand.includes('bullet')) add = 1;

                    // Get the section array id
                    const arrayId = this.minimap.getSectionIdByDirection(this.player.instance.hoveredStand.split('_')[1 + add]);

                    let data;
                    // If the id isn't undefined
                    if(arrayId !== undefined)
                    {
                        // Data to be sent
                        data =
                        {
                            'section_id': this.instance.info.sections[arrayId.split('_')[1]].section_id,
                            'section_name': this.instance.info.sections[arrayId.split('_')[1]].section_name
                        };
                    }
                    else data = { 'section_id': 'exit' };
                                                
                    // Trigger event once to signal that the stand isn't being hovered anymore
                    const stoppedHoveringStandEvent = new CustomEvent( 'stoppedHoveringSectionButton', { detail: data } );
                    window.novvaC3.eventTarget.dispatchEvent(stoppedHoveringStandEvent);
                }
                else if(this.player.instance.hoveredStand !== undefined)
                {
                    // Get add factor
                    let add = 0;
                    if(this.player.instance.hoveredStand.includes('bullet')) add = 1;

                    // Get the stand array id
                    const arrayId = this.player.instance.hoveredStand.split('_')[1 + add];

                    // Data to be sent
                    const data = { 'stand_id': this.instance.standsInfo.stands[arrayId].stand_id };
                                
                    // Trigger event once to signal that the stand isn't being hovered anymore
                    const stoppedHoveringStandEvent = new CustomEvent( 'stoppedHoveringStand', { detail: data } );
                    window.novvaC3.eventTarget.dispatchEvent(stoppedHoveringStandEvent);
                }
                
                // Set cursor to pointer
                document.body.style.cursor = "default";
            }
        });

        // Listen for the switch camera command
        this.camera.on('switchCamera', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                let isVisible;

                // Set minimap elements as visible or not depending on the active camera
                if(this.camera.FIRST_PERSON_CAM === true)
                {
                    // Enable arrow and disable text
                    isVisible = true;
                    if(this.minimap.arrow)
                    {
                        this.minimap.arrow.style.display = '';
                        this.minimap.instance.sections[this.minimap.currentSectionId].firstChild.style.display = 'none';
                    }
                }
                else if(this.camera.FIRST_PERSON_CAM === false)
                {
                    // Enable text and disable arrow
                    isVisible = false;
                    if(this.minimap.arrow)
                    {
                        this.minimap.arrow.style.display = 'none';
                        this.minimap.instance.sections[this.minimap.currentSectionId].firstChild.style.display = '';
                    }
                }

                // Set elements visibility
                for(let i = 0; i < this.walls.roof.length; i++)
                {
                    this.walls.roof[i].visible = isVisible;
                }

                // Set bullets as visible only when the third person camera mode is active
                this.instance.bullets.forEach(bullet => { bullet.visible = !isVisible });

                // For each of the stands
                this.instance.stands.forEach(stand =>
                {
                    // Manage the image screens visibility based on the camera mode
                    stand.instance.customObjs.imageScreens.forEach(elem =>
                    {
                        elem.visible = this.camera.FIRST_PERSON_CAM;
                    });
                    // Manage the video screens visibility based on the camera mode
                    stand.instance.customObjs.videoScreens.forEach(elem =>
                    {
                        elem.visible = this.camera.FIRST_PERSON_CAM;
                    });
                });
            }
        });

        // Listen for the switch section command
        this.minimap.on('switchSection', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the sections are not being switched
                if(switching === false && this.loaded === true)
                {
                    // Set variables
                    this.loaded = false;
                    switching = true;

                    // If the id from the new section is different from the current section, and the section is defined
                    if(this.instance.id !== this.instance.info.sections[this.minimap.currentSectionId].section_id && this.instance.info.sections[this.minimap.currentSectionId] !== undefined)
                    {
                        // Get the new section id
                        this.instance.id = this.instance.info.sections[this.minimap.currentSectionId].section_id;

                        // Do after 1 second
                        setTimeout(() =>
                        {
                            // If the experience is still active
                            if(this.experience)
                            {
                                // Switch to the next section
                                if(!this.experience.INTERRUPTED) this.switchSection(this.minimap.currentSectionId);
                            }
                        }, 50);
                    }
                    // If the id from the new section is the same as the current section
                    else
                    {
                        // Reset variable
                        switching = false;
                    }
                }
            }
        });
    }

    // Private method called to update the model materials
    #updateMaterials(model)
    {
        // Go through all the model's children
        model.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
            {
                // Receive shadows
                child.receiveShadow = true;
 
                // Set children material encoding
                child.material.encoding = THREE.sRGBEncoding;
                if(child.material.map != null) child.material.map.encoding = THREE.sRGBEncoding;
                child.material.needsUpdate = true;
            }
        });
    }

    // Dispose the last section and load the new one
    switchSection(position, npcsQuantity)
    {
        try
        {
            // If the section haven't started switching yet
            if(switching === false)
            {
                // Trigger a click on the respective minimap section
                this.minimap.instance.sections[position].click();
                return;
            }
            // If the section is mid-switch
            else
            {
                // Data to be sent
                const data = { 'section_id': this.instance.info.sections[position].section_id }

                // Trigger event once to signal that the scene is being switched
                const switchSectionEvent = new CustomEvent( 'switchingSection', { detail: data } );
                window.novvaC3.eventTarget.dispatchEvent(switchSectionEvent);

                // Destroy the colliders
                this.colliders.destroy();

                // If the npcs quantity value is invalid
                if(npcsQuantity === "" || isNaN(npcsQuantity))
                {
                    if(npcsQuantity !== undefined) console.log('Invalid value for the quantity of NPCs: ' + npcsQuantity + '. Using the previous value or the default quantity instead (50).')
                    // Set default value
                    npcsQuantity = this.npcsQuantity;
                }
                else this.npcsQuantity = npcsQuantity;

                // If the last section and the current section don't have the same style
                if(this.instance.info.sections[position].section_style !== this.instance.style)
                {
                    // Get the section's info from the JSON object
                    const info = this.instance.info.sections[position];
                    // Dispose the pavilion
                    this.#disposePavilion();
                    // Set the pavilion name to the wall canvas
                    this.#setPavilionName(info.section_name);

                    // Save player info
                    this.saved = {
                        playerPosition: this.minimap.sideToEnter,
                        playerRotation: 0
                    };
                    // Set new pavilion
                    this.#setPavilionSection(info.section_id, info.section_style);
                }
                // If the last section and the current section have the same style
                else
                {
                    // Update section id
                    this.instance.id = this.instance.info.sections[position].section_id;

                    // Save player info
                    this.saved = {
                        playerPosition: this.minimap.sideToEnter,
                        playerRotation: 0
                    };
                    // Set the player position
                    this.player.setPosition(this.saved.playerPosition, this.saved.playerRotation);

                    // Dispose stands
                    this.#disposeStands();
                    // Dispose NPCs
                    this.#disposeNPCs();

                    // Set the doors and the central totem numbers
                    this.#setDoorsAndTotem();
                    // Set new NPCs for this section
                    this.#setNewNPCs(this.npcsQuantity, this.maleNpcsPercentage, this.staticNpcsPercentage);

                    // Update shadow map
                    this.renderer.instance.shadowMap.needsUpdate = true;
                
                    // Trigger event warning that all the elements finished loading
                    this.trigger('loaded3DModel');
                }

                // Reset variable
                switching = false;
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to manage the minimap arrow rotation
    #manageMinimapArrow()
    {
        // Get arrow if needed
        if(!this.arrow) this.arrow = document.getElementById('arrow');

        // Get camera rotation and convert to degrees
        const heading = this.camera.fpCamera.rotation.y;
        const radians = heading > 0 ? heading : (2 * Math.PI) + heading;
        const degrees = (THREE.Math.radToDeg(radians) - 90) * -1;
        
        // Rotate the arrow accordingly to the camera rotation
        this.arrow.style.transform = 'rotate(' + degrees + 'deg)';
    }

    // Private method called to manage the player switching sections by walking into the doors
    #manageDoorsSectionSwitching()
    {
        // If the section is loaded
        if(this.loaded === true)
        {
            // Get the player position
            let pos = this.player.instance.collider.end;

            // If the player is inside one of the doors
            if(pos.x > 59 || pos.x < -59 || pos.z > 65 || pos.z < -65)
            {
                // Get the door direction
                let direction;
                if(pos.x > 59) direction = 's';
                else if(pos.x < -59)  direction = 'n';
                else if(pos.z > 65)  direction = 'w';
                else if(pos.z < -65)  direction = 'e';

                // Get the section id from that direction
                const sectionId = this.minimap.getSectionIdByDirection(direction);

                // If the section id isn't undefined and the sections are not switching
                if(sectionId !== undefined)
                {
                    // Get the id
                    const id = parseInt(sectionId.split('_')[1]);
                    // Trigger a click on the respective minimap section
                    this.minimap.instance.sections[id].click();
                }
                else
                {
                    // Trigger event warning that the exit button in the totem was clicked
                    const exitEvent = new CustomEvent( 'exitPavilion' );
                    window.novvaC3.eventTarget.dispatchEvent(exitEvent);
                }
            }
        }
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Get delta time
        delta = Math.min( 0.05, this.time.delta )

        // For each of the stand
        this.instance.stands.forEach(stand =>
        {
            // Update stand
            stand.update(delta);
        });

        // For each of the NPCs
        this.instance.npcs.forEach(npc =>
        {
            // Update NPC
            npc.update(delta);
        });

        // For each of the bullets
        for(let i = 0; i < this.instance.bullets.length; i++)
        {
            // Animate scaling effect
            this.instance.bullets[i].scale.x += Math.cos(this.time.elapsed * 0.0015) * 0.0075;
            this.instance.bullets[i].scale.y += Math.cos(this.time.elapsed * 0.0015) * 0.0075;
        }

        // If the sections are not switching
        if(switching === false)
        {
            if(this.instance.info.sections.length > 1)
            {
                // Manage the minimap arrow
                this.#manageMinimapArrow();
            }

            // Manage the doors switching
            this.#manageDoorsSectionSwitching();
        }
        
        // If none of the doors is open and the pavilion is loaded
        if(doorOpen === false && this.loaded === true)
        {
            // For each of the four doors
            for(let i = 0; i < this.walls.doors.length; i++)
            {
                // Get the door world position
                const doorPos = this.walls.doors[i].getWorldPosition(new THREE.Vector3(0, 0, 0));
                // Get the distance between the player and the door
                const distance = this.player.instance.collider.end.distanceTo(doorPos);

                // If the player is close enough to the door
                if(distance < 6)
                {
                    // Set door as open
                    doorOpen = true;

                    // Get the door id
                    const id = this.walls.doors[i].name.split("_")[1];
                    // Play the door opening animation
                    this.walls.animations.doors[id].setEffectiveTimeScale(1);
                    this.walls.animations.doors[id].play();
                    this.walls.animations.doors[id].paused = false;
                    // Play the door opening sound effect
                    this.audio.playDoorSFX(true);

                    // Save the door global position and the animation clip
                    this.walls.animations.activeDoor.pos = doorPos;
                    this.walls.animations.activeDoor.action = this.walls.animations.doors[id];
                    break;
                }
            }
        }
        // If one of the doors is open and the pavilion is loaded
        else if(doorOpen === true && this.loaded === true)
        {
            // Get the distance between the player and the open door
            const distance = this.player.instance.collider.end.distanceTo(this.walls.animations.activeDoor.pos);

            // If the player is far enough away from the door
            if(distance >= 6)
            {
                // Play animation on reverse to close the door
                this.walls.animations.activeDoor.action.setEffectiveTimeScale(-1)
                this.walls.animations.activeDoor.action.paused = false;
                // Play the door closing sound effect
                this.audio.playDoorSFX(false);

                // Reset the variable
                this.walls.animations.activeDoor = { "pos": null, "action": null };

                // Set door as closed
                doorOpen = false;
            }
        }

        // Update the animation mixer
        if(this.walls) this.walls.animations.mixer.update(delta);
    }

    // Private method called to dispose all NPCs
    #disposeNPCs()
    {
        // If the NPCs array was created
        if(this.instance.npcs instanceof Array)
        {
            // Dispose each of the NPCs created
            this.instance.npcs.forEach(npc =>
            {
                try { npc.destroy() } catch(e) { console.log(e) };
            });
            // Reset NPCs array
            this.instance.npcs.length = 0;
        }
    }

    // Private method called to dispose all stands
    #disposeStands()
    {
        // Reset array
        if(this.instance.standModels instanceof Array) this.instance.standModels.length = 0;

        // If the stands array was created
        if(this.instance.stands instanceof Array)
        {
            // Dispose all stands
            for(let i = 0; i < this.instance.stands.length; i++)
            {
                // Destroy stand
                try { if(this.instance.stands[i]) this.instance.stands[i].destroy() } catch(e) { console.log(e) };
            }
            // Reset array
            this.instance.stands.length = 0;
        }

        // If the bullets array was created
        if(this.instance.bullets instanceof Array)
        {
            // Dispose all bullets
            for(let i = 0; i < this.instance.bullets.length; i++)
            {
                // Dispose the plane
                try { this.disposer.disposeElements(this.instance.bullets[i]) } catch(e) { console.log(e) };
            }
            // Reset array
            this.instance.bullets.length = 0;
        }

        // Reset JSON object
        this.instance.standsInfo = { "stands": [] };
    }

    // Method called to dispose the pavilion
    #disposePavilion()
    {
        // Get the disposer class from the experience
        if(!this.disposer) this.disposer = this.experience.disposer;

        // Dispose NPCs
        this.#disposeNPCs();
        // Dispose stands
        this.#disposeStands();

        if(this.instance.standCoordinates instanceof Array) this.instance.standCoordinates.length = 0;
        this.loaded = null;

        // Dispose the model
        try { this.disposer.disposeElements(this.instance.sectionColliders) } catch(e) { console.log(e) };
        try { this.disposer.disposeElements(this.instance.model) } catch(e) { console.log(e) };
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        if(this.clock)
        {
            // Stop clock
            this.clock.stop();
            this.clock = null;
        }

        // If the pavilion has loaded walls
        if(this.walls)
        {
            // Stop all animation actions
            try { this.walls.animations.mixer.stopAllAction() } catch(e) { console.log(e) };
        }

        // Dispose the pavilion
        try { this.#disposePavilion() } catch(e) { console.log(e) };

        // If the pavilion has loaded walls
        if(this.walls)
        {
            // Dispose the walls
            try { this.disposer.disposeElements(this.walls.model) } catch(e) { console.log(e) };
        }
        this.walls = null;

        // Destroy the colliders
        try { this.colliders.destroy() } catch(e) { console.log(e) };

        // Remove listeners
        try { this.resources.off('loadedResources') } catch(e) { console.log(e) };
        if(this.player)
        {
            try { this.player.off('hoveringStand') } catch(e) { console.log(e) };
            try { this.player.off('stoppedHoveringStand') } catch(e) { console.log(e) };
        }
        try { this.camera.off('switchCamera') } catch(e) { console.log(e) };
        try { this.minimap.off('switchSection') } catch(e) { console.log(e) };

        // Reset instance
        this.instance = null;
        this.saved = null;

        // Remove references
        this.experience = null;
        this.disposer = null;
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.audio = null;
        this.resources = null;
        this.environment = null;
        this.colliders = null;
        this.minimap = null;
        this.player = null;
    }
}