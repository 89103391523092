import Experience from './Experience/Experience';

// Create encapsulated object for this experience
if(!window.novvaC2) window.novvaC2 = {};
// Create encapsulated event target
if(!window.novvaC2.eventTarget) window.novvaC2.eventTarget = new EventTarget();

// Experience class instance
let _experience = null;



// Set the render quality
function changeRenderQuality(id)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Change quality
        _experience.updateRenderQuality(id);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC2.changeRenderQuality = changeRenderQuality;



// Global function called to update many or all of the objects instantly, without needing to go through the verifications
function updateObjectsByJSON(jsonObj, startingDome, npcsQuantity)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Update objects
        _experience.dome.updateObjectsByJSON(jsonObj, startingDome, npcsQuantity);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC2.updateObjectsByJSON = updateObjectsByJSON;



// Global function called to set a new audio track to the existing soundtrack
function changeAudioTrack(url)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Change the audio track
        _experience.audio.changeAudioTrack(url);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC2.changeAudioTrack = changeAudioTrack;



// Global function called to pause or resume the ambient song
function pauseOrResumeSoundtrack(bool)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Pause or resume the song
        _experience.audio.pauseOrResumeSoundtrack(bool);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC2.pauseOrResumeSoundtrack = pauseOrResumeSoundtrack;



// Global function called to set the ambient soundtrack volume
function setSoundtrackVolume(value)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Pause or resume the song
        _experience.audio.setSoundtrackVolume(value);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC2.setSoundtrackVolume = setSoundtrackVolume;



// Global function called to change the current dome
function switchDome(id, npcsQuantity)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Change dome
        _experience.dome.switchDome(id, npcsQuantity);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC2.switchDome = switchDome;



// Destroy the experience, but maintain persistend data
function destroyTheExperience()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";

        // Destroy the experience
        _experience.destroy();
        console.log('experience C2 destroyed')
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC2.destroyTheExperience = destroyTheExperience;



// Completely wipe the experience
function wipeTheExperience()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";

        // Destroy the experience
        _experience.wipe();
        _experience = null;
        console.log('experience C2 wiped')
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC2.wipeTheExperience = wipeTheExperience;



// Start the experience
function startTheExperience(parameters)
{
    try
    {
        // If the experience already started
        if(_experience !== null)
        {
            // If all the assets are loaded but the scene is null
            if(_experience.resources.loaded >= _experience.resources.toLoad && _experience.scene === null)
            {
                // Restart the experience
                _experience.restart(parameters);
                console.log('experience C2 restarted')
            }
            else throw "The Experience is already running";
        }
        else
        {
            // Create the experience
            _experience = new Experience(document.querySelector('canvas.webgl'), parameters);
            console.log('experience C2 created')
        }
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC2.startTheExperience = startTheExperience;



// Function called when the script is loaded
function loadedScript()
{
    // If there is an async init function
    if(window.novvaC2AsyncInit)
    {
        // Call function to init the experience
        window.novvaC2AsyncInit();
    }
    // If there isn't an async init function, log error
    else
    {
        console.log('The "novvaC2AsyncInit" function was not found.');
    }
}
loadedScript();