import { Raycaster, MeshBasicMaterial, MeshStandardMaterial, Mesh, sRGBEncoding, VideoTexture, Vector2, Texture, DoubleSide, Color } from "three";

import Experience from "../Experience";
import EventEmitter from "../Utils/EventEmitter";
import User from "./User";

let isMobile;

export default class MeetingRoom extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.time = this.experience.time;
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.disposer = this.experience.disposer;
        this.environment = this.experience.environment;

        // Create raycast
        this.raycaster = new Raycaster();
        // Create default material
        this.offMaterial = new MeshStandardMaterial({ color: 0x000000, metalness: 1 });

        // Listen to when the resources are loaded
        this.resources.on('loadedResources', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Get the camera and the pointer class from the experience
                this.camera = this.experience.camera;
                this.composer = this.experience.composer;
                this.pointer = this.experience.pointer;

                // Get the mobile detector class from the experience
                this.mobileDetector = this.experience.mobileDetector;
                // Get if the device is a mobile
                isMobile = this.mobileDetector.isMobile;
                // Remove reference
                this.mobileDetector = null;

                // If the device is a mobile
                if(isMobile === true)
                {
                    // Listen for touch event
                    this.pointer.on('pointerTouch', () =>
                    {
                        // Cast a raycast
                        if(!this.experience.INTERRUPTED) this.#castRaycast(this.pointer.mouse);
                    });
                }

                // Set lights
                if(!this.experience.INTERRUPTED) this.environment.setHemisphereLight();
                if(!this.experience.INTERRUPTED) this.environment.setDirectionalLight();
                if(!this.experience.INTERRUPTED) this.environment.setEnvironmentBackground();
                if(this.experience.parameters.QUALITY > 0)
                {
                    if(!this.experience.INTERRUPTED) this.environment.setEnvironmentMap();
                }
                // Set the listeners
                if(!this.experience.INTERRUPTED) this.#setListeners();
                // Set the background
                if(!this.experience.INTERRUPTED) this.setBackground();
            }
        });
    }

    // Private method called to set up the listeners
    #setListeners()
    {
        // Listen for mouse hovers
        this.on('hoveringUser', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Set data to be sent
                let data;
                if(this.hoveredElem.name != "screen_background") data = { 'hovering': 'user_' + this.hoveredElem.name.split('_')[1] };
                else data = { 'hovering': 'big_screen' };
                
                // Trigger event once to signal that an element is being hovered
                const hoveringEvent = new CustomEvent( 'hoveringUser', { detail: data } );
                window.novvaC5.eventTarget.dispatchEvent(hoveringEvent);
            }
        });

        // Listen for mouse hovers stopping
        this.on('stoppedHoveringUser', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Trigger event once to signal that the element isn't being hovered anymore
                const stoppedHoveringEvent = new CustomEvent( 'stoppedHoveringUser' );
                window.novvaC5.eventTarget.dispatchEvent(stoppedHoveringEvent);
            }
        });
    }

    // Method called to set the scene background
    setBackground()
    {
        // Get background model
        this.roomBackground = this.resources.items.sala_reuniao.scene;
    
        // Create variables and arrays
        this.users = [];
        this.usersColor = "0xFE5101";
        this.usersPositions = [];
        this.interactives = [];
        this.hoveredElem = null;
        this.mainScreen = null;
        this.representatives = [];
        this.repsScreens = [];
        this.screenLayout = [0, 0];
        this.recIcon = null;
        this.meetingName = null;
        this.sharingScreen = [false, null];

        // Go through all the model's children
        this.roomBackground.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof Mesh && child.material instanceof MeshStandardMaterial)
            {
                // Receive shadows
                child.receiveShadow = true;

                // Set children material encoding
                child.material.encoding = sRGBEncoding;
                if(child.material.map != null) child.material.map.encoding = sRGBEncoding;
                child.material.needsUpdate = true;

                // If there is an environment map texture
                if(this.environment.envMapTexture)
                {
                    // Set environment map intensity
                    child.material.envMapIntensity = this.environment.envMapIntensity;
                }
            }

            // Get user heads
            if(child.name.split('_')[0] == "userHead")
            {
                // Get id and video source
                const id = child.name.split('_')[1];

                // If the experience wasn't interrupted
                if(!this.experience.INTERRUPTED)
                {
                    // Create new user
                    this.users[id] = new User();
                    this.users[id].setHead(child);
                    // Set default material
                    this.users[id].userHead.children[0].material = this.offMaterial;
                }
            }
            // Get user positions
            else if(child.name.split('_')[0] == "userPos")
            {
                // Get id and video source
                const id = child.name.split('_')[1];

                // If this head is the user's head
                if(id == "self")
                {
                    // Create new user
                    this.selfUser = child;

                    // If the experience wasn't interrupted
                    if(!this.experience.INTERRUPTED)
                    {
                        // Set camera position
                        this.camera.renderCamera.position.set(child.position.x, child.position.y + 1, child.position.z);
                        this.camera.defaultPosition = this.camera.renderCamera.position.clone();
                    }
                }
                // If this head isn't the user's head
                else
                {
                    this.usersPositions[id] = child;
                }
            }
            // Get TV
            else if(child.name == "screen_background")
            {
                // Get main screen and set as interactive
                this.mainScreen = child;
                this.interactives.push(this.mainScreen);
            }
            // Get the meeting name bar
            else if(child.name == "screen_descriptionBar")
            {
                // Get child and deactivate it
                this.meetingName = child;
                this.meetingName.visible = false;
            }
            // Get the REC icon
            else if(child.name == "screen_REC")
            {
                // Get child and deactivate it
                this.recIcon = child;
                this.recIcon.visible = false;
            }
            // Get all the representatives layouts
            else if(child.name.includes("repsLayout"))
            {
                const id = parseInt(child.name.split("_")[1]);
                // Get child to the array
                this.repsScreens[id] = child;
                this.repsScreens[id].children.forEach(elem => { elem.visible = false });
            }
            // Get the share screen
            else if(child.name == "shareScreen")
            {
                // Get child
                this.sharingScreen[1] = child;
            }
        });

        // For each of the user positions found
        for(let i = 0; i < this.usersPositions.length; i++)
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Get the user id
                const id = this.usersPositions[i].name.split('_')[1];
                // Set the robot body and color
                this.users[id].setBody(this.usersPositions[i]);
                this.users[id].setRobotColor(this.usersColor);
            }
        }

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Add to scene
            this.scene.add(this.roomBackground);
            // Trigger event warning that the basic elements finished loading
            this.trigger('loaded3DScene');
        }
    }

    // Method called to manage the visibility of the REC icon
    setRECIcon(bool)
    {
        // If the REC icon wasn't created yet
        if(this.recIcon.material instanceof MeshStandardMaterial)
        {
            // Set the REC icon to the tv screen
            this.recIcon.material = new MeshBasicMaterial({ map: this.resources.items.recIcon, side: DoubleSide, transparent: true });
            this.recIcon.material.map.generateMipmaps = false;
            this.recIcon.material.encoding = sRGBEncoding;
            this.recIcon.material.map.encoding = sRGBEncoding;
            this.recIcon.material.needsUpdate = true;
        }
        // Set the REC icon visibility
        this.recIcon.visible = bool;
    }

    // Method called to set the meeting name
    setMeetingName(name)
    {
        // Create canvas
        const nameCanvas = document.createElement('canvas');
        const nameCanvasCtx = nameCanvas.getContext('2d');

        // Set canvas height and width to fit inside the geometry
        nameCanvas.height = 512;
        nameCanvas.width = 1400;

        // Create and update the name texture
        const nameTexture = new Texture(nameCanvas);
        nameTexture.generateMipmaps = false;
        nameTexture.needsUpdate = true;

        // Set the name texture as the material map
        this.meetingName.material = new MeshBasicMaterial();
        this.meetingName.material.map = nameTexture;
        this.meetingName.material.transparent = true;
        this.meetingName.material.side = DoubleSide;
        this.meetingName.material.encoding = sRGBEncoding;
        this.meetingName.material.map.encoding = sRGBEncoding;

        // Draw the rounded rectangle
        nameCanvasCtx.strokeStyle = "rgba(0, 0, 0, 1)";
        nameCanvasCtx.beginPath();
        nameCanvasCtx.roundRect(0, 246, 1400, 38, 38);
        nameCanvasCtx.closePath();
        nameCanvasCtx.stroke();
        nameCanvasCtx.fill();

        // Fill the canvas with the meeting name
        nameCanvasCtx.fillStyle = "white";
        nameCanvasCtx.textAlign = "left";
        nameCanvasCtx.textBaseline = "top";
        nameCanvasCtx.font = "22px Nasalization";
        nameCanvasCtx.fillText(name, 25, 256);

        // Update texture
        nameTexture.needsUpdate = true;
        this.meetingName.material.needsUpdate = true;
        this.meetingName.visible = true;
    }

    // Method called to set the users robot color
    setUsersRobotColor(hexcode)
    {
        // If the hexcode is empty, set default value
        if(hexcode !== "" && hexcode !== null && hexcode !== undefined)
        {
            // If the hexcode is on the wrong format
            if(hexcode.slice(0, 1) === '#')
            {
                // Set to correct format
                hexcode = '0x' + hexcode.slice(1);
            }

            // Save the robot color
            this.usersColor = hexcode;

            // For each of the users
            for(let i = 0; i < this.users.length; i++)
            {
                // Set robot color
                this.users[i].setRobotColor(this.usersColor);
            }
        }
        // If there are more than 4 representatives created, show error
        else console.log('The robot color is not valid: ' + hexcode);
    }

    // Method called to create a new representative
    createRepresentative(id, name, acronym, color)
    {
        // If the representatives array was correctly created
        if(this.representatives instanceof Array)
        {
            // If there are less than 4 representatives created
            if(this.representatives.length < 4)
            {
                // Get the current amount of representatives in scene
                const length = this.representatives.length;

                // Find the desired layout to fit a new representative
                if(this.sharingScreen[0] === true) this.screenLayout[0] = 3
                else if(length === 1) this.screenLayout[0] = 1;
                else if(length === 2 || length === 3) this.screenLayout[0] = 2;

                // Create a new user
                const newRep = new User();
                newRep.setId(id);
                newRep.setHead(this.repsScreens[this.screenLayout[0]].children[length]);
                newRep.createUser(name, acronym, color);

                // If the desired layout is different from the current layout
                if(this.screenLayout[0] !== this.screenLayout[1])
                {
                    // For each of the representatives
                    for(let i = 0; i < this.representatives.length; i++)
                    {
                        // Switch screen positions to fit the new layout
                        this.#switchScreenPosition(i, 0);
                    }
                    // Save the set layout
                    this.screenLayout[1] = this.screenLayout[0];
                }

                // Add the new representative to the array of representatives
                this.representatives.push(newRep);
            }
            // If there are more than 4 representatives created, show error
            else console.log('Interrupted setup: Max number of representatives reached.');
        }
    }

    // Private method called to switch the screen positions as the desired layout
    #switchScreenPosition(i, subtract)
    {
        // If the representative is talking, remove the outline effect from the old screen
        if(this.representatives[i].status.talking === true) this.composer.updateOutlineObjects(this.representatives[i].userHead, false);

        // Switch the representative screen to a new panel, keeping the original configurations
        this.representatives[i].userHead.material = this.offMaterial;
        this.representatives[i].setHead(this.repsScreens[this.screenLayout[0]].children[i - subtract]);
        // If the old screen configurations contain an array of materials
        if(this.representatives[i].currentScreen instanceof Array)
        {
            // If the new user head isn't prepared to receive an array of materials
            if(this.representatives[i].userHead.geometry.groups.length !== 2)
            {
                // Create groups to load this materials, from the first pixel to the infinity
                this.representatives[i].userHead.geometry.addGroup( 0, Infinity, 0 );
                this.representatives[i].userHead.geometry.addGroup( 0, Infinity, 1 );
            }
        }
        // If the new user head contains geometry groups that won't be necessary
        else if(this.representatives[i].userHead.geometry.groups.length == 2)
        {
            // Clear the remaining geometry groups
            this.representatives[i].userHead.geometry.clearGroups();
        }
        // Persist the old screen configurations
        this.representatives[i].userHead.material = this.representatives[i].currentScreen;

        // If the representative is talking, add the outline effect to the new screen
        if(this.representatives[i].status.talking === true) this.composer.updateOutlineObjects(this.representatives[i].userHead, true);
    }

    // Method called to remove a representative
    removeRepresentative(repId)
    {
        // For each of the representatives created
        for(let i = 0; i < this.representatives.length; i++)
        {
            // If the id matches this representative's
            if(this.representatives[i].id === repId)
            {
                // Remove the representative
                this.representatives[i].removeUser();
                // Set default screen material
                this.representatives[i].userHead.material = this.offMaterial;
                // Destroy the representative's screen
                this.representatives[i].destroy();
                this.representatives[i] = null;
                // Reorganize the remaining representatives to fill the screen
                this.#reorganizeRepresentatives(1);
                break;
            }
            // If the id matches no representative, show an error
            if(i === (this.representatives.length - 1)) console.log("Could not remove the representative '" + repId + "': representative not found.");
        }
    }

    // Method called to share the screen of one of the representatives
    shareRepresentativeScreen(videoTagId)
    {
        if(videoTagId === false)
        {
            // If the sharing screen is active
            if(this.sharingScreen[0] === true && this.sharingScreen[1].visible === true)
            {
                // Dispose the sharing screen material
                try { this.disposer.disposeElements(this.sharingScreen[1].material) } catch(e) { console.log(e) };
                // Disable the sharing screen
                this.sharingScreen[0] = false;
                this.sharingScreen[1].visible = false;
                // Reorganize the representatives to fit in the new screen layout
                this.#reorganizeRepresentatives(0);
            }
            // If the sharing screen isn't active, show error
            else console.log("Sharing screen is not active, could not deactivate.");
        }
        else
        {
            // Get video element
            const video = document.getElementById(videoTagId);

            // If the video tag could be found
            if(video !== null && video !== undefined)
            {
                // Create video texture
                const texture = new VideoTexture(video);
                texture.generateMipmaps = false;
                this.sharingScreen[1].material = new MeshBasicMaterial({ map: texture });
                this.sharingScreen[1].material.map.flipY = false;

                // Set the screen material encoding
                this.sharingScreen[1].material.encoding = sRGBEncoding;
                this.sharingScreen[1].material.map.encoding = sRGBEncoding;
                this.sharingScreen[1].material.needsUpdate = true;

                // Show the sharing screen
                this.sharingScreen[0] = true;
                this.sharingScreen[1].visible = true;
                // Reorganize the representatives to fit in the new screen layout
                this.#reorganizeRepresentatives(0);
            }
            // If the video tag couldn't be found, show error
            else console.log("Invalid video id: " + videoTagId + "; element not found.");
        }
    }

    // Private method called to reorganize the representatives after the layout has changed
    #reorganizeRepresentatives(subtract)
    {
        // Get the amount of representatives left after removing one of them
        const length = this.representatives.length - subtract;

        // Find the desired screen layout
        if(this.sharingScreen[0] === true) this.screenLayout[0] = 3
        else if(length === 1) this.screenLayout[0] = 0;
        else if(length === 2) this.screenLayout[0] = 1;
        else if(length === 3) this.screenLayout[0] = 2;

        let afterNull = false;
        // For each of the representatives
        for(let i = 0; i < this.representatives.length; i++)
        {
            // If the array slot is null
            if(this.representatives[i] === null) afterNull = true;
            // If the array slot is occupied by a representative
            else
            {
                // Find out if the array was subtracted by one
                let subtract = 0;
                if(afterNull === true) subtract = 1;

                // Switch screen positions to fit the new layout
                this.#switchScreenPosition(i, subtract);
            }
        }

        // Remove null slot from the representatives array
        this.representatives = this.representatives.filter((value, index, arr) => { return value != null; });
        // Save the new screen layout if needed
        if(this.screenLayout[0] !== this.screenLayout[1]) this.screenLayout[1] = this.screenLayout[0];
    }

    // Method called to create a new user
    createUser(userId, name, acronym, color)
    {
        // If the user isn't undefined
        if(this.users[userId] !== undefined)
        {
            // Create user
            this.users[userId].createUser(name, acronym, color);
            // Set as interactive
            this.interactives.push(this.users[userId].userHead.children[0]);
        }
        else console.log("No user " + userId + " available. Must be between 0 and 8.");
    }

    // Method called to remove a user
    removeUser(userId)
    {
        // If the user isn't undefined
        if(this.users[userId] !== undefined)
        {
            // If the user was created
            if(this.users[userId].nametag !== null && this.users[userId].nametag !== undefined)
            {
                // Remove the user
                this.users[userId].removeUser();
                // Set default screen material
                this.users[userId].userHead.children[0].material = this.offMaterial;
                // Remove from the interactable objects array
                this.interactives = this.interactives.filter((value, index, arr) =>
                {
                    return value.name != this.users[userId].userHead.name;
                });
            }
            else console.log("The user " + userId + " was not created yet.");
        }
    }

    // Method called to set the user image
    addUserImage(userId, url, color, isRepresentative)
    {
        // If the image URL is valid
        if(url !== undefined && url !== null && url !== "")
        {
            // If the user isn't a representative
            if(isRepresentative !== true)
            {
                // If the user isn't undefined
                if(this.users[userId] !== undefined)
                {
                    // If the user was created
                    if(this.users[userId].nametag !== null && this.users[userId].nametag !== undefined)
                    {
                        // Set user image
                        this.users[userId].addImage(url, color);
                    }
                    // If the user wasn't created yet, show an error
                    else console.log("The user " + userId + " was not created yet.");
                }
                // If the user is undefined, show an error
                else console.log("No user " + userId + " available. Must be between 0 and 8.");
            }
            // If the user is a representative
            else if(isRepresentative === true)
            {
                // For each of the representatives created
                for(let i = 0; i < this.representatives.length; i++)
                {
                    // If the id matches this representative's
                    if(this.representatives[i].id === userId)
                    {
                        // Set user image
                        this.representatives[i].addImage(url, color);
                        break;
                    }
                    // If the id matches no representative, show an error
                    if(i === (this.representatives.length - 1)) console.log("Could not add the image to the representative '" + userId + "': representative not found.");
                }
            }
        }
        // If the image URL is valid, show an error
        else console.log("Invalid image url: " + url + ".");
    }

    // Method called to remove the user image
    removeUserImage(userId, isRepresentative)
    {
        // If the user isn't a representative
        if(isRepresentative !== true)
        {
            // If the user isn't undefined
            if(this.users[userId] !== undefined)
            {
                // If the user was created
                if(this.users[userId].nametag !== null && this.users[userId].nametag !== undefined)
                {
                    // Remove user video
                    this.users[userId].removeImage();
                }
                // If the user wasn't created yet, show an error
                else console.log("The user " + userId + " was not created yet.");
            }
            // If the user is undefined, show an error
            else console.log("No user " + userId + " available. Must be between 0 and 8.");
        }
        // If the user is a representative
        else if(isRepresentative === true)
        {
            // For each of the representatives created
            for(let i = 0; i < this.representatives.length; i++)
            {
                // If the id matches this representative's
                if(this.representatives[i].id === userId)
                {
                    // Set user image
                    this.representatives[i].removeImage();
                    break;
                }
                // If the id matches no representative, show an error
                if(i === (this.representatives.length - 1)) console.log("Could not remove the image from the representative '" + userId + "': representative not found.");
            }
        }
    }

    // Method called to set the user video
    addUserVideo(userId, videoTagId, isRepresentative)
    {
        // If the video tag can be found
        if(document.getElementById(videoTagId) !== undefined && document.getElementById(videoTagId) !== null)
        {
            // If the user isn't a representative
            if(isRepresentative !== true)
            {
                // If the user isn't undefined
                if(this.users[userId] !== undefined)
                {
                    // If the user was created
                    if(this.users[userId].nametag !== null && this.users[userId].nametag !== undefined)
                    {
                        // Set user video
                        this.users[userId].addVideo(videoTagId);
                    }
                    // If the user wasn't created yet, show an error
                    else console.log("The user " + userId + " was not created yet.");
                }
                // If the user is undefined, show an error
                else console.log("No user " + userId + " available. Must be between 0 and 8.");
            }
            // If the user is a representative
            else if(isRepresentative === true)
            {
                // For each of the representatives created
                for(let i = 0; i < this.representatives.length; i++)
                {
                    // If the id matches this representative's
                    if(this.representatives[i].id === userId)
                    {
                        // Set user image
                        this.representatives[i].addVideo(videoTagId);
                        break;
                    }
                    // If the id matches no representative, show an error
                    if(i === (this.representatives.length - 1)) console.log("Could not add the video to the representative '" + userId + "': representative not found.");
                }
            }
        }
        // If the video tag could not be found, show an error
        else console.log("Invalid video id: " + videoTagId + "; element not found.");
    }

    // Method called to remove the user video
    removeUserVideo(userId, isRepresentative)
    {
        // If the user isn't a representative
        if(isRepresentative !== true)
        {
            // If the user isn't undefined
            if(this.users[userId] !== undefined)
            {
                // If the user was created
                if(this.users[userId].nametag !== null && this.users[userId].nametag !== undefined)
                {
                    // Remove user video
                    this.users[userId].removeVideo();
                }
                // If the user wasn't created yet, show an error
                else console.log("The user " + userId + " was not created yet.");
            }
            // If the user is undefined, show an error
            else console.log("No user " + userId + " available. Must be between 0 and 8.");
        }
        // If the user is a representative
        else if(isRepresentative === true)
        {
            // For each of the representatives created
            for(let i = 0; i < this.representatives.length; i++)
            {
                // If the id matches this representative's
                if(this.representatives[i].id === userId)
                {
                    // Set user image
                    this.representatives[i].removeVideo();
                    break;
                }
                // If the id matches no representative, show an error
                if(i === (this.representatives.length - 1)) console.log("Could not remove the video from the representative '" + userId + "': representative not found.");
            }
        }
    }

    // Method called to set the user status
    setUserStatus(userId, userStatus, bool, isRepresentative)
    {
        // If the user isn't a representative
        if(isRepresentative !== true)
        {
            // If the user isn't undefined
            if(this.users[userId] !== undefined)
            {
                // If the user was created
                if(this.users[userId].nametag !== null && this.users[userId].nametag !== undefined)
                {
                    // Set the desired status
                    this.users[userId].setStatus(userStatus, bool);
                }
                // If the user wasn't created yet, show an error
                else console.log("The user " + userId + " was not created yet.");
            }
            // If the user is undefined, show an error
            else console.log("No user " + userId + " available. Must be between 0 and 8.");
        }
        // If the user is a representative
        else if(isRepresentative === true)
        {
            // For each of the representatives created
            for(let i = 0; i < this.representatives.length; i++)
            {
                // If the id matches this representative's
                if(this.representatives[i].id === userId)
                {
                    // Set the desired status
                    this.representatives[i].setStatus(userStatus, bool);
                    break;
                }
                // If the id matches no representative, show an error
                if(i === (this.representatives.length - 1)) console.log("Could not set the status to the representative '" + userId + "': representative not found.");
            }
        }
    }

    // Method called to get the user status
    getUserStatus(userId, isRepresentative)
    {
        // If the user isn't a representative
        if(isRepresentative !== true)
        {
            // If the user isn't undefined
            if(this.users[userId] !== undefined)
            {
                // If the user was created
                if(this.users[userId].nametag !== null && this.users[userId].nametag !== undefined)
                {
                    // Return the user status
                    return this.users[userId].status;
                }
                // If the user wasn't created yet, show an error
                else console.log("The user " + userId + " was not created yet.");
            }
            // If the user is undefined
            else console.log("No user " + userId + " available. Must be between 0 and 8.");
        }
        // If the user is a representative
        else if(isRepresentative === true)
        {
            // For each of the representatives created
            for(let i = 0; i < this.representatives.length; i++)
            {
                // If the id matches this representative's
                if(this.representatives[i].id === userId)
                {
                    // Return the user status
                    return this.representatives[i].status;
                }
                // If the id matches no representative, show an error
                if(i === (this.representatives.length - 1)) console.log("Could not get the status of the representative '" + userId + "': representative not found.");
            }
        }
    }

    // Method called to update the environment map of all elements
    updateMaterialsEnvMap()
    {
        // Go through all the model's children
        this.roomBackground.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof Mesh && child.material instanceof MeshStandardMaterial)
            {
                // Set environment map intensity
                child.material.envMapIntensity = this.environment.envMapIntensity;
            }
        });
    }

    // Private method called to cast a new raycast
    #castRaycast(coords)
    {
        // Set raycaster
        this.raycaster.setFromCamera(coords, this.camera.renderCamera);
        const intersection = this.raycaster.intersectObjects(this.interactives, false);

        // If the raycaster have intersected with the stands
        if(intersection.length > 0 && this.pointer.insideElem === false)
        {
            // If an element is already being hovered
            if(this.hoveredElem != null)
            {
                // If the user hovered a new element
                if(this.hoveredElem.name != intersection[0].object.name)
                {
                    // Trigger stopped hover event
                    this.trigger('stoppedHoveringUser');
                    // Save the new hovered element
                    this.hoveredElem = intersection[0].object;
                    // Trigger hover event
                    this.trigger('hoveringUser');
                }
            }
            // If no element was being hovered
            else
            {
                // Save the hovered element
                this.hoveredElem = intersection[0].object;
                // Trigger hover event
                this.trigger('hoveringUser');
            }
        }
        // If the user is not hovering an element
        else if(this.hoveredElem != null)
        {
            // Trigger stopped hover event
            this.trigger('stoppedHoveringUser');
            this.hoveredElem = null;
        }
    }

    // Private method called to update the raycast
    #updateRaycast()
    {
        // If the mouse is moving
        if(this.pointer.mouseMove)
        {
            // Reset mouse movement
            this.pointer.mouseMove = false;

            // Set raycast coords
            let coords = new Vector2(0, 0);
            if(this.pointer.insideElem === true) coords = this.pointer.mouse;

            // Cast raycast
            this.#castRaycast(coords);
        }
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Get delta time
        let delta = Math.min( 0.05, this.time.delta );

        // If the device is a desktop
        if(isMobile === false)
        {
            // Update the raycast
            this.#updateRaycast();
        }

        // For each of the users
        this.users.forEach(user =>
        {
            // Update the user
            user.update(delta);
        });
    }

    // Private method called to dispose the meeting room
    #disposeRoom()
    {
        // If the users array was created
        if(this.users instanceof Array)
        {
            // Dispose each of the users
            this.users.forEach(user =>
            {
                try { user.destroy() } catch(e) { console.log(e) };
            });
            this.users.length = 0;
        }
        // If the users array was created
        if(this.representatives instanceof Array)
        {
            // Dispose each of the users
            this.representatives.forEach(rep =>
            {
                try { rep.destroy() } catch(e) { console.log(e) };
            });
            this.representatives.length = 0;
        }

        // Remove variables
        if(this.usersPositions instanceof Array) this.usersPositions.length = 0;
        if(this.interactives instanceof Array) this.interactives.length = 0;
        this.mainScreen = null;
        this.hoveredElem = null;
        this.selfUser = null;
        this.offMaterial = null;
        this.raycaster = null;
        this.screenLayout = null;

        // Dispose the model
        try { this.disposer.disposeElements(this.recIcon) } catch(e) { console.log(e) };
        try { this.disposer.disposeElements(this.meetingName) } catch(e) { console.log(e) };
        try { this.disposer.disposeElements(this.sharingScreen[1]) } catch(e) { console.log(e) };
        try { this.disposer.disposeElements(this.roomBackground) } catch(e) { console.log(e) };
        this.sharingScreen = null;
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Dispose the meeting room
        try { this.#disposeRoom() } catch(e) { console.log(e) };

        // Stop listeners
        try { this.resources.off('loadedResources') } catch(e) { console.log(e) };
        try { if(isMobile === true) this.pointer.off('pointerTouch') } catch(e) { console.log(e) };
        try { this.off('hoveringUser') } catch(e) { console.log(e) };
        try { this.off('stoppedHoveringUser') } catch(e) { console.log(e) };

        // Remove references
        this.time = null;
        this.resources = null;
        this.disposer = null;
        this.environment = null;
        this.scene = null;
        this.disposer = null;
        this.camera = null;
        this.composer = null;
        this.pointer = null;
        this.experience = null;
    }
}