import { LinearFilter, RGBAFormat, WebGLRenderTarget, Vector2, AdditiveBlending, ShaderMaterial } from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';

import Experience from "./Experience";
import EventEmitter from './Utils/EventEmitter.js';

let isMobile;

export default class Composer extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super(); 

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.renderer = this.experience.renderer;

        // Set composer
        if(!this.experience.INTERRUPTED) this.setComposer();

        // Listen for the camera switch event
        this.camera.on('switchCamera', () =>
        {
            // Reset composer
            if(!this.experience.INTERRUPTED) this.clearComposer();
            if(!this.experience.INTERRUPTED) this.setComposer();
        });
    }

    // Method called to create and set up the composer
    setComposer()
    {
        // Create the render pass
        this.renderScene = new RenderPass( this.scene, this.camera.renderCamera );
        this.renderScene.renderToScreen = true;

        // Set rendering parameters
        let renderTargetParameters = { minFilter: LinearFilter, magFilter: LinearFilter, format: RGBAFormat, stencilBuffer: true };
        let renderTarget = new WebGLRenderTarget(this.sizes.width, this.sizes.height, renderTargetParameters);
        // Create the composer
        this.instance = new EffectComposer( this.renderer.instance, renderTarget );

        // Set size and pixel ratio
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);

        // Add render pass to the composer
        if(!this.experience.INTERRUPTED) this.instance.addPass( this.renderScene );

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // If the render quality is high
            if(this.experience.parameters.QUALITY === 2)
            {
                this.#setSSAOPass();
                // Add SSAO pass to the composer
                this.instance.addPass( this.ssaoPass );
            }
        }

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            this.#setOutlinePass();
            // Add outline pass to the composer
            this.instance.addPass( this.outlinePass );
        }
        
        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            this.#setGammaCorrectionPass();
            // Add gamma correction pass to the composer
            this.instance.addPass( this.gammaCorrectionPass );
        }
        
        // Add FXAA pass to the composer if the render quality is medium or high
        if(this.experience.parameters.QUALITY > 0)
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Get the mobile detector class from the experience
                this.mobileDetector = this.experience.mobileDetector;
                // Get if the device is a mobile
                isMobile = this.mobileDetector.isMobile;
                // Remove reference
                this.mobileDetector = null;
                
                // If the device is not a mobile
                if(isMobile === false)
                {
                    this.#setFXAAPass();
                    // Add FXAA pass to the composer
                    this.instance.addPass( this.fxaaPass );
                }
            }
            
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                this.#setVignettePass();
                // Add vignette pass to the composer
                this.instance.addPass( this.vignettePass );
            }
        }
    }

    // Method called to clear all the passes
    clearComposer()
    {
        // If the passes array was created
        if(this.instance.passes instanceof Array)
        {
            // For each of the composer passes
            this.instance.passes.forEach(pass =>
            {
                // Remove pass
                try { this.instance.removePass( pass ) } catch(e) { console.log(e) };
            });
        }
    }

    // Private method called to set up the SSAO pass
    #setSSAOPass()
    {
        // Set the ambient occlusion pass
        this.ssaoPass = new SSAOPass( this.scene, this.camera.renderCamera, this.sizes.width, this.sizes.height );
        this.ssaoPass.kernelRadius = 16;
        this.ssaoPass.minDistance = 0.001;
        this.ssaoPass.maxDistance = 0.3;
        this.ssaoPass.output = SSAOPass.OUTPUT.Default;
        this.ssaoPass.transparent = true;
    }

    // Private method called to set up the Gamma Correction pass
    #setGammaCorrectionPass()
    {
        // Set gamma correction pass for color balancing
        this.gammaCorrectionPass = new ShaderPass( GammaCorrectionShader );
        this.gammaCorrectionPass.material.name = "GammaCorrectionPass";
    }

    // Private method called to set up the FXAA pass
    #setFXAAPass()
    {
        // Set the fxaa pass configurations for antialias
        this.fxaaPass = new ShaderPass( FXAAShader );
        this.fxaaPass.material.name = "FXAAPass";
        this.fxaaPass.uniforms['resolution'].value.set(1 / (this.sizes.width * this.sizes.pixelRatio), 1 / (this.sizes.height * this.sizes.pixelRatio));
    }

    // Private method called to set up the Outline pass
    #setOutlinePass()
    {
        // Set outline pass for the hovered stands
        this.outlinePass = new OutlinePass(new Vector2(this.sizes.width, this.sizes.height), this.scene, this.camera.renderCamera);
        this.outlinePass.renderToScreen = true;
        // Change to normal blending as the default (additive blending) isn't visible enough on white environments
        this.outlinePass.overlayMaterial.blending = AdditiveBlending;
        this.outlinePass.edgeStrength = 2;
        this.outlinePass.edgeGlow = 2;
        this.outlinePass.edgeThickness = 2;
        this.outlinePass.visibleEdgeColor.set(0xffffff);
        this.outlinePass.hiddenEdgeColor.set(0x000000);
    }

    // Private method called to set up the Vignette pass
    #setVignettePass()
    {
        // Create vertex shader
        const vShader = `
            varying vec2 vUv;
            void main()
            {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `;

        // Create fragment shader
        const fShader = `
            uniform float offset;
            uniform float darkness;
            uniform sampler2D tDiffuse;

            varying vec2 vUv;

            void main()
            {
                vec4 texel = texture2D( tDiffuse, vUv );
                vec2 uv = ( vUv - vec2( 0.5 ) ) * vec2( offset );
                gl_FragColor = vec4( mix( texel.rgb, vec3( 1.0 - darkness ), dot( uv, uv ) ), texel.a );
            }
        `;

        // Create vignette shader material
        const vignette = new ShaderMaterial(
        {
            uniforms:
            {
                tDiffuse: { type: 't', value: null },
                offset:   { type: 'f', value: 1.0 },
                darkness: { type: 'f', value: 1.0 }
            },
            vertexShader: vShader,
            fragmentShader: fShader
        });

        // Set the vignette pass configurations
        this.vignettePass = new ShaderPass( vignette );
        this.vignettePass.material.name = "VignettePass";
        this.vignettePass.material.uniforms['offset'].value = 1;
        this.vignettePass.material.uniforms['darkness'].value = 1.15;
    }

    // Method called by external classes to update the outlined objects
    updateOutlineObjects(objects)
    {
        // If the first person camera mode is active
        if(this.camera.FIRST_PERSON_CAM === true)
        {
            this.outlinePass.edgeStrength = 2;
            this.outlinePass.edgeThickness = 2;
        }
        // If the first person camera mode is active
        else if(this.camera.FIRST_PERSON_CAM === false)
        {
            this.outlinePass.edgeStrength = 3;
            this.outlinePass.edgeThickness = 3;
        }

        // If received a null value
        if(objects === null)
        {
            // Remove all objects from the outline pass
            this.outlinePass.selectedObjects = [];
        }
        // If received an object
        else
        {
            // If the object received is an array
            if(objects instanceof Array)
            {
                // Set them to be affected by the outline pass
                this.outlinePass.selectedObjects = objects;
            }
            // If the object received isn't an array
            else
            {
                // Set them to be affected by the outline pass
                this.outlinePass.selectedObjects = [objects];
            }
        }
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Set new size and pixel ratio
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);

        // Update fxaa pass resolution
        if(this.fxaaPass) this.fxaaPass.uniforms['resolution'].value.set(1 / (this.sizes.width * this.sizes.pixelRatio), 1 / (this.sizes.height * this.sizes.pixelRatio));
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Render
        this.instance.render();
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Stop listening for the switch camera event
        try { this.camera.off('switchCamera') } catch(e) { console.log(e) };

        // Clear composer
        this.clearComposer();

        // Reset variables
        this.renderScene = null;
        this.instance = null;
        isMobile = null;

        // Remove passes references
        if(this.ssaoPass) this.ssaoPass = null;
        if(this.gammaCorrectionPass) this.gammaCorrectionPass = null;
        if(this.fxaaPass) this.fxaaPass = null;
        if(this.outlinePass) this.outlinePass = null;
        if(this.vignettePass) this.vignettePass = null;

        // Remove references
        this.experience = null;
        this.sizes = null;
        this.scene = null;
        this.camera = null;
        this.renderer = null;
    }
}