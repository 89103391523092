import * as THREE from "three";
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';

import Experience from "../Experience";

export default class Subway
{
    // Set constructor
    constructor(BACKGROUND_OPTION)
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the background option
        this.BACKGROUND_OPTION = BACKGROUND_OPTION;
    }

    // Method called to create the subway
    setSubway(id, delay)
    {
        // Create new instances
        this.instance = {};
        this.instance.id = id;

        // Get the needed classes from the experience
        this.resources = this.experience.resources;
        this.scene = this.experience.scene;

        // Get model from the resources
        let gltf
        if(this.BACKGROUND_OPTION === 0) gltf = this.resources.items['subway'];
        else gltf = this.resources.items['vehicle_anim'];

        // Clone skinned mesh model
        this.instance.model = SkeletonUtils.clone(gltf.scene);

        // Add to the scene
        this.scene.add(this.instance.model);

        // Set animation mixer
        this.instance.mixer = new THREE.AnimationMixer(this.instance.model);
        this.instance.animation = this.instance.mixer.clipAction(gltf.animations[this.instance.id]);
        if(this.BACKGROUND_OPTION === 0) this.instance.animation.loop = THREE.LoopPingPong;
        else this.instance.animation.loop = THREE.LoopRepeat;

        // If an animation delay was provided
        if(delay !== undefined)
        {
            // Wait for the delay time before playing the animation
            setTimeout(() =>
            {
                // If the experience is still instantiated
                if(this.experience)
                {
                    // If the experience wasn't interrupted
                    if(!this.experience.INTERRUPTED)
                    {
                        // If this subway's instance is still instantiated
                        if(this.instance)
                        {
                            // Play animation
                            if(this.instance.animation) this.instance.animation.play();
                        }
                    }
                }
            }, delay);
        }
        // If no animation delay was provided, play animation instantly
        else this.instance.animation.play();

        // Remove references
        this.resources = null;
        this.scene = null;
    }

    // Method propagated by the experience each tick event
    update(delta)
    {
        // If the scene is loaded
        if(this.experience.SCENE_LOADED)
        {
            // Update the animation mixer
            this.instance.mixer.update(delta);
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Stop all animation actions
        try { this.instance.mixer.stopAllAction() } catch(e) { console.log(e) };

        try
        {
            // Get the disposer class from the experience
            this.disposer = this.experience.disposer;
            // Dispose the model
            this.disposer.disposeElements(this.instance.model);
        }
        catch(e) { console.log(e) };

        // Reset instances
        this.instance = null;

        // Remove references
        this.disposer = null;
        this.experience = null;
    }
}