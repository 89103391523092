import * as THREE from "three";
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';

import Experience from "../Experience";

export default class User
{
    // Set constructor
    constructor()
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the disposer class from the experience
        this.disposer = this.experience.disposer;
        this.composer = this.experience.composer;
        this.resources = this.experience.resources;
    }

    // Method called to set an id to the representative (not needed by users)
    setId(id)
    {
        // Save the id
        this.id = id;
    }

    // Method called to set the robot head or the representative screen
    setHead(head)
    {
        // If the desired head is a screen, and not a robot head
        if(head.name.includes("userHead") === false)
        {
            // If a head was already created, disable the current head
            if(this.userHead !== null && this.userHead !== undefined) this.userHead.visible = false;
        }

        // Set head object
        this.userHead = head;

        // If the desired head is a robot head
        if(this.userHead.name.includes("userHead"))
        {
            // Clone and adjust the head material
            this.userHead.material = head.material.clone();
            this.userHead.material.side = THREE.FrontSide;

            // Clone and adjust the screen material
            this.userHead.children[0].material = new THREE.MeshBasicMaterial();
            this.userHead.children[0].material.side = THREE.FrontSide;

            // Go through all the model's children
            this.userHead.traverse((child) =>
            {
                // If child is a mesh object and their material is a standard material
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
                {
                    // Set children material encoding
                    child.material.encoding = THREE.sRGBEncoding;
                    if(child.material.map != null) child.material.map.encoding = THREE.sRGBEncoding;
                    child.material.needsUpdate = true;
                }
            });
        }
        // If the desired head is a screen,
        else
        {
            // Enable the screen
            this.userHead.visible = true;

            // If the user already has a nametag and status elements created
            if(this.nametag !== null && this.nametag !== undefined)
            {
                // Set the nametag position
                this.#setNametagScreenPosition();
                // Set the hand up icon
                this.#setHandUpIcon();
                // Set the status elements positions
                Object.keys(this.statusElems).forEach((key) => { this.#setStatusScreenPosition(this.statusElems[key]) });
            }
        }
    }

    // Method called to set up the robot body
    setBody(point)
    {
        // Get model from the resources
        let gltf = this.resources.items['robot'];
        // Clone skinned mesh model
        this.body = SkeletonUtils.clone(gltf.scene);

        // Set robot position
        this.body.position.copy(point.position);
        this.body.position.y -= 0.675;
        this.body.rotation.copy(point.rotation);

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Set the animations object
            this.animations = {};
            // Set animation mixer
            this.animations.mixer = new THREE.AnimationMixer(this.body);

            // Get the off animation
            this.animations.off = this.animations.mixer.clipAction(gltf.animations[0]);
            this.animations.off.setLoop(THREE.LoopRepeat);
            this.animations.off.play();
            // Get the on animation
            this.animations.on = this.animations.mixer.clipAction(gltf.animations[1]);
            this.animations.on.setLoop(THREE.LoopRepeat);
            // Get the waiting animation
            this.animations.waiting = this.animations.mixer.clipAction(gltf.animations[2]);
            this.animations.waiting.setLoop(THREE.LoopOnce);
            this.animations.waiting.clampWhenFinished = true;
        }

        // Go through all the model's children
        this.body.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
            {
                // Set children material encoding
                child.material.encoding = THREE.sRGBEncoding;
                if(child.material.map != null) child.material.map.encoding = THREE.sRGBEncoding;
                child.material.needsUpdate = true;

                // If the material is the robot color
                if(child.material.name === "robo_mainColor") this.userColor = child.material;
            }
        });

        // Add to the scene
        if(!this.experience.INTERRUPTED) this.experience.scene.add(this.body);
    }

    // Method called to set up the robot color
    setRobotColor(hexcode)
    {
        // If the user has a body and a robot color material
        if(this.body && this.userColor)
        {
            try
            {
                // Set hexcode
                this.userColor.color.setHex(hexcode).convertSRGBToLinear();
                this.userColor.needsUpdate = true;
            }
            // Catch errors
            catch(e) { console.log(e) };
        }
    }

    // Method called to create the user
    createUser(name, acronym, color)
    {
        // Set status
        this.status = {};
        this.status.waiting = false;
        this.status.talking = false;
        this.status.muted = false;
        this.status.blocked = false;

        // If the user has animations
        if(this.animations)
        {
            // Play the "on" animation
            this.animations.off.stop();
            this.animations.on.play();
        }

        // Save the user name
        this.name = name;
        this.acronym = acronym;
        this.color = color;
        
        // Set name tag, default screen and user status elements
        if(!this.experience.INTERRUPTED) this.#setNameTag();
        if(!this.experience.INTERRUPTED) this.#setDefaultScreen();
        if(!this.experience.INTERRUPTED) this.#setStatusElements();
    }

    // Private method called to set up the name tag
    #setNameTag()
    {
        // Set canvas width
        this.width = this.name.length * 360;
        // Create plane mesh above the robot head
        this.nametag = new THREE.Mesh(new THREE.PlaneGeometry((this.width / 4000), 0.2), new THREE.MeshBasicMaterial({ transparent: true }));
        this.nametag.renderOrder = 1;
        this.nametag.material.depthTest = false;

        // If the head is a robot head
        if(this.userHead.name.includes("userHead"))
        {
            // Set the nametag position and rotation
            const pos = this.userHead.getWorldPosition(new THREE.Vector3(0, 0, 0));
            this.nametag.position.set(pos.x, pos.y, pos.z);
            this.nametag.translateY(1.35);
            this.nametag.lookAt(this.experience.camera.renderCamera.position);
        }
        // If the head is a screen, set the nametag position
        else this.#setNametagScreenPosition();

        // Add to the scene
        this.experience.scene.add(this.nametag);

        // Create canvas
        this.nameCanvas = document.createElement('canvas');
        this.nameCanvasCtx = this.nameCanvas.getContext('2d');

        // Set canvas height and width to fit inside the geometry
        this.nameCanvas.height = 600;
        this.nameCanvas.width = this.width;

        // Create and update texture
        const nameTexture = new THREE.Texture(this.nameCanvas);
        nameTexture.generateMipmaps = false;
        nameTexture.needsUpdate = true;

        // Set text texture as the material map
        this.nametag.material.map = nameTexture;
        this.nametag.material.side = THREE.DoubleSide;
        this.nametag.material.map.encoding = THREE.sRGBEncoding;
        this.nametag.material.encoding = THREE.sRGBEncoding;

        // Draw the rounded rectangle
        this.nameCanvasCtx.strokeStyle = "rgba(0, 0, 0, .75)";
        this.nameCanvasCtx.beginPath();
        this.nameCanvasCtx.roundRect(0, 0, this.width, 600, 600);
        this.nameCanvasCtx.closePath();
        this.nameCanvasCtx.stroke();
        this.nameCanvasCtx.fill();

        // Fill the canvas with the user name
        this.nameCanvasCtx.fillStyle = "white";
        this.nameCanvasCtx.textAlign = "center";
        this.nameCanvasCtx.textBaseline = "middle";
        this.nameCanvasCtx.font = "400px Nasalization";
        this.nameCanvasCtx.fillText(this.name, (this.width / 2), 320);

        // Update texture
        nameTexture.needsUpdate = true;
        this.nametag.material.needsUpdate = true;
    }

    // Private method called to dinamically set the nametag position and scale
    #setNametagScreenPosition()
    {
        // Set the base position
        const pos = this.userHead.getWorldPosition(new THREE.Vector3(0, 0, 0));
        this.nametag.position.set(pos.x, pos.y, pos.z);
        this.nametag.rotation.y = -Math.PI * 0.5;
        // Get the current screen layout
        const layout = this.experience.meetingRoom.screenLayout[0];

        // If only 1 representative is present
        if(layout == 0)
        {
            // Set the nametag position to the bottom left of the screen
            this.nametag.position.y += -0.51;
            this.nametag.position.z += -1.05 + ((this.width / 4000) / 2);
            this.nametag.scale.set(1, 1, 1);
        }
        // If 2 representatives are present
        else if(layout == 1)
        {
            // Set the nametag position to the bottom left of the screen
            this.nametag.position.y += -0.29;
            this.nametag.position.z += -0.65 + ((this.width / 4000) / (2 / 0.8));
            this.nametag.scale.set(0.8, 0.8, 0.8);
        }
        // If 3 of 4 representatives are present
        else if(layout == 2)
        {
            // Set the nametag position to the bottom left of the screen
            this.nametag.position.y += -0.22;
            this.nametag.position.z += -0.51 + ((this.width / 4000) / (2 / 0.7));
            this.nametag.scale.set(0.7, 0.7, 0.7);
        }
        // If one representative is sharing their screen
        else if(layout == 3)
        {
            // Set the nametag position to the bottom left of the screen
            this.nametag.position.y += -0.1;
            this.nametag.position.z += -0.27 + ((this.width / 4000) / (2 / 0.3));
            this.nametag.scale.set(0.3, 0.3, 0.3);
        }

        // If the waiting status is active, consider it when repositioning
        if(this.status.waiting === true) this.nametag.position.z += (600 / 4000) * this.nametag.scale.x;
    }

    // Private method called to dinamically set the status elements position and scale
    #setStatusScreenPosition(status)
    {
        // Set the base position
        const pos = this.userHead.getWorldPosition(new THREE.Vector3(0, 0, 0));
        status.position.set(pos.x, pos.y, pos.z);
        status.rotation.y = -Math.PI * 0.5;
        // Get the current screen layout
        const layout = this.experience.meetingRoom.screenLayout[0];

        // If only 1 representative is present
        if(layout == 0)
        {
            // Set the status element position to the top right of the screen
            status.position.y += 0.54;
            status.position.z += 1;
            status.scale.set(1, 1, 1);
        }
        // If 2 representatives are present
        else if(layout == 1)
        {
            // Set the status element position to the top right of the screen
            status.position.y += 0.3;
            status.position.z += 0.59;
            status.scale.set(0.8, 0.8, 0.8);
        }
        // If 3 or 4 representatives are present
        else if(layout == 2)
        {
            // Set the status element position to the top right of the screen
            status.position.y += 0.23;
            status.position.z += 0.45;
            status.scale.set(0.7, 0.7, 0.7);
        }
        // If one representative is sharing their screen
        else if(layout == 3)
        {
            // Set the status element position to the top right of the screen
            status.position.y += 0.11;
            status.position.z += 0.23;
            status.scale.set(0.3, 0.3, 0.3);
        }
    }

    // Private method called to set up the user default screen
    #setDefaultScreen()
    {
        // Create canvas
        const defaultCanvas = document.createElement('canvas');
        const defaultCanvasCtx = defaultCanvas.getContext('2d');

        // Set canvas height and width to fit inside the geometry
        defaultCanvas.height = 660;
        defaultCanvas.width = 960;

        // Create and update texture
        const defaultTexture = new THREE.Texture(defaultCanvas);
        defaultTexture.generateMipmaps = false;
        defaultTexture.needsUpdate = true;

        // Set text texture as the material map
        this.defaultScreen = new THREE.MeshBasicMaterial({ map: defaultTexture, side: THREE.DoubleSide });
        this.defaultScreen.map.encoding = THREE.sRGBEncoding;
        this.defaultScreen.encoding = THREE.sRGBEncoding;
        this.defaultScreen.map.flipY = false;

        // If the hexcode is on the wrong format, set correct format
        if(this.color.slice(0, 2) === '0x') this.color = '#' + this.color.slice(2);

        defaultCanvasCtx.fillStyle = this.color;
        defaultCanvasCtx.beginPath();
        defaultCanvasCtx.arc(480, 330, 260, 0, 2 * Math.PI);
        defaultCanvasCtx.fill();

        defaultCanvasCtx.fillStyle = "white";
        defaultCanvasCtx.textAlign = "center";
        defaultCanvasCtx.textBaseline = "middle";
        defaultCanvasCtx.font = "150px Nasalization";
        defaultCanvasCtx.fillText(this.acronym.slice(0, 2).toUpperCase(), 480, 350);
        // Update texture
        defaultTexture.needsUpdate = true;

        // If the head is a robot head, set the default screen
        if(this.userHead.name.includes("userHead")) this.userHead.children[0].material = this.defaultScreen;
        // If the head is a screen
        else
        {
            // Set the default material to the screen
            this.userHead.material = this.defaultScreen;
            // Save the default material as the current screen
            this.currentScreen = this.defaultScreen;
        }
    }

    // Private method called to set up the user HUD elements
    #setStatusElements()
    {
        // Create object
        this.statusElems = { "muted": null, "talking": null, "blocked": null };
        // Create basic geometry for the status
        const geometry = new THREE.PlaneGeometry(0.2, 0.2);

        // Create the muted and talking elements
        this.statusElems.muted = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ map: this.resources.items.muted, transparent: true, side: THREE.DoubleSide, visible: false }));
        this.statusElems.talking = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ map: this.resources.items.talking, transparent: true, side: THREE.DoubleSide, visible: false }));
        this.statusElems.blocked = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ map: this.resources.items.blocked, transparent: true, side: THREE.DoubleSide, visible: false }));

        // Go through all the JSON objects
        Object.keys(this.statusElems).forEach((key) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the head is a robot head
                if(this.userHead.name.includes("userHead"))
                {
                    // Set the element position and rotation
                    this.statusElems[key].position.copy(this.nametag.position);
                    this.statusElems[key].lookAt(this.experience.camera.renderCamera.position);
                    this.statusElems[key].translateX((this.width / 8000) + 0.1);

                    // Set children material encoding
                    this.statusElems[key].material.encoding = THREE.sRGBEncoding;
                    this.statusElems[key].material.map.encoding = THREE.sRGBEncoding;
                    this.statusElems[key].material.needsUpdate = true;
                }
                // If the head is a screen
                else
                {
                    // Set status element to always render on top
                    this.statusElems[key].renderOrder = 1;
                    this.statusElems[key].material.depthTest = false;
                    // Set the status element position
                    this.#setStatusScreenPosition(this.statusElems[key]);
                }

                // Add the status element to the scene
                this.experience.scene.add(this.statusElems[key]);
            }
        });
    }

    // Private method called to set up the hand icon used by the representative's waiting status
    #setHandUpIcon()
    {
        // If the hand up element wasn't created yet
        if(this.handUp === null || this.handUp === undefined)
        {
            // Create the plane mesh for the icon
            this.handUp = new THREE.Mesh(new THREE.PlaneGeometry(0.11, 0.16), new THREE.MeshBasicMaterial({ map: this.resources.items.handUpIcon, transparent: true }));
            this.handUp.material.map.encoding = THREE.sRGBEncoding;
            this.handUp.material.encoding = THREE.sRGBEncoding;
            this.handUp.rotation.y = -Math.PI * 0.5;
            // Render it on top of the nametag
            this.handUp.renderOrder = 2;
            this.handUp.material.depthTest = false;
            // Disable the icon
            this.handUp.visible = false;
            // Add the icon to the scene
            this.experience.scene.add(this.handUp);
        }

        // Set the hand element scale accordingly to the nametag
        this.handUp.scale.set(this.nametag.scale.x, this.nametag.scale.y, this.nametag.scale.z);

        // Set the hand element position
        const pos = this.nametag.getWorldPosition(new THREE.Vector3(0, 0, 0));
        this.handUp.position.set(pos.x, pos.y, pos.z);
        this.handUp.position.z -= ((this.width / 2) - (600 / 2.75)) / 4000 * this.nametag.scale.x;
    }

    // Method called to add an image to the user screen
    addImage(url, color)
    {
        // If the hexcode is empty, set default value
        if(color !== "" && color !== null && color !== undefined)
        {
            // If the hexcode is on the wrong format
            if(color.slice(0, 1) === '#')
            {
                // Set to correct format
                color = '0x' + color.slice(1);
            }
        }
        else color = '0x000000'

        // Load image
        this.resources.loaders.textureLoader.load(
            url,
            // OnLoad callback
            (file) =>
            {
                // If the user head was created
                if(this.userHead)
                {
                    // If the head is a robot head
                    if(this.userHead.name.includes("userHead"))
                    {
                        // Create groups to load this materials, from the first pixel to the infinity
                        this.userHead.children[0].geometry.addGroup( 0, Infinity, 0 );
                        this.userHead.children[0].geometry.addGroup( 0, Infinity, 1 );
                    }
                    // If the head is a screen
                    else
                    {
                        // Create groups to load this materials, from the first pixel to the infinity
                        this.userHead.geometry.addGroup( 0, Infinity, 0 );
                        this.userHead.geometry.addGroup( 0, Infinity, 1 );
                    }

                    // Create background material
                    const background = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide });
                    background.color.setHex(color).convertSRGBToLinear();
                    background.encoding = THREE.sRGBEncoding;
                    
                    // Get image
                    const foregroundImage = file.image;
                    foregroundImage.flipY = false;
                    foregroundImage.generateMipmaps = false;

                    // Create canvas
                    const foregroundCanvas = document.createElement('canvas');
                    const foregroundCanvasCtx = foregroundCanvas.getContext('2d');

                    // Set canvas height and width to fit inside the geometry
                    foregroundCanvas.height = foregroundImage.height;
                    foregroundCanvas.width = foregroundImage.height * 1.5;

                    // Adjust the canvas size if the image is too narrow
                    if(foregroundImage.width < (foregroundCanvas.height / 2.5) * 2)
                    {
                        foregroundCanvas.height /= 1.5;
                        foregroundCanvas.width /= 1.5;
                    }

                    // Find the needed displacement to centralize the image
                    let displacement = [0, 0];
                    displacement[0] = (-foregroundImage.width / 2) + (foregroundCanvas.width / 2);
                    displacement[1] = (-foregroundImage.height / 2) + (foregroundCanvas.height / 2);

                    // Create and update texture
                    const foregroundTexture = new THREE.Texture(foregroundCanvas);
                    foregroundTexture.generateMipmaps = false;
                    foregroundTexture.needsUpdate = true;

                    // Set text texture as the material map
                    const foreground = new THREE.MeshBasicMaterial({ map: foregroundTexture, transparent: true });
                    foreground.map.encoding = THREE.sRGBEncoding;
                    foreground.encoding = THREE.sRGBEncoding;
                    foreground.map.flipY = false;

                    // Fill the circle
                    foregroundCanvasCtx.fillStyle = "white";
                    foregroundCanvasCtx.beginPath();
                    foregroundCanvasCtx.arc(foregroundCanvas.width/2, foregroundCanvas.height/2, foregroundCanvas.height/2.5, 0, 2 * Math.PI);
                    foregroundCanvasCtx.fill();
                    // Load only overlayed pixels
                    foregroundCanvasCtx.globalCompositeOperation = "source-in";
                    // Draw the image over the circle mask
                    foregroundCanvasCtx.drawImage(foregroundImage, displacement[0], displacement[1]);
                    // Update texture
                    foregroundTexture.needsUpdate = true;

                    // If the head is a robot head
                    if(this.userHead.name.includes("userHead"))
                    {
                        // Add materials to the screen
                        this.userHead.children[0].material = [background, foreground];
                    }
                    // If the head is a screen
                    else
                    {
                        // Add materials to the screen
                        this.userHead.material = [background, foreground]
                        this.currentScreen = [background, foreground]
                    }
                }
            }
        );
    }

    // Method called to remove the image at the user screen
    removeImage()
    {
        // If the head is a robot head
        if(this.userHead.name.includes("userHead"))
        {
            // Dispose the image material
            this.disposer.disposeElements(this.userHead.children[0].material[0]);
            this.disposer.disposeElements(this.userHead.children[0].material[1]);
            this.userHead.children[0].geometry.clearGroups();
            // Set the default screen
            this.userHead.children[0].material = this.defaultScreen;
        }
        // If the head is a screen
        else
        {
            // Dispose the image material
            this.disposer.disposeElements(this.userHead.material[0]);
            this.disposer.disposeElements(this.userHead.material[1]);
            this.userHead.geometry.clearGroups();
            // Set the default screen
            this.userHead.material = this.defaultScreen;
            this.currentScreen = this.defaultScreen;
        }
    }

    // Method called to add a video tag to the user
    addVideo(videoTagId)
    {
        // Get video element
        const video = document.getElementById(videoTagId);

        // Create video texture
        const texture = new THREE.VideoTexture(video);
        texture.generateMipmaps = false;

        // If the head is a robot head
        if(this.userHead.name.includes("userHead"))
        {
            // Set the video texture to the material
            this.userHead.children[0].material = new THREE.MeshBasicMaterial({ map: texture });
            this.userHead.children[0].material.map.flipY = false;
            // Set children material encoding
            this.userHead.children[0].material.encoding = THREE.sRGBEncoding;
            this.userHead.children[0].material.map.encoding = THREE.sRGBEncoding;
            this.userHead.children[0].material.needsUpdate = true;
        }
        // If the head is a screen
        else
        {
            // Set up the video texture
            const videoScreen = new THREE.MeshBasicMaterial({ map: texture });
            videoScreen.map.flipY = false;
            videoScreen.encoding = THREE.sRGBEncoding;
            videoScreen.map.encoding = THREE.sRGBEncoding;
            videoScreen.needsUpdate = true;

            // Set the video texture to the material
            this.userHead.material = videoScreen;
            this.currentScreen = videoScreen;
        }
    }

    // Method called to remove the video tag from the user
    removeVideo()
    {
        // If the head is a robot head
        if(this.userHead.name.includes("userHead"))
        {
            // Dispose video material
            this.disposer.disposeElements(this.userHead.children[0].material);
            // Set the default screen
            this.userHead.children[0].material = this.defaultScreen;
        }
        // If the head is a screen
        else
        {
            // Dispose video material
            this.disposer.disposeElements(this.userHead.material);
            // Set the default screen
            this.userHead.material = this.defaultScreen;
            this.currentScreen = this.defaultScreen;
        }
    }

    // Set this user status
    setStatus(status, bool)
    {
        // Assess the user status
        switch(status)
        {
            // If the waiting status is being evaluated
            case 0:
            case "waiting":
                // Set the status
                this.status.waiting = bool;

                // If the head is a robot head
                if(this.userHead.name.includes("userHead"))
                {
                    // If the status is being set
                    if(bool === true)
                    {
                        // Stop the other animations
                        if(this.animations.off.isRunning() === true) this.animations.off.stop();
                        if(this.animations.on.isRunning() === true) this.animations.on.stop();

                        // Play the waiting animation
                        if(this.animations.waiting.isRunning() === false) this.animations.waiting.play();
                        this.animations.waiting.setEffectiveTimeScale(1);
                        this.animations.waiting.paused = false;
                    }
                    // If the status is being removed
                    else
                    {
                        // Play the waiting animation in reverse
                        this.animations.waiting.setEffectiveTimeScale(-1);
                        this.animations.waiting.paused = false;

                        // Play the on animation
                        setTimeout(() => { if(this.animations) this.animations.on.play() }, 1000);
                    }
                }
                // If the head is a screen
                else if(this.userHead.name.includes("userHead") === false)
                {
                    // Verify if the nametag need to have an width addition
                    let addition = 0;
                    if(bool === true) addition = 600;

                    if(this.nametag)
                    {
                        // Create a new geometry for the nametag
                        const newGeometry = new THREE.PlaneGeometry(((this.width  + addition) / 4000), 0.2);
                        try { this.nametag.geometry.dispose() } catch(e) { console.log(e) };
                        try { this.nametag.material.map.dispose() } catch(e) { console.log(e) };
                        this.nametag.geometry = newGeometry;

                        // Set the new canvas width
                        this.nameCanvas.width = this.width + addition;

                        // Create and update the nametag texture
                        const nameTexture = new THREE.Texture(this.nameCanvas);
                        nameTexture.generateMipmaps = false;
                        nameTexture.needsUpdate = true;
                        this.nametag.material.map = nameTexture;

                        // If the waiting status is false
                        if(bool === false)
                        {
                            // Set the nametag position further to the left
                            this.nametag.position.z -= (600 / 4000) * this.nametag.scale.x;
                            // Set the black fill style
                            this.nameCanvasCtx.strokeStyle = "rgba(0, 0, 0, .75)";
                            this.nameCanvasCtx.fillStyle = "black";
                        }
                        // If the waiting status is true
                        else if(bool === true)
                        {
                            // Set the nametag position further to the right
                            this.nametag.position.z += (600 / 4000) * this.nametag.scale.x;
                            // Set the white fill style
                            this.nameCanvasCtx.strokeStyle = "rgba(255, 255, 255, .75)";
                            this.nameCanvasCtx.fillStyle = "white";
                        }
                        // Draw the rounded rectangle
                        this.nameCanvasCtx.beginPath();
                        this.nameCanvasCtx.roundRect(0, 0, this.width + addition, 600, 600);
                        this.nameCanvasCtx.closePath();
                        this.nameCanvasCtx.stroke();
                        this.nameCanvasCtx.fill();

                        // Set the color of the letters to contrast the rounded rectangle
                        if(bool === false) this.nameCanvasCtx.fillStyle = "white";
                        else if(bool === true) this.nameCanvasCtx.fillStyle = "black";
                        // Fill the canvas with the user name
                        this.nameCanvasCtx.textAlign = "center";
                        this.nameCanvasCtx.textBaseline = "middle";
                        this.nameCanvasCtx.font = "400px Nasalization";
                        this.nameCanvasCtx.fillText(this.name, ((this.width) / 2 + addition), 320);

                        // Update the nametag texture
                        this.nametag.material.needsUpdate = true;

                        // Set the hand up icon
                        this.#setHandUpIcon();
                        // Enable the hand icon
                        this.handUp.visible = bool;
                    }
                    
                }
            break;

            // If the talking status is being evaluated
            case 1:
            case "talking":
                // Set the status
                this.status.talking = bool;

                // Set the element visiblitity as needed
                this.statusElems.talking.material.visible = bool;
                this.composer.updateOutlineObjects(this.userHead, bool);
            break;

            // If the muted status is being evaluated
            case 2:
            case "muted":
                // Set the status
                this.status.muted = bool;

                // Set the element visiblitity as needed
                this.statusElems.muted.material.visible = bool;
            break;

            // If the blocked status is being evaluated
            case 3:
            case "blocked":
                // Set the status
                this.status.blocked = bool;

                // Set the element visiblitity as needed
                this.statusElems.blocked.material.visible = bool;
            break;
        }
    }

    // Method called to remove the user
    removeUser()
    {
        // Remove all the status
        this.setStatus('waiting', false);
        this.setStatus('talking', false);
        this.setStatus('muted', false);
        this.setStatus('blocked', false);

        // If the user has animations
        if(this.animations)
        {
            // Stop the other animations
            if(this.animations.on.isRunning() === true) this.animations.on.stop();
            if(this.animations.waiting.isRunning() === true) this.animations.waiting.stop();

            // Play the off animation
            this.animations.off.play();
        }
        // If the user doesn't have animations, disable the user head
        else this.userHead.visible = false;

        // If the user object has children
        if(this.userHead.children.length > 0)
        {
            // Dispose video materials
            if(this.userHead.children[0].material instanceof Array)
            {
                this.disposer.disposeElements(this.userHead.children[0].material[0]);
                this.disposer.disposeElements(this.userHead.children[0].material[1]);
            }
            else try { this.disposer.disposeElements(this.userHead.children[0].material) } catch(e) { console.log(e) };
        }
        // If the user object doesn't have children
        else
        {
            // Dispose video materials
            if(this.userHead.material instanceof Array)
            {
                this.disposer.disposeElements(this.userHead.material[0]);
                this.disposer.disposeElements(this.userHead.material[1]);
            }
            else try { this.disposer.disposeElements(this.userHead.material) } catch(e) { console.log(e) };
        }
        
        // Dispose the current screen
        try { this.disposer.disposeElements(this.currentScreen) } catch(e) { console.log(e) };
        this.currentScreen = null;
        // Dispose the default screen
        try { this.disposer.disposeElements(this.defaultScreen) } catch(e) { console.log(e) };
        this.defaultScreen = null;
        // Dispose the tag
        try { this.disposer.disposeElements(this.nametag) } catch(e) { console.log(e) };
        this.nametag = null;
        // Dispose the hand up icon
        try { this.disposer.disposeElements(this.handUp) } catch(e) { console.log(e) };
        this.handUp = null;
        // Reset the canvas references
        this.nameCanvas = null;
        this.nameCanvasCtx = null;
    }

    // Method propagated by the experience each tick event
    update(delta)
    {
        // Update the animation mixer
        this.animations.mixer.update(delta);
    }

    // Private method called to dispose the meeting room
    destroy()
    {
        // Remove the user
        this.removeUser();

        // Stop all animation actions
        if(this.animations) try { this.animations.mixer.stopAllAction() } catch(e) { console.log(e) };

        // Get the disposer class from the experience
        if(!this.disposer) this.disposer = this.experience.disposer;

        // Dispose the model
        try { this.disposer.disposeElements(this.userHead) } catch(e) { console.log(e) };
        if(this.animations) try { this.disposer.disposeElements( this.body ) } catch(e) { console.log(e) };

        // Remove variables
        this.width = null;
        this.acronym = null;
        this.color = null;
        this.nametag = null;
        this.handUp = null;
        this.userHead = null;
        this.body = null;
        this.animations = null;

        // Remove the reference
        this.experience = null;
        this.disposer = null;
        this.composer = null;
    }
}