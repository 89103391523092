import Experience from './Experience/Experience';

// Create encapsulated object for this experience
if(!window.novvaC6) window.novvaC6 = {};
// Create encapsulated event target
if(!window.novvaC6.eventTarget) window.novvaC6.eventTarget = new EventTarget();

// Experience class instance
let _experience = null;



// Set the render quality
function changeRenderQuality(id)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Change quality
        _experience.updateRenderQuality(id);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC6.changeRenderQuality = changeRenderQuality;



// Global function called to update a specific object inside the JSON
function updateObjectByName(key, value)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Update object
        _experience.npc.updateObjectByName(key, value);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC6.updateObjectByName = updateObjectByName;



// Global function called to update many or all of the objects instantly, without needing to go through the verifications
function updateObjectsByJSON(jsonObj)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Update objects
        _experience.npc.updateObjectsByJSON(jsonObj);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC6.updateObjectsByJSON = updateObjectsByJSON;



// Global function called to get the updated JSON object from the stand
function getJSONObjectFromNPC()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Get JSON object
        const jsonObject = _experience.npc.getJSONObject();
        return jsonObject;
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC6.getJSONObjectFromNPC = getJSONObjectFromNPC;



// Destroy the experience, but maintain persistend data
function destroyTheExperience()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";

        // Destroy the experience
        _experience.destroy();
        console.log('experience C6 destroyed')
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC6.destroyTheExperience = destroyTheExperience;



// Completely wipe the experience
function wipeTheExperience()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";

        // Destroy the experience
        _experience.wipe();
        _experience = null;
        console.log('experience C6 wiped')
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC6.wipeTheExperience = wipeTheExperience;



// Start the experience
function startTheExperience(parameters)
{
    try
    {
        // If the experience already started
        if(_experience !== null)
        {
            // If all the assets are loaded but the scene is null
            if(_experience.resources.loaded >= _experience.resources.toLoad && _experience.scene === null)
            {
                // Restart the experience
                _experience.restart(parameters);
                console.log('experience C6 restarted')
            }
            else throw "The Experience is already running";
        }
        else
        {
            // Create the experience
            _experience = new Experience(document.getElementById('complementary-webgl-experience'), parameters);
            console.log('experience C6 created')
        }
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC6.startTheExperience = startTheExperience;



// Function called when the script is loaded
function loadedScript()
{
    // If there is an async init function
    if(window.novvaC6AsyncInit)
    {
        // Call function to init the experience
        window.novvaC6AsyncInit();
    }
    // If there isn't an async init function, log error
    else
    {
        console.log('The "novvaC6AsyncInit" function was not found.');
    }
}
loadedScript();