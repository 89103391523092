import Experience from "../Experience.js";

let keyDownHandler, keyUpHandler;

export default class Keys
{
    // Set constructor
    constructor()
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.mobileDetector = this.experience.mobileDetector;

        // If the device is a desktop
        if(!this.mobileDetector.isMobile)
        {
            // Set key states array
            this.keyStates = [];
            // Set the key listeners
            if(!this.experience.INTERRUPTED) this.#setListeners();
        }
        // Remove reference
        this.mobileDetector = null;
    }

    // Method called by external classes to set the listeners
    #setListeners()
    {
        // Key down event handler
        keyDownHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the scene is loaded
                if(this.experience.SCENE_LOADED)
                {
                    // Set the key state
                    this.keyStates[e.code] = true;
                    /*
                    if(e.code === "KeyC")
                    {
                        this.experience.camera.switchCamera();
                    }
                    */
                }
            }
        };

        // Key up event handler
        keyUpHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the scene is loaded
                if(this.experience.SCENE_LOADED)
                {
                    // Reset the key state
                    this.keyStates[e.code] = false;
                }
            }
        };

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Listen for pressed keys
            document.addEventListener('keydown', keyDownHandler);
            // Listen for released keys
            document.addEventListener('keyup', keyUpHandler);
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        try
        {
            // Stop listening for pressed keys
            document.removeEventListener('keydown', keyDownHandler);
            // Stop listening for released keys
            document.removeEventListener('keyup', keyUpHandler);
        }
        catch(e) { console.log(e) };

        // If the key states array was created
        if(this.keyStates instanceof Array) this.keyStates.length = 0;

        // Destroy references
        this.experience = null;
        keyDownHandler = null;
        keyUpHandler = null;
    }
}