import { Mesh, Object3D, Group, MeshBasicMaterial, MeshStandardMaterial } from "three";

import Experience from "../Experience";

export default class Disposer
{
    // Set constructor
    constructor()
    {
        // Get the experience instance
        this.experience = new Experience();
    }

    // Method called to dispose an element or a group of elements
    disposeElements(elem, avoid)
    {
        // Get scene
        this.scene = this.experience.scene;
        // If the element isn't the scene
        if(elem !== this.scene)
        {
            if(avoid === undefined)
            {
                // Remove element from the scene
                this.scene.remove(elem);
            }
        }

        // If the element is a mesh, object3D or a group
        if(elem instanceof Mesh || elem instanceof Object3D || elem instanceof Group)
        {
            // Go through all the model's children
            elem.traverse(child =>
            {
                // Dispose child
                this.#dispose(child);
            });

            // Dispose element
            this.#dispose(elem);
        }
        // If the element is an array
        else if(elem instanceof Array)
        {
            // Go through all the array elements
            elem.forEach(child =>
            {
                // Call this function again
                this.disposeElements(child);
            });
        }
        // If the element is a material
        else if(elem instanceof MeshBasicMaterial || elem instanceof MeshStandardMaterial)
        {
            // Dispose element
            this.#dispose((elem));
        }
        
        // Reset variable
        elem = undefined;
    }

    // Private method called to dispose the geometry and the materials from the element
    #dispose(elem)
    {
        // Dispose the geometry
        if(elem.geometry) elem.geometry.dispose();
    
        if(elem.material)
        {
            if(elem.material instanceof Array)
            {
                // Dispose all materials
                elem.material.forEach(material =>
                {
                    // Dispose all the maps
                    if (material.map) material.map.dispose ();
                    if (material.lightMap) material.lightMap.dispose ();
                    if (material.bumpMap) material.bumpMap.dispose ();
                    if (material.normalMap) material.normalMap.dispose ();
                    if (material.specularMap) material.specularMap.dispose ();
                    if (material.envMap) material.envMap.dispose ();
                    if (material.alphaMap) material.alphaMap.dispose();
                    if (material.aoMap) material.aoMap.dispose();
                    if (material.displacementMap) material.displacementMap.dispose();
                    if (material.emissiveMap) material.emissiveMap.dispose();
                    if (material.gradientMap) material.gradientMap.dispose();
                    if (material.metalnessMap) material.metalnessMap.dispose();
                    if (material.roughnessMap) material.roughnessMap.dispose();
                    // Dispose the material
                    material.dispose();
                });
            }
            else
            {
                // Dispose the texture
                if(elem.map) elem.material.map.dispose();
                // Dispose the material
                elem.material.dispose();
            }
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        this.scene = null;
        this.experience = null;
    }
}