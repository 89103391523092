import { WebGLRenderer, NoToneMapping, PCFSoftShadowMap } from "three";

import Experience from "./Experience";

export default class Renderer
{
    // Set constructor
    constructor()
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.canvas = this.experience.canvas;
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;

        // Set renderer
        if(!this.experience.INTERRUPTED) this.setRenderer();
    }

    // Method called to create and set up the renderer
    setRenderer()
    {
        // Create renderer instance
        this.instance = new WebGLRenderer({
            canvas: this.canvas,
            alpha: true,
            preserveDrawingBuffer: true,
            powerPreference: "high-performance",
            logarithmicDepthBuffer: true
        });

        // Set size, color and pixel ratio
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setClearColor( 0x000000, 0 );
        this.instance.setPixelRatio(this.sizes.pixelRatio);

        // Set shadows and lights
        this.instance.shadowMap.enabled = true;
        this.instance.shadowMap.autoUpdate = false;
        this.instance.toneMapping = NoToneMapping;
        this.instance.shadowMap.type = PCFSoftShadowMap;
        this.instance.physicallyCorrectLights = true;
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Set new size and pixel ratio
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Render
        this.instance.render(this.scene, this.camera.renderCamera);
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Reset variables
        this.instance = null;

        // Remove references
        this.experience = null;
        this.canvas = null;
        this.sizes = null;
        this.scene = null;
        this.camera = null;
    }
}