import { AmbientLight, HemisphereLight, DirectionalLight, sRGBEncoding, CubeRefractionMapping } from "three";

import Experience from "../Experience.js";

export default class Environment
{
    // Set constructor
    constructor()
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;

        // Set environment map intensity
        this.envMapIntensity = 0.3;
    }

    // Method called to create the ambient light
    setAmbientLight()
    {
        // Set ambient light
        this.ambientLight = new AmbientLight(0xffffff, 1);
        // Add to scene
        this.scene.add(this.ambientLight);
    }

    // Method called to create the hemisphere light
    setHemisphereLight()
    {
        // Set hemisfere light
        this.hemisphereLight = new HemisphereLight(0xffffff, 0x556580, 0.3);
        // Add to scene
        if(!this.experience.INTERRUPTED) this.scene.add(this.hemisphereLight);
    }

    // Method called to create the sirectional light
    setDirectionalLight()
    {
        // Set directional light
        this.directionalLight = new DirectionalLight(0xffffff, 1.8);
        this.directionalLight.position.set(4, 20, 4);

        // Set shadow camera
        this.directionalLight.castShadow = true;
        this.directionalLight.shadow.mapSize.width = 2048 * 2;
        this.directionalLight.shadow.mapSize.height = 2048 * 2;
        // Set shadow camera dimensions
        this.directionalLight.shadow.camera.top = 50;
        this.directionalLight.shadow.camera.right = 50;
        this.directionalLight.shadow.camera.bottom = -50;
        this.directionalLight.shadow.camera.left = -50;
        // Set shadow camera planes
        this.directionalLight.shadow.camera.near = 1;
        this.directionalLight.shadow.camera.far = 50;

        // Erase shadow acne
        this.directionalLight.shadow.bias = -0.005;

        // Add to scene
        if(!this.experience.INTERRUPTED) this.scene.add(this.directionalLight);
    }

    // Method called to set up the environment background
    setEnvironmentBackground()
    {
        // If there isn't a background
        if(!this.envBackground)
        {
            // Create environment background
            this.envBackground = this.resources.items.environmentBackground;
            this.envBackground.encoding = sRGBEncoding;
        }
        
        // Set scene background
        if(!this.experience.INTERRUPTED) this.scene.background = this.envBackground;
    }

    // Method called to set up the environment map
    setEnvironmentMap()
    {
        // If there isn't an environment map
        if(!this.envMapTexture)
        {
            // Create environment map
            this.envMapTexture = this.resources.items.environmentMapTexture;
            this.envMapTexture.mapping = CubeRefractionMapping;
        }

        // Set scene background
        if(!this.experience.INTERRUPTED) this.scene.environment = this.envMapTexture;

        // If there is a hemisphere light, tone their intensity down
        if(this.hemisphereLight) this.hemisphereLight.intensity = 0.3;
    }

    // Method propagated by the experience to destroy this instance
    destroy()
    {
        // Reset variable
        this.envMapIntensity = null;

        // If an ambient light was created
        if(this.ambientLight)
        {
            try
            {
                // Remove from the scene
                this.scene.remove(this.ambientLight);
                this.ambientLight = null;
            }
            catch(e) { console.log(e) };
        }
        // If a hemisphere light was created
        if(this.hemisphereLight)
        {
            try
            {
                // Remove from the scene
                this.scene.remove(this.hemisphereLight);
                this.hemisphereLight = null;
            }
            catch(e) { console.log(e) };
        }
        // If a directional light was created
        if(this.directionalLight)
        {
            try
            {
                // Remove from the scene
                this.scene.remove(this.directionalLight);
                this.directionalLight = null;
            }
            catch(e) { console.log(e) };
        }
        // If an environment background was created
        if(this.envBackground)
        {
            try
            {
                // Remove from the scene
                this.scene.background = null;
                this.envBackground = null;
            }
            catch(e) { console.log(e) };
        }
        // If an environment map texture was created
        if(this.envMapTexture)
        {
            try
            {
                // Remove from the scene
                this.scene.environment = null;
                this.envMapTexture = null;
            }
            catch(e) { console.log(e) };
        }

        // Remove references
        this.experience = null;
        this.scene = null;
        this.resources = null;
    }
}