import { Vector2, Vector3 } from "three";

import Experience from "../Experience.js";
import EventEmitter from './EventEmitter.js';
import gsap from 'gsap';

let lockChangeHandler, mouseUpHandler, mouseMoveHandler, touchHandler;

let touchMoved, isMobile;

export default class Pointer extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.canvas = this.experience.canvas;
        this.sizes = this.experience.sizes;
        this.resources = this.experience.resources;
        this.meetingRoom = this.experience.meetingRoom;
        this.camera = this.experience.camera;

        touchMoved = false;
        this.insideElem = false

        // Set pointer
        if(!this.experience.INTERRUPTED) this.setPointer();
    }

    // Method called to set up the pointer
    setPointer()
    {
        // Mouse position
        this.mouse = new Vector2(Infinity, Infinity);

        // Get the mobile detector class from the experience
        this.mobileDetector = this.experience.mobileDetector;
        // Get if the device is a mobile
        isMobile = this.mobileDetector.isMobile;
        // Remove reference
        this.mobileDetector = null;

        // Get aim element
        this.aim = document.getElementById('firts-person-aim');
        this.aim.style.display = '';

        // If the device is a desktop
        if(isMobile === false)
        {
            // Mouse status
            this.mouseMove = false;
            // Set mouse move listener
            if(!this.experience.INTERRUPTED) this.#setMouseListeners();

            // Pointer status
            this.locked = false;
            // Set listeners related to the pointer locking and unlocking
            if(!this.experience.INTERRUPTED) this.#setPointerLockListeners();
        }
        // If the device is a mobile
        else
        {
            // Set touch listeners
            if(!this.experience.INTERRUPTED) this.#setTouchListeners();
        }
    }

    // Private method called to set up listeners related to the mouse movement
    #setMouseListeners()
    {
        // Mouse move event handler
        mouseMoveHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the experience has loaded and the mouse target is the canvas
                if(this.experience.SCENE_LOADED === true && e.target === this.canvas)
                {
                    // Set mouse status as moving
                    this.mouseMove = true;

                    // Calculate normalized mouse coordinates relative to canvas size
                    const rect = this.canvas.getBoundingClientRect();
                    this.mouse.x = ((e.clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                    this.mouse.y = -((e.clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;

                    // If the first person camera is active and the mouse is locked to the canvas
                    if(document.pointerLockElement === this.canvas)
                    {
                        // Rotate camera as the mouse moves
                        this.camera.renderCamera.rotation.y -= e.movementX / 800;
                        this.camera.renderCamera.rotation.x -= e.movementY / 800;

                        // Add limits to the vertical camera rotation
                        if(this.camera.renderCamera.rotation.x < -(Math.PI * 0.1)) this.camera.renderCamera.rotation.x = -(Math.PI * 0.1);
                        if(this.camera.renderCamera.rotation.x > (Math.PI * 0.2)) this.camera.renderCamera.rotation.x = (Math.PI * 0.2);
                        // Add limits to the horizontal camera rotation
                        if(this.camera.renderCamera.rotation.y < -(Math.PI * 0.9)) this.camera.renderCamera.rotation.y = -(Math.PI * 0.9);
                        if(this.camera.renderCamera.rotation.y > -(Math.PI * 0.1)) this.camera.renderCamera.rotation.y = -(Math.PI * 0.1);
                    }
                }
            }
        };

        // Pointer down event handler
        mouseUpHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the experience has loaded and the mouse target is the canvas
                if(this.experience.SCENE_LOADED === true && e.target === this.canvas)
                {
                    if(this.mouseMove === false)
                    {
                        // If the first person camera is active and the pointer isn't locked
                        if(this.insideElem === false && this.locked === false)
                        {
                            // Trigger event warning that the pointer can be locked
                            const canLockEvent = new CustomEvent( 'lockPointer' );
                            window.novvaC5.eventTarget.dispatchEvent(canLockEvent);
                        }

                        // Update camera
                        this.camera.renderCamera.updateMatrixWorld();
                        // If the user is interacting with the hovered element
                        if(this.insideElem === false && this.locked === true)
                        {
                            // Enter the interaction
                            this.enterInteraction();
                        }
                        // If the user is exiting an interaction with the hovered element
                        else if(this.insideElem === true)
                        {
                            // Exit the interaction
                            this.exitInteraction();
                        }
                    }
                }
            }
        };

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Listen for mouse movements
            document.addEventListener('mousemove', mouseMoveHandler);
            // Listen for mouse releases
            document.addEventListener('mouseup', mouseUpHandler);
        }
    }

    // Method called to start interacting with the hovered element
    enterInteraction()
    {
        // If the user is hovering an element
        if(this.meetingRoom.hoveredElem !== null)
        {
            // Update variable
            this.insideElem = true;

            // If the device is not a mobile, exit pointer lock
            if(isMobile === false) document.exitPointerLock();
            
            // Interacting with the TV
            if(this.meetingRoom.hoveredElem.name.includes("screen"))
            {
                // Get position near to the TV
                let pos = this.meetingRoom.hoveredElem.getWorldPosition(new Vector3(0, 0, 0));
                pos.x -= 2.3;
                
                // Tween camera position and rotation
                gsap.to(this.camera.renderCamera.position, { x: pos.x, y: (pos.y - 0.6), z: pos.z, duration: 0.5 });
                gsap.to(this.camera.renderCamera.rotation, { x: Math.PI * 0.08, y: -Math.PI * 0.5, z: 0, duration: 0.5 });
            }
            // Interacting with another user
            else
            {
                // Get default points
                const pointA = this.camera.renderCamera.position.clone();
                const pointB = this.meetingRoom.hoveredElem.getWorldPosition(new Vector3(0, 0, 0));

                // Set the desired distance to the user head
                const distance = pointA.distanceTo(pointB) - 1.15;
                // Get the direction between the camera and the user head
                const dir = pointB.clone().sub(pointA).normalize().multiplyScalar(distance);
                // Get the point between the camera and the user head at the desired distance
                const pos = pointA.clone().add(dir);

                // Tween camera position
                gsap.to(this.camera.renderCamera.position, { x: pos.x, z: pos.z, duration: 0.5 });

                // Set camera rotation to look at the hovered element
                this.camera.renderCamera.lookAt(pointB.x, (pointB.y + 1.6), pointB.z);
            }

            // Get the name of the clicked elem
            const data = { "object_name": this.meetingRoom.hoveredElem.name };

            // Trigger event warning that an element was clicked, sending the info acquired
            const standClickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
            window.novvaC5.eventTarget.dispatchEvent(standClickEvent);
        }
    }

    // Method called to stop interacting with the hovered element
    exitInteraction()
    {
        // Reset variable
        this.insideElem = false;
        
        // If the device is not a mobile, request pointer lock
        if(isMobile === false) this.canvas.requestPointerLock();

        // Tween camera position
        gsap.to(this.camera.renderCamera.position, { x: this.camera.defaultPosition.x, y: this.camera.defaultPosition.y, z: this.camera.defaultPosition.z, duration: 0.5 });
        gsap.to(this.camera.renderCamera.rotation, { x: Math.PI * 0.04, z: 0, duration: 0.5 });
    }

    // Private method called to set up listeners related to the pointer lock
    #setPointerLockListeners()
    {
        // Lock changes event handler
        lockChangeHandler = () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the pointer is locked
                if(document.pointerLockElement === this.canvas || document.mozPointerLockElement === this.canvas)
                {
                    // Set pointer status as locked
                    this.locked = true;
                    // Activate aim
                    this.aim.style.display = '';
                }
                // If the pointer is unlocked
                else
                {
                    // Set pointer status as unlocked
                    this.locked = false;
                    // Deactivate aim
                    this.aim.style.display = 'none';

                    // Trigger warning event
                    window.novvaC5.eventTarget.dispatchEvent(new CustomEvent('pointerUnlocked'));
                }
            }
        }
        
        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Listen for pointer changes
            document.addEventListener('pointerlockchange', lockChangeHandler);
            document.addEventListener('onmozpointerlockchange', lockChangeHandler);
        }
    }

    // Private method called to set up the touch listeners
    #setTouchListeners()
    {
        // Touch event handler
        touchHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the scene is loaded
                if(this.experience.SCENE_LOADED === true)
                {
                    // Get the canvas rect
                    const rect = this.canvas.getBoundingClientRect();

                    // If the touch started
                    if(e.type === "touchstart")
                    {
                        // Calculate normalized mouse coordinates relative to canvas size
                        this.mouse.x = ((e.touches[0].clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                        this.mouse.y = -((e.touches[0].clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;
                    }
                    // If the touch moved
                    else if(e.type === "touchmove")
                    {
                        touchMoved = true;

                        // If the user isn't interacting with any element
                        if(this.insideElem === false)
                        {
                            // Get touch position
                            const moveX = ((e.touches[0].clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                            const moveY = -((e.touches[0].clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;

                            // Get the difference between the last mouse position saved and the new mouse position
                            const xDiff = this.mouse.x - moveX;
                            const yDiff = this.mouse.y - moveY;

                            // If the movement is horizontal
                            if(Math.abs(xDiff) > Math.abs(yDiff))
                            {
                                // If the swipe is going to the right
                                if(xDiff > 0)
                                {
                                    // Rotate camera to the left
                                    this.camera.renderCamera.rotation.y += -xDiff;
                                }
                                // If the swipe is going to the left
                                else
                                {
                                    // Rotate camera to the right
                                    this.camera.renderCamera.rotation.y += -xDiff;
                                }

                                // Calculate normalized mouse coordinates relative to canvas size
                                this.mouse.x = ((e.touches[0].clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                                this.mouse.y = -((e.touches[0].clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;
                            }
                            // If the movement is vertical
                            else
                            {
                                // If the swipe is going up
                                if(yDiff > 0)
                                {
                                    // Rotate camera down
                                    this.camera.renderCamera.rotation.x += yDiff / 2;
                                    // Add limits to the camera rotation
                                    if(this.camera.renderCamera.rotation.x > (Math.PI * 0.4)) this.camera.renderCamera.rotation.x = (Math.PI * 0.4);
                                }
                                // If the swipe is going down
                                else
                                {
                                    // Rotate camera down
                                    this.camera.renderCamera.rotation.x += yDiff / 2;
                                    // Add limits to the camera rotation
                                    if(this.camera.renderCamera.rotation.x < -(Math.PI * 0.3)) this.camera.renderCamera.rotation.x = -(Math.PI * 0.3);
                                }

                                // Calculate normalized mouse coordinates relative to canvas size
                                this.mouse.x = ((e.touches[0].clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                                this.mouse.y = -((e.touches[0].clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;
                            }
                        }
                    }
                    // If the touch moved
                    else
                    {
                        // If the touch was a swipe
                        if(touchMoved === true)
                        {
                            // Reset variable
                            touchMoved = false;
                        }
                        // If the touch was a click
                        else
                        {
                            // Trigger touch event
                            this.trigger('pointerTouch');

                            // If the user is interacting with the hovered element
                            if(this.insideElem === false)
                            {
                                // Enter the interaction
                                this.enterInteraction();
                            }
                            // If the user is exiting an interaction with the hovered element
                            else
                            {
                                // Exit the interaction
                                this.exitInteraction();
                            }
                        }
                    }
                }
                
            }
        };

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Listen for touches starting
            document.addEventListener('touchstart', touchHandler);
            // Listen for touches moving
            document.addEventListener('touchmove', touchHandler);
            // Listen for touches ending
            document.addEventListener('touchend', touchHandler);
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Remove reference
        this.mouse = null;
        this.insideElem = null;

        // Deactivate aim
        this.aim.style.display = 'none';
        this.aim = null;

        // If the device is a desktop
        if(isMobile === false)
        {
            try
            {
                // Stop listening for mouse events
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
                // Stop listening for pointer lock changes
                document.removeEventListener('pointerlockchange', lockChangeHandler);
                document.removeEventListener('onmozpointerlockchange', lockChangeHandler);
            }
            catch(e) { console.log(e) };
            
            // Reset references
            mouseMoveHandler = null;
            mouseUpHandler = null;
            lockChangeHandler = null;
        }
        // If the device is a mobile
        else
        {
            try
            {
                // Stop listening for touch events
                document.removeEventListener('touchstart', touchHandler);
                document.removeEventListener('touchmove', touchHandler);
                document.removeEventListener('touchend', touchHandler);
            }
            catch(e) { console.log(e) };
            
            // Reset reference
            touchHandler = null;
        }

        // Remove references
        isMobile = null;
        this.experience = null;
        this.sizes = null;
        this.camera = null;
        this.canvas = null;
        this.resources = null;
        this.meetingRoom = null;
    }
}