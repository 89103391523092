import * as THREE from "three";
import { Pathfinding } from 'three-pathfinding';

import Experience from "../Experience"
import EventEmitter from "../Utils/EventEmitter";

export default class Navmesh extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed class from the experience
        this.dome = this.experience.dome;

        // Create instance
        this.instance = {};
        // Create pathfinder
        this.instance.pathfinder = new Pathfinding();

        // Listen to when the dome is loaded
        this.dome.on('loaded3DScene', () =>
        {
            // Set navmesh
            if(!this.experience.INTERRUPTED) this.setNavmesh();
        });
    }

    // Method called to set the navmesh
    setNavmesh()
    {
        // Create NPCs navmesh from the dome child
        this.instance.npcsNavmesh = this.dome.instance.model.getObjectByName('Navmesh_NPCs', true);
        this.instance.npcsNavmesh.material.visible = false;

        // Create a new pathfinding zone for the NPCs
        const npcsZone = Pathfinding.createZone(this.instance.npcsNavmesh.geometry);
        this.instance.pathfinder.setZoneData("npcsNavmesh", npcsZone);
    }

    // Method called externally to try to create a path towards the target
    tryPath(position, target)
    {
        // Get the navmesh group
        const group = this.instance.pathfinder.getGroup("npcsNavmesh", new THREE.Vector3(0, 0, 0));

        // Calculate a path to the target and store it
        const path = this.instance.pathfinder.findPath(position, target, "npcsNavmesh", group);

        // If there isn't a path to be run
        if(!path)
        {
            // Set path steps
            const closestPlayerNode = this.instance.pathfinder.getClosestNode(position, "npcsNavmesh", group);
            const clamped = new THREE.Vector3();

            this.instance.pathfinder.clampStep(position, target, closestPlayerNode, "npcsNavmesh", group, clamped);
        }
        // If there is a path to be run
        else if(path && path.length)
        {
            return path;
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Stop listening for the loaded pavilion event
        try { this.dome.off('loaded3DScene') } catch(e) { console.log(e) };

        try
        {
            // If the navmesh still exists
            if(this.instance.npcsNavmesh)
            {
                // Get the disposer class from the experience
                this.disposer = this.experience.disposer;
                // Dispose the navmesh
                this.disposer.disposeElements(this.instance.npcsNavmesh);
                // Remove reference
                this.disposer = null;
            }
        }
        catch(e) { console.log(e) };

        // Reset the instance
        this.instance = null;

        // Remove references
        this.disposer = null;
        this.dome = null;
        this.experience = null;
    }
}