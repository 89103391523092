import Experience from './Experience/Experience';

// Create encapsulated object for this experience
if(!window.novvaC4) window.novvaC4 = {};
// Create encapsulated event target
if(!window.novvaC4.eventTarget) window.novvaC4.eventTarget = new EventTarget();

// Experience class instance
let _experience = null;



// Set the render quality
function changeRenderQuality(id)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Change quality
        _experience.updateRenderQuality(id);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.changeRenderQuality = changeRenderQuality;



// Global function called to update a specific object inside the JSON
function updateObjectByName(key, value)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Update object
        _experience.stand.updateObjectByName(key, value);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.updateObjectByName = updateObjectByName;



// Global function called to update many or all of the objects instantly, without needing to go through the verifications
function updateObjectsByJSON(jsonObj)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Update objects
        _experience.stand.updateObjectsByJSON(jsonObj);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.updateObjectsByJSON = updateObjectsByJSON;



// Global function called to reset the stand model to the initial state
function resetObjects()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Create empty JSON object
        const emptyJson =
        {
            "logo": "",

            "tooltip": 
            {
                "name": "",
                "logo": ""
            },

            "main_color": "",
            "sec_color": "",
            "base_color": "",

            "image_screen_0": "",
            "image_screen_1": "",

            "image_orientation": "",

            "video_screen_0": "",
            "video_screen_1": "",

            "totem_about": false,
            "totem_brochures": false,
            "totem_huddle": false,
            "totem_video": false,

            "npcs_preset": "",
            "npcs": []
        };

        // Update objects to their empty value
        _experience.stand.updateObjectsByJSON(emptyJson);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.resetObjects = resetObjects;



// Global function that saves a screenshot of the stand
function saveScreenshotAsImage()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Save screenshot
        _experience.camera.saveScreenshotAsImage();
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.saveScreenshotAsImage = saveScreenshotAsImage;



// Method called to reset the camera after zooming in on the video screen
function exitVideoScreenInteraction()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Save screenshot
        _experience.stand.exitVideoScreenInteraction();
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.exitVideoScreenInteraction = exitVideoScreenInteraction;



// Global function called to set a new audio track to the existing soundtrack
function changeAudioTrack(url)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Change the audio track
        _experience.audio.changeAudioTrack(url);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.changeAudioTrack = changeAudioTrack;



// Global function called to pause or resume the ambient song
function pauseOrResumeSoundtrack(bool)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Pause or resume the song
        _experience.audio.pauseOrResumeSoundtrack(bool);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.pauseOrResumeSoundtrack = pauseOrResumeSoundtrack;



// Global function called to set the ambient soundtrack volume
function setSoundtrackVolume(value)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Pause or resume the song
        _experience.audio.setSoundtrackVolume(value);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.setSoundtrackVolume = setSoundtrackVolume;



// Global function called to get the updated JSON object from the stand
function getJSONObjectFromStand()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Get JSON object
        const jsonObject = _experience.stand.getJSONObject();
        return jsonObject;
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.getJSONObjectFromStand = getJSONObjectFromStand;



// Destroy the experience, but maintain persistend data
function destroyTheExperience()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";

        // Destroy the experience
        _experience.destroy();
        console.log('experience C4 destroyed')
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.destroyTheExperience = destroyTheExperience;



// Completely wipe the experience
function wipeTheExperience()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";

        // Destroy the experience
        _experience.wipe();
        _experience = null;
        console.log('experience C4 wiped')
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.wipeTheExperience = wipeTheExperience;



// Start the experience
function startTheExperience(parameters)
{
    try
    {
       // If the experience already started
       if(_experience !== null)
       {
           // If all the assets are loaded but the scene is null
           if(_experience.resources.loaded >= _experience.resources.toLoad && _experience.scene === null)
           {
               // Restart the experience
               _experience.restart(parameters);
               console.log('experience C4 restarted')
           }
           else throw "The Experience is already running";
       }
       else
       {
           // Create the experience
           _experience = new Experience(document.querySelector('canvas.webgl'), parameters);
           console.log('experience C4 created')
       }
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC4.startTheExperience = startTheExperience;



// Function called when the script is loaded
function loadedScript()
{
    // If there is an async init function
    if(window.novvaC4AsyncInit)
    {
        // Call function to init the experience
        window.novvaC4AsyncInit();
    }
    // If there isn't an async init function, log error
    else
    {
        console.log('The "novvaC4AsyncInit" function was not found.');
    }
}
loadedScript();