import * as THREE from "three";

import Experience from "../Experience";

export default class NPC
{
    // Set constructor
    constructor()
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.navmesh = this.experience.navmesh;
    }

    // Method called to create the NPC
    setNPC()
    {
        // Create new instances
        this.instance = {};
        this.instance.animations = {};

        // Get resources class from the experience
        this.resources = this.experience.resources;
        // Clone NPC mesh model
        this.instance.model = this.resources.items['npc'].scene.clone();
        // Remove reference
        this.resources = null;

        // Set NPC initial position
        this.#setPosition();
    }

    // Private method called to set the NPC position in the navmesh
    #setPosition()
    {
        // Create the necessary variables
        let gotPosition = false;
        let randomX, randomY;
        let position;

        // Create raycast
        this.raycast = new THREE.Raycaster();
        let intersects;

        // While a valid position haven't been found
        while(gotPosition === false)
        {
            // Set random X position
            randomX = Math.random() * 300 + 5;
            if( Math.random() < 0.5 ) randomX *= -1;
            // Set random Y position
            randomY = Math.random() * 300 + 5;
            if( Math.random() < 0.5 ) randomY *= -1;
            
            // Set new position to where the NPC will try to be
            position = new THREE.Vector3(randomX, 200, randomY);

            // Set raycaster
            this.raycast.set(position, new THREE.Vector3(0, -1, 0));
            // Verify for intersections with the navmesh
            intersects = this.raycast.intersectObject(this.navmesh.instance.npcsNavmesh);

            // If there is an intersection with the navmesh
            if(intersects.length > 0)
            {
                const point = intersects[0].point;
                // Set model position and rotation
                this.instance.model.position.set(point.x, point.y, point.z);
                this.instance.model.rotation.y = Math.PI * (Math.random());

                // Get the scene from the experience
                this.scene = this.experience.scene;
                // Add the NPC to the scene
                this.scene.add(this.instance.model);
                // Remove reference
                this.scene = null;

                // Set to true to end the loop
                gotPosition = true;
            }
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        try
        {
            // Get the disposer class from the experience
            this.disposer = this.experience.disposer;
            // Dispose the model
            this.disposer.disposeElements(this.instance.model);
        }
        catch(e) { console.log(e) };

        // Reset instances
        this.instance = null;

        // Remove references
        this.experience = null;
        this.scene = null;
        this.disposer = null;
        this.navmesh = null;
    }
}