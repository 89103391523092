export default [
    {
        name: 'environmentBackground',
        type: 'cubeTexture',
        path:
        [
            'environment/darkBlue_px.png',
            'environment/darkBlue_nx.png',
            'environment/darkBlue_py.png',
            'environment/darkBlue_ny.png',
            'environment/darkBlue_pz.png',
            'environment/darkBlue_nz.png'
        ]
    },
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'environment/envMap/test_px.png',
            'environment/envMap/test_nx.png',
            'environment/envMap/test_py.png',
            'environment/envMap/test_ny.png',
            'environment/envMap/test_pz.png',
            'environment/envMap/test_nz.png'
        ]
    },
    {
        name: 'pavilion_background',
        type: 'gltfModel',
        path: 'models/background/cenario_stand.gltf'
    },
    {
        name: 'npc_0',
        type: 'gltfModel',
        path: 'models/npcs/npc_0/npc_0.gltf'
    },
    {
        name: 'npc_1',
        type: 'gltfModel',
        path: 'models/npcs/npc_1/npc_1.gltf'
    },
    {
        name: 'npc_2',
        type: 'gltfModel',
        path: 'models/npcs/npc_2/npc_2.gltf'
    },
    {
        name: 'npc_3',
        type: 'gltfModel',
        path: 'models/npcs/npc_3/npc_3.gltf'
    },
    {
        name: 'npc_4',
        type: 'gltfModel',
        path: 'models/npcs/npc_4/npc_4.gltf'
    },
    {
        name: 'npc_5',
        type: 'gltfModel',
        path: 'models/npcs/npc_5/npc_5.gltf'
    },
    {
        name: 'npc_6',
        type: 'gltfModel',
        path: 'models/npcs/npc_6/npc_6.gltf'
    },
    {
        name: 'npc_7',
        type: 'gltfModel',
        path: 'models/npcs/npc_7/npc_7.gltf'
    },
    {
        name: 'npc_8',
        type: 'gltfModel',
        path: 'models/npcs/npc_8/npc_8.gltf'
    },
    {
        name: 'glowImage',
        type: 'texture',
        path: 'images/icons/brilho_v2.png'
    },
    {
        name: 'glowMask',
        type: 'texture',
        path: 'images/icons/circleMask.png'
    },
    {
        name: 'playButton',
        type: 'texture',
        path: 'images/icons/playButton.png'
    },
    {
        name: 'soundtrack_1',
        type: 'audio',
        path: 'audio/songs/relaxing-light-background.mp3'
    }
]