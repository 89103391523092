import Time from "./Utils/Time.js";
import VideosManager from "./Utils/VideosManager.js";

// Global instance
let instance = null;
// Time variables
let delta, interval;

export default class Experience
{
    // Set constructor
    constructor(parameters)
    {
        // Return the existing instance
        if(instance) return instance;
        // Create a new instance if needed
        instance = this;

        delta = 0;
        interval = 1 / 30;

        // Scene loaded verifier
        this.SCENE_LOADED = false;

        // Init parameters object
        this.parameters = {};
        // Set the assets path inside the project
        this.parameters.ASSETS_PATH = typeof parameters.ASSETS_PATH == 'undefined' ? '' : parameters.ASSETS_PATH;
        // Set muted audio verifier
        this.parameters.AUDIO_MUTED = typeof parameters.AUDIO_MUTED == 'undefined' ? false : parameters.AUDIO_MUTED;

        // Set utils
        this.#setUtils();
    }

    // Method called to set up the needed classed
    #setUtils()
    {
        // Setup utils
        if(!this.INTERRUPTED) this.time = new Time();
        if(!this.INTERRUPTED) this.videosManager = new VideosManager(this.parameters.AUDIO_MUTED);

        // Set listeners
        if(!this.INTERRUPTED) this.#setListeners();
    }

    // Private method called to set all the listeners
    #setListeners()
    {
        // Time tick event
        this.time.on('tick', () =>
        {
            // If the experience wasn't interrupted
            if(!this.INTERRUPTED)
            {
                // Update delta
                delta += this.time.delta / 1000;
                // If the delta time surpassed the interval needed
                if(delta > interval)
                {
                    // If the scene is loaded
                    if(this.SCENE_LOADED === true)
                    {
                        // Call local update method
                        this.update();
                    }
                    
                    // Reset delta
                    delta = delta % interval;
                }
            }
        });

        // Loaded main video event
        this.videosManager.on('loaded3DScene', () =>
        {
            // If the experience wasn't interrupted
            if(!this.INTERRUPTED)
            {
                // Set verification variable to true
                this.SCENE_LOADED = true;

                // Data to be sent
                const data = { 'scene_id': 1 }

                // Trigger event once to signal that the video has been loaded
                const loaded3DSceneEvent = new CustomEvent( 'loaded3DScene', { detail: data } );
                window.novvaC1.eventTarget.dispatchEvent(loaded3DSceneEvent);
            }
        });

        // Show training video event
        this.videosManager.on('addingVideoPanorama', () =>
        {
            // If the experience wasn't interrupted
            if(!this.INTERRUPTED)
            {
                // Data to be sent
                const data = { 'video_id': this.videosManager.step }

                // Trigger event once to signal that the training video can be shown
                const addingVideoEvent = new CustomEvent( 'addingVideoPanorama', { detail: data } );
                window.novvaC1.eventTarget.dispatchEvent(addingVideoEvent);
            }
        });

        // Show training video event
        this.videosManager.on('removingVideoPanorama', () =>
        {
            // If the experience wasn't interrupted
            if(!this.INTERRUPTED)
            {
                // Data to be sent
                const data = { 'video_id': this.videosManager.step }

                // Trigger event once to signal that the training video can be shown
                const removingVideoEvent = new CustomEvent( 'removingVideoPanorama', { detail: data } );
                window.novvaC1.eventTarget.dispatchEvent(removingVideoEvent);
            }
        });

        // Show training video event
        this.videosManager.on('showTrainingVideo', () =>
        {
            // If the experience wasn't interrupted
            if(!this.INTERRUPTED)
            {
                // Trigger event once to signal that the training video can be shown
                const showTrainingVideoEvent = new CustomEvent( 'showTrainingVideo' );
                window.novvaC1.eventTarget.dispatchEvent(showTrainingVideoEvent);
            }
        });

        // Main video ended event
        this.videosManager.on('videoEnded', () =>
        {
            // If the experience wasn't interrupted
            if(!this.INTERRUPTED)
            {
                // Trigger event once to signal that the video has ended
                const videoEndedEvent = new CustomEvent( 'videoEnded' );
                window.novvaC1.eventTarget.dispatchEvent(videoEndedEvent);
            }
        });
    }

    // Method called by the tick event that propagates it to the classes that need updates every tick
    update()
    {
        // Update the videos
        this.videosManager.update();
    }

    // Method called to wipe the mempory clear
    wipe()
    {
        // Set scene as interrupted and unloaded
        this.INTERRUPTED = true;
        this.SCENE_LOADED = false;

        // Remove listeners
        try { this.time.off('tick') } catch(e) { console.log(e) };
        try { this.videosManager.off('loaded3DScene') } catch(e) { console.log(e) };
        try { this.videosManager.off('showTrainingVideo') } catch(e) { console.log(e) };
        try { this.videosManager.off('videoEnded') } catch(e) { console.log(e) };

        // Destroy instances
        try { this.time.destroy() } catch(e) { console.log(e) };
        try { this.videosManager.destroy() } catch(e) { console.log(e) };

        // Reset references
        this.time = null;
        this.videosManager = null;

        // Reset instance
        instance = null;
    }
}