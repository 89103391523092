import { PerspectiveCamera } from "three";

import Experience from "./Experience.js";
import EventEmitter from './Utils/EventEmitter.js';

let isMobile;

export default class Camera extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.canvas = this.experience.canvas;
        this.keys = this.experience.keys;

        // Set camera instances
        this.setInstances();
    }

    // Method called to create and set up the cameras
    setInstances()
    {
        // Get the mobile detector class from the experience
        this.mobileDetector = this.experience.mobileDetector;
        // Get if the device is a mobile
        isMobile = this.mobileDetector.isMobile;
        // Remove reference
        this.mobileDetector = null;

        // Create perspective camera
        this.renderCamera = new PerspectiveCamera(45, this.sizes.width / this.sizes.height, 0.1, 2000);
        this.renderCamera.rotation.order = 'YXZ';
        this.renderCamera.rotation.y = -Math.PI * 0.5;
        this.renderCamera.rotation.x = Math.PI * 0.04;

        this.defaultPosition = this.renderCamera.position.clone();

        // Add to scene
        if(!this.experience.INTERRUPTED) this.scene.add(this.renderCamera);
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Update camera aspect
        this.renderCamera.aspect = this.sizes.width / this.sizes.height;
        this.renderCamera.updateProjectionMatrix();
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Remove variables
        this.defaultPosition = null;
        this.renderCamera = null;

        // Remove references
        this.experience = null;
        this.sizes = null;
        this.scene = null;
        this.canvas = null;
        this.keys = null;
    }
}