import { Vector2 } from "three";

import Experience from "../Experience.js";
import EventEmitter from './EventEmitter.js';

let touchHandler, mouseDownHandler, mouseMoveHandler, mouseUpHandler, mouseWheelHandler;

let pointerDown, pointerMoved, startPos;
let isMobile;

export default class Pointer extends EventEmitter
{
    // Set constructor
    constructor(BACKGROUND_OPTION)
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.canvas = this.experience.canvas;
        this.sizes = this.experience.sizes;
        this.audio = this.experience.audio;
        this.resources = this.experience.resources;
        this.camera = this.experience.camera;

        pointerDown = false;
        pointerMoved = false;
        startPos = new Vector2(0, 0);

        // Get the background option
        this.BACKGROUND_OPTION = BACKGROUND_OPTION;

        // When the resources are loaded
        this.resources.on('loadedResources', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Get the needed classes from the experience
                this.dome = this.experience.dome;
                // Set pointer
                this.setPointer();
            }
        });
    }

    // Method called to set up the pointer
    setPointer()
    {
        // Mouse position
        this.mouse = new Vector2(Infinity, Infinity);

        // Get the mobile detector class from the experience
        this.mobileDetector = this.experience.mobileDetector;
        // Get if the device is a mobile
        isMobile = this.mobileDetector.isMobile;
        // Remove reference
        this.mobileDetector = null;

        // If the device is a desktop
        if(isMobile === false)
        {
            // Mouse status
            this.mouseMove = false;
            // Set mouse move listener
            if(!this.experience.INTERRUPTED) this.#setMouseMoveListener();
        }
        // If the device is a mobile
        else
        {
            // Set touch listeners
            if(!this.experience.INTERRUPTED) this.#setTouchListeners();
        }
    }

    // Private method called to set up listeners related to the mouse movement
    #setMouseMoveListener()
    {
        // If the background option chosen is the moon landscape
        if(this.BACKGROUND_OPTION === 0)
        {
            mouseWheelHandler = (e) =>
            {
                // If the experience wasn't interrupted
                if(!this.experience.INTERRUPTED)
                {
                    // If the experience has loaded and the mouse target is the canvas
                    if(this.experience.SCENE_LOADED === true && e.target === this.canvas)
                    {
                        // If the dome isn't mid switch
                        if(this.dome.switching === false)
                        {
                            // Move camera and rotate on the Y axis as the mouse wheel is used
                            this.camera.renderCamera.position.y += e.deltaY / 10;
                            if(this.camera.renderCamera.position.y < 190) this.camera.renderCamera.position.y = 190;
                            if(this.camera.renderCamera.position.y > 800) this.camera.renderCamera.position.y = 800;

                            // Set height limits to the camera
                            if(this.camera.renderCamera.position.y < 350) this.camera.renderCamera.rotation.y -= e.deltaY * 0.0002;
                            else this.camera.renderCamera.rotation.y -= e.deltaY * 0.00008;

                            // Set rotation limits to the camera
                            if(this.camera.renderCamera.rotation.y < 0.358) this.camera.renderCamera.rotation.y = 0.358;
                            if(this.camera.renderCamera.rotation.y > 1.027) this.camera.renderCamera.rotation.y = 1.027;
                        }
                    }
                }
            }
        }

        mouseDownHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the experience has loaded
                if(this.experience.SCENE_LOADED === true)
                {
                    // If this is the first click
                    if(this.audio.firstClick === false)
                    {
                        // Play audio if not muted
                        this.audio.firstClick = true;
                        if(this.audio.AUDIO_MUTED === false) this.audio.pauseOrResumeSoundtrack(true);
                    }

                    // If the click target is the canvas
                    if(e.target === this.canvas)
                    {
                        // If the dome isn't mid switch
                        if(this.dome.switching === false)
                        {
                            // Set variable to true to alert that the mouse has been pressed
                            pointerDown = true;

                            // Calculate normalized mouse coordinates relative to canvas size
                            const rect = this.canvas.getBoundingClientRect();
                            startPos.x = ((e.clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                            startPos.y = -((e.clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;
                        }
                    }
                }
            }
        };

        mouseMoveHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the experience has loaded and the mouse target is the canvas
                if(this.experience.SCENE_LOADED === true && e.target === this.canvas)
                {
                    // If the dome isn't mid switch
                    if(this.dome.switching === false)
                    {
                        // Set mouse status as moving
                        this.mouseMove = true;

                        // Calculate normalized mouse coordinates relative to canvas size
                        const rect = this.canvas.getBoundingClientRect();
                        this.mouse.x = ((e.clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                        this.mouse.y = -((e.clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;

                        // If the third person camera is active
                        if(pointerDown === true)
                        {
                            // Move camera accordingly to the mouse position
                            this.camera.renderCamera.position.x -= (e.movementY / 5);
                            this.camera.renderCamera.position.z += (e.movementX / 5);

                            // If the background option chosen is the moon landscape
                            if(this.BACKGROUND_OPTION === 0)
                            {
                                // Set limits to the camera vertically
                                if(this.camera.renderCamera.position.x < 182) this.camera.renderCamera.position.x = 182;
                                else if(this.camera.renderCamera.position.x > 426) this.camera.renderCamera.position.x = 426;
                                // Set limits to the camera horizontally
                                if(this.camera.renderCamera.position.z > 150) this.camera.renderCamera.position.z = 150;
                                else if(this.camera.renderCamera.position.z < -150) this.camera.renderCamera.position.z = -150;
                            }
                            // If the background option chosen is the earth landscape
                            else
                            {
                                // Set limits to the camera vertically
                                if(this.camera.renderCamera.position.x < 278) this.camera.renderCamera.position.x = 278;
                                else if(this.camera.renderCamera.position.x > 338) this.camera.renderCamera.position.x = 338;
                                // Set limits to the camera horizontally
                                if(this.camera.renderCamera.position.z > 20) this.camera.renderCamera.position.z = 20;
                                else if(this.camera.renderCamera.position.z < -20) this.camera.renderCamera.position.z = -20;
                            }
                        }
                    }
                }
            }
        };

        mouseUpHandler =(e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the experience has loaded and the mouse target is the canvas
                if(this.experience.SCENE_LOADED === true && e.target === this.canvas)
                {
                    // If the dome isn't mid switch
                    if(this.dome.switching === false)
                    {
                        // Set variable to true to alert that the mouse has been released
                        pointerDown = false;

                        // Calculate normalized mouse coordinates relative to canvas size
                        const rect = this.canvas.getBoundingClientRect();
                        this.mouse.x = ((e.clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                        this.mouse.y = -((e.clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;

                        // If the scene is loaded and the mouse click hasn't moved
                        if(this.equals(startPos, this.mouse, 0.02) === true)
                        {
                            // Update camera
                            this.camera.renderCamera.updateMatrixWorld();

                            // If the user is hovering an interactive element during the click
                            if(this.dome.hoveredElem !== null)
                            {
                                // Get add factor
                                let add = 0;
                                if(this.dome.hoveredElem.includes('bullet')) add = 1;

                                // Get the element type
                                const type = this.dome.hoveredElem.split('_')[0 + add];

                                // Data to be sent
                                let data;
                                // If the element is a pavilion, get pavilion id
                                if(type === 'pavilion')
                                {
                                    let domeArrayPos;
                                    for(let i = 0; i < this.dome.instance.info.domes.length; i++)
                                    {
                                        if(this.dome.instance.info.domes[i].dome_id === this.dome.instance.id)
                                        {
                                            domeArrayPos = i;
                                            break;
                                        }
                                    }

                                    // Get the element id
                                    const arrayId = parseInt(this.dome.hoveredElem.split('_')[1 + add]);
                                    const id = this.dome.instance.info.domes[domeArrayPos].pavilions[arrayId].pavilion_id;

                                    data = { 'pavilion_id': id };
                                }
                                // If the element is a connection, get connection id
                                else
                                {
                                    // Get the element id
                                    const arrayId = parseInt(this.dome.hoveredElem.split('_')[1 + add]);
                                    const id = this.dome.instance.info.domes[arrayId].dome_id;

                                    data = { 'dome_id': id };
                                }

                                // Trigger event warning that this pavilion was clicked, sending the info acquired
                                const clickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
                                window.novvaC2.eventTarget.dispatchEvent(clickEvent);

                                // If the element is a connection
                                if(type === 'connection')
                                {
                                    // Switch dome
                                    this.dome.switchDome(this.dome.hoveredElem.split('_')[1 + add]);
                                }

                                // Set cursor to pointer
                                document.body.style.cursor = "default";
                            }
                        }
                    }
                }
            }
        };

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // If the background option chosen is the moon landscape, listen for mouse wheel
            if(this.BACKGROUND_OPTION === 0) document.addEventListener('mousewheel', mouseWheelHandler);
            // Listen for mouse clicks
            document.addEventListener('mousedown', mouseDownHandler);
            // Listen for mouse movements
            document.addEventListener('mousemove', mouseMoveHandler);
            // Listen for mouse releases
            document.addEventListener('mouseup', mouseUpHandler);
        }
    }

    // Private method called to set up the touch listeners
    #setTouchListeners()
    {
        // Touch event handler
        touchHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the experience has loaded
                if(this.experience.SCENE_LOADED === true)
                {
                    // If this is the first click
                    if(this.audio.firstClick === false)
                    {
                        // Play audio if not muted
                        this.audio.firstClick = true;
                        if(this.audio.AUDIO_MUTED === false) this.audio.pauseOrResumeSoundtrack(true);
                    }

                    // Get the canvas rect
                    const rect = this.canvas.getBoundingClientRect();

                    // If the click target is the canvas
                    if(e.target.id === this.canvas.id)
                    {
                        // If the scene is loaded and the touch target is the canvas
                        if(this.dome.switching === false)
                        {
                            // If the touch started
                            if(e.type === "touchstart")
                            {
                                // Calculate normalized mouse coordinates relative to canvas size
                                this.mouse.x = ((e.touches[0].clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                                this.mouse.y = -((e.touches[0].clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;
                            }
                            // If the touch moved
                            else if(e.type === "touchmove")
                            {
                                // Set verifier to true
                                pointerMoved = true;

                                // Get current position
                                const currentMousePos = new Vector2(((e.touches[0].clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1, -((e.touches[0].clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1);

                                // Get differencial between the start position and the current position
                                const dif = new Vector2(0, 0);
                                dif.copy(this.mouse);
                                dif.sub(currentMousePos);

                                // Move camera and rotate on the Y axis as user pinches
                                if(e.touches.length > 1)
                                {
                                    this.camera.renderCamera.position.y += dif.y * 50;
                                    if(this.camera.renderCamera.position.y < 190) this.camera.renderCamera.position.y = 190;
                                    if(this.camera.renderCamera.position.y > 800) this.camera.renderCamera.position.y = 800;

                                    // Set height limits to the camera
                                    if(this.camera.renderCamera.position.y < 350) this.camera.renderCamera.rotation.y -= dif.y * 0.5;
                                    else this.camera.renderCamera.rotation.y -= dif.y * 0.2;

                                    // Set rotation limits to the camera
                                    if(this.camera.renderCamera.rotation.y < 0.358) this.camera.renderCamera.rotation.y = 0.358;
                                    if(this.camera.renderCamera.rotation.y > 1.027) this.camera.renderCamera.rotation.y = 1.027;
                                }
                                // Pan camera as the user slides
                                else
                                {
                                    // Move camera accordingly to the touch movement
                                    this.camera.renderCamera.position.x -= dif.y * 100;
                                    this.camera.renderCamera.position.z -= dif.x * 100;

                                    // Set limits to the camera vertically
                                    if(this.camera.renderCamera.position.x < 182) this.camera.renderCamera.position.x = 182;
                                    else if(this.camera.renderCamera.position.x > 426) this.camera.renderCamera.position.x = 426;
                                    // Set limits to the camera horizontally
                                    if(this.camera.renderCamera.position.z > 150) this.camera.renderCamera.position.z = 150;
                                    else if(this.camera.renderCamera.position.z < -150) this.camera.renderCamera.position.z = -150;
                                }

                                // Calculate normalized mouse coordinates relative to canvas size
                                this.mouse.x = ((e.touches[0].clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                                this.mouse.y = -((e.touches[0].clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;
                            }
                            // If the touch ended
                            else if(e.type === "touchend")
                            {
                                // If the touch didn't move
                                if(pointerMoved === false)
                                {
                                    // If the user is hovering an interactive element during the click
                                    if(this.dome.hoveredElem !== null)
                                    {
                                        // Get add factor
                                        let add = 0;
                                        if(this.dome.hoveredElem.includes('bullet')) add = 1;

                                        // Get touched element
                                        const hoveredElem = this.dome.hoveredElem;

                                        // Trigger raycast
                                        this.trigger('pointerTouch');

                                        // If the same element was clicked twice
                                        if(hoveredElem === this.dome.hoveredElem)
                                        {
                                            // If the element clicked is a pavilion
                                            if(this.dome.hoveredElem.includes('pav'))
                                            {
                                                // Find the pavilion array position
                                                let domeArrayPos;
                                                for(let i = 0; i < this.dome.instance.info.domes.length; i++)
                                                {
                                                    if(this.dome.instance.info.domes[i].dome_id === this.dome.instance.id)
                                                    {
                                                        domeArrayPos = i;
                                                        break;
                                                    }
                                                }
                                                
                                                // Get the element id
                                                const arrayId = parseInt(this.dome.hoveredElem.split('_')[1 + add]);
                                                const id = this.dome.instance.info.domes[domeArrayPos].pavilions[arrayId].pavilion_id;

                                                const data = { 'pavilion_id': id };

                                                // Reset variable
                                                this.dome.hoveredElem = null;

                                                // Trigger event warning that this pavilion was clicked, sending the info acquired
                                                const clickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
                                                window.novvaC2.eventTarget.dispatchEvent(clickEvent);
                                            }
                                        }
                                        // If another element was clicked
                                        else if(this.dome.hoveredElem !== null)
                                        {
                                            // If the clicked element is a connection
                                            if(this.dome.hoveredElem.includes('connection'))
                                            {
                                                // Get add factor
                                                let add = 0;
                                                if(this.dome.hoveredElem.includes('bullet')) add = 1;

                                                // Get the element id
                                                const arrayId = parseInt(this.dome.hoveredElem.split('_')[1 + add]);
                                                const id = this.dome.instance.info.domes[arrayId].dome_id;

                                                const data = { 'dome_id': id };

                                                // Trigger event warning that this connection was clicked, sending the info acquired
                                                const clickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
                                                window.novvaC2.eventTarget.dispatchEvent(clickEvent);

                                                // Switch dome
                                                this.dome.switchDome(this.dome.hoveredElem.split('_')[1 + add]);

                                                // Reset variable
                                                this.dome.hoveredElem = null;
                                            }
                                        }
                                    }
                                    // If the user isn't touching a stand during the click
                                    else
                                    {
                                        this.trigger('pointerTouch');

                                        // Get touched element
                                        const hoveredElem = this.dome.hoveredElem;

                                        // If the user is hovering an interactive element during the click
                                        if(hoveredElem !== null)
                                        {
                                            // Get add factor
                                            let add = 0;
                                            if(hoveredElem.includes('bullet')) add = 1;

                                            // Get the element type
                                            const type = hoveredElem.split('_')[0 + add];

                                            // If the element is a connection
                                            if(type === 'connection')
                                            {
                                                // Get the element id
                                                const arrayId = parseInt(this.dome.hoveredElem.split('_')[1 + add]);
                                                const id = this.dome.instance.info.domes[arrayId].dome_id;

                                                const data = { 'dome_id': id };

                                                // Trigger event warning that this pavilion was clicked, sending the info acquired
                                                const clickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
                                                window.novvaC2.eventTarget.dispatchEvent(clickEvent);

                                                // Switch dome
                                                this.dome.switchDome(this.dome.hoveredElem.split('_')[1 + add]);

                                                // Reset variable
                                                this.dome.hoveredElem = null;
                                            }
                                        }
                                    }
                                }
                                // If the touch moved
                                else
                                {
                                    // Reset variable
                                    pointerMoved = false;
                                }
                            }
                        }
                    }
                }
            }
        };

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Listen for touches starting
            document.addEventListener('touchstart', touchHandler);
            // Listen for touches moving
            document.addEventListener('touchmove', touchHandler);
            // Listen for touches ending
            document.addEventListener('touchend', touchHandler);
        }
    }

    // Method called to compare vectors and get if they are the same
    equals(firstValue, secValue, tolerance)
    {
        // If the tolerance factor isn't defined
        if(tolerance === undefined)
        {
            // Return literal sameness
            return ((firstValue.x === secValue.x) && (firstValue.y === secValue.y));
        }
        // If the tolerance factor is defined
        else
        {
            // Return if the vectors are the same within the tolerance
            return ((Math.abs(firstValue.x - secValue.x) < tolerance) && (Math.abs(firstValue.y - secValue.y) < tolerance));
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Remove reference
        this.mouse = null;

        // Stop listening for the resources loading process
        try { this.resources.off('loadedResources') } catch(e) { console.log(e) };

        // If the device is a desktop
        if(isMobile === false)
        {
            try
            {
                // Stop listening for mouse events
                if(this.BACKGROUND_OPTION === 0) document.removeEventListener('mousewheel', mouseWheelHandler);
                document.removeEventListener('mousedown', mouseDownHandler);
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
            }
            catch(e) { console.log(e) };
        }
        // If the device is a mobile
        else
        {
            try
            {
                // Stop listening for touch events
                document.removeEventListener('touchstart', touchHandler);
                document.removeEventListener('touchmove', touchHandler);
                document.removeEventListener('touchend', touchHandler);
            }
            catch(e) { console.log(e) };
        }

        // Remove references
        isMobile = null;
        this.sizes = null;
        this.audio = null;
        this.camera = null;
        this.canvas = null;
        this.dome = null;
        this.experience = null;
    }
}