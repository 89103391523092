import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import Experience from "../Experience.js";
import EventEmitter from "./EventEmitter.js";

export default class Resources extends EventEmitter
{
    // Set constructor
    constructor(sources, ASSETS_PATH)
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();

        // Get sources
        this.sources = sources;
        this.ASSETS_PATH = ASSETS_PATH;

        // Initialize variables
        this.items = {};
        this.toLoad = this.sources.length;
        this.loaded = 0;

        // Set loaders
        this.#setLoaders();
        // Start loading the resources
        this.#startLoading();
    }

    // Private method called to create and set up the loaders
    #setLoaders()
    {
        // Enable cache
        THREE.Cache.enabled = true;

        // Initialize instance
        this.loaders = {};

        // Create GLTF and DRACO loaders
        this.loaders.gltfLoader = new GLTFLoader();

        // Create texture loader
        this.loaders.textureLoader = new THREE.TextureLoader();
        this.loaders.textureLoader.setCrossOrigin("anonymous");
    }

    // Private method called to start loading the resources
    #startLoading()
    {
        // Load each source
        for(const source of this.sources)
        {
            // If the loading process wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the source is a GLTF model
                if(source.type === 'gltfModel')
                {
                    // Load model
                    this.loaders.gltfLoader.load(
                        this.ASSETS_PATH + source.path,
                        // OnLoad callback
                        (file) =>
                        {
                            // Resource finished loading
                            this.sourceLoaded(source, file);
                        },
                        // OnProgress callback
                        () => {},
                        // OnError callback
                        (error) => 
                        {
                            console.log("An error happened while loading the GLTF model < " + source.name + " >. See log below.");
                            console.log(error);
                        }
                    );
                }
                // If the source is a texture
                else if(source.type === 'texture')
                {
                    // Load texture
                    this.loaders.textureLoader.load(
                        this.ASSETS_PATH + source.path,
                        // OnLoad callback
                        (file) =>
                        {
                            // Resource finished loading
                            this.sourceLoaded(source, file);
                        },
                        // OnProgress callback
                        () => {},
                        // OnError callback
                        (error) => 
                        {
                            console.log("An error happened while loading the texture < " + source.name + " >. See log below.");
                            console.log(error);
                        }
                    );
                }
            }
        }
    }

    // Private method called to account the loaded resources
    sourceLoaded(source, file)
    {
        // If the array of items exist
        if(this.items)
        {
            // Save loaded resource
            this.items[source.name] = file;

            // Add to counter
            this.loaded++;

            // Get the loading percentage
            window.novvaC6.assetsLoadingPercentage = parseFloat((this.loaded * 100) / this.toLoad);

            // If all the resources have been loaded
            if(this.loaded === this.toLoad)
            {
                // Trigger event
                this.trigger('loadedResources');
                this.sources = null;
            }
        }
    }

    // Method propagated by the experience to destroy this instance
    destroy()
    {
        this.sources = null;
        this.items = null;

        this.toLoad = null;
        this.loaded = 0;
        window.novvaC6.assetsLoadingPercentage = 0;

        this.loaders = null;
    }
}