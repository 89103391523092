import * as THREE from "three";
import * as PANOLENS from 'panolens';

import Experience from "../Experience";
import EventEmitter from "./EventEmitter";

let onCanPlayHandler, clickHandler, visibilityHandler;

export default class VideosManager extends EventEmitter
{
    // Set constructor
    constructor(AUDIO_MUTED)
    {
        super();

        // Get the experience instance
        this.experience = new Experience();

        // Audio muted at the start
        this.AUDIO_MUTED = AUDIO_MUTED;
        this.volume = 0.6;

        // Set video variables
        this.mainVideo = null;
        this.panolens = null;
        this.infospot = null;
        // Set the interaction steps
        this.step = 0;
        
        // Set the main video
        if(!this.experience.INTERRUPTED) this.#setMainVideo();
    }

    // Private method called to set up the main video
    #setMainVideo()
    {
        // Create and load the main video
        this.mainVideo = document.getElementsByClassName('panolens-video')[0];
        this.mainVideo.src = this.experience.parameters.ASSETS_PATH + 'video/0791_Video_Site_Novva_Lookdev_v2.2.mp4';
        this.mainVideo.muted = this.AUDIO_MUTED;
        this.mainVideo.volume = this.volume;
        this.mainVideo.load();

        // Handle if the video can play
        onCanPlayHandler = () =>
        {
            // Trigger loaded event
            if(!this.experience.INTERRUPTED) this.trigger('loaded3DScene');
        }
        // Hanlde if the current tab is hidden or not
        visibilityHandler = () =>
        {
            try
            {
                // If the document is hidden
                if(document.hidden === true)
                {
                    // Pause the video
                    if(this.mainVideo) this.mainVideo.pause();
                }
                // If the document isn't hidden
                else if(this.panoLens === null || this.panoLens === undefined)
                {
                    // If the video wasn't manually paused
                    if(!this.manuallyPaused)
                    {
                        // Resume the video
                        if(this.mainVideo) this.mainVideo.play();
                    }
                }
            }
            catch(e) { console.log(e) };
        };

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Listen to when the video is loaded enough to play
            this.mainVideo.oncanplay = onCanPlayHandler;
            // Listen to when the document is hidden, meaning the user switched tabs
            document.addEventListener('visibilitychange', visibilityHandler);
        }
    }

    // Method called to play the main video
    playIntroductionVideo()
    {
        // Display and play the video
        if(this.mainVideo.style.display === 'none') this.mainVideo.style.display = '';
        this.mainVideo.play();

        // Reset the manual pause if needed
        if(this.manuallyPaused) this.manuallyPaused = undefined;
    }

    // Method called to pause the main video
    pauseIntroductionVideo()
    {
        // If the video isn't already paused
        if(this.mainVideo.paused === false)
        {
            // Pause the video
            this.mainVideo.pause();
            // Set the manual pause
            this.manuallyPaused = true;
        }
    }

    // Method called to mute or unmute the video
    muteOrUnmuteVideo(bool)
    {
        // If bool is undefined, define if the audio should be muted or not
        if(bool === null || bool === undefined) bool = !this.mainVideo.muted;

        // Mute or unmute the soundtrack
        this.mainVideo.muted = bool;
    }

    // Method called to set the video volume
    setAudioVolume(value)
    {
        // If the value isn't a number
        if(isNaN(value) || value === "")
        {
            console.log("Volume set to the audio is not a number: " + value);
        }
        // If the value is a number
        else
        {
            // Set min value as 0, max value as 1
            this.volume = Math.min(Math.abs(value), 1);

            // If the main video is available
            if(this.mainVideo !== null && this.mainVideo !== undefined)
            {
                // Set video volume
                this.mainVideo.volume = this.volume;
            }
        }
    }

    // Private method called to set up a panoLens canvas
    #setPanoLens()
    {
        // Trigger panorama set up
        this.trigger('addingVideoPanorama');

        let url = "", lookAt;
        // If the first panorama must be loaded
        if(this.step === 0)
        {
            // Get video url and camera position
            url = this.experience.parameters.ASSETS_PATH + 'video/Plano360_c02_v2.0.mp4';
            lookAt = new THREE.Vector3(30, -2.5, -7.4);
        }
        // If the second panorama must be loaded
        else if(this.step === 1)
        {
            // Get video url and camera position
            url = this.experience.parameters.ASSETS_PATH + 'video/Plano360_c01_v2.0.mp4';
            lookAt = new THREE.Vector3(6, -1.9, -0.5);
        }

        // Create video panorama
        this.panoLens = new PANOLENS.VideoPanorama( url, { autoplay: true, loop: true } );
        this.viewer = new PANOLENS.Viewer();
        this.viewer.add(this.panoLens);

        this.panoLens.addEventListener( 'enter-fade-start', () =>
        {
            this.viewer.tweenControlCenter(lookAt, 0);
        });

        // If the first panorama must be loaded
        if(this.step === 0)
        {
            // Set the info spot by the chair
            this.infospot = new PANOLENS.Infospot(2, PANOLENS.DataImage.Info);
            this.infospot.position.set(30, -2.5, -7.4);
        }
        // If the second panorama must be loaded
        else if(this.step === 1)
        {
            // Set the info spot in front of the camera
            this.infospot = new PANOLENS.Infospot(1, PANOLENS.DataImage.Info);
            this.infospot.position.set(6, -1.9, -0.5);
        }
        this.panoLens.add(this.infospot);

        // Click handler
        clickHandler = (e) =>
        {
            // If the click hit an object
            if(e.intersects.length > 0)
            {
                // Get the clicked object
                const intersect = e.intersects[0].object;
    
                // If the clicked object is an info spot
                if(intersect instanceof PANOLENS.Infospot)
                {
                    this.viewer.tweenControlCenter(this.infospot.position, 300);

                    setTimeout(() =>
                    {
                        // Remove the panorama and the style elements
                        document.getElementsByClassName('panolens-container')[0].remove();
                        document.getElementById('panolens-style-addon').remove();

                        // Remove the click listener
                        this.panoLens.removeEventListener('click', clickHandler);
                        this.trigger('removingVideoPanorama');

                        // Dispose the panorama
                        this.infospot.dispose();
                        this.panoLens.dispose();
                        this.viewer.dispose();

                        this.infospot = null;
                        this.panoLens = null;
                        this.viewer = null;

                        // Resume the main video
                        this.mainVideo.play();
                        // Progress a step
                        this.step++;
                    }, 300);
                }
            }
        }

        // Listen for clicks
        this.panoLens.addEventListener('click', clickHandler);
    }

    // Method propagated by the experience each tick event
    update()
    {
        // If the first panorama must be loaded
        if(this.step === 0)
        {
            // If the correct time arrived and the main video hasn't been paused yet
            if(this.mainVideo.currentTime > 29 && this.mainVideo.paused === false)
            {
                // Set panorama and pause the main video
                this.#setPanoLens();
                this.mainVideo.pause();
            }
        }
        // If the second panorama must be loaded
        else if(this.step === 1)
        {
            // If the correct time arrived and the main video hasn't been paused yet
            if(this.mainVideo.currentTime > 86 && this.mainVideo.paused === false)
            {
                // Set panorama and pause the main video
                this.#setPanoLens();
                this.mainVideo.pause();
            }
        }
        // If the training video must be shown
        else if(this.step === 2)
        {
            // If the correct time arrived and the main video hasn't been paused yet
            if(this.mainVideo.currentTime > 94 && this.mainVideo.paused === false)
            {
                // Pause the main video and trigger the training video event
                this.mainVideo.pause();
                this.trigger('showTrainingVideo');
                this.step++;
            }
        }
        // If the video is at the last step
        else if(this.step === 3)
        {
            // If the main video ended
            if(this.mainVideo.currentTime >= this.mainVideo.duration)
            {
                // Hide the main video and trigger the ending event
                document.getElementsByClassName('panolens-video')[0].style.display = 'none';
                this.trigger('videoEnded');
            }
        }
    }

    // Method propagated by the experience to destroy this instance
    destroy()
    {
        // Pause the main video if needed
        try { if(this.mainVideo.paused === false) this.mainVideo.pause() } catch(e) { console.log(e) };
        try { this.mainVideo.oncanplay = undefined } catch(e) { console.log(e) };
        try { this.mainVideo.removeEventListener('visibilityChange', visibilityHandler) } catch(e) { console.log(e) };

        // Dispose the panorama
        try { if(this.infospot) this.infospot.dispose() } catch(e) { console.log(e) };
        try { if(this.panoLens) this.panoLens.dispose() } catch(e) { console.log(e) };
        try { if(this.viewer) this.viewer.dispose() } catch(e) { console.log(e) };

        // Reset variables
        this.mainVideo = null;
        this.panolens = null;
        this.step = null;

        // Remove references
        this.experience = null;
    }
}