import * as THREE from "three";

import Experience from "./Experience";
import EventEmitter from "./Utils/EventEmitter";

export default class Audio extends EventEmitter
{
    // Set constructor
    constructor(AUDIO_MUTED)
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.resources = this.experience.resources;

        // Set audios object
        this.audios = {};
        this.firstClick = false;
        this.volume = 0.1;

        // Audio muted at the start
        this.AUDIO_MUTED = AUDIO_MUTED;

        // Set audio listener
        this.listener = new THREE.AudioListener();

        // Listen to when the resources are loaded
        this.resources.on('loadedResources', () =>
        {
            // Set the audio listener
            if(!this.experience.INTERRUPTED) this.#setAudioListener();
            // Set ambiance sound
            if(!this.experience.INTERRUPTED) this.#setAmbiance();
        });
    }

    // Private method called to set up the audio listener
    #setAudioListener()
    {
        // Get the camera class from the experience
        this.camera = this.experience.camera;
        // Add to the camera
        this.camera.renderCamera.add(this.listener);
        // Remove reference
        this.camera = null;
    }

    // Private method called to set up the ambiance sound
    #setAmbiance()
    {
        // If the soundtrack hasn't been loaded yet
        if(this.audios.bg_soundtrack === undefined)
        {
            // Create audio
            this.audios.bg_soundtrack = new THREE.Audio(this.listener);
            // Get soundtrack from the resources
            this.audios.bg_soundtrack.setBuffer(this.resources.items.soundtrack_1);
            // Set loop and volume
            this.audios.bg_soundtrack.setLoop(true);
            this.audios.bg_soundtrack.setVolume(this.volume);
        }
    }

    // Method called to change the audio track
    changeAudioTrack(url)
    {
        // Load the section model
        this.resources.loaders.audioLoader.load( url,
            // OnLoad callback
            (buffer) =>
            {
                // If the soundtrack is already loaded
                if(this.audios.bg_soundtrack !== undefined)
                {
                    // If the audio is playing, stop it before the change
                    const playing = this.audios.bg_soundtrack.isPlaying;
                    if(playing === true) this.audios.bg_soundtrack.stop();

                    // Set new buffer and volume
                    this.audios.bg_soundtrack.setBuffer(buffer);
                    this.audios.bg_soundtrack.setVolume(this.volume);

                    // If the audio was playing before the change, play it after done
                    if(playing === true) this.audios.bg_soundtrack.play();
                }
                // If the soundtrack hasn't been loaded yet
                else
                {
                    // Create audio
                    this.audios.bg_soundtrack = new THREE.Audio(this.listener);
                    // Get soundtrack from the resources
                    this.audios.bg_soundtrack.setBuffer(buffer);
                    // Set loop and volume
                    this.audios.bg_soundtrack.setLoop(true);
                    this.audios.bg_soundtrack.setVolume(this.volume);
                }
            },
            // OnProgress callback
            () => {},
            // OnError callback
            (error) => 
            {
                console.log("An error happened while loading the new audio track. See log below.");
                console.log(error);
            }
        );
    }

    // Method called to pause or resume the ambient soundtrack
    pauseOrResumeSoundtrack(bool)
    {
        // If the variable sent isn't null or undefined
        if(bool !== null && bool !== undefined)
        {
            // If the variable is true
            if(bool === true)
            {
                // Resume the song
                this.audios.bg_soundtrack.play();
            }
            // If the variable is false
            else
            {
                // Pause the song
                this.audios.bg_soundtrack.pause();
            }
        }
        // If the variable sent is null or undefined
        else
        {
            // If the song is playing
            if(this.audios.bg_soundtrack.isPlaying === true)
            {
                // Pause the song
                this.audios.bg_soundtrack.pause();
            }
            // If the song isn't playing
            else
            {
                // Resume the song
                this.audios.bg_soundtrack.play();
            }
        }
    }

    // Method called to set the ambient soundtrack volume
    setSoundtrackVolume(value)
    {
        // If the value isn't a number
        if(isNaN(value) || value === "")
        {
            console.log("Volume set to the background song is not a number: " + value);
        }
        // If the value is a number
        else
        {
            // Set min value as 0, max value as 1
            value = Math.min(Math.abs(value), 1);

            // If the audio haven't been loaded yet
            if(this.audios.bg_soundtrack === undefined)
            {
                // Save volume for later
                this.volume = value;
            }
            // If the audio is loaded
            else
            {
                // Set audio volume
                this.volume = value;
                this.audios.bg_soundtrack.setVolume(this.volume);
            }
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Stop listening for the loaded resources event
        try { this.resources.off('loadedResources') } catch(e) { console.log(e) };

        try
        {
            // If the soundtrack was created
            if(this.audios.bg_soundtrack !== undefined)
            {
                // Stop soundtrack
                if(this.audios.bg_soundtrack.isPlaying) this.audios.bg_soundtrack.stop();
                this.audios.bg_soundtrack = null;
            }
        }
        catch(e) { console.log(e) };

        // Reset variables
        this.audios = null;
        this.firstClick = null;
        this.volume = null;
        this.AUDIO_MUTED = null;
        this.listener = null;

        // Remove references
        this.experience = null;
        this.resources = null;
    }
}