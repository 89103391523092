import Experience from "../Experience.js";
import EventEmitter from './EventEmitter.js';

let keyDownHandler;

export default class Keys extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.mobileDetector = this.experience.mobileDetector;
        this.canvas = this.experience.canvas;

        // If the device is a desktop
        if(!this.mobileDetector.isMobile)
        {
            // Set the key listeners
            if(!this.experience.INTERRUPTED) this.#setListeners();
        }

        // Remove reference
        this.mobileDetector = null;
    }

    // Method called by external classes to set the listeners
    #setListeners()
    {
        // Key down event handler
        keyDownHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the scene is loaded
                if(this.experience.SCENE_LOADED)
                {
                    // Trigger interactions
                    if(e.code == 'KeyE')
                    {
                        // Get the pointer class from the experience
                        if(!this.pointer) this.pointer = this.experience.pointer;

                        // If outside an interaction
                        if(this.pointer.insideElem === false)
                        {
                            // Enter the interaction
                            this.pointer.enterInteraction();
                        }
                        // If inside an interaction
                        else if(this.pointer.insideElem === true)
                        {
                            // Exit the interaction
                            this.pointer.exitInteraction();
                        }
                    }
                }
            }
        };

        // Listen for pressed keys
        if(!this.experience.INTERRUPTED) document.addEventListener('keydown', keyDownHandler);
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Stop listening for pressed keys
        try { document.removeEventListener('keydown', keyDownHandler) } catch(e) { console.log(e) };

        // Destroy references
        keyDownHandler = null;
        this.experience = null;
    }
}