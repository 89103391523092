export default [
    {
        name: 'environmentBackground',
        type: 'cubeTexture',
        path:
        [
            'environment/earth_px.png',
            'environment/earth_nx.png',
            'environment/earth_py.png',
            'environment/earth_ny.png',
            'environment/earth_pz.png',
            'environment/earth_nz.png'
        ]
    },
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'environment/envMap/test_px.png',
            'environment/envMap/test_nx.png',
            'environment/envMap/test_py.png',
            'environment/envMap/test_ny.png',
            'environment/envMap/test_pz.png',
            'environment/envMap/test_nz.png'
        ]
    },
    {
        name: 'dome',
        type: 'gltfModel',
        path: 'models/dome/dome.gltf'
    },
    {
        name: 'dome_earth',
        type: 'gltfModel',
        path: 'models/dome/dome_earth.gltf'
    },
    {
        name: 'dome_earth_ground',
        type: 'gltfModel',
        path: 'models/dome/dome_earth_ground.gltf'
    },
    {
        name: 'pavModel_b0',
        type: 'gltfModel',
        path: 'models/pavilions/pavModel_b0.gltf'
    },
    {
        name: 'pavModel_b1',
        type: 'gltfModel',
        path: 'models/pavilions/pavModel_b1.gltf'
    },
    {
        name: 'pavModel_b2',
        type: 'gltfModel',
        path: 'models/pavilions/pavModel_b2.gltf'
    },
    {
        name: 'pavModel_b3',
        type: 'gltfModel',
        path: 'models/pavilions/pavModel_b3.gltf'
    },
    {
        name: 'pavModel_s0',
        type: 'gltfModel',
        path: 'models/pavilions/pavModel_s0.gltf'
    },
    {
        name: 'pavModel_s1',
        type: 'gltfModel',
        path: 'models/pavilions/pavModel_s1.gltf'
    },
    {
        name: 'pavModel_s2',
        type: 'gltfModel',
        path: 'models/pavilions/pavModel_s2.gltf'
    },
    {
        name: 'pavModel_s3',
        type: 'gltfModel',
        path: 'models/pavilions/pavModel_s3.gltf'
    },
    {
        name: 'pavModel_s4',
        type: 'gltfModel',
        path: 'models/pavilions/pavModel_s4.gltf'
    },
    {
        name: 'connection',
        type: 'gltfModel',
        path: 'models/tube/tubeOut.gltf'
    },
    {
        name: 'street',
        type: 'gltfModel',
        path: 'models/connections/street_out.gltf'
    },
    {
        name: 'subway',
        type: 'gltfModel',
        path: 'models/subway/subway.gltf'
    },
    {
        name: 'vehicle',
        type: 'gltfModel',
        path: 'models/subway/vehicle.gltf'
    },
    {
        name: 'vehicle_anim',
        type: 'gltfModel',
        path: 'models/subway/vehicleAnim.gltf'
    },
    {
        name: 'npc',
        type: 'gltfModel',
        path: 'models/npcs/npc_basic/npc.gltf'
    },
    {
        name: 'soundtrack_0',
        type: 'audio',
        path: 'audio/songs/calm-background.mp3'
    },
    {
        name: 'bullet_outer',
        type: 'texture',
        path: 'images/icons/bullet_outer.png'
    },
    {
        name: 'bullet_inner',
        type: 'texture',
        path: 'images/icons/bullet_inner.png'
    },
    {
        name: 'grass_txt',
        type: 'texture',
        path: 'models/dome/T_Grass.jpg'
    }
]