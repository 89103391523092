import { Group, Mesh, AnimationMixer, sRGBEncoding, Box3, Vector3 } from "three";
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';

import Experience from "./Experience";
import EventEmitter from "./Utils/EventEmitter";

// Time variable
let animHandler;

export default class NPC extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.disposer = this.experience.disposer;
        this.camera = this.experience.camera;

        this.instance = {};
        this.instance.colors = [];
        this.instance.materials = [[], [], [], []];
        this.instance.loadedStyle = null;

        this.#initJSONfile();
    }

    // Private method called to initialize the local JSON object
    #initJSONfile()
    {
        // JSON object containing the stand customization info
        this.instance.info =
        {
            'id': '',
            'position': 0,
            'style': '',

            'hair_color': '',
            'top_color': '',
            'bottom_color': '',
            'shoes_color': ''
        };
    }

    // Method called to update a specific object inside the JSON
    updateObjectByName(key, value)
    {
        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Update content
            this.instance.info[key] = value;

            // Set stand tooltip info
            if(key === 'style')
            {
                if(!this.experience.INTERRUPTED) this.#setStyle(this.instance.info[key]);
            }
            // If the stand model is loaded
            else if(this.instance.model !== undefined && key.includes('color'))
            {
                if(!this.experience.INTERRUPTED) this.#setColor(key, this.instance.info[key]);
            }
        }
    }

    // Method called to update many or all of the objects
    updateObjectsByJSON(jsonObj)
    {
        // Set instance JSON file
        if(jsonObj instanceof Object) this.instance.info = jsonObj;
        else
        {
            // Go through all the JSON objects
            Object.keys(this.instance.info).forEach((key) =>
            {
                // Set json parameter
                this.instance.info[key] = jsonObj[key];
            });
        }

        // Set the NPC
        if(!this.experience.INTERRUPTED) this.#setStyle(this.instance.info['style']);
        if(!this.experience.INTERRUPTED) this.#setColor('hair_color', this.instance.info['hair_color']);
        if(!this.experience.INTERRUPTED) this.#setColor('top_color', this.instance.info['top_color']);
        if(!this.experience.INTERRUPTED) this.#setColor('bottom_color', this.instance.info['bottom_color']);
        if(!this.experience.INTERRUPTED) this.#setColor('shoes_color', this.instance.info['shoes_color']);
    }

    // Method called to get the JSON object from the stand
    getJSONObject()
    {
        // Return JSON object
        return this.instance.info;
    }

    // Method called to set the NPC style
    #setStyle(style)
    {
        // If this instance already has a loaded model
        if(this.instance.model instanceof Group)
        {
            // Dispose the old model
            this.instance.model.visible = false;
            if(!this.experience.INTERRUPTED) this.disposer.disposeElements(this.instance.model);
            this.instance.model = null;

            // Set new style
            if(!this.experience.INTERRUPTED) this.#setStyle(style);
            return;
        }

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // If the NPC has a style
            if(style !== '' && style !== null && style !== undefined)
            {
                // Get model from the resources
                this.gltf = this.resources.items['npc_' + style];
                // Limit animations
                this.gltf.animations = [this.gltf.animations[0], this.gltf.animations[1], this.gltf.animations[2], this.gltf.animations[3], this.gltf.animations[4], this.gltf.animations[5], this.gltf.animations[6]];

                // Clone skinned mesh model
                this.instance.model = SkeletonUtils.clone(this.gltf.scene);
                this.instance.loadedStyle = style;

                // Rotate child objet to face the correct way
                this.instance.model.children[0].rotateZ(-Math.PI * 0.5);

                // Add to the scene
                this.scene.add(this.instance.model);

                // Get custom materials
                this.#getMaterials();
                // Restore values that got lost
                this.#restoreValues();
                // Update materials
                this.#updateMaterials();

                // Set the model position
                this.instance.model.position.set(0.1, 0, -0.05);

                // Update camera position
                this.#setCameraPosition(false);

                // If the experience wasn't interrupted
                if(!this.experience.INTERRUPTED)
                {
                    // Set animations
                    this.#setDefaultAnimations();
                    // Play idle anim
                    this.#startStopAnim(this.animations.idleBasic, true);
                }

                // Trigger event warning that this stand finished loading
                if(!this.experience.INTERRUPTED) this.trigger('loaded3DModel');
            }
        }
    }

    // Method called to set the NPC colors
    #setColor(name, hexcode)
    {
        // Get id
        let id = 0;
        if(name === "top_color") id = 1;
        else if(name === "bottom_color") id = 2;
        else if(name === "shoes_color") id = 3;

        // If the hexcode is empty, set default value
        if(hexcode === "" || hexcode === null || hexcode === undefined)
        {
            hexcode = "0x000000";
        }
        // If the hexcode is on the wrong format
        else if(hexcode.slice(0, 1) === '#')
        {
            // Set to correct format
            hexcode = '0x' + hexcode.slice(1);
        }

        // Save the hexcode
        this.instance.colors[id] = hexcode;

        // For each of the materials
        this.instance.materials[id].forEach(material =>
        {
            // Set the hexcode to the material
            material.color.setHex(hexcode).convertSRGBToLinear();
            material.needsUpdate = true;
        });
    }

    // Private method called to restore possibly lost values
    #restoreValues()
    {
        // If the colors were previously set
        if(this.instance.colors.length > 0)
        {
            // If there is a registered hair color
            if(this.instance.colors[0])
            {
                // Set color
                if(this.instance.colors[0] !== this.instance.info['hair_color']) this.instance.colors[0] = this.instance.info['hair_color'];
                this.#setColor('hair_color', this.instance.colors[0]);
            }
            // If there is a registered shirt color
            if(this.instance.colors[1])
            {
                // Set color
                if(this.instance.colors[1] !== this.instance.info['top_color']) this.instance.colors[1] = this.instance.info['top_color'];
                this.#setColor('top_color', this.instance.colors[1]);
            }
            // If there is a registered pants color
            if(this.instance.colors[2])
            {
                // Set color
                if(this.instance.colors[2] !== this.instance.info['bottom_color']) this.instance.colors[2] = this.instance.info['bottom_color'];
                this.#setColor('bottom_color', this.instance.colors[2]);
            }
            // If there is a registered shoes color
            if(this.instance.colors[3])
            {
                // Set color
                if(this.instance.colors[3] !== this.instance.info['shoes_color']) this.instance.colors[3] = this.instance.info['shoes_color'];
                this.#setColor('shoes_color', this.instance.colors[3]);
            }
        }
        else
        {
            this.#setColor('hair_color');
            this.#setColor('top_color');
            this.#setColor('bottom_color');
            this.#setColor('shoes_color');
        }
    }

    // Private method called to find the customizable materials
    #getMaterials()
    {
        // Go through all the model's children
        this.instance.model.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof Mesh && child.material)
            {
                // If the material is the hair color
                if(child.material.name.includes("hair_color"))
                {
                    // Clone material to make it unique for this NPC
                    child.material = child.material.clone();
                    // Add material to the array
                    this.instance.materials[0].push(child.material);
                }
                // If the material is the shirt color
                else if(child.material.name.includes("top_color"))
                {
                    // Clone material to make it unique for this NPC
                    child.material = child.material.clone();
                    // Add material to the array
                    this.instance.materials[1].push(child.material);
                }
                // If the material is the pants color
                else if(child.material.name.includes("bottom_color"))
                {
                    // Clone material to make it unique for this NPC
                    child.material = child.material.clone();
                    // Add material to the array
                    this.instance.materials[2].push(child.material);
                }
                // If the material is the shoes color
                else if(child.material.name.includes("shoes_color"))
                {
                    // Clone material to make it unique for this NPC
                    child.material = child.material.clone();
                    // Add material to the array
                    this.instance.materials[3].push(child.material);
                }
            }
        });
    }

    // Private method called to set the default animations
    #setDefaultAnimations()
    {
        // Create object
        this.animations = {};
        this.animations.randomize = true;

        // Set animation mixer
        this.animations.mixer = new AnimationMixer(this.instance.model);
        
        // Set idle animation actions
        this.animations.idleBasic = this.animations.mixer.clipAction(this.gltf.animations[0]);
        this.animations.idleWarm = this.animations.mixer.clipAction(this.gltf.animations[1]);
        this.animations.idleThinking = this.animations.mixer.clipAction(this.gltf.animations[2]);

        // Set animation handler
        animHandler = (e) =>
        {
            // Stop the last animation
            this.#startStopAnim(e.action, false);
            // Play idle animation
            this.#startStopAnim(this.animations.idleBasic, true);

            // Allow anim randomizations
            this.animations.randomize = true;
        };
        // Set listener
        this.animations.mixer.addEventListener('loop', animHandler);
    }

    // Private method called to update the model materials
    #updateMaterials()
    {
        // Go through all the model's children
        this.instance.model.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof Mesh && child.material)
            {
                // Set children material encoding
                child.material.encoding = sRGBEncoding;
                if(child.material.map != null) child.material.map.encoding = sRGBEncoding;
                child.material.needsUpdate = true;
            }
        });
    }

    #setCameraPosition(reposition)
    {
        // Calculate the camera distance
        var distance = Math.abs( 2 / Math.sin( this.camera.renderCamera.fov ) );
        this.camera.defaultPosition.x = distance;
        // Set min and max distance
        this.camera.controls.minDistance = distance - 0.7;
        this.camera.controls.maxDistance = distance + 1.3;

        // Set the ideal camera distance
        if(reposition === true) this.camera.dynamicCameraRepositioning();
    }

    // Private method called to randomize whitch animation should play
    #randomizeAnims()
    {
        // If the randomization is allowed
        if( Math.random() < 0.003 )
        {
            // Set to false to avoid extra randomizations
            this.animations.randomize = false;

            this.#startStopAnim(this.animations.idleBasic, false);
            this.#startStopAnim(this.animations.idleWarm, false);
            this.#startStopAnim(this.animations.idleThinking, false);

            // Start randomized animation
            if( Math.random() <= 0.5 )
                this.#startStopAnim(this.animations.idleWarm, true);
            else
                this.#startStopAnim(this.animations.idleThinking, true);
        }
    }

    // Private method called to start or stop the sent animation
    #startStopAnim(clip, bool)
    {
        // If the animation must be started
        if(bool === true)
        {
            // Reset and play animation with fade in effect
            clip.reset()
            .setEffectiveWeight(1)
            .fadeIn(0.5)
            .play();
        }
        // If the animation must be stopped
        else if(bool === false)
        {
            // Fade out animation for a gradual stop
            clip.fadeOut(0.5);
        }
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Update camera position
        this.#setCameraPosition(true);
    }

    // Method propagated by the stand each tick event
    update(delta)
    {
        if(this.experience)
        {
            // If the scene is loaded
            if(this.experience.SCENE_LOADED)
            {
                // If the animations are not undefined
                if(this.animations)
                {
                    // Update the animation mixer
                    if(this.animations.mixer) this.animations.mixer.update(delta);
                    // If the animation randomization is allowed, randomize
                    if(this.animations.randomize === true) this.#randomizeAnims();
                }
            }
        }
    }

    // Method called to destroy the instance
    destroy()
    {
        // If there are animations registered
        if(this.animations)
        {
            // Stop all animation actions
            try { this.animations.mixer.stopAllAction() } catch(e) { console.log(e) };
            try { this.animations.mixer.removeEventListener('loop', animHandler) } catch(e) { console.log(e) };
        }

        // If a model was loaded
        if(this.instance.model)
        {
            // Dispose the model
            this.instance.model.visible = false;
            try { this.disposer.disposeElements(this.instance.model) } catch(e) { console.log(e) };
            this.instance.model = null;
        }

        // Reset instances
        this.instance = {};
        this.animations = {};

        // Remove references
        this.experience = null;
        this.resources = null;
        this.disposer = null;
        this.scene = null;
    }
}