import Experience from './Experience/Experience';



// Create encapsulated object for this experience
if(!window.novvaC1) window.novvaC1 = {};
// Create encapsulated event target
if(!window.novvaC1.eventTarget) window.novvaC1.eventTarget = new EventTarget();

// Experience class instance
let _experience = null;



// Play or resume the introduction video
function playIntroductionVideo()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D video isn't loaded yet";

        // Destroy the experience
        _experience.videosManager.playIntroductionVideo();
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC1.playIntroductionVideo = playIntroductionVideo;



// Pause the introduction video
function pauseIntroductionVideo()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D video isn't loaded yet";

        // Destroy the experience
        _experience.videosManager.pauseIntroductionVideo();
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC1.pauseIntroductionVideo = pauseIntroductionVideo;



// Mute or unmute the audio
function muteOrUnmuteVideo(bool)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D video isn't loaded yet";

        // Destroy the experience
        _experience.videosManager.muteOrUnmuteVideo(bool);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC1.muteOrUnmuteVideo = muteOrUnmuteVideo;



// Mute or unmute the audio
function setAudioVolume(value)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";

        // Destroy the experience
        _experience.videosManager.setAudioVolume(value);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC1.setAudioVolume = setAudioVolume;



// Completely wipe the experience
function wipeTheExperience()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";

        // Destroy the experience
        _experience.wipe();
        _experience = null;
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC1.wipeTheExperience = wipeTheExperience;



// Start the experience
function startTheExperience(parameters)
{
    try
    {
        // If the experience already started
        if(_experience !== null) throw "The Experience is already running";
        else
        {
            // Create the experience
            _experience = new Experience(parameters);
        }
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC1.startTheExperience = startTheExperience;



// Function called when the script is loaded
function loadedScript()
{
    // If there is an async init function
    if(window.novvaC1AsyncInit)
    {
        // Call function to init the experience
        window.novvaC1AsyncInit();
    }
    // If there isn't an async init function, log error
    else
    {
        console.log('The "novvaC1AsyncInit" function was not found.');
    }
}
loadedScript();