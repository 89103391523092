import Experience from "../Experience";
import EventEmitter from "../Utils/EventEmitter";

export default class Minimap extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();

        // Set variables
        this.instance = {};
        // Set array of sections
        this.instance.sections = [];
        this.instance.container = null;

        // Current section id variable
        this.currentSectionId = null;
    }

    // Method called to create and set up the minimap
    setMinimap(sections, startingSection)
    {
        // Get number of sections needed
        this.instance.numOfSections = sections.length;
        // Set player position in the minimap
        this.currentSectionId = startingSection;

        // Get container
        this.instance.container = document.getElementById('minimap-container');

        // Only create minimap if there is more than one section
        if(this.instance.numOfSections > 1)
        {
            // Show minimap
            this.instance.container.style.display = 'grid';
            // Create grid container
            if(!this.experience.INTERRUPTED) this.#getContainer();
            // Set rows and columns
            if(!this.experience.INTERRUPTED) this.#setRowsAndColumns();
            // Set default section
            if(!this.experience.INTERRUPTED) this.#setDefaultSection(sections[0]);
            // Set other sections
            if(!this.experience.INTERRUPTED) this.#setOtherSections(sections);
            // Set the player arrow
            if(!this.experience.INTERRUPTED) this.#setArrow();

            // Set the current section style
            if(!this.experience.INTERRUPTED) this.instance.sections[startingSection].classList.add('minimapSection__current');
        }
        else this.instance.container.style.display = 'none';
    }

    // Private method called to create and set up the container for the minimap grid
    #getContainer()
    {
        // Get container
        this.instance.container = document.getElementById('minimap-container');
        this.instance.container.style.display = '';
    }

    // Private method called to set the grid's rows and columns
    #setRowsAndColumns()
    {
        // Get the number of rows and columns needed
        this.instance.rows = 1;
        this.instance.columns = 1;

        // Get number of sections
        const sections = this.instance.numOfSections;

        // Set number of rows
        if(sections <= 3) this.instance.rows = 2;
        else this.instance.rows = 3;

        // Set number of columns
        if(sections == 2) this.instance.columns = 2;
        else this.instance.columns = 3;

        // Set base template
        this.instance.container.style.gridTemplateRows = '30px';
        this.instance.container.style.gridTemplateColumns = '30px';

        // For each of the rows
        for(let h = 1; h < this.instance.rows; h++)
        {
            // Add a row to the container
            if(!this.experience.INTERRUPTED) this.instance.container.style.gridTemplateRows += ' 30px';
        }
        // For each of the columns
        for(let w = 1; w < this.instance.columns; w++)
        {
            // Add a column to the container
            if(!this.experience.INTERRUPTED) this.instance.container.style.gridTemplateColumns += ' 30px';
        }
    }

    // Private method called to set the default minimap section, where the user always start
    #setDefaultSection(section)
    {
        // Get default position
        this.instance.defaultPos = [1, 2];

        // Get the default column coordinate at the center of the minimap
        if(this.instance.columns <= 3) this.instance.defaultPos[0] = 2;
        else this.instance.defaultPos[0] = 3;

        // Create the default section in the minimap
        const mainSection = document.createElement('div');
        const paragraph = document.createElement('p');
        paragraph.innerHTML = section.dome_name;
        mainSection.appendChild(paragraph);

        // Set row and column coordinates
        mainSection.style.gridColumn = this.instance.defaultPos[0];
        mainSection.style.gridRow = this.instance.defaultPos[1];

        // Set id and class
        mainSection.setAttribute('id', 'dome_0');
        mainSection.setAttribute('class', 'minimapSection');

        // Listen for clicks on the section
        mainSection.onclick = (e) =>
        {
            // If the experiente wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Get the dome class if needed
                if(!this.dome) this.dome = this.experience.dome;

                if(e.target.id.split('_')[1] != this.currentSectionId && this.dome.switching === false)
                {
                    // Set player position in the minimap
                    this.#setPlayerPosition(e);
                    // Trigger switch section event
                    this.trigger('switchDome');
                }
            }
        };

        // Add to sections array
        this.instance.sections[0] = mainSection;
        // Add to grid container
        if(!this.experience.INTERRUPTED) this.instance.container.appendChild(mainSection);
    }

    // Private method called to set the other minimap sections
    #setOtherSections(sections)
    {
        // Get all positions
        let positions =
        [
            // 0 [Default]
            [0, 0],

            // 1 to 4
            [-1, -1], // Column, Row
            [1, -1],
            [-1, 1],
            [1, 1],

            // 5 and 6
            [-1, 0], // Column, Row
            [1, 0]
        ]

        // For each of the sections
        for(let i = 1; i < this.instance.numOfSections; i++)
        {
            // Get row and column coordinates
            const column = this.instance.defaultPos[0] + positions[i][0];
            const row = this.instance.defaultPos[1] + positions[i][1];

            // Create a new section in the minimap
            const section = this.instance.sections[0].cloneNode(true);
            section.firstChild.innerHTML = sections[i].dome_name;

            // Set id and class
            section.setAttribute('id', 'dome_' + i);
            
            // Set row and column coordinates
            section.style.gridColumn = column;
            section.style.gridRow = row;

            // Listen for clicks on the section
            section.onclick = (e) =>
            {
                // If the experiente wasn't interrupted
                if(!this.experience.INTERRUPTED)
                {
                    // Get the dome class if needed
                    if(!this.dome) this.dome = this.experience.dome;

                    if(e.target.id.split('_')[1] != this.currentSectionId && this.dome.switching === false)
                    {
                        // Set player position in the minimap
                        this.#setPlayerPosition(e);
                        // Trigger switch section event
                        this.trigger('switchDome');
                    }
                }
            };

            // Add to sections array
            this.instance.sections[i] = section;
            // Add to grid container
            if(!this.experience.INTERRUPTED) this.instance.container.appendChild(section);

            // Set margin to fill the open slot in the center of the row
            if(i === 1 || i === 3)
            {
                section.style.marginLeft = '15px';
                section.style.marginRight = '-15px';
            }
            else if(i === 2 || i === 4)
            {
                section.style.marginLeft = '-15px';
                section.style.marginRight = '15px';
            }
        }
    }

    // Private method called to create and set up the minimap arrow
    #setArrow()
    {
        // Create arrow image
        this.arrow = document.createElement('img');
        this.arrow.setAttribute('id', 'arrow');
        this.arrow.src = this.experience.parameters.ASSETS_PATH + 'images/icons/arrow.png';

        // If the experiente wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Append arrow to the default section
            this.instance.sections[this.currentSectionId].appendChild(this.arrow);
            this.instance.sections[this.currentSectionId].firstChild.style.display = 'none';
        }
    }

    // Private method called to set the player position in the minimap
    #setPlayerPosition(e)
    {
        // Remove the current style from the section id
        this.instance.sections[this.currentSectionId].classList.remove('minimapSection__current');
        this.instance.sections[this.currentSectionId].firstChild.style.display = '';

        // If the target is the minimap section
        if(e.target.id.includes('dome_'))
        {
            // Save as the current position
            this.currentSectionId = parseInt(e.target.id.split('_')[1]);
        }
        // If the target is the paragraph inside the minimap section
        else
        {
            // Get the paragraph inner html
            const name = e.target.innerHTML;

            // For each of the minimap sections
            for(let i = 0; i < this.instance.sections.length; i++)
            {
                // If this section's paragraph contains the same name
                if(this.instance.sections[i].firstChild.innerHTML == name)
                {
                    // Save as the current position
                    this.currentSectionId = parseInt(this.instance.sections[i].id.split('_')[1]);
                    break;
                }
            }
        }

        // Add the current style to the new section id
        this.instance.sections[this.currentSectionId].classList.add('minimapSection__current');

        // Remove arrow from the last section
        this.arrow.remove();
        // Add arrow to the current section
        this.instance.sections[this.currentSectionId].appendChild(this.arrow);
        this.instance.sections[this.currentSectionId].firstChild.style.display = 'none';
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Remove all the sections from the document
        this.instance.sections.forEach(section =>
        {
            try
            {
                // Remove click listener
                section.removeAttribute("onclick");
                section.remove();
            }
            catch(e) { console.log(e) };
        });

        try
        {
            // If the container was found
            if(this.instance.container !== null && this.instance.container !== undefined)
            {
                // Disable container
                this.instance.container.style.display = 'none';
                this.instance.container.style.gridTemplateRows = '0px';
                this.instance.container.style.gridTemplateColumns = '0px';
            }
        }
        catch(e) { console.log(e) };

        // Reset variables
        this.instance = null;
        this.currentSectionId = null;
    }
}