import { Vector3 } from "three";
import { Pathfinding } from 'three-pathfinding';

import Experience from "../Experience"
import EventEmitter from "../Utils/EventEmitter";

export default class Navmesh extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed class from the experience
        this.pavilion = this.experience.pavilion;

        // Create instance
        this.instance = {};
        // Create pathfinder
        this.instance.pathfinder = new Pathfinding();

        // Listen to when the pavilion is loaded
        this.pavilion.on('pavilionLoaded', () =>
        {
            // Set navmesh
            this.setNavmesh();
        });
    }

    // Method called to set the navmesh
    setNavmesh()
    {
        // Create navmesh from the pavilion child
        this.instance.navmesh = this.pavilion.instance.model.getObjectByName('navmesh', true);
        this.instance.navmesh.position.y = 0;
        this.instance.navmesh.material.visible = false;

        // Set zone name
        this.instance.zoneName = "pavilion_" + this.pavilion.instance.id;
        // Create a new pathfinding zone
        this.instance.zone = Pathfinding.createZone(this.instance.navmesh.geometry);
        this.instance.pathfinder.setZoneData(this.instance.zoneName, this.instance.zone);

        // Set the player's navigation mesh group
        this.instance.group = this.instance.pathfinder.getGroup(this.instance.zoneName, new Vector3(0, 0, -10));
    }

    // Method called externally to try to create a path towards the NPC target
    tryPath(position, target)
    {
        // Calculate a path to the target and store it
        const path = this.instance.pathfinder.findPath(position, target, this.instance.zoneName, this.instance.group);

        // If there isn't a path to be run
        if(!path)
        {
            // Set path steps
            const closestPlayerNode = this.instance.pathfinder.getClosestNode(position, this.instance.zoneName, this.instance.group);
            const clamped = new Vector3();

            this.instance.pathfinder.clampStep(position, target, closestPlayerNode, this.instance.zoneName, this.instance.group, clamped);
        }
        // If there is a path to be run
        else if(path && path.length)
        {
            return path;
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Get the disposer class from the experience
        this.disposer = this.experience.disposer;

        // Stop listening for the loaded pavilion event
        try { this.pavilion.off('pavilionLoaded') } catch(e) { console.log(e) };

        // If the navmesh still exists
        if(this.instance.navmesh)
        {
            // Dispose the navmesh
            try { this.disposer.disposeElements(this.instance.navmesh) } catch(e) { console.log(e) };
        }

        // Reset the instance
        this.instance = null;

        // Remove references
        this.experience = null;
        this.pavilion = null;
        this.disposer = null;
    }
}