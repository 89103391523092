import { AnimationMixer, Raycaster, Vector3, Mesh, sRGBEncoding } from "three";
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';
import { SteeringEntity } from '../Utils/ThreeSteer.js';

import Experience from "../Experience";

export default class NPC
{
    // Set constructor
    constructor()
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.audio = this.experience.audio;
        this.colorize = this.experience.colorize;
        this.navmesh = this.experience.navmesh;
        this.player = this.experience.player;
    }

    // Method called to create the NPC
    setNPC(gender)
    {
        // Create new instance
        this.instance = {};
        this.instance.animations = {};

        // Get the resources class from the experience
        this.resources = this.experience.resources;

        let gotStyle = false;
        // While a valid style haven't been found
        while(gotStyle === false)
        {
            // Get a random style
            let style = Math.floor(Math.random() * (8 - 0 + 1) + 0);

            // If the style is correct accordingly to the determined gender
            if((gender === 0 && [3, 4, 5, 6, 7].includes(style)) || (gender === 1 && [0, 1, 2, 8].includes(style)))
            {
                // Save style
                this.instance.style = style;

                // Set to true to end the loop
                gotStyle = true;
            }
        }

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Get model from the resources
            let gltf = this.resources.items['npc_' + this.instance.style];
            // Limit animations
            gltf.animations = [gltf.animations[0], gltf.animations[1], gltf.animations[2], gltf.animations[3], gltf.animations[4], gltf.animations[5], gltf.animations[6], gltf.animations[7]];
            // Remove reference
            this.resources = null;

            // Clone skinned mesh model
            this.instance.model = SkeletonUtils.clone(gltf.scene);
            // Set animation mixer
            this.instance.animations.mixer = new AnimationMixer(this.instance.model);

            // Set NPC initial position
            this.#setPosition();

            // Set idle animation action
            this.instance.animations.idleAnim = this.instance.animations.mixer.clipAction(gltf.animations[0]);
            this.instance.animations.idleAnim.play();
            // Set walk animation action
            this.instance.animations.walkAnim = this.instance.animations.mixer.clipAction(gltf.animations[7]);

            // Update the model materials
            this.#updateMaterials();

            // Remove reference
            gltf = null;
        }
    }

    // Method called to set up the NPC dinamism
    setStatic(isStatic)
    {
        // Set if the NPC is static or dynamic
        this.instance.static = isStatic;

        // If the NPC is dynamic
        if(this.instance.static === false)
        {
            // Create a steering entity
            if(!this.experience.INTERRUPTED) this.#setSteeringEntity();
        }
    }

    // Private method called to set the NPC position in the navmesh
    #setPosition()
    {
        // Create the necessary variables
        let gotPosition = false;
        let randomX, randomY;
        let position;

        // Create raycast
        const raycast = new Raycaster();
        let intersects;

        // While a valid position haven't been found
        while(gotPosition === false)
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Set random X position
                randomX = Math.random() * 40 + 5;
                if( Math.random() < 0.5 ) randomX *= -1;

                // Set random Y position
                randomY = Math.random() * 50 + 5;
                if( Math.random() < 0.5 ) randomY *= -1;
                
                // Set new position to where the NPC will try to be
                position = new Vector3(randomX, 2, randomY);

                // Set raycaster
                raycast.set(position, new Vector3(0, -1, 0));
                // Verify for intersections with the navmesh
                intersects = raycast.intersectObject(this.navmesh.instance.navmesh);

                // If there is an intersection with the navmesh
                if(intersects.length > 0)
                {
                    const point = intersects[0].point;
                    // Set model position and rotation
                    this.instance.model.position.set(point.x, 0, point.z);
                    this.instance.model.rotation.y = Math.PI * (Math.random());

                    // Get the scene from the experience
                    this.scene = this.experience.scene;
                    // Add the NPC to the scene
                    if(!this.experience.INTERRUPTED) this.scene.add(this.instance.model);
                    // Remove reference
                    this.scene = null;

                    // Set to true to end the loop
                    gotPosition = true;
                }
            }
            else break;
        }
    }

    // Private method called to create a steering entity AI instance
    #setSteeringEntity()
    {
        // Create steering entity
        this.instance.entity = new SteeringEntity(this.instance.model);
        this.instance.entity.loop = false;
        this.instance.entity.thresholdRadius = 0.1;
        this.instance.entity.lookAtDirection = true;

        // Set path
        this.instance.path = null;
        this.instance.wait = true;

        // Set target position
        this.instance.target = new Vector3();

        // Set footsteps manager
        this.footstepsManager = {
            audios: [],
            volume: 0.1,
            radius: 8,
            playing: false
        };
        // Set a local set of footstep audios
        if(!this.experience.INTERRUPTED) this.audio.createSetOfFootsteps(this.footstepsManager.audios);
    }

    // Private method called to manage the dynamic NPCs animations
    #manageAnims()
    {
        // If the steering entity is in the idle state
        if(this.instance.entity.state === "idle")
        {
            // If the idle animation isn't playing
            if(this.instance.animations.idleAnim.isRunning() === false)
            {
                // Fade out walk animation for a gradual stop
                this.instance.animations.walkAnim.fadeOut(0.2);
                // Reset idle animation and fade in for a gradual start
                this.instance.animations.idleAnim.reset()
                    .setEffectiveTimeScale(1)
                    .setEffectiveWeight(1)
                    .fadeIn(0.2)
                    .play();
            }
        }
        // If the steering entity is in the walking state
        else
        {
            // If the walk animation isn't playing
            if(this.instance.animations.walkAnim.isRunning() === false)
            {
                // Fade out idle animation for a gradual stop
                this.instance.animations.idleAnim.fadeOut(0.5);
                // Reset walk animation and fade in for a gradual start
                this.instance.animations.walkAnim.reset()
                    .setEffectiveTimeScale(0.8)
                    .setEffectiveWeight(1)
                    .fadeIn(0.5)
                    .play();
            }
        }
    }
    
    // Private method called to update the pathfinding
    #updatePathfinding(delta)
    {
        // If there is no path to follow
        if(!this.instance.path)
        {
            // Set entity to idle
            this.instance.entity.idle();

            // If the entity must wait a little before trying to find a path
            if(this.instance.wait === true)
            {
                // Wait for the ideal conditions to stop waiting
                if(Math.random() < 0.01) this.instance.wait = false;
            }
            // If the entity is done waiting
            else
            {
                // Set random X position
                let randomX = Math.random() * 40 + 5;
                if( Math.random() < 0.5 ) randomX *= -1;
                // Set random Y position
                let randomY = Math.random() * 50 + 5;
                if( Math.random() < 0.5 ) randomY *= -1;
                
                // Set a new target to where the entity will try to move
                const newPosition = new Vector3(randomX , 0, randomY);
                this.instance.target.copy(newPosition);

                // Try to create a path
                this.instance.path = this.navmesh.tryPath(this.instance.model.position, this.instance.target);
            }
        }
        // If there is a path to follow
        else
        {
            // Set entity speed
            this.instance.entity.maxSpeed = delta;

            // Set entity to follow the path
            this.instance.entity.followPath(this.instance.path, this.instance.entity.loop, this.instance.entity.thresholdRadius)
            this.instance.entity.lookWhereGoing(true);
            
            // Get rounded time of the animation
            const roundedTime = parseFloat(this.instance.animations.walkAnim.time.toFixed(2));
            // When one of the model's feet is touching the ground
            if((roundedTime >= 0.45 && roundedTime <= 0.55) || (roundedTime >= 0.95 && roundedTime <= 1.05))
            {
                // Play footstep audio
                this.#playFootstepsAudio();
            }

            // If the entity arrived, delete the late path
            if(this.instance.entity.state === "idle")
                this.instance.path = null;

            // Set a new waiting cycle
            this.instance.wait = true;
        }

        // Manage animations
        this.#manageAnims();
    }

    // Private method called to set the footstep playing time
    #playFootstepsAudio()
    {
        // If the footstep timer isn't counting
        if(this.footstepsManager.playing === false)
        {
            // Start footstep counting
            this.footstepsManager.playing = true;

            // Get distance to the player
            let distance = this.instance.model.position.distanceTo(this.player.instance.collider.end);
            let relativeVolume = 0;

            // If the player is inside the audio radius
            if(distance <= this.footstepsManager.radius)
            {
                // Set volume based on the distance to the player
                relativeVolume = this.footstepsManager.volume * (1 - distance / this.footstepsManager.radius);

                // Call audio manager to play the footstep audio
                this.audio.playFootstep(this.footstepsManager.audios, relativeVolume);
            }

            // Set time out
            setTimeout(() =>
            {
                // Signal the footstep count ending
                if(this.footstepsManager) this.footstepsManager.playing = false;
            }, 400);
        }
    }

    // Private method called to update the model materials
    #updateMaterials()
    {
        // Get four random colors
        const colors = this.colorize.randomize();

        // Go through all the model's children
        this.instance.model.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof Mesh && child.material)
            {
                // If the material is the hair color
                if(child.material.name.includes("hair_color"))
                {
                    // Clone material to make it unique for this NPC
                    child.material = child.material.clone();
                    // Set the hexcode to the material
                    child.material.color.setHex(colors[0]).convertSRGBToLinear();
                }
                // If the material is the shirt color
                else if(child.material.name.includes("top_color"))
                {
                    // Clone material to make it unique for this NPC
                    child.material = child.material.clone();
                    // Set the hexcode to the material
                    child.material.color.setHex(colors[1]).convertSRGBToLinear();
                }
                // If the material is the pants color
                else if(child.material.name.includes("bottom_color"))
                {
                    // Clone material to make it unique for this NPC
                    child.material = child.material.clone();
                    // Set the default hexcode to the material
                    if(this.instance.style === 6) child.material.color.setHex(0x89b2cb).convertSRGBToLinear();
                    else child.material.color.setHex(colors[2]).convertSRGBToLinear();
                }
                // If the material is the shoes color
                else if(child.material.name.includes("shoes_color"))
                {
                    // Clone material to make it unique for this NPC
                    child.material = child.material.clone();
                    // Set the hexcode to the material
                    child.material.color.setHex(colors[3]).convertSRGBToLinear();
                }

                // Set children material encoding
                child.material.encoding = sRGBEncoding;
                if(child.material.map != null) child.material.map.encoding = sRGBEncoding;
                child.material.needsUpdate = true;
            }
        });
    }

    // Method propagated by the experience each tick event
    update(delta)
    {
        // If the scene is loaded
        if(this.experience.SCENE_LOADED)
        {
            // If the NPC is positioned in the navmesh
            if(this.instance.position !== null || this.instance.position !== undefined)
            {
                // If the NPC is dynamic
                if(this.instance.static === false)
                {
                    // Call method to update the entity pathfinding
                    this.#updatePathfinding(delta);
                    this.instance.entity.update();
                }

                // Update the animation mixer
                this.instance.animations.mixer.update(delta);
            }
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // If there are animations registered
        if(this.animations)
        {
            // Stop all animation actions
            try { this.instance.animations.mixer.stopAllAction() } catch(e) { console.log(e) };
        }

        // Reset instances
        this.instance.path = null;
        this.instance.entity = null;

        // Get the disposer class from the experience
        this.disposer = this.experience.disposer;
        // Dispose the model
        try { this.disposer.disposeElements(this.instance.model) } catch(e) { console.log(e) };

        // Reset instances
        this.instance = null;
        this.footstepsManager = null;

        // Remove references
        this.experience = null;
        this.scene = null;
        this.resources = null;
        this.disposer = null;
        this.audio = null;
        this.navmesh = null;
        this.player = null;
    }
}