import { Mesh, PlaneGeometry, MeshStandardMaterial, sRGBEncoding } from "three";

import Experience from "../Experience";
import EventEmitter from "../Utils/EventEmitter";

export default class Backgrounds extends EventEmitter
{
    // Set constructor
    constructor(BACKGROUND_OPTION)
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.environment = this.experience.environment;

        // 0 = 3D environment; 1 = blue environment; 2 = transparent background
        this.BACKGROUND_OPTION = BACKGROUND_OPTION;

        // Listen to when the resources are loaded
        this.resources.on('loadedResources', () =>
        {
            // Set lights
            if(!this.experience.INTERRUPTED) this.environment.setHemisphereLight();
            if(!this.experience.INTERRUPTED) this.environment.setDirectionalLight();
            if(this.experience.parameters.QUALITY > 0)
            {
                if(!this.experience.INTERRUPTED) this.environment.setEnvironmentMap();
            }

            // Set the background
            if(!this.experience.INTERRUPTED) this.setBackground();

            // Trigger event warning that the basic elements finished loading
            if(!this.experience.INTERRUPTED) this.trigger('loaded3DScene');
        });
    }

    // Method called to set the scene background
    setBackground()
    {
        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // 3D background
            if(this.BACKGROUND_OPTION == 0)
            {
                this.#set3DBackground();
            }
            // Cubemap texture background
            else if(this.BACKGROUND_OPTION == 1)
            {
                this.environment.setEnvironmentBackground();
                this.#setGroundPlane();
            }
            // No background
            else if(this.BACKGROUND_OPTION == 2)
            {
                this.scene.background = null;
            }
        }
    }

    // Private method called to set up the scene ground
    #setGroundPlane()
    {
        // Set the ground plane
        this.groundPlane = new Mesh(new PlaneGeometry(100, 100), new MeshStandardMaterial({ opacity: 0.3, transparent: true }));
        this.groundPlane.name = "ground_plane";
        // Set material encoding
        this.groundPlane.material.color.setHex(0x000000).convertSRGBToLinear();
        this.groundPlane.material.encoding = sRGBEncoding;
        this.groundPlane.material.needsUpdate = true;
        // Set rotation and position
        this.groundPlane.rotation.x = -Math.PI * 0.5;
        this.groundPlane.position.y = 0.01;
        // Receive shadows from other objects
        this.groundPlane.receiveShadow = true;

        // Add to scene and background items array
        if(!this.experience.INTERRUPTED) this.scene.add(this.groundPlane);
    }

    // Private method called to set the 3D background
    #set3DBackground()
    {
        // Get background model
        this.pavilionBackground = this.resources.items.pavilion_background.scene;

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Update materials
            this.#updateMaterials();
            // Add to scene
            this.scene.add(this.pavilionBackground);
        }
    }

    // Method called to update the environment map of all elements
    updateMaterialsEnvMap()
    {
        if(this.BACKGROUND_OPTION == 0)
        {
            // Go through all the model's children
            this.pavilionBackground.traverse((child) =>
            {
                // If child is a mesh object and their material is a standard material
                if(child instanceof Mesh && child.material instanceof MeshStandardMaterial)
                {
                    // Set environment map intensity
                    child.material.envMapIntensity = this.environment.envMapIntensity;
                }
            });
        }
    }

    // Private method called to update the model materials
    #updateMaterials()
    {
        // Go through all the model's children
        this.pavilionBackground.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof Mesh && child.material instanceof MeshStandardMaterial)
            {
                // Deactivate the navmesh object
                if(child.name === 'navmesh')
                {
                    child.visible = false;
                    return;
                }

                // Receive shadows
                child.receiveShadow = true;
 
                // Set children material encoding
                child.material.encoding = sRGBEncoding;
                if(child.material.map != null) child.material.map.encoding = sRGBEncoding;
                child.material.needsUpdate = true;

                // If there is an environment map texture
                if(this.environment.envMapTexture)
                {
                    // Set environment map intensity
                    child.material.envMapIntensity = this.environment.envMapIntensity;
                }
            }
        });
    }

    // Method propagated by the experience to destroy this instance
    destroy()
    {
        // Stop listening for the loaded event
        try { this.resources.off('loadedResources') } catch(e) { console.log(e) };

        // Get the disposer class from the experience
        this.disposer = this.experience.disposer;

        if(this.groundPlane)
        {
            try
            {
                this.disposer.disposeElements(this.groundPlane);
                this.groundPlane = null;
            }
            catch(e) { console.log(e) };
        }
        if(this.pavilionBackground)
        {
            try
            {
                this.disposer.disposeElements(this.pavilionBackground);
                this.pavilionBackground = null;
            }
            catch(e) { console.log(e) };
        }

        // Remove references
        this.experience = null;
        this.scene = null;
        this.resources = null;
        this.environment = null;
    }
}