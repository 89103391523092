import { PerspectiveCamera, Vector2, Vector3 } from "three";
import gsap from 'gsap';

import Experience from "./Experience.js";
import EventEmitter from './Utils/EventEmitter.js';

let isMobile;

export default class Camera extends EventEmitter
{
    // Set constructor
    constructor(BACKGROUND_OPTION)
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;

        // Get the background option
        this.BACKGROUND_OPTION = BACKGROUND_OPTION;

        // Set camera instances
        this.setInstances();
    }

    // Method called to create and set up the cameras
    setInstances()
    {
        // Get the mobile detector class from the experience
        this.mobileDetector = this.experience.mobileDetector;
        // Get if the device is a mobile
        isMobile = this.mobileDetector.isMobile;
        // Remove reference
        this.mobileDetector = null;

        // Create third person perspective camera
        this.renderCamera = new PerspectiveCamera(48, this.sizes.width / this.sizes.height, 0.1, 2000);

        // If the background option chosen is the moon landscape
        if(this.BACKGROUND_OPTION === 0)
        {
            this.maxHeight = 650;
            this.renderCamera.position.set(340, this.maxHeight, 0);
            this.renderCamera.lookAt(new Vector3(10, 0, 0));
            // Set default position
            this.defaultPosition = new Vector3(340, this.maxHeight, 0);
        }
        // If the background option chosen is the earth landscape
        else
        {
            this.maxHeight = 500;
            this.renderCamera.position.set(300, this.maxHeight, 0);
            this.renderCamera.lookAt(new Vector3(40, 0, 0));
            // Set default position
            this.defaultPosition = new Vector3(300, this.maxHeight, 0);
        }

        // Get camera center
        this.cameraCenter = new Vector2(this.renderCamera.position.x, this.renderCamera.position.z);

        // Add cameras to scene
        if(!this.experience.INTERRUPTED) this.scene.add(this.renderCamera);

        // Remove reference
        this.scene = null;
    }

    // Method called to animate the dome transition
    switchAnimation(targetPos)
    {
        // Get the dome class from the experience
        this.dome = this.experience.dome;

        // Set the animation parameters
        this.params =
        {
            camX: this.renderCamera.position.x,
            camY: this.renderCamera.position.y,
            camZ: this.renderCamera.position.z,
            camRot: this.renderCamera.rotation.y,
            planeY: 0
        };
        // If the background option chosen is the moon landscape, also get the clipping plane position
        if(this.BACKGROUND_OPTION === 0) this.params.planeY = this.dome.instance.clippingPlane.constant;

        // Tween camera position to the target position
        gsap.to(this.params, { camX: this.defaultPosition.x + targetPos.x, camY: this.maxHeight + 200, camZ: targetPos.z, camRot: 0.5, planeY: 250, duration: 0.6, ease: "expo.in",
        onUpdate: () =>
        {
            // Update camera position
            this.renderCamera.position.set(this.params.camX, this.params.camY, this.params.camZ);
            this.renderCamera.rotation.y = this.params.camRot;
            // If the background option chosen is the moon landscape, update the clipping plane position
            if(this.BACKGROUND_OPTION === 0) this.dome.instance.clippingPlane.constant = this.params.planeY;
        },
        onComplete: () =>
        {
            // Invert the camera position to pretend that the camera transitioned to a new dome
            this.renderCamera.position.x = this.defaultPosition.x - targetPos.x;
            this.renderCamera.position.z = -targetPos.z;

            // Update the animation parameters
            this.params =
            {
                camX: this.renderCamera.position.x,
                camY: this.renderCamera.position.y,
                camZ: this.renderCamera.position.z,
                camRot: this.renderCamera.rotation.y
            };

            // If the background option chosen is the moon landscape
            if(this.BACKGROUND_OPTION === 0)
            {
                // Get the clipping plane position
                this.params.planeY = this.dome.instance.clippingPlane.constant;

                // Tween camera to center the dome
                gsap.to(this.params, { camX: this.defaultPosition.x, camY: this.maxHeight, camZ: 0, camRot: 0.47, planeY: 170, duration: 0.6, ease: "expo.out",
                onUpdate: () =>
                {
                    // Update camera position
                    this.renderCamera.position.set(this.params.camX, this.params.camY, this.params.camZ);
                    this.renderCamera.rotation.y = this.params.camRot;
                    // Update the clipping plane position
                    this.dome.instance.clippingPlane.constant = this.params.planeY;
                }});
            }
            else
            {
                // Tween camera to center the dome
                gsap.to(this.params, { camX: this.defaultPosition.x, camY: this.maxHeight, camZ: 0, camRot: 0.48, duration: 0.6, ease: "expo.out",
                onUpdate: () =>
                {
                    // Update camera position
                    this.renderCamera.position.set(this.params.camX, this.params.camY, this.params.camZ);
                    this.renderCamera.rotation.y = this.params.camRot;
                }});
            }
        }});
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Update first person camera aspect
        this.renderCamera.aspect = this.sizes.width / this.sizes.height;
        this.renderCamera.updateProjectionMatrix();
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Stop all tweens from the parameters object
        try { gsap.killTweensOf(this.params) } catch(e) { console.log(e) };

        // Reset variables
        this.renderCamera = null;
        this.defaultPosition = null;
        this.cameraCenter = null;
        this.firstPerson = null;
        this.params = null;

        // Remove references
        this.sizes = null;
        this.dome = null;
        this.experience = null;
    }
}