export default [
    {
        name: 'npc_0',
        type: 'gltfModel',
        path: 'models/npcs/npc_0/npc_0.gltf'
    },
    {
        name: 'npc_1',
        type: 'gltfModel',
        path: 'models/npcs/npc_1/npc_1.gltf'
    },
    {
        name: 'npc_2',
        type: 'gltfModel',
        path: 'models/npcs/npc_2/npc_2.gltf'
    },
    {
        name: 'npc_3',
        type: 'gltfModel',
        path: 'models/npcs/npc_3/npc_3.gltf'
    },
    {
        name: 'npc_4',
        type: 'gltfModel',
        path: 'models/npcs/npc_4/npc_4.gltf'
    },
    {
        name: 'npc_5',
        type: 'gltfModel',
        path: 'models/npcs/npc_5/npc_5.gltf'
    },
    {
        name: 'npc_6',
        type: 'gltfModel',
        path: 'models/npcs/npc_6/npc_6.gltf'
    },
    {
        name: 'npc_7',
        type: 'gltfModel',
        path: 'models/npcs/npc_7/npc_7.gltf'
    },
    {
        name: 'npc_8',
        type: 'gltfModel',
        path: 'models/npcs/npc_8/npc_8.gltf'
    }
]