import { PerspectiveCamera, Vector3 } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import gsap from 'gsap';

import Experience from "./Experience.js";
import EventEmitter from './Utils/EventEmitter.js';

let controlsChangeHandler;

export default class Camera extends EventEmitter
{
    // Set constructor
    constructor(QUALITY, BACKGROUND_OPTION, FIRST_PERSON_CAM)
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.canvas = this.experience.canvas;

        // Set parameters
        this.QUALITY = QUALITY;
        this.BACKGROUND_OPTION = BACKGROUND_OPTION;
        this.FIRST_PERSON_CAM = FIRST_PERSON_CAM;

        // Set camera instances
        this.setInstances();
    }

    // Method called to create and set up the cameras
    setInstances()
    {
        // Create perspective camera
        this.renderCamera = new PerspectiveCamera(40, this.sizes.width / this.sizes.height, 0.1, 2000);
        this.defaultPosition = new Vector3(0, 0, 0);
        this.takingScreenshot = false;

        // If the camera angle must be from a first person perspective
        if(this.FIRST_PERSON_CAM === true)
        {
            // Set low position
            this.renderCamera.position.set(45, 1, 10);
        }
        // If the camera angle must be from a third person perspective
        else if(this.FIRST_PERSON_CAM === false)
        {
            // Set high position
            this.renderCamera.position.set(28, 16, 10);
        }
    
        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Add to scene
            this.scene.add(this.renderCamera);
            this.#setOrbitControls();
        }
    }

    // Private method called to set up the orbit controls
    #setOrbitControls()
    {
        // Create orbit controls
        this.controls = new OrbitControls(this.renderCamera, this.canvas);
        // Set motion dampening for a smooth effect
        this.controls.enablePan = false;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.3;
        this.controls.rotateSpeed = 0.28;
        this.controls.zoomSpeed = 0.5;

        // Set min and max distance
        this.controls.minDistance = 7;
        if(this.sizes.width > this.sizes.height) this.controls.maxDistance = 25;
        else this.controls.maxDistance = 50;

        // Set min and max vertical angles
        this.controls.minPolarAngle = Math.PI * 0.25;
        this.controls.maxPolarAngle = Math.PI * 0.5;

        // Set the control's target
        this.controls.target.set(0, 2, 0);

        // Orbit controls changes event handler
        controlsChangeHandler = () =>
        {
            // Trigger the controls change event
            if(!this.experience.INTERRUPTED) this.trigger('controlsChanged');
        };

        // Request render when the OrbitControls change
        if(!this.experience.INTERRUPTED) this.controls.addEventListener('change', controlsChangeHandler);
    }

    // Method called externally to run the camera dynamic start animation
    dynamicCameraStart()
    {
        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Get the stand class from the experience
            this.stand = this.experience.stand;
        
            // Get totems info
            let activeTotems = [];
            activeTotems[0] = [this.stand.instance.info.totem_about, this.stand.instance.customObjs.totems[0], 2, 2];
            activeTotems[1] = [this.stand.instance.info.totem_brochures, this.stand.instance.customObjs.totems[1], -2, 2];
            activeTotems[2] = [this.stand.instance.info.totem_huddle, this.stand.instance.customObjs.totems[2], -2, -2];
            activeTotems[3] = [this.stand.instance.info.totem_video, this.stand.instance.customObjs.totems[3], 2, -2];

            // Set each totem animation
            activeTotems.forEach(totem =>
            {
                // Just tween totem hologram scale and opacity without moving the camera
                gsap.to(totem[1].scale, { x: 1, y: 1, z: 1, duration: 0.5, ease: "cubic.easeOut" });
                gsap.to(totem[1].material, { opacity: 1, delay: 0.4, duration: 0.25, ease: "sine.easeOut" });
            });

            // Animate camera approach
            gsap.to(this.renderCamera.position, { x: this.defaultPosition.x, y: this.defaultPosition.y, z: this.defaultPosition.z, delay: 0.5, duration: 0.5, ease: "sine.easeInOut" });
        }
    }

    // Method called to save a screenshot of the stand
    saveScreenshotAsImage()
    {
        this.takingScreenshot = true;

        // Get time needed to prepare
        let time = 0;
        if(this.renderCamera.position != this.defaultPosition) time = 0.5;

        if(!this.renderer) this.renderer = this.experience.renderer;
        if(!this.environment) this.environment = this.experience.environment;
        if(!this.backgrounds) this.backgrounds = this.experience.backgrounds;

        // Get the stand class from the experience
        if(!this.stand) this.stand = this.experience.stand;
        // Deactivate totems
        this.stand.instance.customObjs.totems.forEach(totem => { totem.visible = false });

        // Reposition camera if needed
        gsap.to(this.renderCamera.position, { x: this.defaultPosition.x, y: this.defaultPosition.y, z: this.defaultPosition.z, duration: time, onComplete: () =>
        {
            if(this.BACKGROUND_OPTION == 0)
            {
                // Disable 3D background
                this.backgrounds.pavilionBackground.visible = false;
            }
            else if(this.BACKGROUND_OPTION == 1)
            {
                // Disable 2D background
                this.scene.background = null;
                // Disable ground plane
                this.backgrounds.groundPlane.visible = false;
            }

            // If the render quality is high
            if(this.QUALITY === 2)
            {
                // Remove the final pass from the composer to be able to take the screenshot
                if(!this.composer) this.composer = this.experience.composer;
                this.composer.addOrRemoveFinalPass(true);
            }

            // Take the print
            setTimeout(() =>
            {
                this.imgData = "";

                // Try to take the screenshot
                try
                {
                    // Screenshot setttings
                    let strMime = "image/png";
                    //let strDownloadMime = "image/octet-stream";
                    this.imgData = this.renderer.instance.domElement.toDataURL(strMime, 1);

                    /*
                    var link = document.createElement('a');
                    if (typeof link.download === 'string')
                    {
                        document.body.appendChild(link);
                        link.download = "stand.png";
                        link.href = this.imgData.replace(strMime, strDownloadMime);
                        link.click();
                        document.body.removeChild(link);
                    }
                    else
                    {
                        location.replace(uri);
                    }
                    */

                    // Activate totems
                    this.stand.instance.customObjs.totems.forEach(totem => { totem.visible = true });

                    if(this.BACKGROUND_OPTION == 0)
                    {
                        // Enable 3D background
                        this.backgrounds.pavilionBackground.visible = true;
                    }
                    else if(this.BACKGROUND_OPTION == 1)
                    {
                        // Enable 2D background
                        this.environment.setEnvironmentBackground();
                        // Enable ground plane
                        this.backgrounds.groundPlane.visible = true;
                    }

                    // Add the final pass to the composer if the pass was previously removed
                    if(this.QUALITY === 2) this.composer.addOrRemoveFinalPass(false);

                    // Data to be sent
                    const data = { 'screenshot_data': this.imgData };

                    // Trigger event once to signal that the screenshot was taken
                    const standScreenshotTakenEvent = new CustomEvent( 'standScreenshotTaken', { detail: data } );
                    window.novvaC4.eventTarget.dispatchEvent(standScreenshotTakenEvent);
                }
                // Show error if can't take the screenshot
                catch(e)
                {
                    // Activate totems
                    this.stand.instance.customObjs.totems.forEach(totem => { totem.visible = true });

                    if(this.BACKGROUND_OPTION == 0)
                    {
                        // Enable 3D background
                        this.backgrounds.pavilionBackground.visible = true;
                    }
                    else if(this.BACKGROUND_OPTION == 1)
                    {
                        // Enable 2D background
                        this.environment.setEnvironmentBackground();
                        // Enable ground plane
                        this.backgrounds.groundPlane.visible = true;
                    }

                    // Reset variable
                    this.takingScreenshot = false;

                    // Data to be sent
                    const data = { 'error': e };

                    // Trigger event once to signal that the screenshot was taken
                    const standScreenshotTakenEvent = new CustomEvent( 'standScreenshotTaken', { detail: data } );
                    window.novvaC4.eventTarget.dispatchEvent(standScreenshotTakenEvent);
                }
            }, 250);
        }});
    }

    // Method called to animate the camera towards a target position
    animateCameraToTarget(position, target)
    {
        // Disable controls
        this.controls.enabled = false;

        // Set min and max distance
        this.controls.minDistance = 0;
        // Set min and max vertical angles
        this.controls.minPolarAngle = 0;
        this.controls.maxPolarAngle = Math.PI;

        // Animate camera approach
        gsap.to(this.controls.target, { x: target.x, y: target.y, z: target.z, duration: 0.5, ease: "sine.easeInOut" });
        gsap.to(this.renderCamera.position, { x: position.x, y: position.y, z: position.z, duration: 0.5, ease: "sine.easeInOut" });
    }

    // Method called to reset the camera to their default values
    resetCameraPosition()
    {
        // Animate camera approach
        gsap.to(this.renderCamera.position, { x: this.defaultPosition.x, y: this.defaultPosition.y, z: this.defaultPosition.z, duration: 0.5, ease: "sine.easeInOut" });
        gsap.to(this.controls.target, { x: 0, y: 2, z: 0, duration: 0.5, ease: "sine.easeInOut", onComplete: () =>
        {
            // Enable controls
            this.controls.enabled = true;

            // Set min and max distance
            this.controls.minDistance = 7;
            // Set min and max vertical angles
            this.controls.minPolarAngle = Math.PI * 0.25;
            this.controls.maxPolarAngle = Math.PI * 0.5;

            // Update render camera matrix
            this.renderCamera.updateProjectionMatrix();
        } });
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Update camera aspect
        this.renderCamera.aspect = this.sizes.width / this.sizes.height;
        this.renderCamera.updateProjectionMatrix();
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Update the orbit controls
        this.controls.update();
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Stop listening for orbit controls changes
        try { this.controls.removeEventListener('change', controlsChangeHandler) } catch(e) { console.log(e) };
        controlsChangeHandler = null;

        // Dispose the orbit controls
        try { this.controls.dispose() } catch(e) { console.log(e) };

        // Reset variables
        this.controls = null;
        this.renderCamera = null;
        this.defaultPosition = null;
        this.takingScreenshot = null;

        // Remove references
        this.experience = null;
        this.sizes = null;
        this.scene = null;
        this.canvas = null;
    }
}