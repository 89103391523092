import { PerspectiveCamera, Vector3 } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import gsap from 'gsap';

import Experience from "./Experience.js";

export default class Camera
{
    // Set constructor
    constructor()
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.canvas = this.experience.canvas;

        // Set camera instances
        this.setInstances();
    }

    // Method called to create and set up the cameras
    setInstances()
    {
        // Create a new perspective camera for the complementary scene
        this.renderCamera = new PerspectiveCamera(40, this.sizes.width / this.sizes.height, 0.1, 200);
        this.defaultPosition = new Vector3(2.68, 1.3, 0);
        this.renderCamera.position.copy(this.defaultPosition);

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Add to scene
            this.scene.add(this.renderCamera);
            // Set the orbit controls
            this.#setOrbitControls();
        }
    }

    // Private method called to set up the orbit controls
    #setOrbitControls()
    {
        // Create new orbit controls for the complementary scene
        this.controls = new OrbitControls(this.renderCamera, this.canvas);
        // Set motion dampening for a smooth effect
        this.controls.enablePan = false;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.3;
        this.controls.rotateSpeed = 0.6;
        this.controls.zoomSpeed = 0.5;
        // Set min and max vertical angles
        this.controls.minPolarAngle = Math.PI * 0.45;
        this.controls.maxPolarAngle = Math.PI * 0.45;
        // Set the control's target
        this.controls.target.set(0, 0.88, 0);
    }

    // Method called externally to run the camera dynamic start animation
    dynamicCameraRepositioning()
    {
        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Animate camera approach
            gsap.to(this.renderCamera.position, { x: this.defaultPosition.x, y: this.defaultPosition.y, z: this.defaultPosition.z, duration: 0.5, ease: "sine.easeInOut" });
        }
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Update camera aspect
        this.renderCamera.aspect = this.sizes.width / this.sizes.height;
        this.renderCamera.updateProjectionMatrix();
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Update the orbit controls
        this.controls.update();
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Dispose the orbit controls
        try { this.controls.dispose() } catch(e) { console.log(e) };

        // Reset variables
        this.controls = null;
        this.renderCamera = null;

        // Remove references
        this.experience = null;
        this.sizes = null;
        this.scene = null;
        this.canvas = null;
    }
}