import * as THREE from "three";
import gsap from "gsap";

import Experience from "../Experience.js";
import EventEmitter from '../Utils/EventEmitter.js';
import NPC from './NPC.js';
import Subway from './Subway.js';

let isMobile, switching, animateBullet;
let tubeGeometry;
let delta;

export default class Dome extends EventEmitter
{
    constructor(BACKGROUND_OPTION)
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.time = this.experience.time;
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.environment = this.experience.environment;
        this.camera = this.experience.camera;
        this.composer = this.experience.composer;
        this.pointer = this.experience.pointer;
        this.minimap = this.experience.minimap;

        // Default values
        isMobile = false;
        switching = false;
        animateBullet = false;

        // Get the background option
        this.BACKGROUND_OPTION = BACKGROUND_OPTION;

        // Create instance
        this.instance = {};
        this.instance.npcs = [];
        this.hoveredElem = null;

        // Set switching variable
        this.switching = false;

        // Local clock for animations
        this.clock = new THREE.Clock();

        this.#initJSONfile();

        // Listen to when the resources are loaded
        this.resources.on('loadedResources', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Get the mobile detector class from the experience
                this.mobileDetector = this.experience.mobileDetector;
                // Get if the device is a mobile
                isMobile = this.mobileDetector.isMobile;
                // Remove reference
                this.mobileDetector = null;

                // Create raycast
                this.raycaster = new THREE.Raycaster();

                // If the device is a mobile
                if(isMobile === true)
                {
                    // Listen for touch event
                    this.pointer.on('pointerTouch', () =>
                    {
                        // Cast a raycast
                        if(!this.experience.INTERRUPTED) this.#castRaycast(this.pointer.mouse);
                    });
                }

                try
                {
                    // Set environment lights
                    this.environment.setHemisphereLight();
                    if(this.BACKGROUND_OPTION !== 2) this.environment.setDirectionalLight();
                    else this.environment.setPointLight();
                    // Set environment background
                    this.environment.setEnvironmentBackground();
                    // If the render quality is medium or high
                    if(this.experience.parameters.QUALITY > 0)
                    {
                        // Set environment map
                        this.environment.setEnvironmentMap();
                    }
                }
                catch(e) { console.log(e) };

                // Create tube geometry
                tubeGeometry = new THREE.CylinderGeometry(5, 5, 300, 8);

                // Set listeners
                if(!this.experience.INTERRUPTED) this.#setListeners();
                // Set dome
                if(!this.experience.INTERRUPTED) this.#setDome();

                // Trigger event warning that the basic elements finished loading
                if(!this.experience.INTERRUPTED) this.trigger('loaded3DScene');
            }
        });
    }

    // Private method called to initialize the local JSON object
    #initJSONfile()
    {
        // JSON object containing the dome customization info
        this.instance.info =
        {
            "domes":
            [
                {
                    "dome_id" : 0,
                    "pavilions":
                    [
                        {
                            "pavilion_id": 0,
                            "pavilion_style": 0,
                            "pavilion_name": ""
                        }
                    ]
                }
            ],
            "bullets_color": ""
        };
    }

    // Method called to update many or all of the objects
    updateObjectsByJSON(jsonObj, startingDome, npcsQuantity)
    {
        // Set instance JSON file
        this.instance.info = jsonObj;

        let arrayPos = 0;
        // If the section id value is invalid
        if(startingDome !== "" && startingDome !== null && startingDome !== undefined)
        {
            // For each of the sections
            for(let i = 0; i < this.instance.info.domes.length; i++)
            {
                // If the section id is the same as the starting section id
                if(this.instance.info.domes[i].dome_id === startingDome)
                {
                    // Get the array position
                    arrayPos = i;
                    break;
                }
            }
        }
        if(this.BACKGROUND_OPTION !== 0) this.instance.ground.rotation.y = THREE.MathUtils.degToRad(60) * arrayPos;

        // If the npcs quantity value is invalid
        if(npcsQuantity === "" || isNaN(npcsQuantity))
        {
            if(npcsQuantity !== undefined) console.log('Invalid value for the quantity of NPCs: ' + npcsQuantity + '. Using default quantity instead (200).')
            // Set default value
            npcsQuantity = 200;
        }
        this.npcsQuantity = npcsQuantity;

        // Get the section id
        this.instance.id = this.instance.info.domes[arrayPos].dome_id;

        // Create minimap
        if(!this.experience.INTERRUPTED) this.minimap.setMinimap(this.instance.info.domes, arrayPos);

        // Set the pavilions
        if(!this.experience.INTERRUPTED) this.#setPavilions(arrayPos);
        // Set the NPCs
        if(!this.experience.INTERRUPTED) if(this.BACKGROUND_OPTION === 0) this.#setNewNPCs(npcsQuantity);
        // Set the connections between domes
        if(!this.experience.INTERRUPTED) this.#setConnections(arrayPos);
        // Set the interactive elements
        if(!this.experience.INTERRUPTED) this.#setInteractives(this.instance.info.bullets_color);

        // Trigger event warning that all the elements finished loading
        if(!this.experience.INTERRUPTED) this.trigger('loaded3DModel');
    }

    // Private method called to set up the listeners
    #setListeners()
    {
        // Listen for the switch section command
        this.minimap.on('switchDome', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the sections are not being switched
                if(switching === false)
                {
                    // Set to true
                    switching = true;

                    // If the id from the new section is different from the current section, and the section is defined
                    if(this.instance.id != this.instance.info.domes[this.minimap.currentSectionId].dome_id && this.instance.info.domes[this.minimap.currentSectionId] !== undefined)
                    {
                        if(this.hoveredElem !== null) this.trigger('stoppedHoveringPavilion');
                        this.hoveredElem = null;

                        // Get the new section id
                        this.instance.id = this.instance.info.domes[this.minimap.currentSectionId].dome_id;

                        // Do after 1 second
                        setTimeout(() =>
                        {
                            // Switch to the next section
                            this.switchDome(this.minimap.currentSectionId);
                        }, 50);
                    }
                    // If the id from the new section is the same as the current section
                    else
                    {
                        // Reset variable
                        switching = false;
                    }
                }
            }
        });

        // Listen for mouse hovers
        this.on('hoveringPavilion', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the hovered element is a pavilion
                if(this.hoveredElem.includes('pavilion'))
                {
                    // Get the dome array position
                    let domeArrayPos = null;
                    for(let i = 0; i < this.instance.info.domes.length; i++)
                    {
                        if(this.instance.info.domes[i].dome_id === this.instance.id)
                        {
                            domeArrayPos = i;
                            break;
                        }
                    }

                    // If the dome array position was found
                    if(domeArrayPos !== null)
                    {
                        // Get the element array position
                        let arrayPos;
                        if(this.hoveredElem.includes('bullet')) arrayPos = this.hoveredElem.split('_')[2];
                        else arrayPos = this.hoveredElem.split('_')[1];

                        // Get the needed info from the pavilion
                        const pavId = this.instance.info.domes[domeArrayPos].pavilions[arrayPos].pavilion_id;
                        const pavName = this.instance.info.domes[domeArrayPos].pavilions[arrayPos].pavilion_name;

                        // Data to be sent
                        const data =
                        {
                            'pavilion_id': pavId,
                            'pavilion_name': pavName
                        };

                        // Trigger event once to signal that the pavilion is being hovered
                        const hoveringPavilionEvent = new CustomEvent( 'hoveringPavilion', { detail: data } );
                        window.novvaC2.eventTarget.dispatchEvent(hoveringPavilionEvent);
                    }
                }
                else
                {
                    // Get the element array position
                    let arrayPos;
                    if(this.hoveredElem.includes('bullet')) arrayPos = this.hoveredElem.split('_')[2];
                    else arrayPos = this.hoveredElem.split('_')[1];
                    
                    // Data to be sent
                    const data =
                    {
                        'dome_id': this.instance.info.domes[arrayPos].dome_id,
                        'dome_name': this.instance.info.domes[arrayPos].dome_name
                    };

                    // Trigger event once to signal that the dome connection is being hovered
                    const hoveringPavilionEvent = new CustomEvent( 'hoveringDomeConnection', { detail: data } );
                    window.novvaC2.eventTarget.dispatchEvent(hoveringPavilionEvent);
                }

                // Set cursor to pointer
                document.body.style.cursor = "pointer";
            }
            
        });

        // Listen for mouse hovers stopping
        this.on('stoppedHoveringPavilion', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the hovered element is a pavilion
                if(this.hoveredElem.includes('pavilion'))
                {
                    // Get the dome array position
                    let domeArrayPos = null;
                    for(let i = 0; i < this.instance.info.domes.length; i++)
                    {
                        if(this.instance.info.domes[i].dome_id === this.instance.id)
                        {
                            domeArrayPos = i;
                            break;
                        }
                    }

                    // If the dome array position was found
                    if(domeArrayPos !== null)
                    {
                        // Get the element array position
                        let arrayPos;
                        if(this.hoveredElem.includes('bullet')) arrayPos = this.hoveredElem.split('_')[2];
                        else arrayPos = this.hoveredElem.split('_')[1];

                        // Data to be sent
                        const data = { 'pavilion_id': this.instance.info.domes[domeArrayPos].pavilions[arrayPos].pavilion_id };

                        // Trigger event once to signal that the stand isn't being hovered anymore
                        const stoppedHoveringPavilionEvent = new CustomEvent( 'stoppedHoveringPavilion', { detail: data } );
                        window.novvaC2.eventTarget.dispatchEvent(stoppedHoveringPavilionEvent);
                    }
                }
                else
                {
                    // Get the element array position
                    let arrayPos;
                    if(this.hoveredElem.includes('bullet')) arrayPos = this.hoveredElem.split('_')[2];
                    else arrayPos = this.hoveredElem.split('_')[1];

                    // Data to be sent
                    const data = { 'dome_id': this.instance.info.domes[arrayPos].dome_id };
                                
                    // Trigger event once to signal that the stand isn't being hovered anymore
                    const stoppedHoveringPavilionEvent = new CustomEvent( 'stoppedHoveringDomeConnection', { detail: data } );
                    window.novvaC2.eventTarget.dispatchEvent(stoppedHoveringPavilionEvent);
                }

                // Set cursor to pointer
                document.body.style.cursor = "default";
            }
        });
    }

    // Private method called to set up the dome model
    #setDome()
    {
        // If the background option chosen is the moon landscape, only get the dome model
        if(this.BACKGROUND_OPTION === 0) this.instance.model = this.resources.items["dome"].scene.clone();
        // If the background option chosen is the earth landscape
        else
        {
            // Get the dome model
            this.instance.model = this.resources.items["dome_earth"].scene.clone();

            // Set the ground mesh
            this.instance.ground = this.resources.items["dome_earth_ground"].scene.clone();
            this.scene.add(this.instance.ground);
            // Update materials
            this.#updateMaterials(this.instance.ground);
        }

        // Create array of coordinates
        this.instance.pavCoords = [];
        this.instance.tubeCoords = [];
        this.instance.subways = [];

        // Go through all the model's children
        this.instance.model.traverse((child) =>
        {
            // If the child contains a material
            if(child.material instanceof THREE.MeshStandardMaterial)
            {
                // If the child contains a normal map
                if(child.material.normalMap)
                {
                    // Set scale and wrap
                    child.material.normalScale.set(2, 2);
                    child.material.normalMap.wrapS = THREE.RepeatWrapping;
                    child.material.normalMap.wrapT = THREE.RepeatWrapping;
                }
            }

            // If the child is the glass dome
            if(child.name.includes("Cupula") && this.BACKGROUND_OPTION === 0)
            {
                // Create clipping plane
                this.instance.clippingPlane = new THREE.Plane(new THREE.Vector3(-0.25, -0.75, 0), 170);
                // Set opacity
                child.material.opacity = 0.5;
                child.material.clippingPlanes = [ this.instance.clippingPlane ];
            }
            // If the child is the lunar ground
            else if(child.name.includes("Lua") && this.BACKGROUND_OPTION === 0)
            {
                // Add normal map
                child.material.normalScale.set(5, 5);
                child.material.color = new THREE.Color(0xa0a09f);
            }
            // If the child is a pavilion coordinate
            else if(child.name.split('_')[0] == "pavCoords")
            {
                // Get variables from the coordinate name
                const id = child.name.split('_')[1];
                const number = parseInt(id[1]);

                let add;
                // Get pavilion coordinate type
                if(id.includes('b')) add = 0;
                else if(id.includes('s')) add = 3;
  
                // If the array slot for this type of coordinate is already filled
                if(this.instance.pavCoords[number + add] !== undefined)
                {
                    // If the array slot contains an inner array (multiple variants for the same slot)
                    if(Array.isArray(this.instance.pavCoords[number + add]))
                    {
                        // Add to the array
                        this.instance.pavCoords[number + add].push(child);
                    }
                    // If the array slot contains a single object
                    else
                    {
                        // Get the previous object
                        const obj = this.instance.pavCoords[number + add]
                        // Create an array with the previous and the current object
                        this.instance.pavCoords[number + add] = [obj, child];
                    }
                }
                // If the array slot for this type of coordinate is empty
                else
                {
                    // Add object to the slot
                    this.instance.pavCoords[number + add] = child;
                }
            }
            // If the child is a tube coordinate
            else if(child.name.split('_')[0] == "tubeCoords")
            {
                // Get id from the coordinate name
                const id = child.name.split('_')[1];

                // Add coordinate to the array
                this.instance.tubeCoords[id] = child;
            }
        });

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Add to the scene
            this.scene.add(this.instance.model);
            // Update materials
            this.#updateMaterials(this.instance.model);
        }

        // If the background option chosen is the moon landscape
        if(this.BACKGROUND_OPTION === 0)
        {
            // Create multiple subway
            for(let i = 0; i < 4; i++)
            {
                // If the experience wasn't interrupted
                if(!this.experience.INTERRUPTED)
                {
                    // Setup subway
                    let subway = new Subway(this.BACKGROUND_OPTION);
                    subway.setSubway(i);
                    // Add to subway array
                    this.instance.subways.push(subway);
                }
            }
        }
        // If the background option chosen is the earth landscape
        else
        {
            // Set the vehicles movement delays
            this.instance.subwayDelays = [];

            // For each of the desired vehicles
            for(let i = 0; i < 30; i++)
            {
                if(!this.experience.INTERRUPTED)
                {
                    // Create a new vehicle
                    let vehicle = this.resources.items["vehicle"].scene.clone();
                    vehicle.lookAt(new THREE.Vector3(0, 7.9, 0));

                    // Set the vehicle position
                    let delay = Math.random() * 200;
                    if(i < 15) vehicle.position.set(Math.cos(delay) * 251, 7.9, Math.sin(delay) * 251);
                    else vehicle.position.set(Math.cos(delay) * 257, 7.9, Math.sin(delay) * 257);

                    // Save the ehicle movement delay
                    this.instance.subwayDelays.push(delay);

                    // Save the vehicle and add it to the scene
                    this.instance.subways.push(vehicle);
                    this.scene.add(vehicle);
                }
            }

            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Create array of animated vehicles
                if(this.instance.animatedVehicles === undefined) this.instance.animatedVehicles = [];
                // For each of the desired vehicles
                for(let i = 0; i < 2; i++)
                {
                    // Setup subway
                    let subway = new Subway(this.BACKGROUND_OPTION);
                    subway.setSubway(0, 10000 * i);
                    // Add to subway array
                    this.instance.animatedVehicles.push(subway);
                }
            }
        }
    }

    // Private method called to set up the pavilions inside the dome
    #setPavilions(pos)
    {
        // Get dome id
        this.instance.id = this.instance.info.domes[pos].dome_id;

        // Create array of pavilion coordinates
        this.instance.pavilions = [];

        // Set each stand from the JSON file
        for(let i = 0; i < this.instance.info.domes[pos].pavilions.length; i++)
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Get the pavilion
                let pav = this.instance.info.domes[pos].pavilions[i];

                // If the pavilion object is in the correct format
                if(typeof pav === "object" && pav !== null && pav !== undefined)
                {
                    // Get the pavilion style
                    const style = pav.pavilion_style;

                    // Get respective pavilion model
                    const pavModel = this.resources.items["pavModel_" + style].scene.clone();
                    pavModel.name = "pavilion_" + [i];

                    // Get pavilion type (big or small)
                    let type;
                    if(style.includes('b')) type = 0;
                    else if(style.includes('s')) type = 1;

                    // Get the desired position
                    const position = pav.pavilion_position;

                    // If the pavilion is big, but the chosen position is narrow
                    if(type === 0 && position > 2)
                    {
                        console.log("Adding a big pavilion to a narrow position (3, 4 or 5) is not recommended (at pavilion id: " + pav.pavilion_id + ", dome id: " + this.instance.id + ")");
                    }
                    // If the pavilion is small, but the chosen position is wide
                    else if(type === 1 && position < 3)
                    {
                        console.log("Adding a small pavilion to a wide position (0, 1 or 2) is not recommended (at pavilion id: " + pav.pavilion_id + ", dome id: " + this.instance.id + ")");
                    }

                    let coords;
                    // If the chosen coordinates are a part of an array of variants
                    if(Array.isArray(this.instance.pavCoords[position]))
                    {
                        // For each of the coordinates variants
                        for(let i = 0; i < this.instance.pavCoords[position].length; i++)
                        {
                            // Get this variant's allowed types
                            const allowedTypes = this.instance.pavCoords[position][i].name.split('_')[2];
                            // If the pavilion style is allowed on this variant
                            if(allowedTypes.includes(style[1]))
                            {
                                // Get coordinate variant
                                coords = this.instance.pavCoords[position][i];
                                break;
                            }
                        }

                        // If no coords was found, get the first from the array
                        if(coords === undefined) coords = this.instance.pavCoords[position][0];
                    }
                    // If the chosen coordinates is a single coordinate
                    else
                    {
                        // Get the coordinate
                        coords = this.instance.pavCoords[position];
                    }

                    // Set pavilion position and rotation
                    pavModel.position.set(coords.position.x, coords.position.y, coords.position.z);
                    pavModel.rotation.copy(coords.rotation);

                    // If the experience wasn't interrupted
                    if(!this.experience.INTERRUPTED)
                    {
                        // Add to the scene
                        this.scene.add(pavModel);
                        // Add object to the pavilions array
                        this.instance.pavilions[i] = pavModel;
                        // Update materials
                        this.#updateMaterials(pavModel);
                    }
                }
            }
        }

        if(!this.renderer) this.renderer = this.experience.renderer;
        // Update shadow map
        this.renderer.instance.shadowMap.needsUpdate = true;
    }

    // Method called to set up new NPC instances to this dome
    #setNewNPCs(numOfNPCs)
    {
        // Set NPCs array
        this.instance.npcs = [];

        // Create multiple NPCs
        for(let i = 0; i < numOfNPCs; i++)
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Setup NPC
                let npc = new NPC();
                npc.setNPC();
                // Add to NPCs array
                this.instance.npcs.push(npc);
            }
        }
    }

    // Private method called to create and set up the connections between the domes
    #setConnections(pos)
    {
        // Create array of connections
        this.instance.connections = [];

        // If the current dome is the center dome
        if(pos === 0)
        {
            // For each of the other domes
            for(let i = 1; i < Math.min(this.instance.info.domes.length, 7); i++)
            {
                // If the experience wasn't interrupted
                if(!this.experience.INTERRUPTED)
                {
                    switch(i)
                    {
                        case 1:
                            // Connection to the dome 1 (top left)
                            this.#createTubeConnection(1, this.instance.tubeCoords[0]);
                        break;
                        case 2:
                            // Connection to the dome 2 (top right)
                            this.#createTubeConnection(2, this.instance.tubeCoords[1]);
                        break;
                        case 3:
                            // Connection to the dome 3 (bottom left)
                            this.#createTubeConnection(3, this.instance.tubeCoords[2]);
                        break;
                        case 4:
                            // Connection to the dome 4 (bottom right)
                            this.#createTubeConnection(4, this.instance.tubeCoords[3]);
                        break;
                        case 5:
                            // Connection to the dome 5 (left)
                            this.#createTubeConnection(5, this.instance.tubeCoords[4]);
                        break;
                        case 6:
                            // Connection to the dome 6 (right)
                            this.#createTubeConnection(6, this.instance.tubeCoords[5]);
                        break;
                    }
                }
            }
        }
        // If the current dome is one of the lateral domes
        else
        {
            switch(pos)
            {
                case 1:
                    // Set the connection to the dome 0
                    this.#createTubeConnection(0, this.instance.tubeCoords[3]);

                    // If there is a dome of id 2, connect
                    if(this.instance.info.domes[2] !== undefined)
                    {
                        this.#createTubeConnection(2, this.instance.tubeCoords[5]);
                    }
                    // If there is a dome of id 5, connect
                    if(this.instance.info.domes[5] !== undefined)
                    {
                        this.#createTubeConnection(5, this.instance.tubeCoords[2]);
                    }
                break;
                case 2:
                    // Set the connection to the dome 0
                    this.#createTubeConnection(0, this.instance.tubeCoords[2]);

                    // If there is a dome of id 1, connect
                    if(this.instance.info.domes[1] !== undefined)
                    {
                        this.#createTubeConnection(1, this.instance.tubeCoords[4]);
                    }
                    // If there is a dome of id 6, connect
                    if(this.instance.info.domes[6] !== undefined)
                    {
                        this.#createTubeConnection(6, this.instance.tubeCoords[3]);
                    }
                break;
                case 3:
                    // Set the connection to the dome 0
                    this.#createTubeConnection(0, this.instance.tubeCoords[1]);

                    // If there is a dome of id 4, connect
                    if(this.instance.info.domes[4] !== undefined)
                    {
                        this.#createTubeConnection(4, this.instance.tubeCoords[5]);
                    }
                    // If there is a dome of id 5, connect
                    if(this.instance.info.domes[5] !== undefined)
                    {
                        this.#createTubeConnection(5, this.instance.tubeCoords[0]);
                    }
                break;
                case 4:
                    // Set the connection to the dome 0
                    this.#createTubeConnection(0, this.instance.tubeCoords[0]);

                    // If there is a dome of id 3, connect
                    if(this.instance.info.domes[3] !== undefined)
                    {
                        this.#createTubeConnection(3, this.instance.tubeCoords[4]);
                    }
                    // If there is a dome of id 6, connect
                    if(this.instance.info.domes[6] !== undefined)
                    {
                        this.#createTubeConnection(6, this.instance.tubeCoords[1]);
                    }
                break;
                case 5:
                    // Set the connection to the dome 0
                    this.#createTubeConnection(0, this.instance.tubeCoords[5]);

                    // If there is a dome of id 1, connect
                    if(this.instance.info.domes[1] !== undefined)
                    {
                        this.#createTubeConnection(1, this.instance.tubeCoords[1]);
                    }
                    // If there is a dome of id 3, connect
                    if(this.instance.info.domes[3] !== undefined)
                    {
                        this.#createTubeConnection(3, this.instance.tubeCoords[3]);
                    }
                break;
                case 6:
                    // Set the connection to the dome 0
                    this.#createTubeConnection(0, this.instance.tubeCoords[4]);

                    // If there is a dome of id 2, connect
                    if(this.instance.info.domes[2] !== undefined)
                    {
                        this.#createTubeConnection(2, this.instance.tubeCoords[0]);
                    }
                    // If there is a dome of id 4, connect
                    if(this.instance.info.domes[4] !== undefined)
                    {
                        this.#createTubeConnection(4, this.instance.tubeCoords[2]);
                    }
                break;
            }
        }

        // If the background option chosen is the earth landscape
        if(this.BACKGROUND_OPTION !== 0)
        {
            // For each of the dome connections
            for(let i = 0; i < this.instance.connections.length; i++)
            {
                // Get the vehicle target
                let target = this.instance.connections[i].children[0].getWorldPosition(new THREE.Vector3(0, 0, 0));
                // Tween the vehicle position towards their target
                gsap.to(this.instance.connectionVehicles[i].position, { x: target.x, z: target.z, duration: 5, yoyo: true, repeat: -1, ease: "none" });
            }
        }
    }

    // Private method called to create tubes for the connections
    #createTubeConnection(id, coords)
    {
        let connection;
        // Create tube
        if(this.BACKGROUND_OPTION === 0) connection = this.resources.items["connection"].scene.clone();
        else connection = this.resources.items["street"].scene.clone();

        // Set the tube name
        connection.name = 'connection_' + id;

        // Get the coordinates
        const position = coords.position;
        let rotation = coords.rotation;

        // Set tube rotation and position
        connection.position.set(position.x, position.y, position.z);
        connection.rotation.copy(rotation);

        // Add tube to the connections array
        this.instance.connections.push(connection);
        // Add tube to the scene
        if(!this.experience.INTERRUPTED) this.scene.add(connection);

        // If the background option chosen is the earth landscape
        if(this.BACKGROUND_OPTION !== 0)
        {
            // If the vehicle array hasn't been created yet, create it
            if(this.instance.connectionVehicles === undefined) this.instance.connectionVehicles = [];
            // Create a new vehicle inside the connection
            let vehicle = this.resources.items["vehicle"].scene.clone();
            vehicle.position.set(position.x, 7.9, position.z);
            // Set the vehicle rotation
            vehicle.rotation.copy(rotation);
            vehicle.children[0].rotateY(Math.PI * 0.5);
            // Add the vehicle to the array and the scene
            this.instance.connectionVehicles.push(vehicle);
            this.scene.add(vehicle);
        }
    }

    // Private method called to set up the interactives array
    #setInteractives(hexcode)
    {
        // Create array of interactives
        this.instance.interactives = [];
        this.instance.bullets = [];

        // If there are pavilions in the dome
        if(this.instance.pavilions.length > 0)
        {
            // Add pavilions to the array
            this.instance.interactives = this.instance.pavilions;
        }
        // If there are connections in the dome
        if(this.instance.connections.length > 0)
        {
            // If the interactives array already contains data
            if(this.instance.interactives.length > 0)
            {
                // Add pavilions and connections to the same array
                this.instance.interactives = this.instance.pavilions.concat(this.instance.connections);
            }
            // If the interactives array doesn't contain data yet
            else
            {
                // Add connections to the array
                this.instance.interactives = this.instance.connections;
            }
        }

        // Create the bullet mesh
        if(!this.instance.bulletMesh)
        {
            // If the hexcode is empty, set the default color
            if(hexcode === "" || hexcode === null || hexcode === undefined) hexcode = "0xfe5101";
            // If the hexcode is on the wrong format, set to correct format
            if(hexcode.slice(0, 1) === '#') hexcode = '0x' + hexcode.slice(1);

            // Create the inner circle, whose color is customizable
            let innerMat = new THREE.MeshBasicMaterial({ map: this.resources.items.bullet_inner, transparent: true, side: THREE.DoubleSide, opacity: 0.9 });
            innerMat.map.encoding = THREE.sRGBEncoding;
            innerMat.encoding = THREE.sRGBEncoding;
            // Set color hexcode
            innerMat.color.setHex(hexcode).convertSRGBToLinear();
            innerMat.needsUpdate = true;

            // Create the outer circle, permanently white
            let outerMat = new THREE.MeshBasicMaterial({ map: this.resources.items.bullet_outer, transparent: true, side: THREE.DoubleSide, opacity: 0.9 });
            outerMat.map.encoding = THREE.sRGBEncoding;
            outerMat.encoding = THREE.sRGBEncoding;

            // Create the plane geometry
            let geometry;
            if(this.BACKGROUND_OPTION === 0) geometry = new THREE.PlaneGeometry(40, 40);
            else geometry = new THREE.PlaneGeometry(30, 30);
            // Create groups to load this materials, from the first pixel to the infinity
            geometry.addGroup( 0, Infinity, 0 );
            geometry.addGroup( 0, Infinity, 1 );

            // Set the bullet materials
            this.instance.bulletMesh = new THREE.Mesh(geometry, [innerMat, outerMat]);
            this.instance.bulletMesh.renderOrder = 1;
            this.instance.bulletMesh.material[0].depthTest = false;
            this.instance.bulletMesh.material[1].depthTest = false;
            this.instance.bulletMesh.material.needsUpdate = true;
        }

        // For each of the interactive elements
        this.instance.interactives.forEach(interactive =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Create a bullet instance
                let bullet = this.instance.bulletMesh.clone();
                bullet.material = [bullet.material[0].clone(), bullet.material[1].clone()];
                bullet.position.copy(interactive.position);

                // If the background option chosen is the moon landscape
                if(this.BACKGROUND_OPTION === 0)
                {
                    // Set the bullet position further from the interactive
                    bullet.position.y += 50;
                    bullet.position.x -= 30;
                }
                // If the background option chosen is the earth landscape
                else
                {
                    // Set the bullet position near the interactive
                    bullet.position.y += 20;
                    bullet.position.x -= 10;
                }

                // Set bullet rotation
                bullet.lookAt(this.camera.renderCamera.position);
                bullet.rotation.z = 0;
                bullet.name = "bullet_" + interactive.name;

                // Add to the interactives array and the bullets array
                this.instance.interactives.push(bullet);
                this.instance.bullets.push(bullet);

                // Add to the scene
                if(!this.experience.INTERRUPTED) this.scene.add(bullet);
            }
        });
    }

    // Method called to switch from one dome to the next
    switchDome(position, npcsQuantity)
    {
        try
        {
            // If section id is invalid, throw exception
            if(this.instance.info.domes[position] === undefined) throw "Invalid dome at position: " + position + "; Dome does not exists";
        
            // If the dome isn't switching yet
            if(switching === false)
            {
                // Trigger a click on the respective minimap section
                this.minimap.instance.sections[position].click();
                return;
            }

            // Trigger hover as stopped
            if(this.hoveredElem !== null) this.trigger('stoppedHoveringPavilion');
            animateBullet = false;

            // Data to be sent
            const data = { 'dome_id': this.instance.info.domes[position].dome_id }

            // Trigger event once to signal that the scene is being switched
            const switchDomeEvent = new CustomEvent( 'switchingDome', { detail: data } );
            window.novvaC2.eventTarget.dispatchEvent(switchDomeEvent);

            // Set switching to true
            this.switching = true;

            // Set coordinates to the dome 0 (central)
            let coords = new THREE.Vector3();
            coords.copy(this.instance.connections[0].position);

            // Go through all the domes
            for(let i = 0; i < this.instance.connections.length; i++)
            {
                // Found the target dome
                if(this.instance.connections[i].name.split('_')[1] == position)
                {
                    // Copy the dome connection coordinates
                    coords.copy(this.instance.connections[i].position);
                }
            }

            // Call the camera animation to switch to the target dome
            this.camera.switchAnimation(coords);

            // Only set the target dome after 0.6s so the camera can animate the transition
            setTimeout(() =>
            {
                // Rotate the ground around
                if(this.BACKGROUND_OPTION !== 0) this.instance.ground.rotation.y = THREE.MathUtils.degToRad(60) * position;

                // If the npcs quantity value is invalid
                if(npcsQuantity === "" || isNaN(npcsQuantity))
                {
                    if(npcsQuantity !== undefined) console.log('Invalid value for the quantity of NPCs: ' + npcsQuantity + '. Using the previous value or the default quantity instead (200).')
                    // Set default value
                    npcsQuantity = this.npcsQuantity;
                }
                else this.npcsQuantity = npcsQuantity;

                // Dispose the current dome elements
                this.#disposeDomeElements();
                // Set new pavilions
                this.#setPavilions(position);

                // If the background option chosen is the earth landscape
                if(this.BACKGROUND_OPTION !== 0)
                {
                    // If the connection vehicles array was created
                    if(this.instance.connectionVehicles instanceof Array)
                    {
                        // Dispose all vehicles
                        this.instance.connectionVehicles.forEach(vehicle =>
                        {
                            try { this.disposer.disposeElements(vehicle) } catch(e) { console.log(e) };
                        });
                        this.instance.connectionVehicles.length = 0;
                    }
                }

                // Set new connections
                this.#setConnections(position);
                // Set the interactive elements
                this.#setInteractives();

                // Only trigger the event after 0.6s so the camera can finish the transition animation
                setTimeout(() =>
                {
                    // Set switching to false
                    this.switching = false;

                    // Trigger event warning that all the elements finished loading
                    this.trigger('loaded3DModel');

                    // Reset variable
                    switching = false;
                }, 600);
            }, 600);
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to update the model materials
    #updateMaterials(model)
    {
        // Go through all the model's children
        model.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
            {
                // If the child isn't the glass dome
                if(child.name.includes("Cupula") === false)
                {
                    // Cast shadows
                    child.castShadow = true;
                }
                // Receive shadows
                child.receiveShadow = true;
 
                // Set children material encoding
                child.material.encoding = THREE.sRGBEncoding;
                if(child.material.map != null) child.material.map.encoding = THREE.sRGBEncoding;
                child.material.needsUpdate = true;
            }
        });
    }

    // Private method called to find the pavilion object from the hovered child
    #getIntersectionParent(object)
    {
        // Initialize variables
        let parentObject = object.parent;
        let gotRightParent = false;

        // Loop until the pavilion parent is found
        do
        {
            // Get parent name
            let parentName = parentObject.name;

            // Get parent
            if(!parentName.includes('pavilion') && !parentName.includes('connection'))
            {
                let furtherParent = parentObject.parent;
                parentObject = furtherParent;
            }
            // Finish the loop
            else gotRightParent = true;

        } while(gotRightParent === false);

        // Return parent found
        return parentObject;
    }

    // Private method called to cast a new raycast
    #castRaycast(coords)
    {
        // If there are interactive elements in the dome
        if(this.instance.interactives.length > 0 && switching === false)
        {
            // Set raycaster
            this.raycaster.setFromCamera(coords, this.camera.renderCamera);
            const intersection = this.raycaster.intersectObjects(this.instance.interactives, true);

            // If the raycaster have intersected with the pavilions
            if(intersection.length > 0)
            {
                // Get the intersected object
                let object = intersection[0].object;
                // If the intersected object isn't a bullet
                if(object.name.includes('bullet') === false)
                {
                    // Get the object parent
                    object = this.#getIntersectionParent(intersection[0].object);

                    // For each of the bullets
                    for(let i = 0; i < this.instance.bullets.length; i++)
                    {
                        // If the bullet is relative to the hovered object
                        if(this.instance.bullets[i].name.includes(object.name))
                        {
                            // If the bullet can be animated
                            if(animateBullet === false)
                            {
                                // Set as animated
                                animateBullet = true;

                                // Tween bullet scale and opacity
                                gsap.to(this.instance.bullets[i].scale, { x: 1.5, y: 1.5, duration: 0.2 });
                                gsap.to(this.instance.bullets[i].material, { opacity: 1, duration: 0.2 });
                            }
                            break;
                        }
                    }
                }
                // If the intersected object is a bullet and it's not animated
                else if(animateBullet === false)
                {
                    // Set as animated
                    animateBullet = true;

                    // Tween bullet scale and opacity
                    gsap.to(object.scale, { x: 1.5, y: 1.5, duration: 0.2 });
                    gsap.to(object.material, { opacity: 1, duration: 0.2 });

                    // If the object is a pavilion
                    if(object.name.includes('pavilion'))
                    {
                        // For each of the pavilions
                        for(let i = 0; i < this.instance.pavilions.length; i++)
                        {
                            // If the hovered object contains the same id as the pavilion model
                            if(object.name.split('_')[2] === this.instance.pavilions[i].name.split('_')[1])
                            {
                                // Outline the pavilion
                                this.composer.updateOutlineObjects(this.instance.pavilions[i]);
                                break;
                            }
                        }
                    }
                    // If the object is a connection
                    else if(object.name.includes('connection'))
                    {
                        // For each of the connections
                        for(let i = 0; i < this.instance.connections.length; i++)
                        {
                            // If the hovered object contains the same id as the connection model
                            if(object.name.split('_')[2] === this.instance.connections[i].name.split('_')[1])
                            {
                                // Outline the connection
                                this.composer.updateOutlineObjects(this.instance.connections[i]);
                                break;
                            }
                        }
                    }
                }

                // If the user was already hovering an element
                if(this.hoveredElem != null)
                {
                    // If the user hovered a new element
                    if(this.hoveredElem != object.name)
                    {
                        // If the new hovered object isn't their bullet
                        if(!this.hoveredElem.includes(object.name) && !object.name.includes(this.hoveredElem))
                        {
                            // Trigger hover as stopped
                            this.trigger('stoppedHoveringPavilion');

                            // Update the outline pass objects
                            this.hoveredElem = object.name;

                            // Trigger hover event
                            this.trigger('hoveringPavilion');

                            // Update outlined objects
                            if(object.name.includes('bullet') === false) this.composer.updateOutlineObjects(object);
                        }
                    }
                }
                // If the user wasn't previously hovering an element
                else
                {
                    // Update the outline pass objects
                    this.hoveredElem = object.name;

                    // Trigger hover event
                    this.trigger('hoveringPavilion');

                    // Update outlined objects
                    if(object.name.includes('bullet') === false) this.composer.updateOutlineObjects(object);
                }
            }
            // If the user is not hovering an element
            else
            {
                // If the user was previously hovering an element
                if(this.hoveredElem !== null)
                {
                    // Trigger hover as stopped
                    this.trigger('stoppedHoveringPavilion');

                    // If the bullet is animated
                    if(animateBullet === true)
                    {
                        // For each of the bullets
                        for(let i = 0; i < this.instance.bullets.length; i++)
                        {
                            // If the correct bullet was found
                            if(this.instance.bullets[i].name.includes(this.hoveredElem))
                            {
                                // Set as not animated
                                animateBullet = false;

                                // Tween bullet scale and opacity
                                gsap.to(this.instance.bullets[i].scale, { x: 1, y: 1, duration: 0.2 });
                                gsap.to(this.instance.bullets[i].material, { opacity: 0.8, duration: 0.2 });
                                
                                break;
                            }
                        }
                    }

                    // Reset outline objects
                    this.hoveredElem = null;
                    // Update outlined objects
                    this.composer.updateOutlineObjects(null);
                }
            }
        }
    }

    // Private method called to update the raycast
    #updateRaycast()
    {
        // If the mouse is moving
        if(this.pointer.mouseMove)
        {
            // Reset mouse movement
            this.pointer.mouseMove = false;

            // If the sections are not switching
            if(switching === false && !this.experience.INTERRUPTED)
            {
                // Cast raycast
                this.#castRaycast(this.pointer.mouse);
            }
        }
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Get delta time
        delta = Math.min( 0.05, this.clock.getDelta() )

        // If the domes aren't switching
        if(switching === false)
        {
            // If the device is a desktop
            if(isMobile === false)
            {
                // Update the raycast
                if(!this.experience.INTERRUPTED) this.#updateRaycast();
            }

            // If the subways array was instantiated
            if(this.instance.subways instanceof Array)
            {
                // For each of the subways
                for(let i = 0; i < this.instance.subways.length; i++)
                {
                    // Update subways
                    if(this.BACKGROUND_OPTION === 0) this.instance.subways[i].update(delta);
                    else
                    {
                        // Get the delayed position
                        let delay = (this.clock.elapsedTime / 6) + this.instance.subwayDelays[i];
                        // Set the vehicle movement, considering the delayed position
                        if(i < 15) this.instance.subways[i].position.set(Math.cos(delay) * 251, 7.9, Math.sin(delay) * 251);
                        else this.instance.subways[i].position.set(Math.cos(-delay) * 257, 7.9, Math.sin(-delay) * 257);
                        // Keep looking at the center of the dome
                        this.instance.subways[i].lookAt(new THREE.Vector3(0, 7.9, 0));
                    }
                }
            }
            // If the animated vehicles array was instantiated
            if(this.instance.animatedVehicles instanceof Array)
            {
                // For each of the subways
                for(let i = 0; i < this.instance.animatedVehicles.length; i++)
                {
                    // Update subways
                    this.instance.animatedVehicles[i].update(delta);
                }
            }
            // If the bullets array was instantiated
            if(this.instance.bullets instanceof Array)
            {
                // For each of the bullets
                for(let i = 0; i < this.instance.bullets.length; i++)
                {
                    // Animate scaling effect
                    this.instance.bullets[i].scale.x += Math.cos(this.time.elapsed * 0.0015) * 0.0075;
                    this.instance.bullets[i].scale.y += Math.cos(this.time.elapsed * 0.0015) * 0.0075;
                }
            }
        }
    }

    // Private method called to dispose all NPCs
    #disposeNPCs()
    {
        // If the NPCs array was created
        if(this.instance.npcs instanceof Array)
        {
            // Dispose each of the NPCs created
            this.instance.npcs.forEach(npc =>
            {
                try { npc.destroy() } catch(e) { console.log(e) };
            });
            // Reset NPCs array
            this.instance.npcs.length = 0;
        }
    }

    // Private method called to dispose all subways
    #disposeSubways()
    {
        // If the subways array was created
        if(this.instance.subways instanceof Array)
        {
            // Dispose all subways
            this.instance.subways.forEach(subway =>
            {
                if(this.BACKGROUND_OPTION === 0) try { subway.destroy() } catch(e) { console.log(e) }
                else try { this.disposer.disposeElements(subway) } catch(e) { console.log(e) };
            });
            // Reset subways array
            this.instance.subways.length = 0;
        }

        // If the connection vehicles array was created
        if(this.instance.connectionVehicles instanceof Array)
        {
            // Dispose all vehicles
            this.instance.connectionVehicles.forEach(vehicle =>
            {
                try { gsap.killTweensOf(vehicle) } catch(e) { console.log(e) };
                try { this.disposer.disposeElements(vehicle) } catch(e) { console.log(e) };
            });
            this.instance.connectionVehicles.length = 0;
        }

        // If the animated vehicles array was created
        if(this.instance.animatedVehicles instanceof Array)
        {
            // For each of the animated vehicles
            this.instance.animatedVehicles.forEach(vehicle =>
            {
                // Destroy vehicle
                try { vehicle.destroy() } catch(e) { console.log(e) }
            });
            this.instance.animatedVehicles.length = 0;
        }
    }

    // Method called to dispose the dome elements
    #disposeDomeElements()
    {
        // Get the disposer class from the experience
        if(!this.disposer) this.disposer = this.experience.disposer;

        // If the bullets array was created
        if(this.instance.bullets instanceof Array)
        {
            // Dispose all bullets
            for(let i = 0; i < this.instance.bullets.length; i++)
            {
                // Stop the bullet animation
                try { gsap.killTweensOf(this.instance.bullets[i]) } catch(e) { console.log(e) };
                // Dispose the bullet
                try { this.disposer.disposeElements(this.instance.bullets[i]) } catch(e) { console.log(e) };
            }
            this.instance.bullets.length = 0;
        }
        
        // If the pavilions array was created
        if(this.instance.pavilions instanceof Array)
        {
            // Dispose all pavilions
            for(let i = 0; i < this.instance.pavilions.length; i++)
            {
                // Dispose the model
                try { this.disposer.disposeElements(this.instance.pavilions[i]) } catch(e) { console.log(e) };
            }
            this.instance.pavilions.length = 0;
        }
        
        // If the connections array was created
        if(this.instance.connections instanceof Array)
        {
            // Dispose all connections
            for(let i = 0; i < this.instance.connections.length; i++)
            {
                // Dispose the model
                try { this.disposer.disposeElements(this.instance.connections[i]) } catch(e) { console.log(e) };
            }
            this.instance.connections.length = 0;
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Stop clock
        this.clock.stop();
        this.clock = null;

        // Dispose the pavilion
        try { this.#disposeDomeElements() } catch(e) { console.log(e) };
        this.#disposeNPCs();
        this.#disposeSubways();

        if(this.BACKGROUND_OPTION !== 0) try { this.disposer.disposeElements(this.instance.ground) } catch(e) { console.log(e) };
        try { this.disposer.disposeElements(tubeGeometry) } catch(e) { console.log(e) };
        try { this.disposer.disposeElements(this.ground) } catch(e) { console.log(e) };
        try { this.disposer.disposeElements(this.instance.model) } catch(e) { console.log(e) };
        try { this.disposer.disposeElements(this.instance.clippingPlane) } catch(e) { console.log(e) };

        // Remove listeners
        try { this.minimap.off('switchDome') } catch(e) { console.log(e) };
        try { this.off('hoveringPavilion') } catch(e) { console.log(e) };
        try { this.off('stoppedHoveringPavilion') } catch(e) { console.log(e) };
        try { if(isMobile) this.pointer.off('pointerTouch') } catch(e) { console.log(e) };

        // Reset instance
        this.raycaster = null;
        this.hoveredElem = null;
        this.instance = null;
        isMobile = null;

        // Remove references
        this.disposer = null;
        this.scene = null;
        this.camera = null;
        this.composer = null;
        this.pointer = null;
        this.renderer = null;
        this.resources = null;
        this.environment = null;
        this.minimap = null;
        this.experience = null;
    }
}