export default class Colorize
{
    // Set constructor
    constructor()
    {
        // Create new instance
        this.hexcodes = {};

        // Set hexcodes
        this.#setHairColors();
        this.#setTopColors();
        this.#setBottomColors();
        this.#setShoesColors();
    }

    // Method called to get a random color for each of the customizable items
    randomize()
    {
        // Get a random hair color
        const hair = this.hexcodes.hair[ Math.floor( Math.random() * this.hexcodes.hair.length ) ];
        // Get a random shirt color
        const top = this.hexcodes.top[ Math.floor( Math.random() * this.hexcodes.top.length ) ];
        // Get a random pants color
        const bottom = this.hexcodes.bottom[ Math.floor( Math.random() * this.hexcodes.bottom.length ) ];
        // Get a random shoes color
        const shoes = this.hexcodes.shoes[ Math.floor( Math.random() * this.hexcodes.shoes.length ) ];

        // Create array of random colors
        const randomizedArray = [ hair, top, bottom, shoes ];

        // Return array of colors
        return randomizedArray;
    }

    // Private method called to set up the hair hexcodes
    #setHairColors()
    {
        // Create array of hair colors
        this.hexcodes.hair =
        [
            '0x280e12', // Dark brown
            '0x532802', // Medium brown
            '0x9e6730', // Light Brown
            '0x3d270d', // Dark blonde
            '0xcd832c', // Medium blonde
            '0xdfa345', // Light blonde
            '0x988880', // Grey
            '0xe4e1cd', // White
            '0xae3317', // Ginger
            '0x5f2618', // Dark ginger
            '0x620a15', // Dark red
            '0x171111'  // Black
        ];
    }

    // Private method called to set up the shirt hexcodes
    #setTopColors()
    {
        // Create array of shirt colors
        this.hexcodes.top =
        [
            '0xf22613', // Red
            '0xbf0f30', // Marsala
            '0x812b8c', // Purple
            '0x976df2', // Lilac
            '0xf24b99', // Pink
            '0xf0470d', // Orange
            '0xf2a30f', // Golden
            '0xebf20c', // Yellow
            '0xa9bf04', // Yellow green
            '0x0aa605', // Green
            '0x1a90d9', // Sky blue
            '0x32dba2'  // Turquoise
        ];
    }

    // Private method called to set up the pants hexcodes
    #setBottomColors()
    {
        // Create array of pants colors
        this.hexcodes.bottom =
        [
            '0x1b1b1b',
            '0x292828',
            '0x0f2330',
            '0x38332c',
            '0x3b393a',
            '0x4c4842',
            '0x595959',
            '0x3b3c40',
            '0x384954',
            '0x453d32',
            '0x8c8368',
            '0x453d32'
        ];
    }

    // Private method called to set up the shoes hexcodes
    #setShoesColors()
    {
        // Create array of shoes colors
        this.hexcodes.shoes =
        [
            '0x0e0e0e',
            '0x151414',
            '0x081218',
            '0x1c1a16',
            '0x1e1d1d',
            '0x262421',
            '0x2d2d2d',
            '0x1e1e20',
            '0x1c252a',
            '0x272f31',
            '0x464234',
            '0x231f19'
        ];
    }

    // Method propagated by the experience to destroy this instance
    destroy()
    {
        // Reset arrays
        this.hexcodes.hair.length = 0;
        this.hexcodes.top.length = 0;
        this.hexcodes.bottom.length = 0;
        this.hexcodes.shoes.length = 0;

        // Remove instance
        this.hexcodes = null;
    }
}