export default [
    {
        name: 'environmentBackground',
        type: 'cubeTexture',
        path:
        [
            'environment/cub_px.png',
            'environment/cub_nx.png',
            'environment/cub_py.png',
            'environment/cub_ny.png',
            'environment/cub_pz.png',
            'environment/cub_nz.png'
        ]
    },
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'environment/envMap/test_px.png',
            'environment/envMap/test_nx.png',
            'environment/envMap/test_py.png',
            'environment/envMap/test_ny.png',
            'environment/envMap/test_pz.png',
            'environment/envMap/test_nz.png'
        ]
    },
    {
        name: 'environmentBackground_day',
        type: 'cubeTexture',
        path:
        [
            'environment/day/day_px.png',
            'environment/day/day_nx.png',
            'environment/day/day_py.png',
            'environment/day/day_ny.png',
            'environment/day/day_pz.png',
            'environment/day/day_nz.png'
        ]
    },
    {
        name: 'environmentBackground_night',
        type: 'cubeTexture',
        path:
        [
            'environment/night/night_px.png',
            'environment/night/night_nx.png',
            'environment/night/night_py.png',
            'environment/night/night_ny.png',
            'environment/night/night_pz.png',
            'environment/night/night_nz.png'
        ]
    },
    {
        name: 'sala_reuniao',
        type: 'gltfModel',
        path: 'models/meetingRoom/sala-reuniao.gltf'
    },
    {
        name: 'robot',
        type: 'gltfModel',
        path: 'models/robot/robot.gltf'
    },
    {
        name: 'aim',
        type: 'texture',
        path: 'images/icons/aim.png'
    },
    {
        name: 'muted',
        type: 'texture',
        path: 'images/icons/muted.png'
    },
    {
        name: 'talking',
        type: 'texture',
        path: 'images/icons/talking.png'
    },
    {
        name: 'blocked',
        type: 'texture',
        path: 'images/icons/blocked.png'
    },
    {
        name: 'recIcon',
        type: 'texture',
        path: 'images/icons/rec.png'
    },
    {
        name: 'handUpIcon',
        type: 'texture',
        path: 'images/icons/handUpIcon.png'
    }
]