import * as THREE from "three";

import Experience from "../Experience.js";

export default class Environment
{
    // Set constructor
    constructor(BACKGROUND_OPTION)
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;

        // Set environment map intensity
        this.envMapIntensity = 0.3;
        // Get the background option
        this.BACKGROUND_OPTION = BACKGROUND_OPTION;
    }

    // Method called to create the ambient light
    setAmbientLight()
    {
        // Set ambient light
        this.ambientLight = new THREE.AmbientLight(0xffffff, 1);
        // Add to scene
        if(!this.experience.INTERRUPTED) this.scene.add(this.ambientLight);
    }

    // Method called to create the hemisphere light
    setHemisphereLight()
    {
        // Set hemisfere light
        if(this.BACKGROUND_OPTION !== 2) this.hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x556580, 1);
        else if(this.BACKGROUND_OPTION === 2) this.hemisphereLight = new THREE.HemisphereLight(0xB695f7, 0x2b3950, 1);
        // Add to scene
        if(!this.experience.INTERRUPTED) this.scene.add(this.hemisphereLight);
    }

    // Method called to create the directional light
    setDirectionalLight()
    {
        // Set directional light
        this.directionalLight = new THREE.DirectionalLight(0xffffff, 1.8);
        this.directionalLight.position.set(4, 100, 4);

        // Set shadow camera
        this.directionalLight.castShadow = true;
        this.directionalLight.shadow.mapSize.width = 1024;
        this.directionalLight.shadow.mapSize.height = 1024;
        // Set shadow camera dimensions
        this.directionalLight.shadow.camera.top = 500;
        this.directionalLight.shadow.camera.right = 500;
        this.directionalLight.shadow.camera.bottom = -500;
        this.directionalLight.shadow.camera.left = -500;
        // Set shadow camera planes
        this.directionalLight.shadow.camera.near = 1;
        this.directionalLight.shadow.camera.far = 200;

        // Erase shadow acne
        this.directionalLight.shadow.bias = -0.03;

        // Add to scene
        if(!this.experience.INTERRUPTED) this.scene.add(this.directionalLight);
    }

    // Method called to create the point light
    setPointLight()
    {
        // Set point light
        this.pointLight = new THREE.PointLight(0xFfdf4f, 300);
        this.pointLight.position.y = 150;
        // Add to scene
        if(!this.experience.INTERRUPTED) this.scene.add(this.pointLight);
    }

    // Method called to set up the environment background
    setEnvironmentBackground()
    {
        // If there isn't a background
        if(!this.envBackground)
        {
            // Create environment background
            this.envBackground = this.resources.items.environmentBackground;
            this.envBackground.encoding = THREE.sRGBEncoding;
        }
        
        // Set scene background
        if(!this.experience.INTERRUPTED) this.scene.background = this.envBackground;
    }

    // Method called to set up the environment map
    setEnvironmentMap()
    {
        // If there isn't an environment map
        if(!this.envMapTexture)
        {
            // Create environment map
            this.envMapTexture = this.resources.items.environmentMapTexture;
            this.envMapTexture.mapping = THREE.CubeRefractionMapping;
        }

        // Set scene background
        if(!this.experience.INTERRUPTED) this.scene.environment = this.envMapTexture;

        // If there is a hemisphere light, tone their intensity down
        if(this.hemisphereLight) this.hemisphereLight.intensity = 0.3;
    }

    // Method propagated by the experience to destroy this instance
    destroy()
    {
        // Reset variable
        this.envMapIntensity = null;

        // If an ambient light was created
        if(this.ambientLight)
        {
            try
            {
                // Remove from the scene
                this.scene.remove(this.ambientLight);
                this.ambientLight = null;
            }
            catch(e) { console.log(e) };
        }
        // If a hemisphere light was created
        if(this.hemisphereLight)
        {
            try
            {
                // Remove from the scene
                this.scene.remove(this.hemisphereLight);
                this.hemisphereLight = null;
            }
            catch(e) { console.log(e) };
        }
        // If a directional light was created
        if(this.directionalLight)
        {
            try
            {
                // Remove from the scene
                this.scene.remove(this.directionalLight);
                this.directionalLight = null;
            }
            catch(e) { console.log(e) };
        }
        // If a point light was created
        if(this.pointLight)
        {
            try
            {
                // Remove from the scene
                this.scene.remove(this.pointLight);
                this.pointLight = null;
            }
            catch(e) { console.log(e) };
        }
        // If an environment background was created
        if(this.envBackground)
        {
            try
            {
                // Remove from the scene
                this.scene.background = null;
                this.envBackground = null;
            }
            catch(e) { console.log(e) };
        }
        // If an environment map texture was created
        if(this.envMapTexture)
        {
            try
            {
                // Remove from the scene
                this.scene.environment = null;
                this.envMapTexture = null;
            }
            catch(e) { console.log(e) };
        }

        // Remove references
        this.scene = null;
        this.resources = null;
        this.experience = null;
    }
}