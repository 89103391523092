import { Vector2 } from "three";

import Experience from "../Experience.js";
import EventEmitter from './EventEmitter.js';

let mouseDownHandler, mouseMoveHandler, mouseUpHandler, touchHandler;

let pointerMoved, startPos;
let isMobile;

export default class Pointer extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.canvas = this.experience.canvas;
        this.sizes = this.experience.sizes;
        this.audio = this.experience.audio;
        this.stand = this.experience.stand;

        pointerMoved = false;
        startPos = new Vector2(0, 0);

        // Set pointer
        if(!this.experience.INTERRUPTED) this.setPointer();
    }

    // Method called to set up the pointer
    setPointer()
    {
        // Mouse position
        this.mouse = new Vector2(Infinity, Infinity);

        // Get the mobile detector class from the experience
        this.mobileDetector = this.experience.mobileDetector;
        // Get if the device is a mobile
        isMobile = this.mobileDetector.isMobile;
        // Remove reference
        this.mobileDetector = null;

        // If the device is a desktop
        if(isMobile === false)
        {
            // Get the camera class from the experience
            this.camera = this.experience.camera;

            // Mouse status
            this.mouseMove = false;
            // Set click listener
            if(!this.experience.INTERRUPTED) this.#setClickListeners();
        }
        // If the device is a mobile
        else
        {
            // Set touch listeners
            if(!this.experience.INTERRUPTED) this.#setTouchListeners();
        }
    }

    // Private method called to set up listeners related to clicks
    #setClickListeners()
    {
        // Mouse down event handler
        mouseDownHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the experience has loaded
                if(this.experience.SCENE_LOADED === true)
                {
                    // If this is the first click
                    if(this.audio.firstClick === false)
                    {
                        // Play audio if not muted
                        this.audio.firstClick = true;
                        if(this.audio.AUDIO_MUTED === false) this.audio.pauseOrResumeSoundtrack(true);
                    }

                    // If the click target is the canvas
                    if(e.target === this.canvas)
                    {
                        // Calculate normalized mouse coordinates relative to canvas size
                        const rect = this.canvas.getBoundingClientRect();
                        startPos.x = ((e.clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                        startPos.y = -((e.clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;
                    }
                }
            }
        };

        // Mouse move event handler
        mouseMoveHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the experience has loaded and the mouse target is the canvas
                if(this.experience.SCENE_LOADED === true && e.target === this.canvas)
                {
                    // Set mouse status as moving
                    this.mouseMove = true;

                    // Calculate normalized mouse coordinates relative to canvas size
                    const rect = this.canvas.getBoundingClientRect();
                    this.mouse.x = ((e.clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                    this.mouse.y = -((e.clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;
                }
            }
        };

        // Mouse up event handler
        mouseUpHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the experience has loaded and the mouse target is the canvas
                if(this.experience.SCENE_LOADED === true && e.target === this.canvas)
                {
                    // Calculate normalized mouse coordinates relative to canvas size
                    const rect = this.canvas.getBoundingClientRect();
                    this.mouse.x = ((e.clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                    this.mouse.y = -((e.clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;

                    // If the scene is loaded and the mouse click hasn't moved
                    if(this.equals(startPos, this.mouse, 0.02) === true)
                    {
                        // Update camera
                        this.camera.renderCamera.updateMatrixWorld();

                        // Get the player class if the current reference is empty
                        if(!this.stand) this.stand = this.experience.stand;

                        // If the user is hovering a stand during the click
                        if(this.stand.instance.hoveredObject !== undefined && this.stand.instance.insideVideoScreen === false)
                        {
                            // Get object name
                            let name = this.stand.instance.hoveredObject;
                            const split = name.split('_');

                            // If the hovered object is an image screen
                            if(name.includes('imageScreen'))
                            {
                                name = 'image_screen_' + split[1];
                            }
                            // If the hovered object is a video screen
                            else if(name.includes('videoScreen'))
                            {
                                name = 'video_screen_' + split[1];
                            }

                            // Data to be sent
                            const data = { 'stand_id': this.stand.instance.id, 'object_name': name };

                            // If the user is interacting with a video screen
                            if(name.includes('video_screen'))
                            {
                                // Set camera interaction
                                this.stand.videoScreenInteraction(split[1]);

                                // Wait the time of the animation
                                setTimeout(() =>
                                {
                                    // Trigger event warning that this stand was clicked
                                    const standClickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
                                    window.novvaC4.eventTarget.dispatchEvent(standClickEvent);
                                }, 600);
                            }
                            // If the user isn't interacting with a video screen
                            else
                            {
                                // Trigger event warning that this stand was clicked
                                const standClickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
                                window.novvaC4.eventTarget.dispatchEvent(standClickEvent);
                            }

                            // Set cursor to pointer
                            document.body.style.cursor = "default";
                        }
                    }
                }
            }
        };

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Listen for mouse clicks
            document.addEventListener('mousedown', mouseDownHandler);
            // Listen for mouse movements
            document.addEventListener('mousemove', mouseMoveHandler);
            // Listen for mouse releases
            document.addEventListener('mouseup', mouseUpHandler);
        }
    }

    // Private method called to set up the touch listeners
    #setTouchListeners()
    {
        // Touch event handler
        touchHandler = (e) =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the scene is loaded
                if(this.experience.SCENE_LOADED === true)
                {
                    // If this is the first touch
                    if(this.audio.firstClick === false)
                    {
                        // Play audio if not muted
                        this.audio.firstClick = true;
                        if(this.audio.AUDIO_MUTED === false) this.audio.pauseOrResumeSoundtrack(true);
                    }

                    // If the touch target is the canvas
                    if(e.target === this.canvas)
                    {
                        // If the touch started
                        if(e.type === "touchstart")
                        {
                            // Calculate normalized mouse coordinates relative to canvas size
                            const rect = this.canvas.getBoundingClientRect();
                            this.mouse.x = ((e.touches[0].clientX - rect.left) / this.sizes.canvasWidth) * 2 - 1;
                            this.mouse.y = -((e.touches[0].clientY - rect.top) / this.sizes.canvasHeight) * 2 + 1;
                        }
                        // If the touch moved
                        else if(e.type === "touchmove")
                        {
                            // Set verifier to true
                            pointerMoved = true;
                        }
                        // If the touch ended
                        else if(e.type === "touchend")
                        {
                            // If the touch didn't move
                            if(pointerMoved === false)
                            {
                                // Get the stand class if the current reference is empty
                                if(!this.stand) this.stand = this.experience.stand;

                                // If the user isn't inside the TV
                                if(this.stand.instance.insideVideoScreen === false)
                                {
                                    // Trigger touch event
                                    this.trigger('pointerTouch');

                                    // If the user is touching an interactable object during the click
                                    if(this.stand.instance.hoveredObject !== undefined && this.stand.instance.insideVideoScreen === false)
                                    {
                                        // Get object name
                                        let name = this.stand.instance.hoveredObject;
                                        const split = name.split('_');

                                        // If the hovered object is an image screen
                                        if(name.includes('imageScreen'))
                                        {
                                            name = 'image_screen_' + split[1];
                                        }
                                        // If the hovered object is a video screen
                                        else if(name.includes('videoScreen'))
                                        {
                                            name = 'video_screen_' + split[1];
                                        }

                                        // Data to be sent
                                        const data = { 'stand_id': this.stand.instance.id, 'object_name': name };

                                        // If the user is interacting with a video screen
                                        if(name.includes('video'))
                                        {
                                            // Set camera interaction
                                            this.stand.videoScreenInteraction(split[1]);

                                            // Wait the time of the animation
                                            setTimeout(() =>
                                            {
                                                // Trigger event warning that this stand was clicked
                                                const standClickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
                                                window.novvaC4.eventTarget.dispatchEvent(standClickEvent);
                                            }, 600);
                                        }
                                        // If the user isn't interacting with a video screen
                                        else
                                        {
                                            // Trigger event warning that this stand was clicked
                                            const standClickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
                                            window.novvaC4.eventTarget.dispatchEvent(standClickEvent);
                                        }
                                    }
                                }
                                
                            }
                            // If the touch moved
                            else
                            {
                                // Reset variable
                                pointerMoved = false;
                            }
                        }
                    }
                }
            }
        };

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Listen for touches starting
            document.addEventListener('touchstart', touchHandler);
            // Listen for touches moving
            document.addEventListener('touchmove', touchHandler);
            // Listen for touches ending
            document.addEventListener('touchend', touchHandler);
        }
    }

    // Method called to compare vectors and get if they are the same
    equals(firstValue, secValue, tolerance)
    {
        // If the tolerance factor isn't defined
        if(tolerance === undefined)
        {
            // Return literal sameness
            return ((firstValue.x === secValue.x) && (firstValue.y === secValue.y));
        }
        // If the tolerance factor is defined
        else
        {
            // Return if the vectors are the same within the tolerance
            return ((Math.abs(firstValue.x - secValue.x) < tolerance) && (Math.abs(firstValue.y - secValue.y) < tolerance));
        }
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Remove reference
        this.mouse = null;
        this.mouseMove = null;
        pointerMoved = null;
        startPos = null;

        // If the device is a desktop
        if(isMobile === false)
        {
            try
            {
                // Stop listening for mouse events
                document.removeEventListener('mousedown', mouseDownHandler);
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
            }
            catch(e) { console.log(e) };
        }
        // If the device is a mobile
        else
        {
            try
            {
                // Stop listening for touch events
                document.removeEventListener('touchstart', touchHandler);
                document.removeEventListener('touchmove', touchHandler);
                document.removeEventListener('touchend', touchHandler);
            }
            catch(e) { console.log(e) };
        }

        // Remove references
        isMobile = null;
        this.experience = null;
        this.canvas = null;
        this.sizes = null;
        this.audio = null;
        this.camera = null;
        this.stand = null;
    }
}