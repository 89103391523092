import { Scene } from "three";

import Sizes from "./Utils/Sizes.js";
import Time from "./Utils/Time.js";
import MobileDetector from "./Utils/MobileDetector.js";
import Disposer from "./Utils/Disposer.js";
import Resources from "./Utils/Resources.js";
import Colorize from "./Utils/Colorize.js";
import Camera from "./Camera.js";
import Audio from "./Audio.js";
import Renderer from "./Renderer.js";
import Composer from "./Composer.js";
import Pointer from "./Utils/Pointer.js";
import Environment from "./World/Environment.js";
import Backgrounds from "./World/Backgrounds.js";
import Stand from "./World/Stand.js";

import sources from "./sources.js";

// Global instance
let instance = null;
// Time variables
let delta, interval;

export default class Experience
{
    // Set constructor
    constructor(canvas, parameters)
    {
        // Return the existing instance
        if(instance) return instance;
        // Create a new instance if needed
        instance = this;

        delta = 0;
        interval = 1 / 30;

        // If the received element is a canvas, set the canvas
        if(canvas instanceof HTMLCanvasElement) this.canvas = canvas;
        // If no canvas was received
        else
        {
            // Create the canvas
            this.canvas = document.createElement("canvas");
            this.canvas.classList.add('webgl');
            this.canvas.id = 'webgl-experience';
            document.body.appendChild(this.canvas);
        }

        // Scene loaded verifier
        this.SCENE_LOADED = false;

        // Init parameters object
        this.parameters = {};

        // Set the assets path inside the project
        this.parameters.ASSETS_PATH = typeof parameters.ASSETS_PATH == 'undefined' ? '' : parameters.ASSETS_PATH;
        // Set the render quality option
        this.parameters.QUALITY = typeof parameters.QUALITY == 'undefined' ? 1 : parameters.QUALITY;
        if(this.parameters.QUALITY !== 0 && this.parameters.QUALITY !== 1 && this.parameters.QUALITY !== 2) this.parameters.QUALITY = 1;
        // Set the background option: 0 = 3D environment; 1 = blue environment; 2 = transparent background
        this.parameters.BACKGROUND_OPTION = typeof parameters.BACKGROUND_OPTION == 'undefined' ? 0 : parameters.BACKGROUND_OPTION;
        // Set the camera mode
        this.parameters.FIRST_PERSON_CAM = typeof parameters.FIRST_PERSON_CAM == 'undefined' ? true : parameters.FIRST_PERSON_CAM;
        // Set muted audio verifier
        this.parameters.AUDIO_MUTED = typeof parameters.AUDIO_MUTED == 'undefined' ? false : parameters.AUDIO_MUTED;

        // Set persistens utils
        this.#setPersistentUtils();
        // Set utils
        this.#setUtils();
    }

    // Method called to set up the persistent classes
    #setPersistentUtils()
    {
        // Load resources
        if(!this.INTERRUPTED) this.resources = new Resources(sources, this.parameters.ASSETS_PATH);
    }

    // Method called to set up the needed classed
    #setUtils()
    {
        // Setup utils
        if(!this.INTERRUPTED) this.sizes = new Sizes(this.canvas);
        if(!this.INTERRUPTED) this.time = new Time();
        if(!this.INTERRUPTED) this.mobileDetector = new MobileDetector();
        if(!this.INTERRUPTED) this.disposer = new Disposer();

        // Create scene
        if(!this.INTERRUPTED) this.scene = new Scene();
        if(!this.INTERRUPTED) this.audio = new Audio(this.parameters.AUDIO_MUTED);
        // Setup environment
        if(!this.INTERRUPTED) this.environment = new Environment();
        // Set background
        if(!this.INTERRUPTED) this.backgrounds = new Backgrounds(this.parameters.BACKGROUND_OPTION);

        // Set colorizer
        if(!this.INTERRUPTED) this.colorize = new Colorize();

        // Setup camera and rendering
        if(!this.INTERRUPTED) this.camera = new Camera(this.parameters.QUALITY, this.parameters.BACKGROUND_OPTION, this.parameters.FIRST_PERSON_CAM);
        if(!this.INTERRUPTED) this.renderer = new Renderer();
        if(!this.INTERRUPTED) this.composer = new Composer(this.parameters.BACKGROUND_OPTION);
        if(!this.INTERRUPTED) this.pointer = new Pointer();

        // Setup stand
        if(!this.INTERRUPTED) this.stand = new Stand();

        // Set listeners
        this.#setListeners();
    }

    // Private method called to set all the listeners
    #setListeners()
    {
        // Listen for resize event
        this.sizes.on('resize', () =>
        {
            // If the experience wasn't interrupted
            if(!this.INTERRUPTED) this.resize();
        });

        // Time tick event
        this.time.on('tick', () =>
        {
            // If the experience wasn't interrupted
            if(!this.INTERRUPTED)
            {
                // Update delta
                delta += this.time.delta / 1000;
                // If the delta time surpassed the interval needed
                if(delta > interval)
                {
                    // If the scene is loaded
                    if(this.SCENE_LOADED === true)
                    {
                        // Call local update method
                        this.update();
                    }
                    
                    // Reset delta
                    delta = delta % interval;
                }
            }
        });

        // Low FPS event
        this.time.on('lowFPSAlert', () =>
        {
            // If the experience wasn't interrupted
            if(!this.INTERRUPTED)
            {
                // If the scene is loaded
                if(this.SCENE_LOADED === true)
                {
                    // Trigger warning event
                    window.novvaC4.eventTarget.dispatchEvent(new CustomEvent('lowFPSAlert'));
                }
            }
        });

        // Listen for loaded resources event
        this.backgrounds.on('loaded3DScene', () =>
        {
            // If the experience wasn't interrupted
            if(!this.INTERRUPTED)
            {
                // Set verification variable to true
                this.SCENE_LOADED = true;
                // Update shadow map
                this.renderer.instance.shadowMap.needsUpdate = true;

                // Data to be sent
                const data = { 'scene_id': 4 }

                // Trigger event once to signal that all the content has been loaded
                const loaded3DSceneEvent = new CustomEvent( 'loaded3DScene', { detail: data } );
                window.novvaC4.eventTarget.dispatchEvent(loaded3DSceneEvent);
            }
        });

        // Listen to when the stand is ready
        this.stand.on('loaded3DModel', () =>
        {
            // If the experience wasn't interrupted
            if(!this.INTERRUPTED)
            {
                // Data to be sent
                const data = {
                    'stand_id': this.stand.instance.info.stand_id,
                    'stand_style': this.stand.instance.info.stand_style
                }

                // Trigger event once to signal that the stand has been loaded
                const loaded3DModelEvent = new CustomEvent( 'loaded3DModel', { detail: data } );
                window.novvaC4.eventTarget.dispatchEvent(loaded3DModelEvent);
            }
        });
    }

    // Method called externally to change the graphics quality
    updateRenderQuality(id)
    {
        try
        {
            // If the id is not valid, throw an exception
            if(id !== 0 && id !== 1 && id !== 2) throw 'Render quality value is not a valid number (0, 1 or 2): ' + id;

            // If the chosen quality isn't the same as the current quality
            if(this.parameters.QUALITY !== id)
            {
                // Set new quality
                this.parameters.QUALITY = id;
                
                this.composer.clearComposer();
                // Generate new composer
                this.composer.setComposer();

                // If the render quality is medium or high
                if(this.parameters.QUALITY > 0)
                {
                    // Set environment map
                    this.environment.setEnvironmentMap();
                    // Set background and stand environment map intensity
                    this.backgrounds.updateMaterialsEnvMap();
                    this.stand.updateMaterialsEnvMap();
                    // Set hemisphere light intensity
                    this.environment.hemisphereLight.intensity = 0.1;
                }
                // If the render quality is low
                else
                {
                    // Remove environment map
                    this.scene.environment = null;
                    // Reset hemisphere light intensity
                    this.environment.hemisphereLight.intensity = 1;
                }
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Method called when the screen is resized
    resize()
    {
        // Propagate to the classes that need to be updated
        this.camera.resize();
        this.renderer.resize();
        this.composer.resize();
    }

    // Method called by the tick event that propagates it to the classes that need updates every tick
    update()
    {
        // Update the sizes handler
        if(!this.INTERRUPTED) this.sizes.resizeHandler(this.canvas);
        if(!this.INTERRUPTED) this.camera.update();
        if(!this.INTERRUPTED) this.stand.update();
        if(!this.INTERRUPTED) this.composer.update();
    }

    // Method called to restart the scene after a destroy
    restart(parameters)
    {
        // Stop interruption
        this.INTERRUPTED = undefined;

        // Set the assets path inside the project
        this.parameters.ASSETS_PATH = typeof parameters.ASSETS_PATH == 'undefined' ? '' : parameters.ASSETS_PATH;
        // Set the background option: 0 = 3D environment; 1 = blue environment; 2 = transparent background
        this.parameters.BACKGROUND_OPTION = typeof parameters.BACKGROUND_OPTION == 'undefined' ? 0 : parameters.BACKGROUND_OPTION;
        // Set the camera mode
        this.parameters.FIRST_PERSON_CAM = typeof parameters.FIRST_PERSON_CAM == 'undefined' ? true : parameters.FIRST_PERSON_CAM;
        // Set muted audio verifier
        this.parameters.AUDIO_MUTED = typeof parameters.AUDIO_MUTED == 'undefined' ? false : parameters.AUDIO_MUTED;

        // Set utils that werw temporarily destroyed
        this.#setUtils();
        // Trigger loaded resources
        this.resources.trigger('loadedResources');
    }

    // Method called to destroy the scene
    destroy()
    {
        // Set scene as interrupted and unloaded
        this.INTERRUPTED = true;
        this.SCENE_LOADED = false;

        // Exit pointer lock
        document.exitPointerLock();

        // Stop listening for the events
        try { this.sizes.off('resize') } catch(e) { console.log(e) };
        try { this.time.off('tick') } catch(e) { console.log(e) };
        try { this.time.off('lowFPSAlert') } catch(e) { console.log(e) };
        try { this.backgrounds.off('loaded3DScene') } catch(e) { console.log(e) };
        try { this.stand.off('loaded3DModel') } catch(e) { console.log(e) };

        // Propagate the destroy method
        try { this.pointer.destroy() } catch(e) { console.log(e) };
        try { this.environment.destroy() } catch(e) { console.log(e) };
        try { this.backgrounds.destroy() } catch(e) { console.log(e) };
        try { this.colorize.destroy() } catch(e) { console.log(e) };
        try { this.camera.destroy() } catch(e) { console.log(e) };
        try { this.audio.destroy() } catch(e) { console.log(e) };
        try { this.composer.destroy() } catch(e) { console.log(e) };
        try { this.stand.destroy() } catch(e) { console.log(e) };
        try { this.sizes.destroy() } catch(e) { console.log(e) };
        try { this.time.destroy() } catch(e) { console.log(e) };
        try { this.mobileDetector.destroy() } catch(e) { console.log(e) };

        try
        {
            // Clear renderer
            this.renderer.instance.renderLists.dispose();
            this.renderer.instance.clear();
            // Dispose the renderer instance
            this.renderer.instance.dispose();
            this.renderer.destroy();
        }
        catch(e) { console.log(e) };

        // Dispose scene
        try { this.disposer.disposeElements(this.scene) } catch(e) { console.log(e) };
        try { this.scene.clear() } catch(e) { console.log(e) };

        // Remove utils
        this.sizes = null;
        this.time = null;
        this.mobileDetector = null;
        this.camera = null;
        this.audio = null;
        this.renderer = null;
        this.composer = null;
        this.pointer = null;
        this.environment = null;
        this.backgrounds = null;
        this.colorize = null;
        this.stand = null;

        // Reset scene
        this.scene = null;
    }

    // Method called to wipe the mempory clear
    wipe()
    {
        // Destroy the scene
        this.destroy();

        try
        {
            // Dispose loaded assets
            Object.keys(this.resources.items).forEach((item) =>
            {
                try { this.disposer.disposeElements(this.resources.items[item], true) } catch(e) { console.log(e) };
            });
        }
        catch(e) { console.log(e) };
        
        try { this.resources.destroy() } catch(e) { console.log(e) };
        try { this.disposer.destroy() } catch(e) { console.log(e) };
        
        // Remove utils
        this.disposer = null;
        this.resources = null;

        // Reset variables
        this.canvas = null;
        // Reset instance
        instance = null;
    }
}