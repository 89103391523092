import { PerspectiveCamera, Vector2, Vector3 } from "three";

import Experience from "./Experience.js";
import EventEmitter from './Utils/EventEmitter.js';

let isMobile;

export default class Camera extends EventEmitter
{
    // Set constructor
    constructor(FIRST_PERSON_CAM)
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.sizes = this.experience.sizes;
        this.environment = this.experience.environment;
        this.scene = this.experience.scene;
        this.canvas = this.experience.canvas;

        // Set parameters
        this.FIRST_PERSON_CAM = FIRST_PERSON_CAM;

        // Set camera instances
        this.setInstances();
    }

    // Method called to create and set up the cameras
    setInstances()
    {
        // Get the mobile detector class from the experience
        this.mobileDetector = this.experience.mobileDetector;
        // Get if the device is a mobile
        isMobile = this.mobileDetector.isMobile;
        // Remove reference
        this.mobileDetector = null;

        // Create first person perspective camera
        this.fpCamera = new PerspectiveCamera(50, this.sizes.width / this.sizes.height, 0.1, 2000);
        this.fpCamera.rotation.order = 'YXZ';
        this.fpCamera.position.set(55, 1.5, 0);
        this.fpCamera.lookAt(new Vector3(0, 1.5, 0));
        this.fpCamera.name = "FistPersonCamera";

        // Create third person perspective camera
        this.tpCamera = new PerspectiveCamera(45, this.sizes.width / this.sizes.height, 0.1, 2000);
        this.tpCamera.position.set(70, 70, 0);
        this.tpCamera.name = "ThirdPersonCamera";
        this.tpCamera.lookAt(new Vector3(10, 0, 0));

        // Get camera center
        this.cameraCenter = new Vector2(this.tpCamera.position.x, this.tpCamera.position.z);

        // Add cameras to scene
        if(!this.experience.INTERRUPTED) this.scene.add(this.fpCamera, this.tpCamera);

        // Set main camera as the first person camera
        if(this.FIRST_PERSON_CAM === true) this.renderCamera = this.fpCamera;
        // Set main camera as the third person camera
        else this.renderCamera = this.tpCamera;

        // If the device is a mobile
        if(isMobile === true)
        {
            if(!this.experience.INTERRUPTED)
            {
                // Get buttons
                const switchButton = document.getElementById('switch-camera-mode');
                // Listen for clicks
                switchButton.onclick = () =>
                {
                    // Switch camera event
                    if(!this.experience.INTERRUPTED) switchCamera();
                };

                // Get the joystick class from the experience
                this.joystick = this.experience.joystick;
            }
        }
    }

    // Method called to switch between the two cameras
    switchCamera(firstPerson)
    {
        // Get the pointer class if the current reference is empty
        if(!this.pointer) this.pointer = this.experience.pointer;

        // Switch from first to third person camera
        if(this.renderCamera === this.tpCamera || firstPerson === true)
        {
            if(this.FIRST_PERSON_CAM === false)
            {
                // Set current camera to first person camera
                this.renderCamera = this.fpCamera;
                // Set the environment background to the dome landscape
                this.environment.setEnvironmentBackground();

                // Set global variable used by the C4 to true
                this.FIRST_PERSON_CAM = true;

                // If the device is a desktop, request pointer lock
                if(isMobile === false) this.canvas.requestPointerLock();
                // If the device is a mobile
                else
                {
                    // Show the aim
                    this.pointer.aim.style.display = "";
                    // Display joysticks
                    this.joystick.movementJoystick.container.style.display = '';
                    this.joystick.cameraJoystick.container.style.display = '';
                }

                if(!this.renderer) this.renderer = this.experience.renderer;
                // Update shadow map
                this.renderer.instance.shadowMap.needsUpdate = true;

                // Data to be sent
                const data = { 'first_person_mode': this.FIRST_PERSON_CAM };

                // Trigger event once to signal that the stand has been loaded
                const cameraModeEvent = new CustomEvent( 'switchingCameraMode', { detail: data } );
                window.novvaC3.eventTarget.dispatchEvent(cameraModeEvent);

                // Trigger camera switch
                this.trigger('switchCamera');
            }
        }
        // Switch from third to first person camera
        else if(this.renderCamera === this.fpCamera || firstPerson === false)
        {
            if(this.FIRST_PERSON_CAM === true)
            {
                // Set current camera to third person camera
                this.renderCamera = this.tpCamera;
                // Set the environment background to none
                this.scene.background = null;

                // Set global variable used by the C4 to false
                this.FIRST_PERSON_CAM = false;

                // If the device is a desktop, request pointer to exit lock
                if(isMobile === false) document.exitPointerLock();
                // If the device is a mobile
                else
                {
                    // Hide the aim
                    this.pointer.aim.style.display = "none";
                    // Don't display joysticks
                    this.joystick.movementJoystick.container.style.display = 'none';
                    this.joystick.cameraJoystick.container.style.display = 'none';
                }

                if(!this.renderer) this.renderer = this.experience.renderer;
                // Update shadow map
                this.renderer.instance.shadowMap.needsUpdate = true;

                // Data to be sent
                const data = { 'first_person_mode': this.FIRST_PERSON_CAM };

                // Trigger event once to signal that the stand has been loaded
                const cameraModeEvent = new CustomEvent( 'switchingCameraMode', { detail: data } );
                window.novvaC3.eventTarget.dispatchEvent(cameraModeEvent);

                // Trigger camera switch
                this.trigger('switchCamera');
            }
        }
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Update first person camera aspect
        this.fpCamera.aspect = this.sizes.width / this.sizes.height;
        this.fpCamera.updateProjectionMatrix();

        // Update third person camera aspect
        this.tpCamera.aspect = this.sizes.width / this.sizes.height;
        this.tpCamera.updateProjectionMatrix();

        // Update the render camera aspect
        this.renderCamera.updateProjectionMatrix();
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Reset variables
        this.fpCamera = null;
        this.tpCamera = null;
        this.renderCamera = null;
        this.cameraCenter = null;
        this.FIRST_PERSON_CAM = null;

        try
        {
            // If the device is a mobile, remove onclick events
            if(isMobile === true) document.getElementById('switch-camera-mode').removeAttribute("onclick");
        }
        catch(e) { console.log(e) };

        // Remove references
        this.experience = null;
        this.sizes = null;
        this.environment = null;
        this.canvas = null;
        if(this.pointer) this.pointer = null;
        if(this.joystick) this.joystick = null;
    }
}