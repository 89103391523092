import Experience from './Experience/Experience';

// Create encapsulated object for this experience
if(!window.novvaC3) window.novvaC3 = {};
// Create encapsulated event target
if(!window.novvaC3.eventTarget) window.novvaC3.eventTarget = new EventTarget();

// Experience class instance
let _experience = null;



// Set the render quality
function changeRenderQuality(id)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Change quality
        _experience.updateRenderQuality(id);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC3.changeRenderQuality = changeRenderQuality;



// Set the camera mode
function changeCameraMode(firstPerson)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Change the camera mode
        _experience.camera.switchCamera(firstPerson);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC3.changeCameraMode = changeCameraMode;



// Global function called to change the current pavilion section
function switchSection(id, npcsQuantity)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Change pavilion section
        _experience.pavilion.switchSection(id, npcsQuantity);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC3.switchSection = switchSection;



// Global function called to set a new audio track to the existing soundtrack
function changeAudioTrack(id, url)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Change the audio track
        _experience.audio.changeAudioTrack(id, url);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC3.changeAudioTrack = changeAudioTrack;



// Global function called to pause or resume the ambient song
function pauseOrResumeSoundtrack(id, bool)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Pause or resume the song
        _experience.audio.pauseOrResumeSoundtrack(id, bool);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC3.pauseOrResumeSoundtrack = pauseOrResumeSoundtrack;



// Global function called to set the ambient soundtrack volume
function setSoundtrackVolume(id, value)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // If all the volumes must be changed
        if(id === 0)
        {
            // Set the soundtrack volume
            _experience.audio.setSoundtrackVolume(id, value);
            // Set the footsteps volume
            _experience.setFootstepsVolume(value);
        }
        // If only the soundtrack audios must be changed
        else if(id > 0 && id < 3)
        {
            // Set the soundtrack volume
            _experience.audio.setSoundtrackVolume(id, value);
        }
        // If only the footsteps volume must be changed
        else if(id === 3)
        {
            // Set the footsteps volume
            _experience.setFootstepsVolume(value);
        }
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC3.setSoundtrackVolume = setSoundtrackVolume;



// Global function called to customize the NPC colors
function setCustomNPCColors(type, array)
{
    // Switch the type of colors being customized
    switch(type)
    {
        // Set hair colors
        case 0:
            _experience.colorize.setCustomHairColors(array);
        break;
        // Set top colors
        case 1:
            _experience.colorize.setCustomTopColors(array);
        break;
        // Set bottom colors
        case 2:
            _experience.colorize.setCustomBottomColors(array);
        break;
        // Set shoes colors
        case 3:
            _experience.colorize.setCustomShoesColors(array);
        break;
        // Set all colors
        case 4:
            _experience.colorize.setCustomHairColors(array[0]);
            _experience.colorize.setCustomTopColors(array[1]);
            _experience.colorize.setCustomBottomColors(array[2]);
            _experience.colorize.setCustomShoesColors(array[3]);
        break;
    }
}
window.novvaC3.setCustomNPCColors = setCustomNPCColors;



// Global function called to update the pavilion
function updateObjectsByJSON(jsonObj, startingSection, playerPosition, playerRotation, npcsQuantity, maleNpcsPercentage, staticNpcsPercentage)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Update objects
        _experience.pavilion.updateObjectsByJSON(jsonObj, startingSection, playerPosition, playerRotation, npcsQuantity, maleNpcsPercentage, staticNpcsPercentage);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC3.updateObjectsByJSON = updateObjectsByJSON;



// Global function called to update the section stands
function updateStandsByJSON(jsonObj)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Update objects
        _experience.pavilion.updateStandsByJSON(jsonObj);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC3.updateStandsByJSON = updateStandsByJSON;



// Destroy the experience, but maintain persistend data
function destroyTheExperience()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";

        // Destroy the experience
        _experience.destroy();
        console.log('experience C3 destroyed')
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC3.destroyTheExperience = destroyTheExperience;



// Completely wipe the experience
function wipeTheExperience()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";

        // Destroy the experience
        _experience.wipe();
        _experience = null;
        console.log('experience C3 wiped')
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC3.wipeTheExperience = wipeTheExperience;



// Start the experience
function startTheExperience(parameters)
{
    try
    {
        // If the experience already started
        if(_experience !== null)
        {
            // If all the assets are loaded but the scene is null
            if(_experience.resources.loaded >= _experience.resources.toLoad && _experience.scene === null)
            {
                // Restart the experience
                _experience.restart(parameters);
                console.log('experience C3 restarted')
            }
            else throw "The Experience is already running";
        }
        else
        {
            // Create the experience
            _experience = new Experience(document.querySelector('canvas.webgl'), parameters);
            console.log('experience C3 created')
        }
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC3.startTheExperience = startTheExperience;



// Function called when the script is loaded
function loadedScript()
{
    // If there is an async init function
    if(window.novvaC3AsyncInit)
    {
        // Call function to init the experience
        window.novvaC3AsyncInit();
    }
    // If there isn't an async init function, log error
    else
    {
        console.log('The "novvaC3AsyncInit" function was not found.');
    }
}
loadedScript();