import { LinearFilter, RGBAFormat, WebGLRenderTarget } from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';

import Experience from "./Experience";

let isMobile;

export default class Composer
{
    // Set constructor
    constructor()
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.renderer = this.experience.renderer;

        // Set composer
        if(!this.experience.INTERRUPTED) this.setComposer();
    }

    // Method called to create and set up the composer
    setComposer()
    {
        // Create the render pass
        this.renderScene = new RenderPass( this.scene, this.camera.renderCamera );

        // Set rendering parameters
        let renderTargetParameters = { minFilter: LinearFilter, magFilter: LinearFilter, format: RGBAFormat, stencilBuffer: true };
        let renderTarget = new WebGLRenderTarget(this.sizes.width, this.sizes.height, renderTargetParameters);
        // Create the composer
        this.instance = new EffectComposer( this.renderer.instance, renderTarget );

        // Set size and pixel ratio
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);

        // Add render pass to the composer
        if(!this.experience.INTERRUPTED) this.instance.addPass( this.renderScene );

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            this.#setGammaCorrectionPass();
            // Add gamma correction pass to the composer
            this.instance.addPass( this.gammaCorrectionPass );
        }

        // Add FXAA pass to the composer if the render quality is medium or high
        if(this.experience.parameters.QUALITY > 0)
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Get the mobile detector class from the experience
                this.mobileDetector = this.experience.mobileDetector;
                // Get if the device is a mobile
                isMobile = this.mobileDetector.isMobile;
                // Remove reference
                this.mobileDetector = null;
                
                // If the device is not a mobile
                if(isMobile === false)
                {
                    this.#setFXAAPass();
                    // Add FXAA pass to the composer
                    this.instance.addPass( this.fxaaPass );
                }
            }
        }
    }

    // Method called to clear all the passes
    clearComposer()
    {
        // If the passes array was created
        if(this.instance.passes instanceof Array)
        {
            // For each of the composer passes
            this.instance.passes.forEach(pass =>
            {
                // Remove pass
                try { this.instance.removePass( pass ) } catch(e) { console.log(e) };
            });
        }
    }

    // Private method called to set up the Gamma Correction pass
    #setGammaCorrectionPass()
    {
        // Set gamma correction pass for color balancing
        this.gammaCorrectionPass = new ShaderPass( GammaCorrectionShader );
        this.gammaCorrectionPass.material.name = "GammaCorrectionPass";
    }

    // Private method called to set up the FXAA pass
    #setFXAAPass()
    {
        // Set the fxaa pass configurations for antialias
        this.fxaaPass = new ShaderPass( FXAAShader );
        this.fxaaPass.material.name = "FXAAPass";
        this.fxaaPass.uniforms['resolution'].value.set(1 / (this.sizes.width * this.sizes.pixelRatio), 1 / (this.sizes.height * this.sizes.pixelRatio));
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Set new size and pixel ratio
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);

        // Update fxaa pass resolution
        if(this.fxaaPass) this.fxaaPass.uniforms['resolution'].value.set(1 / (this.sizes.width * this.sizes.pixelRatio), 1 / (this.sizes.height * this.sizes.pixelRatio));
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Render
        this.instance.render();
    }

    
    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Clear composer
        this.clearComposer();

        // Reset variables
        this.renderScene = null;
        this.instance = null;
        isMobile = null;

        // Remove passes references
        if(this.gammaCorrectionPass) this.gammaCorrectionPass = null;
        if(this.fxaaPass) this.fxaaPass = null;

        // Remove references
        this.experience = null;
        this.sizes = null;
        this.scene = null;
        this.camera = null;
        this.renderer = null;
    }
}