import { MeshBasicMaterial, DoubleSide, Raycaster, sRGBEncoding, Texture, Mesh, MeshStandardMaterial, PlaneGeometry, Vector3, Box3 } from "three";
import gsap from 'gsap';

import Experience from "../Experience.js";
import EventEmitter from "../Utils/EventEmitter.js";
import NPC from "./NPC.js";

let isMobile, delta;

export default class Stand extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.resources = this.experience.resources;
        this.disposer = this.experience.disposer;
        this.renderer = this.experience.renderer;
        this.environment = this.experience.environment;
        this.time = this.experience.time;
        this.pointer = this.experience.pointer;
        this.camera = this.experience.camera;

        // Get the mobile detector class from the experience
        this.mobileDetector = this.experience.mobileDetector;
        // Get if the device is a mobile
        isMobile = this.mobileDetector.isMobile;
        // Remove reference
        this.mobileDetector = null;

        // If the device is a mobile
        if(isMobile === true)
        {
            // Listen for touch events
            this.pointer.on('pointerTouch', () =>
            {
                // If the experience wasn't interrupted
                if(!this.experience.INTERRUPTED)
                {
                    // Cast a raycast
                    this.#castRaycast(this.pointer.mouse);
                }
            });
        }

        // Create new instance
        this.instance = {};
        this.instance.insideVideoScreen = false;
        
        // Set default id
        this.instance.id = 0;
        // Create new instance of custom objects
        this.instance.customObjs = {};
        // Create array of NPCs
        this.instance.npcs = [];

        // Initialize the JSON file
        this.#initJSONfile();
    }

    // Private method called to initialize the local JSON object
    #initJSONfile()
    {
        // JSON object containing the stand customization info
        this.instance.info =
        {
            "stand_id": 0,
            "stand_style": "",

            "logo": "",

            "tooltip": 
            {
                "name": "",
                "logo": ""
            },

            "main_color": "",
            "sec_color": "",
            "base_color": "",

            "image_screen_0": "",
            "image_screen_1": "",

            "image_orientation": "h",

            "video_screen_0": "",
            "video_screen_1": "",

            "totem_about": false,
            "totem_brochures": false,
            "totem_huddle": false,
            "totem_video": false,

            "npcs_preset": 0,
            "npcs": [ ]
        };
    }

    // Method called to update a specific object inside the JSON
    updateObjectByName(key, value)
    {
        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // If the NPCs value is a single NPC instead of an array, do an exception
            if(key === "npcs" && Array.isArray(value) === false)
            {
                let gotPlace = false;
                // For each of the NPCs in the array
                for(let i = 0; i < this.instance.info[key].length; i++)
                {
                    // If the NPC being updated was already created
                    if(this.instance.info[key][i].id == value.id)
                    {
                        // Update JSON values
                        this.instance.info[key][i] = value;
                        // Set verifier as true
                        gotPlace = true;
                    }
                }

                // If the NPC being updated is new, update content inside the last array position
                if(gotPlace === false) this.instance.info[key][this.instance.info[key].length] = value;

                // Apply changes to the stand model
                this.#applyChangesToObj(key, value);
            }
            // For all the other situations
            else
            {
                // Update content
                this.instance.info[key] = value;

                // If the stand id is being updated
                if(key === "stand_id") this.#setId(this.instance.info.stand_id);
                // If the stand style is being updated
                else if(key === "stand_style") this.#setStand(this.instance.info.stand_style);
                // Apply changes to the stand model
                else this.#applyChangesToObj(key, this.instance.info[key]);
            }
        }
    }

    // Method called to update many or all of the objects
    updateObjectsByJSON(jsonObj)
    {
        // Set instance JSON file
        if(jsonObj instanceof Object) this.instance.info = jsonObj;
        else
        {
            // Go through all the JSON objects
            Object.keys(this.instance.info).forEach((key) =>
            {
                // Set json parameter
                this.instance.info[key] = jsonObj[key];
            });
        }

        // Set id and tooltip info
        if(!this.experience.INTERRUPTED) this.#setId(this.instance.info.stand_id);
        if(!this.experience.INTERRUPTED) this.#setStand(this.instance.info.stand_style);
    }

    // Method called to get the JSON object from the stand
    getJSONObject()
    {
        // Return JSON object
        return this.instance.info;
    }

    #applyChangesToObj(key, value)
    {
        // If the stand model is loaded
        if(this.instance.model !== undefined)
        {
            // Set stand tooltip info
            if(key === 'tooltip')
            {
                this.#setTooltipInfo(value);
            }
            // Set logo
            else if(key === 'logo')
            {
                this.#setLogo(value, false);
            }
            // Set image screen
            else if(key === 'image_screen_0' || key === 'image_screen_1')
            {
                this.#setImage(key.split('_')[2], value, false);
            }
            // Set image screens orientation
            else if(key === 'image_orientation')
            {
                this.#setImageOrientation(value);
            }
            // Set video screen
            else if(key === 'video_screen_0' || key === 'video_screen_1')
            {
                this.#setVideoImage(key.split('_')[2], value, false);
            }
            // Set totem
            else if(key.split('_')[0] === 'totem')
            {
                this.#setTotem(key, value);
            }
            // Set main color
            else if(key === 'main_color')
            {
                this.#setColor(this.instance.customObjs.mainColorMaterials, value);
            }
            // Set secondary color
            else if(key === 'sec_color')
            {
                this.#setColor(this.instance.customObjs.secColorMaterials, value);
            }
            // Set base color
            else if(key === 'base_color')
            {
                this.#setColor(this.instance.customObjs.baseColorMaterials, value);
            }
            // Set NPCs preset
            else if(key === "npcs_preset")
            {
                this.#setNPCPreset(value);
            }
            // Set NPCs
            else if(key === "npcs" || key === "npc")
            {
                // If the sent element is an array
                if(Array.isArray(value) === true)
                {
                    // If the length is above zero
                    if(value.length > 0)
                    {
                        // For each of the values
                        for(let i = 0; i < value.length; i++)
                        {
                            // If the experience wasn't interrupted
                            if(!this.experience.INTERRUPTED)
                            {
                                // If it's under the limit
                                if(i < this.npcsLimit)
                                {
                                    // Get the respective point from the stand
                                    const point = this.instance.customObjs.npcPoints[this.instance.info.npcs_preset][value[i].position];
                                    // Set new NPC
                                    this.#setNPC(point, value[i]);
                                }
                            }
                        }
                    }
                    // If the length is zero
                    else
                    {
                        // If the experience wasn't interrupted
                        if(!this.experience.INTERRUPTED)
                        {
                            // Destroy all existing NPCs
                            for(let i = 0; i < this.instance.npcs.length; i++)
                            {
                                try { this.instance.npcs[i].destroy() } catch(e) { console.log(e) };
                            }
                            this.instance.npcs.length = 0;

                            // Render new shadows
                            this.renderer.instance.shadowMap.needsUpdate = true;
                        }
                    }
                }
                // If the sent element is a single object
                else
                {
                    // If it's under the limit
                    if(value.position < this.npcsLimit)
                    {
                        // If the experience wasn't interrupted
                        if(!this.experience.INTERRUPTED)
                        {
                            // Get the respective point from the stand
                            const point = this.instance.customObjs.npcPoints[this.instance.info.npcs_preset][value.position];
                            // Set NPC
                            this.#setNPC(point, value);
                        }
                    }
                }
            }
        }
        // If the stand model isn't loaded
        else if(key !== 'stand_id' && key !== 'stand_style' && key !== 'tooltip')
        {
            console.log("The stand model hasn't been loaded yet to allow further customization");
        }
    }

    // Method called to set the stand id
    #setId(id)
    {
        try
        {
            // If the id is not valid, throw an exception
            if(id === "" || id === null || id === undefined) throw "'stand_id' value is not valid: " + id;

            // Set instance id
            this.instance.id = id;
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Method called to set the stand tooltip info
    #setTooltipInfo(obj)
    {
        // Create tooltip object
        this.instance.tooltip = {};

        try
        {
            // Set instance tooltip info
            this.instance.tooltip.name = obj.name;
            this.instance.tooltip.logo = obj.logo;
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to create and set up the stand
    #setStand(style)
    {
        try
        {
            // If the section style wasn't loaded yet
            if(this.resources.items["stand_" + style] === undefined)
            {
                // Set the section info
                const source = {
                    name: "stand_" + style,
                    type: "gltfModel",
                    path: "models/stands/stand_" + style + "/stand_" + style + ".gltf"
                };

                // Load the section model
                this.resources.loaders.gltfLoader.load(
                    this.resources.ASSETS_PATH + source.path,
                    (file) =>
                    {
                        // Save the loaded resource
                        this.resources.sourceLoaded(source, file);
                        // Set stand model
                        this.#setStandModel(style);
                    }
                );
            }
            else
            {
                // Set stand model
                this.#setStandModel(style);
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    #setStandModel(style)
    {
        // If an instance already exist
        if(this.instance.model)
        {
            // Dispose old stand model
            if(!this.experience.INTERRUPTED) this.disposeStand();
            // Set new stand
            if(!this.experience.INTERRUPTED) this.updateObjectsByJSON(this.instance.info);
            return;
        }

        // Get model
        this.instance.model = this.resources.items["stand_" + style].scene.clone();

        // Set default materials
        this.instance.customObjs.defaultMaterial = new MeshBasicMaterial({ color: 0x999999, side: DoubleSide });

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Set the totem holograms
            if(!this.instance.glowMaterial) this.#setTotemHolograms();
        }

        // Create raycast
        this.raycaster = new Raycaster();
        // Set interactions variable
        this.instance.interactableObjects = [];
        this.instance.hoveredObject = undefined;

        // Get all the customizable objects
        if(!this.experience.INTERRUPTED) this.#setCustomObjects();

        // Go through all the JSON objects
        Object.keys(this.instance.info).forEach((key) =>
        {
            // Apply changes to the stand model
            if(!this.experience.INTERRUPTED) this.#applyChangesToObj(key, this.instance.info[key]);
        });

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Update materials
            this.#updateMaterials();
            this.updateMaterialsEnvMap();

            // Set the camera default position for this stand
            this.#setCameraDefaultPosition();
        }

        // Get the scene from the experience
        if(!this.scene) this.scene = this.experience.scene;
        // Add to scene
        if(!this.experience.INTERRUPTED)this.scene.add(this.instance.model);

        setTimeout(() =>
        {
            if(this.experience)
            {
                // Trigger event warning that this stand finished loading
                if(!this.experience.INTERRUPTED) this.trigger('loaded3DModel');
            }
        }, 500);

        // Listen for when the orbit controls change (camera moves)
        this.camera.on('controlsChanged', () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Rotate holograms to keep them looking at the camera
                this.instance.activeHolograms.forEach(hologram =>
                {
                    // Prepare variables
                    let baseRotation = Math.PI * 0.5;
                    let rotationDirection = 1;

                    // Mirror the rotation of the holograms in the right
                    if(hologram.position.z < 0)
                    {
                        baseRotation *= -1;
                        if(hologram.children[0].rotation.y > 0) hologram.children[0].rotation.y *= -1;
                    }
            
                    // Set hologram rotation
                    hologram.rotation.y = (this.camera.controls.getAzimuthalAngle() * rotationDirection) + baseRotation;
                });
            }
        });

        // Run basic camera animation and deactivate the loading screen
        if(!this.experience.INTERRUPTED) this.camera.dynamicCameraStart();
    }

    // Private method called to load the texture to the logo screens
    #setLogo(url, generateMipmaps)
    {
        try
        {
            // If the value isn't empty
            if(url !== "" && url !== null && url !== undefined)
            {
                // Load logo texture
                const texture = this.resources.loaders.textureLoader.load(url);
                texture.flipY = false;
                texture.generateMipmaps = generateMipmaps;

                // Set material encoding
                let newMaterial = new MeshBasicMaterial({ map: texture, side: DoubleSide });
                newMaterial.map.encoding = sRGBEncoding;
                newMaterial.encoding = sRGBEncoding;
                newMaterial.map.needsUpdate = true;

                // Set logo image
                this.instance.customObjs.logos.forEach(logo =>
                {
                    // Set material to the logo screen
                    logo.material = newMaterial;
                    logo.material.needsUpdate = true;
                });
            }
            // If the value is empty
            else
            {
                // Set logo image
                this.instance.customObjs.logos.forEach(logo =>
                {
                    // Load default material
                    logo.material = this.instance.customObjs.defaultMaterial;
                    logo.material.needsUpdate = true;
                });
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to load the texture to the image screens
    #setImage(id, url, generateMipmaps)
    {
        try
        {
            // If the value isn't empty
            if(url !== "" && url !== null && url !== undefined)
            {
                // Load texture
                const texture = this.resources.loaders.textureLoader.load(url);
                texture.flipY = false;
                texture.generateMipmaps = generateMipmaps;

                // Set material encoding
                let newMaterial = new MeshBasicMaterial({ map: texture, side: DoubleSide });
                newMaterial.map.encoding = sRGBEncoding;
                newMaterial.encoding = sRGBEncoding;
                // Set material to the image screen
                this.instance.customObjs.imageScreens[id].material = newMaterial;
                this.instance.customObjs.imageScreens[id].material.needsUpdate = true;

                // If this stand supports multiple image screen orientations
                if(this.instance.customObjs.imageScreenVariations.length > 2)
                {
                    // Also set the material to the image screen of same id but opposite orientation
                    this.instance.customObjs.imageScreenVariations.forEach(screen =>
                    {
                        // Get the image from the opposite orientation
                        let name = screen.name.split('_');
                        if(name[1] == id && name[2].split('')[0] != this.instance.info["image_orientation"])
                        {
                            // Set material to the image screen
                            screen.material = newMaterial;
                            screen.material.needsUpdate = true;
                        }
                    });
                }
            }
            // If the value is empty
            else
            {
                // Load default material
                this.instance.customObjs.imageScreens[id].material = this.instance.customObjs.defaultMaterial;
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to activate the correct image screen orientation
    #setImageOrientation(value)
    {
        try
        {
            // If the image orientation value is not valid, throw an exception
            if(value !== "" && value !== null && value !== undefined)
            {
                if(value !== 'h' && value !== 'v') throw "'image_orientation' value is not a valid string ('h' or 'v'): " + value;
                
                // If this stand supports multiple image screen orientations
                if(this.instance.customObjs.imageScreenVariations.length > 2)
                {
                    // Go through all image screens
                    this.instance.customObjs.imageScreenVariations.forEach(screen =>
                    {
                        // Get name and segment
                        let seg, name = screen.name.split('_');
                        if(name[0] == "imageScreen") seg = 2;
                        else if(name[0] == "imageScreenBase") seg = 1;

                        // If the first letter of the orientation matches the new orientation
                        if(name[seg].split('')[0] == value)
                        {
                            // Enable screen
                            screen.visible = true;
                        }
                        // If the first letter of the orientation doesn't match the new orientation
                        else
                        {
                            // Disable screen
                            screen.visible = false;
                        }
                    });
                }
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }
    
    // Private method called to load the video thumbnail to the video screens
    #setVideoImage(id, url, generateMipmaps)
    {
        try
        {
            // If the value isn't empty
            if(url !== "" && url !== null && url !== undefined)
            {
                // Load texture
                const texture = this.resources.loaders.textureLoader.load(url);
                texture.flipY = false;
                texture.generateMipmaps = generateMipmaps;

                // Load play button texture
                const texturePlay = this.resources.items.playButton;
                texturePlay.flipY = false;
                texturePlay.generateMipmaps = generateMipmaps;

                // Set thumbnail material encoding
                let materialThumbnail = new MeshBasicMaterial({ map: texture, side: DoubleSide });
                materialThumbnail.map.encoding = sRGBEncoding;
                materialThumbnail.encoding = sRGBEncoding;
                // Set play material encoding
                let materialPlay = new MeshBasicMaterial({ map: texturePlay, side: DoubleSide, transparent: true });
                materialPlay.map.encoding = sRGBEncoding;
                materialPlay.encoding = sRGBEncoding;
                
                // Create groups to load this materials, from the first pixel to the infinity
                this.instance.customObjs.videoScreens[id].geometry.addGroup( 0, Infinity, 0 );
                this.instance.customObjs.videoScreens[id].geometry.addGroup( 0, Infinity, 1 );
                // Add materials to the screen
                this.instance.customObjs.videoScreens[id].material = [materialThumbnail, materialPlay];
                this.instance.customObjs.videoScreens[id].material.needsUpdate = true;

                // Add screen to the interactable objects array
                this.instance.interactableObjects.push(this.instance.customObjs.videoScreens[id]);
            }
            // If the value is empty
            else
            {
                // Load default material
                this.instance.customObjs.videoScreens[id].material = this.instance.customObjs.defaultMaterial;

                // Remove from the interactable objects array
                this.instance.interactableObjects = this.instance.interactableObjects.filter((value, index, arr) =>
                { 
                    return value.name != this.instance.customObjs.videoScreens[id].name;
                });
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to activate the active totems
    #setTotem(name, bool)
    {
        try
        {
            // If the totem values isn't valid, throw an exception
            if(name !== 'totem_about' && name !== 'totem_brochures' && name !== 'totem_huddle' && name !== 'totem_video') throw "Invalid totem name: " + name;
            if(bool !== false && bool !== true) throw "'" + name + "' value is not a boolean: " + bool;

            // Get id
            let id = 0;
            if(name.split("_")[1] == "brochures") id = 1;
            else if(name.split("_")[1] == "huddle") id = 2;
            else if(name.split("_")[1] == "video") id = 3;

            // If totem is active
            if(bool === true)
            {
                // Enable glow
                this.instance.customObjs.totems[id].children[0].visible = true;

                // Add to active totems array
                this.instance.activeHolograms.push(this.instance.customObjs.totems[id]);
                // Add totem glow to the interactable objects array (as the totem mesh is too small)
                this.instance.interactableObjects.push(this.instance.customObjs.totemsGlow[id]);
                
                // Set 'active' material
                this.instance.customObjs.totems[id].material = new MeshBasicMaterial({ color: 0xffffff, side: DoubleSide });
            }
            // If isn't active
            else if(bool === false)
            {
                // Disable glow
                this.instance.customObjs.totems[id].children[0].visible = false;

                // Remove from the active totems array
                this.instance.activeHolograms = this.instance.activeHolograms.filter((value, index, arr) =>
                {
                    return value.name != this.instance.customObjs.totemsGlow[id].name;
                });

                // Remove from the interactable objects array
                this.instance.interactableObjects = this.instance.interactableObjects.filter((value, index, arr) =>
                {
                    return value.name != this.instance.customObjs.totemsGlow[id].name;
                });
                
                // Set 'inactive' material
                this.instance.customObjs.totems[id].material = new MeshBasicMaterial({ color: 0x808080, side: DoubleSide });
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
        
    }

    // Private method called to set the color code to the colored materials
    #setColor(array, hexcode)
    {
        try
        {
            // If the hexcode is empty, set default value
            if(hexcode !== "" && hexcode !== null && hexcode !== undefined)
            {
                // If the hexcode is on the wrong format
                if(hexcode.slice(0, 1) === '#')
                {
                    // Set to correct format
                    hexcode = '0x' + hexcode.slice(1);
                }

                // Set the color to all materials
                array.forEach(material =>
                {
                    // Set hexcode
                    material.color.setHex(hexcode).convertSRGBToLinear();
                    material.needsUpdate = true;
                });
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to set the NPCs preset
    #setNPCPreset(preset)
    {
        try
        {
            // If the preset value isn't empty
            if(preset !== "" && preset !== null && preset !== undefined)
            {
                // If the preset value isn't valid, throw an exception
                if(preset !== 0 && preset !== 1 && preset !== 2) throw "'npcs_preset' value is not a valid number (0, 1 or 2): " + preset;

                // If there are NPCs on the stand
                if(this.instance.npcs.length > 0)
                {
                    // For each of the NPCs
                    for(let i = 0; i < this.instance.npcs.length; i++)
                    {
                        // Get the current point where the NPC is positioned
                        const currentPoint = this.instance.npcs[i].instance.point;
                        // Get the same point from the preset
                        const newPoint = this.instance.customObjs.npcPoints[preset][this.instance.info.npcs[i].position];

                        // If the current point isn't the same as the new preset point
                        if(currentPoint !== newPoint)
                        {
                            // Update position
                            this.instance.npcs[i].setPosition(newPoint);

                            // Render new shadows
                            this.renderer.instance.shadowMap.needsUpdate = true;
                        }
                    }
                }

                // If there are no NPCs to be created
                if(this.instance.info.npcs.length == 0)
                {
                    // For each of the NPC slots
                    for(let i = 0; i < this.npcsLimit; i++)
                    {
                        // Get a random style
                        let style = Math.floor(Math.random() * (8 - 0 + 1) + 0);

                        // Create a simple JSON object for the NPC
                        const json = {
                            "id": i,
                            "position": i,
                            "style": style,

                            "hair_color": "",
                            "top_color": "",
                            "bottom_color": "",
                            "shoes_color": ""
                        }

                        // Get the respective point from the stand
                        const point = this.instance.customObjs.npcPoints[preset][i];

                        // Create a new NPC
                        this.#setNPC(point, json);

                        // Add NPC to the JSON object
                        this.instance.info.npcs[i] = json;
                    }

                    // Randomize NPCs colors
                    this.randomizeNPCsColors();
                }
            }
            // If the preset value is empty
            else if(this.instance.npcs)
            {
                // Destroy all existing NPCs
                for(let i = 0; i < this.instance.npcs.length; i++)
                {
                    this.instance.npcs[i].destroy();
                }
                this.instance.npcs.length = 0;
            }
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to set the NPCs on their respective spots
    #setNPC(point, json)
    {
        try
        {
            // If the npc values aren't valid, throw exceptions
            if(typeof json !== 'object') throw "'npcs' value is not a JSON object: " + json;
            if(json.id === "" || json.id === null || json.id === undefined) throw "'npcs' 'id' value is not valid: " + json.id;
            if(isNaN(json.position) || json.position === "") throw "'npcs' 'position' value is not a number: " + json.position;
            if(json.position < 0 || json.position > 4) throw "'npcs' 'position' value is not a valid number (0 to 4): " + json.position;

            // Get the array position
            let arrayPos = null;
            // For each of the NPCs in the array
            for(let i = 0; i < this.instance.info["npcs"].length; i++)
            {
                // If the NPC being updated was already created
                if(this.instance.info["npcs"][i].id == json.id) arrayPos = i;
            }
            if(arrayPos === null) throw "'npcs' of 'id': " + json.id + " was not found";

            // If the NPC is being created
            if(this.instance.npcs[arrayPos] === undefined || this.instance.npcs[arrayPos] === null)
            {
                if(json.style !== "" && json.style !== null || json.style !== undefined)
                {
                    // If the npc style isn't valid, throw exceptions
                    if(this.resources.items["npc_" + json.style] === undefined) throw "'npcs' 'style' value is not a valid number (0 to 4): " + json.style;

                    // Create new NPC
                    const npc = new NPC();

                    // If the experience wasn't interrupted
                    if(!this.experience.INTERRUPTED)
                    {
                        // Set style, position and rotation
                        npc.setStyle(json.style);
                        npc.setPosition(point);
                        // Set colors
                        npc.setColor("hair_color", json.hair_color);
                        npc.setColor("top_color", json.top_color);
                        npc.setColor("bottom_color", json.bottom_color);
                        npc.setColor("shoes_color", json.shoes_color);
                    }

                    // Render new shadows
                    this.renderer.instance.shadowMap.needsUpdate = true;

                    // If the NPC is part of a talking group
                    if(npc.instance.talkGroup !== undefined)
                    {
                        // Go through each of the set NPCs
                        this.instance.npcs.forEach(companion =>
                        {
                            // If there is a NPC in the same talk group
                            if(companion.instance.talkGroup === npc.instance.talkGroup)
                            {
                                // Set talking animations to both of them
                                companion.instance.talking = true;
                                npc.instance.talking = true;
                            }
                        });
                    }

                    // Add to the NPCs array
                    this.instance.npcs[arrayPos] = npc;
                }
            }
            // If the NPC is being changed
            else
            {
                // If the style value is valid
                if(json.style !== "" && json.style !== null && json.style !== undefined)
                {
                    // If the NPC style need to be changed
                    if(this.instance.npcs[arrayPos].instance.model.name.split('_')[1] != json.style)
                    {
                        // If the npc style isn't valid, throw exceptions
                        if(this.resources.items["npc_" + json.style] === undefined) throw "'npcs' 'style' value is not a valid number (0 to 4): " + json.style;
                        // Set new style
                        if(!this.experience.INTERRUPTED) this.instance.npcs[arrayPos].setStyle(json.style);
                    }
                    
                    // If the NPC position need to be changed
                    if(this.instance.npcs[arrayPos].instance.position != point.position)
                    {
                        // Call method to change position
                        if(!this.experience.INTERRUPTED) this.instance.npcs[arrayPos].setPosition(point);
                        // Render new shadows
                        this.renderer.instance.shadowMap.needsUpdate = true;
                    }

                    // If the experience wasn't interrupted
                    if(!this.experience.INTERRUPTED)
                    {
                        // Set colors
                        this.instance.npcs[arrayPos].setColor("hair_color", json.hair_color);
                        this.instance.npcs[arrayPos].setColor("top_color", json.top_color);
                        this.instance.npcs[arrayPos].setColor("bottom_color", json.bottom_color);
                        this.instance.npcs[arrayPos].setColor("shoes_color", json.shoes_color);
                    }
                }
                // If the style value isn't valid
                else
                {
                    // Destroy NPC
                    this.instance.npcs[arrayPos].destroy();
                    // Remove NPC from their arrays
                    this.instance.npcs[arrayPos] = null;
                    this.instance.info.npcs[arrayPos] = null;

                    // Render new shadows
                    this.renderer.instance.shadowMap.needsUpdate = true;
                }
            }

            // Verify NPC talking groups
            if(!this.experience.INTERRUPTED) this.#verifyTalkingGroups();
        }
        // Catch errors
        catch(e)
        {
            console.log(e);
        }
    }

    // Private method called to verify if the talking groups are still formed after NPC changes
    #verifyTalkingGroups()
    {
        let groups = [];

        // Go through each of the set NPCs
        this.instance.npcs.forEach(npc =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the element is a NPC
                if(npc !== null && npc !== undefined)
                {
                    // If there is a NPC in the same talk group
                    if(npc.instance.talkGroup !== undefined)
                    {
                        // If the talk group array wasn't formed yet
                        if(groups[npc.instance.talkGroup] == undefined)
                        {
                            // Create the talk group array
                            groups[npc.instance.talkGroup] = [];
                        }

                        // Add NPC to the talk group array
                        groups[npc.instance.talkGroup].push(npc);
                    }
                }
            }
        });

        // For each of the talk groups
        groups.forEach(group =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the group is consisted by one NPC
                if(group.length === 1)
                {
                    // Stop talking
                    group[0].instance.talking = false;
                }
            }
        });
    }

    // Method called to randomize all the NPCs colors
    randomizeNPCsColors()
    {
        // Get the colorize class from the experience
        if(!this.colorize) this.colorize = this.experience.colorize;

        // For each of the NPCs
        for(let i = 0; i < this.instance.npcs.length; i++)
        {
            // Get four random colors
            const colors = this.colorize.randomize();

            // Save to the JSON object
            this.instance.info.npcs[i].hair_color = colors[0];
            this.instance.info.npcs[i].top_color = colors[1];
            this.instance.info.npcs[i].bottom_color = colors[2];
            this.instance.info.npcs[i].shoes_color = colors[3];

            // Set colors to the NPC
            this.instance.npcs[i].setColor("hair_color", this.instance.info.npcs[i].hair_color);
            this.instance.npcs[i].setColor("top_color", this.instance.info.npcs[i].top_color);
            this.instance.npcs[i].setColor("bottom_color", this.instance.info.npcs[i].bottom_color);
            this.instance.npcs[i].setColor("shoes_color", this.instance.info.npcs[i].shoes_color);
        }
    }

    // Private method called to set up the totems hologram effect
    #setTotemHolograms()
    {
        // Glow effect parameters
        this.instance.glowParams = {
            pulse: 0
        };

        // Create materials
        this.instance.glowMaterial = new MeshBasicMaterial({ transparent: true, depthWrite: false, side: DoubleSide });
        this.instance.circlePulseMaterial = new MeshBasicMaterial({ transparent: true, depthWrite: false, side: DoubleSide });
    
        // Load glow texture
        let glowImage = this.resources.items.glowImage;
        this.instance.glowMaterial.map = glowImage;
        this.instance.glowMaterial.map.encoding = sRGBEncoding;
        this.instance.glowMaterial.encoding = sRGBEncoding;

        // Create canvas
        let circlePulseCanvas = document.createElement('canvas');
        let circlePulseCanvasCtx = circlePulseCanvas.getContext('2d');

        // Get image
        let circleMaskImage = this.resources.items.glowMask.image;

        // Set canvas height and width to fit inside the geometry
        circlePulseCanvas.height = circleMaskImage.height;
        circlePulseCanvas.width = circleMaskImage.width;
        const radius = circleMaskImage.height / 2;

        // Create and update texture
        let circleMaskTexture = new Texture(circlePulseCanvas);
        circleMaskTexture.needsUpdate = true;

        // Set glow texture as the material map
        this.instance.circlePulseMaterial.map = circleMaskTexture;
        this.instance.circlePulseMaterial.map.encoding = sRGBEncoding;
        this.instance.circlePulseMaterial.encoding = sRGBEncoding;

        // Tween the pulse animation
        gsap.to(this.instance.glowParams, { pulse: radius + 250, duration: 1.5, repeat: -1, onUpdate: () =>
        {
            // If the experience wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Clear the canvas rendering
                circlePulseCanvasCtx.clearRect(0, 0, circlePulseCanvas.width, circlePulseCanvas.height);

                // Load on top
                circlePulseCanvasCtx.globalCompositeOperation = "source-over";

                // Set path style
                circlePulseCanvasCtx.strokeStyle = "rgb(255, 255, 255)";
                circlePulseCanvasCtx.lineWidth = 16;
        
                // Draw first circle path
                circlePulseCanvasCtx.beginPath();
                circlePulseCanvasCtx.arc(radius, radius, this.instance.glowParams.pulse, 0, 2 * Math.PI);
                circlePulseCanvasCtx.closePath();
                circlePulseCanvasCtx.stroke();

                // Draw second circle path
                circlePulseCanvasCtx.beginPath();
                circlePulseCanvasCtx.arc(radius, radius, Math.max(this.instance.glowParams.pulse - 250, 0), 0, 2 * Math.PI);
                circlePulseCanvasCtx.closePath();
                circlePulseCanvasCtx.stroke();

                // Load only overlayed pixels
                circlePulseCanvasCtx.globalCompositeOperation = "source-in";

                // Draw the glow texture
                circlePulseCanvasCtx.drawImage(circleMaskImage, 0, 0);

                // Update texture
                circleMaskTexture.needsUpdate = true;
            }
        },
        onComplete: () =>
        {
            // Reset variables
            if(!this.experience.INTERRUPTED) this.instance.glowParams.pulse = 0;
        }});
        gsap.ticker.fps(30);
    }

    // Private method called to get all the customizable objects from the stand model
    #setCustomObjects()
    {
        // Reset all variables
        this.instance.activeHolograms = [];
        this.instance.interactableObjects = [];
        this.instance.customObjs.logos = [];
        this.instance.customObjs.imageScreens = [];
        this.instance.customObjs.imageScreenVariations = [];
        this.instance.customObjs.imageScreenBases = [];
        this.instance.customObjs.videoScreens = [];
        this.instance.customObjs.cameraPoints = [];
        this.instance.customObjs.totems = [];
        this.instance.customObjs.totemsGlow = [];
        this.instance.customObjs.mainColorMaterials = [];
        this.instance.customObjs.secColorMaterials = [];
        this.instance.customObjs.baseColorMaterials = [];
        this.instance.customObjs.npcPoints = [[], [], []];

        // Go through all the model's children
        this.instance.model.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof Mesh && child.material instanceof MeshStandardMaterial)
            {
                if(child.geometry) child.geometry = child.geometry.clone();
                if(child.material) child.material = child.material.clone();

                // Get the child's splitted name
                const splitObj = child.name.split("_");
                const splitMat = child.material.name.split("_");

                // Get logo screen
                if(splitObj[0] == "logoScreen")
                {
                    child.material.side = DoubleSide;
                    // Add to logos array
                    this.instance.customObjs.logos.push(child);
                }
                // Set image screen
                else if(splitObj[0] == "imageScreen")
                {
                    child.material.side = DoubleSide;
                    // Add to general array
                    this.instance.customObjs.imageScreenVariations.push(child);

                    // If the stand have multiple image screen orientations
                    if(splitObj.length > 2)
                    {
                        // Add to the image array only the screens in the correct orientation
                        if(splitObj[2].slice(0, 1) == this.instance.info.image_orientation)
                        {
                            this.instance.customObjs.imageScreens[splitObj[1]] = child;
                        }
                        // Disable unused image screens
                        else child.visible = false;
                    }
                    // If the stand doesn't have multiple image screen orientations, add screen to image array directly
                    else this.instance.customObjs.imageScreens[splitObj[1]] = child;
                }
                // Set video screen
                else if(splitObj[0] == "videoScreen")
                {
                    child.material.side = DoubleSide;
                    this.instance.customObjs.videoScreens[splitObj[1]] = child;
                }
                // Set totem material
                else if(splitObj[0] == "totem" && child.material.name != "glowing_circle")
                {
                    // Activate child
                    child.visible = true;

                    // Get id
                    let id = 0;
                    if(child.name.split("_")[1] == "brochures") id = 1;
                    else if(child.name.split("_")[1] == "huddle") id = 2;
                    else if(child.name.split("_")[1] == "video") id = 3;
                    // Add to array
                    this.instance.customObjs.totems[id] = child;
                    
                    // Create the geometry and the material later used by the glow plane
                    let glowGeometry = new PlaneGeometry(1, 1);
                    // Create groups to load this materials, from the first pixel to the infinity
                    glowGeometry.addGroup( 0, Infinity, 0 );
                    glowGeometry.addGroup( 0, Infinity, 1 );

                    // Set glow effect behind the totem
                    const glow = new Mesh();
                    glow.geometry = glowGeometry;
                    glow.material = [ this.instance.glowMaterial, this.instance.circlePulseMaterial ];
                    glow.material.needsUpdate = true;
                    glow.rotation.y = Math.PI * 0.5;

                    // Set glow names
                    glow.material[0].name = "glowing_circle";
                    glow.material[1].name = "glowing_circle";
                    glow.name = child.name;

                    // Set back to avoid clipping
                    if(child.position.z < 0) glow.position.x -= 0.02;
                    else if(child.position.z > 0) glow.position.x += 0.02;

                    // Add glow to the totem mesh
                    child.add(glow);

                    // Prepare hologram variables for the dynamic animation
                    child.scale.set(0, 0, 0);
                    child.material.opacity = 0;

                    // Add to array
                    this.instance.customObjs.totemsGlow[id] = glow;
                    this.instance.customObjs.totemsGlow[id].visible = false;
                }
                // Set main color
                else if(splitMat[0] == "mainColor")
                {
                    this.instance.customObjs.mainColorMaterials.push(child.material);
                }
                // Set secondary color
                else if(splitMat[0] == "secColor")
                {
                    this.instance.customObjs.secColorMaterials.push(child.material);
                }
                // Set base color
                else if(splitMat[0] == "thirdColor")
                {
                    this.instance.customObjs.baseColorMaterials.push(child.material);
                }
            }

            // Set the image orientation
            if(child.name.split("_")[0] == "imageScreenBase")
            {
                this.instance.customObjs.imageScreenVariations.push(child);
                // Disable the unused base
                if(child.name.split("_")[1].split('')[0] != this.instance.info["image_orientation"]) child.visible = false;
            }
            // Set the NPC points
            else if(child.name.split("_")[0] == "npcPoint")
            {
                const type = child.name.split("_")[1];
                const id = child.name.split("_")[2];
                this.instance.customObjs.npcPoints[type][id] = child;
            }
            // Set the camera points
            else if(child.name.includes('camPoint'))
            {
                this.instance.customObjs.cameraPoints.push(child);
            }
        });

        // Get the NPC limit
        this.npcsLimit = this.instance.customObjs.npcPoints[0].length;
    }

    // Set the default camera position for the stand
    #setCameraDefaultPosition()
    {
        let multiplier;
        // Set camera default position related to the stand model
        switch(this.instance.info["stand_style"])
        {
            case "s0":
                multiplier = 0.8;
                this.camera.defaultPosition = new Vector3(14.35, 5.61, 10.86);
            break;
            case "s1":
                multiplier = 0.9;
                this.camera.defaultPosition = new Vector3(16.8, 6.6, 9.5);
            break;
            case "s2":
                multiplier = 0.9;
                this.camera.defaultPosition = new Vector3(15.7, 6.2, 10.34);
            break;
            case "g0":
                multiplier = 1.4;
                this.camera.defaultPosition = new Vector3(12.5, 6.5, -6.85);
            break;
            case "g1":
                multiplier = 0.95;
                this.camera.defaultPosition = new Vector3(11.8, 5.44, -7.45);
            break;
            case "g2":
                multiplier = 1.2;
                this.camera.defaultPosition = new Vector3(9.88, 5.59, -9.74);
            break;
            case "g3":
                multiplier = 1.2;
                this.camera.defaultPosition = new Vector3(10.56, 6.9, -10.66);
            break;
        }

        // Get stand bounding box
        let bbox = new Box3().setFromObject(this.instance.model);
        let size = bbox.getSize(new Vector3());
        // Get bigger size
        let biggerSize = Math.max(size.z, size.y) * multiplier;

        // Calculate the camera distance
        var distance = Math.abs( biggerSize / Math.sin( this.camera.renderCamera.fov ) );
        this.camera.defaultPosition.x = distance;

        // Set controls target
        let middle = (size.y / 2) - 1.5;
        if(this.instance.info["stand_style"] == "s2") middle /= 2.6;
        this.camera.controls.target.set(0, middle, 0);
    }

    // Method called to update the environment map of all elements
    updateMaterialsEnvMap()
    {
        // Go through all the model's children
        this.instance.model.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof Mesh && child.material instanceof MeshStandardMaterial)
            {
                // Set environment map intensity
                child.material.envMapIntensity = this.environment.envMapIntensity;
                child.material.envMapIntensity += 0.2;
            }
        });
    }

    // Private method called to update the model materials
    #updateMaterials()
    {
        if(this.instance.model !== undefined)
        {
            // Go through all the model's children
            this.instance.model.traverse((child) =>
            {
                // If child is a mesh object and their material is a standard material
                if(child instanceof Mesh && child.material)
                {
                    // If the child is not a totem
                    if(child.name.includes("totem") === false)
                    {
                        // Receive and cast shadows
                        child.receiveShadow = true;
                        child.castShadow = true;
                    }
        
                    // Set children material encoding
                    child.material.encoding = sRGBEncoding;
                    if(child.material.map != null) child.material.map.encoding = sRGBEncoding;
                    child.material.needsUpdate = true;

                    // If there is an environment map texture
                    if(this.environment.envMapTexture)
                    {
                        // Set environment map intensity
                        child.material.envMapIntensity = this.environment.envMapIntensity;
                    }
                }
            });

            this.renderer.instance.shadowMap.needsUpdate = true;
        }
    }

    // Method called to interact with a video screen
    videoScreenInteraction(screenId)
    {
        try
        {
            // Set variables
            let minDistance = 100;
            let target;

            // For each of the camera points
            for(let i = 0; i < this.instance.customObjs.cameraPoints.length; i++)
            {
                // Get camera point
                const elem = this.instance.customObjs.cameraPoints[i];

                // If the camera point refers to the clicked screen
                if(elem.name.split('_')[1] == screenId)
                {
                    // Find the shortest camera point to the intersected point
                    if(this.instance.intersectionPoint.distanceTo(elem.position) < minDistance)
                    {
                        minDistance = this.instance.intersectionPoint.distanceTo(elem.position);
                        target = elem;
                    }
                }
            }

            // If a camera point was found
            if(target !== undefined)
            {
                // Set user as interacting
                this.instance.insideVideoScreen = true;

                // Get camera position
                const camPosition = target.children[0].getWorldPosition(new Vector3(0, 0, 0));

                // Animate the camera
                if(!this.experience.INTERRUPTED) this.camera.animateCameraToTarget(camPosition, target.position);
            }
        }
        catch(e) { console.log(e) };
    }

    // Method called to reset the camera after interacting with a video screen
    exitVideoScreenInteraction()
    {
        // If the user was interacting with a video screen
        if(this.instance.insideVideoScreen === true)
        {
            // Reset variable and camera
            this.instance.insideVideoScreen = false;
            if(!this.experience.INTERRUPTED) this.camera.resetCameraPosition();
        }
    }

    // Private method called to cast a new raycast
    #castRaycast()
    {
        // Set raycaster
        this.raycaster.setFromCamera(this.pointer.mouse, this.camera.renderCamera);
        const intersection = this.raycaster.intersectObjects(this.instance.model.children, true);
        let notInteractive = true;

        // If the raycaster have intersected with the stands
        if(intersection.length > 0)
        {
            // Go through all of the intersections
            for(let i = 0; i < this.instance.interactableObjects.length; i++)
            {
                if(intersection[0].object.name === this.instance.interactableObjects[i].name)
                {
                    notInteractive = false;
                    // Set cursor to pointer
                    document.body.style.cursor = "pointer";

                    // If the currently hovered element is different from the previously hovered element
                    if(this.instance.hoveredObject !== intersection[0].object.name)
                    {
                        let data;
                        
                        if(isMobile === false)
                        {
                            // If the previously hovered element isn't empty
                            if(this.instance.hoveredObject !== null && this.instance.hoveredObject !== undefined)
                            {
                                data = { 'hoveredElem': this.instance.hoveredObject };

                                // Trigger event once to signal that the stand isn't being hovered anymore
                                const stoppedHoverEvent = new CustomEvent( 'stoppedHoveringElem', { detail: data } );
                                window.novvaC4.eventTarget.dispatchEvent(stoppedHoverEvent);
                            }
                        }

                        // Get the visible intersected object
                        this.instance.hoveredObject = intersection[0].object.name;

                        if(isMobile === false)
                        {
                            data = { 'hoveredElem': this.instance.hoveredObject };

                            // Trigger event once to signal that the stand is being hovered
                            const hoverEvent = new CustomEvent( 'hoveringElem', { detail: data } );
                            window.novvaC4.eventTarget.dispatchEvent(hoverEvent);
                        }
                    }

                    // Find the intersection coordinates
                    this.instance.intersectionPoint = intersection[0].point;

                    break;
                }
            }
        }
        // If the raycaster haven't intersected with any stand
        if(intersection.length === 0 || (intersection.length > 0 && notInteractive === true))
        {
            // If the previous hovered element isn't empty
            if(this.instance.hoveredObject !== null && this.instance.hoveredObject !== undefined)
            {
                if(isMobile === false)
                {
                    const data = { 'hoveredElem': this.instance.hoveredObject };

                    // Trigger event once to signal that the stand isn't being hovered anymore
                    const stoppedHoverEvent = new CustomEvent( 'stoppedHoveringElem', { detail: data } );
                    window.novvaC4.eventTarget.dispatchEvent(stoppedHoverEvent);
                }

                // Set cursor to default
                document.body.style.cursor = "default";
                // Reset the intersected object variable
                this.instance.hoveredObject = undefined;
            }
        }
    }

    // Private method called to update the raycast
    #updateRaycast()
    {
        // If the player is moving
        if(this.pointer.mouseMove === true)
        {
            // Reset variable
            this.pointer.mouseMove = false;

            // Cast a raycast
            this.#castRaycast();
        }
    }

    // Method propagated by the experience each tick event
    update()
    {
        if(this.instance.model)
        {
            // If there are active holograms
            if(this.instance.activeHolograms)
            {
                // Animate holograms with a hover effect
                this.instance.activeHolograms.forEach(hologram =>
                {
                    // Get time in secons
                    let time = this.time.elapsed;
                    time = time / 1000;

                    // Set hologram position for a hover effect
                    hologram.position.y = 1.75 + Math.cos(time * 2) * 0.05;
                });
            }

            // Update the raycast
            this.#updateRaycast();

            // If there are active NPCs
            if(this.instance.npcs.length > 0)
            {
                // Get delta time
                delta = Math.min( 0.05, (this.time.delta / 1000) );
                        
                // For each of the NPCs
                this.instance.npcs.forEach(npc =>
                {
                    // If the NPC is an instance of the NPC class
                    if(npc instanceof NPC)
                    {
                        // Update
                        npc.update(delta);
                    }
                });
            }
        }
    }

    // Method called to dispose the stand
    disposeStand()
    {
        // Kill gsap animation
        try { gsap.killTweensOf(this.instance.glowParams) } catch(e) { console.log(e) };

        // If the NPCs array was created
        if(this.instance.npcs instanceof Array)
        {
            // Destroy NPCs
            this.instance.npcs.forEach(npc =>
            {
                try { npc.destroy() } catch(e) { console.log(e) };
            });
            this.instance.npcs.length = 0;
        }
        
        // Dispose the totems and logo elements
        try { this.disposer.disposeElements(this.instance.glowMaterial) } catch(e) { console.log(e) };
        this.instance.glowMaterial = null;
        try { this.disposer.disposeElements(this.instance.circlePulseMaterial) } catch(e) { console.log(e) };
        this.instance.circlePulseMaterial = null;

        // If the active holograms array was created
        if(this.instance.activeHolograms instanceof Array)
        {
            // Dispose the hologram elements
            this.instance.activeHolograms.forEach(hologram =>
            {
                try { this.disposer.disposeElements(hologram) } catch(e) { console.log(e) };
            });
            this.instance.activeHolograms.length = 0;
        }

        // If the interactables array was created
        if(this.instance.interactableObjects instanceof Array)
        {
            // Dispose the interactable objects
            this.instance.interactableObjects.forEach(obj =>
            {
                try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
            });
            this.instance.interactableObjects.length = 0;
        }

        // If the custom objects were created
        if(this.instance.customObjs)
        {
            // If the logo material wascreated
            if(this.instance.customObjs.logos)
            {
                // Dispose the logo screens
                this.instance.customObjs.logos.forEach(obj => {
                    try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
                });
                this.instance.customObjs.logos.length = 0;
            }

            // If the image screens array was created
            if(this.instance.imageScreens instanceof Array)
            {
                // Dispose the custom image screens
                this.instance.customObjs.imageScreens.forEach(obj =>
                {
                    try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
                });
                this.instance.customObjs.imageScreens.length = 0;
            }

            // If the image screen variations array was created
            if(this.instance.imageScreenVariations instanceof Array)
            {
                // Dispose the custom image screens variations
                this.instance.customObjs.imageScreenVariations.forEach(obj =>
                {
                    try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
                });
                this.instance.customObjs.imageScreenVariations.length = 0;
            }
            
            // If the image screen bases array was created
            if(this.instance.imageScreenBases instanceof Array)
            {
                // Dispose the custom image screens bases
                this.instance.customObjs.imageScreenBases.forEach(obj =>
                {
                    try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
                });
                this.instance.customObjs.imageScreenBases.length = 0;
            }
            
            // If the video screen array was created
            if(this.instance.videoScreens instanceof Array)
            {
                // Dispose the custom video screens
                this.instance.customObjs.videoScreens.forEach(obj =>
                {
                    try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
                });
                this.instance.customObjs.videoScreens.length = 0;
            }

            // If the camera points array was created
            if(this.instance.cameraPoints instanceof Array)
            {
                // Dispose the custom video screens
                this.instance.customObjs.cameraPoints.forEach(obj =>
                {
                    try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
                });
                this.instance.customObjs.cameraPoints.length = 0;
            }
            
            // If the totems array was created
            if(this.instance.totems instanceof Array)
            {
                // Dispose the custom totems
                this.instance.customObjs.totems.forEach(obj =>
                {
                    try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
                });
                this.instance.customObjs.totems.length = 0;
            }
            
            // If the totem glows array was created
            if(this.instance.totemsGlow instanceof Array)
            {
                // Dispose the custom totems glow
                this.instance.customObjs.totemsGlow.forEach(obj =>
                {
                    try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
                });
                this.instance.customObjs.totemsGlow.length = 0;
            }
            
            // If the main color materials array was created
            if(this.instance.mainColorMaterials instanceof Array)
            {
                // Dispose the custom main color material
                this.instance.customObjs.mainColorMaterials.forEach(obj =>
                {
                    try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
                });
                this.instance.customObjs.mainColorMaterials.length = 0;
            }
            
            // If the secondary color materials array was created
            if(this.instance.secColorMaterials instanceof Array)
            {
                // Dispose the custom secondary color material
                this.instance.customObjs.secColorMaterials.forEach(obj =>
                {
                    try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
                });
                this.instance.customObjs.secColorMaterials.length = 0;
            }
            
            // If the base color materials array was created
            if(this.instance.baseColorMaterials instanceof Array)
            {
                // Dispose the custom base color material
                this.instance.customObjs.baseColorMaterials.forEach(obj =>
                {
                    try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
                });
                this.instance.customObjs.baseColorMaterials.length = 0;
            }
            
            // If the NPC points array was created
            if(this.instance.npcPoints instanceof Array)
            {
                // Dispose the custom NPC points
                this.instance.customObjs.npcPoints.forEach(point =>
                {
                    point.forEach(obj =>
                    {
                        try { this.disposer.disposeElements(obj) } catch(e) { console.log(e) };
                    });
                    point.length = 0;
                });
                this.instance.customObjs.npcPoints.length = 0;
            }
        }

        // Dispose the model
        try { this.disposer.disposeElements(this.instance.model) } catch(e) { console.log(e) };
        this.instance.model = undefined;

        // Update shadow map
        this.renderer.instance.shadowMap.needsUpdate = true;
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        // Remove listeners
        try { if(isMobile === true) this.pointer.off('pointerTouch') } catch(e) { console.log(e) };
        try { this.camera.off('controlsChanged') } catch(e) { console.log(e) };

        // Dispose the stand
        try { this.disposeStand() } catch(e) { console.log(e) };

        // Reset instance
        isMobile = null;
        this.instance = null;
        this.raycaster = null;

        // Remove references
        this.experience = null;
        this.scene = null;
        this.resources = null;
        this.disposer = null;
        this.renderer = null;
        this.environment = null;
        this.time = null;
        this.pointer = null;
        this.camera = null;
    }
}