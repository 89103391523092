import EventEmitter from './EventEmitter.js';

export default class Time extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();
        
        // Get the current time
        this.start = Date.now();
        this.current = this.start;
        this.elapsed = 0;
        this.delta = 16;

        // Get the FPS
        this.filterStrength = 5;
        this.frameTime = 0;
        this.lastLoop = new Date;
        this.thisLoop;
        this.fps = 60.0;
        this.lowFpsTimer = 0;

        // Wait one frame so the delta isn't equal to 0
        window.requestAnimationFrame(() =>
        {
            // Call the tick method each frame
            this.tick()
        });
    }

    // Method called each frame to update the time variables
    tick()
    {
        // Update time variables
        const currentTime = Date.now();
        this.delta = currentTime - this.current;
        this.current = currentTime;
        this.elapsed = this.current - this.start;

        // Update FPS meters
        const thisFrameTime = (this.thisLoop = new Date) - this.lastLoop;
        this.frameTime += (thisFrameTime - this.frameTime) / this.filterStrength;
        this.lastLoop = this.thisLoop;
        this.fps = (1000 / this.frameTime).toFixed(1);

        // If the FPS is less than ideal
        if(this.fps < 20.0)
        {
            this.lowFpsTimer += (this.delta / 1000);

            // If the FPS has been less than ideal for more than 10 seconds
            if(this.lowFpsTimer > 10.0)
            {
                this.trigger('lowFPSAlert');
                // Reset timer
                this.lowFpsTimer = 0.0;
            }
        }
        // If the FPS is ideal, reset timer
        else this.lowFpsTimer = 0.0;

        // Trigger the tick event
        this.trigger('tick');

        // Request animation frame to keep updating each frame
        window.requestAnimationFrame(() =>
        {
            if(this.stop === undefined) this.tick()
        });
    }

    // Method propagated by the experience to destroy this instance and their listeners
    destroy()
    {
        this.stop = null;

        // Reset variables
        this.start = null;
        this.current = null;
        this.elapsed = null;
        this.delta = null;
        this.filterStrength = null;
        this.frameTime = null;
        this.lastLoop = null;
        this.thisLoop = null;;
        this.fps = null;
    }
}