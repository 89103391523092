import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import Experience from "../Experience.js";
import EventEmitter from "./EventEmitter.js";

export default class Resources extends EventEmitter
{
    // Set constructor
    constructor(sources, ASSETS_PATH, BACKGROUND_OPTION)
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();

        // Get sources
        this.sources = sources;
        this.ASSETS_PATH = ASSETS_PATH;
        this.BACKGROUND_OPTION = BACKGROUND_OPTION;

        // Initialize variables
        this.items = {};
        this.toLoad = this.sources.length - 2;
        this.loaded = 0;

        // Set loaders
        this.#setLoaders();
        // Start loading the resources
        this.#startLoading();
    }

    // Private method called to create and set up the loaders
    #setLoaders()
    {
        // Enable cache
        THREE.Cache.enabled = true;

        // Initialize instance
        this.loaders = {};

        // Create GLTF and DRACO loaders
        this.loaders.gltfLoader = new GLTFLoader();

        // Create texture loader
        this.loaders.textureLoader = new THREE.TextureLoader();
        this.loaders.textureLoader.setCrossOrigin("anonymous");

        // Create cube texture loader
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader();

        // Create audio loader
        this.loaders.audioLoader = new THREE.AudioLoader();
    }

    // Private method called to start loading the resources
    #startLoading()
    {
        if(document.fonts.check("20px Nasalization") === false)
        {
            // If the loading process wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // Set font face
                const font = new FontFace("Nasalization", 'url(./public/fonts/Nasalization.otf)');
                // Load font
                font.load().then(() =>
                {
                    // Add font to the document
                    document.fonts.add(font);
                });
            }
        }

        // Load each source
        for(const source of this.sources)
        {
            // If the background option chosen is the earth landscape, don't load the moon models
            if((source.name === "environmentBackground" || source.name === "environmentMapTexture") && this.BACKGROUND_OPTION !== 0) continue;
            // If the background option chosen is the moon landscape, don't load the earth models
            if((source.name === "environmentBackground_day" || source.name === "environmentBackground_night") && this.BACKGROUND_OPTION === 0) continue;

            // If the loading process wasn't interrupted
            if(!this.experience.INTERRUPTED)
            {
                // If the source is a GLTF model
                if(source.type === 'gltfModel')
                {
                    // Load model
                    this.loaders.gltfLoader.load(
                        this.ASSETS_PATH + source.path,
                        // OnLoad callback
                        (file) =>
                        {
                            // Resource finished loading
                            this.#sourceLoaded(source, file);
                        },
                        // OnProgress callback
                        () => {},
                        // OnError callback
                        (error) => 
                        {
                            console.log("An error happened while loading the GLTF model < " + source.name + " >. See log below.");
                            console.log(error);
                        }
                    );
                }
                // If the source is a texture
                else if(source.type === 'texture')
                {
                    // Load texture
                    this.loaders.textureLoader.load(
                        this.ASSETS_PATH + source.path,
                        // OnLoad callback
                        (file) =>
                        {
                            // Resource finished loading
                            this.#sourceLoaded(source, file);
                        },
                        // OnProgress callback
                        () => {},
                        // OnError callback
                        (error) => 
                        {
                            console.log("An error happened while loading the texture < " + source.name + " >. See log below.");
                            console.log(error);
                        }
                    );
                }
                // If the source is a cube texture
                else if(source.type === 'cubeTexture')
                {
                    // Add the assets path to the start of each of the source paths
                    let completeUrls = [];
                    for(let i = 0; i < source.path.length; i++)
                    {
                        let url = this.ASSETS_PATH + source.path[i];
                        completeUrls[i] = url;
                    }

                    // Load array of textures
                    this.loaders.cubeTextureLoader.load(
                        completeUrls,
                        // OnLoad callback
                        (file) =>
                        {
                            // Resource finished loading
                            this.#sourceLoaded(source, file);
                        },
                        // OnProgress callback
                        () => {},
                        // OnError callback
                        (error) => 
                        {
                            console.log("An error happened while loading the cube texture < " + source.name + " >. See log below.");
                            console.log(error);
                        }
                    )
                }
                // If the source is an audio file
                else if(source.type === "audio")
                {
                    // Load audio
                    this.loaders.audioLoader.load(
                        this.ASSETS_PATH + source.path,
                        // OnLoad callback
                        (file) =>
                        {
                            // Resource finished loading
                            this.#sourceLoaded(source, file);
                        },
                        // OnProgress callback
                        () => {},
                        // OnError callback
                        (error) => 
                        {
                            console.log("An error happened while loading the audio track < " + source.name + " >. See log below.");
                            console.log(error);
                        }
                    );
                }
            }
        }
    }

    // Private method called to account the loaded resources
    #sourceLoaded(source, file)
    {
        // If the array of items exist
        if(this.items)
        {
            // Save loaded resource
            this.items[source.name] = file;

            // Add to counter
            this.loaded++;

            // Get the loading percentage
            window.novvaC5.assetsLoadingPercentage = parseFloat((this.loaded * 100) / this.toLoad);

            // If all the resources have been loaded
            if(this.loaded === this.toLoad)
            {
                // Trigger event
                this.trigger('loadedResources');
                this.sources = null;
            }
        }
    }

    // Method propagated by the experience to destroy this instance
    destroy()
    {
        this.sources = null;
        this.items = null;

        this.toLoad = null;
        this.loaded = 0;
        window.novvaC5.assetsLoadingPercentage = 0;

        this.loaders = null;
    }
}