import { AnimationMixer, Mesh, sRGBEncoding } from "three";

import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';

import Experience from "../Experience";

// Time variable
let animHandler;

export default class StationaryNPC
{
    // Set constructor
    constructor()
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.disposer = this.experience.disposer;

        this.instance = {};
    }

    // Method called to set the NPC style
    setStyle(style)
    {
        // Get model from the resources
        this.gltf = this.resources.items['npc_' + style];

        // Clone skinned mesh model
        this.instance.model = SkeletonUtils.clone(this.gltf.scene);
        this.instance.model.name = 'npc_' + style;

        // Rotate child objet to face the correct way
        this.instance.model.children[0].rotateZ(-Math.PI * 0.5);

        // If the experience wasn't interrupted
        if(!this.experience.INTERRUPTED)
        {
            // Add to the scene
            this.scene.add(this.instance.model);
            // Get custom materials
            this.#getMaterials();
            // Update materials
            this.#updateMaterials();
        }
    }

    // Method called to set the NPC position
    setPosition(point)
    {
        // If the point is valid
        if(point !== undefined)
        {
            // Save the point
            this.instance.point = point;
            point.add(this.instance.model);

            // If the NPC is on a chair spot
            if(point.name.includes('seated'))
            {
                // Set as a sitting NPC
                this.instance.sitting = true;

                // Set animations
                this.#setDefaultAnimations();

                // Play seated anim
                this.#startStopAnim(this.animations.seatedIdle, true);
            }
            // If the NPC is on a standing spot
            else
            {
                // Set as a standing NPC
                this.instance.sitting = false;

                // Set animations
                this.#setDefaultAnimations();

                // Play idle anim
                this.#startStopAnim(this.animations.idleBasic, true);
            }

            // If the chosen position is inside a talk group
            if(point.name.includes("talkGroup"))
            {
                // Save the talk group id
                this.instance.talkGroup = point.name.split('_').pop();

                // Set talking animation action
                if(this.instance.sitting === true)
                    this.animations.seatedTalking = this.animations.mixer.clipAction(this.gltf.animations[6]);
                else
                    this.animations.talking = this.animations.mixer.clipAction(this.gltf.animations[3]);
            }
            // If the chosen position is outside a talk group
            else
            {
                // Reset the talk group
                this.instance.talkGroup = undefined;
            }

            this.gltf = null;
        }
    }

    // Method called to set the NPC colors
    setColor(name, hexcode)
    {
        // Get id
        let id = 0;
        if(name === "top_color") id = 1;
        else if(name === "bottom_color") id = 2;
        else if(name === "shoes_color") id = 3;

        // If the hexcode is empty, set default value
        if(hexcode === "" || hexcode === null || hexcode === undefined)
        {
            hexcode = "0x000000";
        }
        // If the hexcode is on the wrong format
        else if(hexcode.slice(0, 1) === '#')
        {
            // Set to correct format
            hexcode = '0x' + hexcode.slice(1);
        }

        // Create array of colors if needed
        if(!this.instance.colors) this.instance.colors = [];

        // Save the hexcode
        this.instance.colors[id] = hexcode;

        // For each of the materials
        this.instance.materials[id].forEach(material =>
        {
            // Set the hexcode to the material
            material.color.setHex(hexcode).convertSRGBToLinear();
            material.needsUpdate = true;
        });
    }

    // Private method called to find the customizable materials
    #getMaterials()
    {
        // Create array of materials
        this.instance.materials = [[], [], [], []];

        // Go through all the model's children
        this.instance.model.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof Mesh && child.material)
            {
                // Clone material to make it unique for this NPC
                child.material = child.material.clone();

                // If the material is the hair color
                if(child.material.name.includes("hair_color"))
                {
                    // Add material to the array
                    this.instance.materials[0].push(child.material);
                }
                // If the material is the shirt color
                else if(child.material.name.includes("top_color"))
                {
                    // Add material to the array
                    this.instance.materials[1].push(child.material);
                }
                // If the material is the pants color
                else if(child.material.name.includes("bottom_color"))
                {
                    // Add material to the array
                    this.instance.materials[2].push(child.material);
                }
                // If the material is the shoes color
                else if(child.material.name.includes("shoes_color"))
                {
                    // Add material to the array
                    this.instance.materials[3].push(child.material);
                }
            }
        });
    }

    // Private method called to set the default animations
    #setDefaultAnimations()
    {
        // Create object
        this.animations = {};
        this.animations.randomize = true;

        // Set animation mixer
        this.animations.mixer = new AnimationMixer(this.instance.model);

        if(this.instance.sitting === true)
        {
            // Set seated animation action
            this.animations.seatedIdle = this.animations.mixer.clipAction(this.gltf.animations[4]);
            this.animations.seatedLooking = this.animations.mixer.clipAction(this.gltf.animations[5]);
        }
        else
        {
            // Set idle animation actions
            this.animations.idleBasic = this.animations.mixer.clipAction(this.gltf.animations[0]);
            this.animations.idleWarm = this.animations.mixer.clipAction(this.gltf.animations[1]);
            this.animations.idleThinking = this.animations.mixer.clipAction(this.gltf.animations[2]);
        }

        // Set animation handler
        animHandler = (e) =>
        {
            // Stop the last animation
            this.#startStopAnim(e.action, false);

            // If the NPS is a seated NPC
            if(this.instance.sitting === true)
            {
                // If the NPS is in a talking group, play talking animation
                if(this.instance.talking === true)
                    this.#startStopAnim(this.animations.seatedTalking, true);
                // If the NPS isn't in a talking group, play idle animation
                else
                    this.#startStopAnim(this.animations.seatedIdle, true);
            }
            // If the NPS is a standing NPC
            else
            {
                // If the NPS is in a talking group, play talking animation
                if(this.instance.talking === true)
                    this.#startStopAnim(this.animations.talking, true);
                // If the NPS isn't in a talking group, play idle animation
                else
                    this.#startStopAnim(this.animations.idleBasic, true);
            }

            // Allow anim randomizations
            this.animations.randomize = true;
        };
        // Set listener
        this.animations.mixer.addEventListener('loop', animHandler);
    }

    // Private method called to update the model materials
    #updateMaterials()
    {
        // Go through all the model's children
        this.instance.model.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof Mesh && child.material)
            {
                // Receive and cast shadows
                child.receiveShadow = true;
                child.castShadow = true;
    
                // Set children material encoding
                child.material.encoding = sRGBEncoding;
                if(child.material.map != null) child.material.map.encoding = sRGBEncoding;
                child.material.needsUpdate = true;
            }
        });
    }

    // Private method called to randomize whitch animation should play
    #randomizeAnims()
    {
        // If the NPS is a seated NPC
        if(this.instance.sitting === true)
        {
            // If the randomization is allowed
            if( Math.random() < 0.003 )
            {
                // Set to false to avoid extra randomizations
                this.animations.randomize = false;

                // Start randomized animation
                this.#startStopAnim(this.animations.seatedLooking, true);

                // Stop last animation
                if(this.instance.talking === true)
                    this.#startStopAnim(this.animations.seatedTalking, false);
                else
                    this.#startStopAnim(this.animations.seatedIdle, false);
            }
        }
        // If the NPS is a standing NPC
        else
        {
            // If the randomization is allowed
            if( Math.random() < 0.003 )
            {
                // Set to false to avoid extra randomizations
                this.animations.randomize = false;

                // Start randomized animation
                if( Math.random() <= 0.5 )
                    this.#startStopAnim(this.animations.idleWarm, true);
                else
                    this.#startStopAnim(this.animations.idleThinking, true);

                // Stop last animation
                if(this.instance.talking === true)
                    this.#startStopAnim(this.animations.talking, false);
                else
                    this.#startStopAnim(this.animations.idleBasic, false);
            }
        }
    }

    // Private method called to start or stop the sent animation
    #startStopAnim(clip, bool)
    {
        // If the animation must be started
        if(bool === true)
        {
            // If the NPC is a seated NPC
            if(this.instance.sitting === true)
            {
                // Reset and play animation
                clip.reset()
                .setEffectiveWeight(1)
                .play();
            }
            // If the NPC is a standing NPC
            else
            {
                // Reset and play animation with fade in effect
                clip.reset()
                .setEffectiveWeight(1)
                .fadeIn(0.5)
                .play();
            }
        }
        // If the animation must be stopped
        else if(bool === false)
        {
            if(this.instance.sitting === true)
            {
                // Fade out animation for a gradual stop
                clip.fadeOut(1);
            }
            else
            {
                // Fade out animation for a gradual stop
                clip.fadeOut(0.5);
            }
        }
    }

    // Method propagated by the stand class each tick event
    update(delta)
    {
        if(this.experience)
        {
            // If the scene is loaded
            if(this.experience.SCENE_LOADED)
            {
                // If the animations are not undefined
                if(this.animations)
                {
                    // Update the animation mixer
                    if(this.animations.mixer) this.animations.mixer.update(delta);
                    // If the animation randomization is allowed, randomize it
                    if(this.animations.randomize === true) this.#randomizeAnims();
                }
            }
        }
    }

    // Method called to destroy the instance
    destroy()
    {
        // If there are animations registered
        if(this.animations)
        {
            // Stop all animation actions
            try { this.animations.mixer.stopAllAction() } catch(e) { console.log(e) };
            try { this.animations.mixer.removeEventListener('loop', animHandler) } catch(e) { console.log(e) };
        }

        // Dispose the old model
        try { this.disposer.disposeElements(this.instance.model) } catch(e) { console.log(e) };

        // Reset instances
        this.instance = {};
        this.animations = {};

        // Remove references
        this.experience = null;
        this.resources = null;
        this.disposer = null;
    }
}