import Experience from './Experience/Experience';

// Create encapsulated object for this experience
if(!window.novvaC5) window.novvaC5 = {};
// Create encapsulated event target
if(!window.novvaC5.eventTarget) window.novvaC5.eventTarget = new EventTarget();

// Experience class instance
let _experience = null;



// Set the render quality
function changeRenderQuality(id)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Change quality
        _experience.updateRenderQuality(id);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.changeRenderQuality = changeRenderQuality;



// Create a new user
function createUser(userId, userName, userAcronym, userColor)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Create new user
        _experience.meetingRoom.createUser(userId, userName, userAcronym, userColor);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.createUser = createUser;



// Remove an existing user
function removeUser(userId)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Remove user
        _experience.meetingRoom.removeUser(userId);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.removeUser = removeUser;



// Set the user image
function addUserImage(userId, url, color, isRepresentative)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Set the user image
        _experience.meetingRoom.addUserImage(userId, url, color, isRepresentative);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.addUserImage = addUserImage;



// Turn off the user image
function removeUserImage(userId, isRepresentative)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Remove user image
        _experience.meetingRoom.removeUserImage(userId, isRepresentative);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.removeUserImage = removeUserImage;



// Set the user video
function addUserVideo(userId, videoTagId, isRepresentative)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Set the user video
        _experience.meetingRoom.addUserVideo(userId, videoTagId, isRepresentative);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.addUserVideo = addUserVideo;



// Turn off the user video
function removeUserVideo(userId, isRepresentative)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Remove user
        _experience.meetingRoom.removeUserVideo(userId, isRepresentative);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.removeUserVideo = removeUserVideo;



// Set the user status
function setUserStatus(userId, userStatus, bool, isRepresentative)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Set status
        _experience.meetingRoom.setUserStatus(userId, userStatus, bool, isRepresentative);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.setUserStatus = setUserStatus;



// Get the user status
function getUserStatus(userId, isRepresentative)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Get status and return
        return _experience.meetingRoom.getUserStatus(userId, isRepresentative);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.getUserStatus = getUserStatus;



// Set all the users robot color
function setUsersRobotColor(hexcode)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Set users robot color
        _experience.meetingRoom.setUsersRobotColor(hexcode);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.setUsersRobotColor = setUsersRobotColor;



// Set the meeting name above the representatives on the TV screen
function setMeetingName(name)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Set the meeting name
        _experience.meetingRoom.setMeetingName(name);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.setMeetingName = setMeetingName;



// Set the meeting REC icon visibility on the TV screen
function setRECIcon(bool)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Set the REC icon to show or hide
        _experience.meetingRoom.setRECIcon(bool);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.setRECIcon = setRECIcon;



// Create a new representative
function createRepresentative(repId, repName, repAcronym, repColor)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Set the new representative
        _experience.meetingRoom.createRepresentative(repId, repName, repAcronym, repColor);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.createRepresentative = createRepresentative;



// Remove an existing representative
function removeRepresentative(repId)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Remove the representative
        _experience.meetingRoom.removeRepresentative(repId);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.removeRepresentative = removeRepresentative;



// Share one of the representative's screen
function shareRepresentativeScreen(videoTagId)
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";
        if(_experience.SCENE_LOADED === false) throw "The 3D scene isn't loaded yet";

        // Share screen video
        _experience.meetingRoom.shareRepresentativeScreen(videoTagId);
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.shareRepresentativeScreen = shareRepresentativeScreen;



// Destroy the experience, but maintain persistend data
function destroyTheExperience()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";

        // Destroy the experience
        _experience.destroy();
        console.log('experience C5 destroyed')
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.destroyTheExperience = destroyTheExperience;



// Completely wipe the experience
function wipeTheExperience()
{
    try
    {
        if(_experience === null) throw "The Experience hasn't started yet";

        // Destroy the experience
        _experience.wipe();
        _experience = null;
        console.log('experience C5 wiped')
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.wipeTheExperience = wipeTheExperience;



// Start the experience
function startTheExperience(parameters)
{
    try
    {
        // If the experience already started
        if(_experience !== null)
        {
            // If all the assets are loaded but the scene is null
            if(_experience.resources.loaded >= _experience.resources.toLoad && _experience.scene === null)
            {
                // Restart the experience
                _experience.restart(parameters);
                console.log('experience C5 restarted')
            }
            else throw "The Experience is already running";
        }
        else
        {
            // Create the experience
            _experience = new Experience(document.querySelector('canvas.webgl'), parameters);
            console.log('experience C5 created')
        }
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.novvaC5.startTheExperience = startTheExperience;



// Function called when the script is loaded
function loadedScript()
{
    // If there is an async init function
    if(window.novvaC5AsyncInit)
    {
        // Call function to init the experience
        window.novvaC5AsyncInit();
    }
    // If there isn't an async init function, log error
    else
    {
        console.log('The "novvaC5AsyncInit" function was not found.');
    }
}
loadedScript();